import React from 'react'
import Attention from '../../../src/assets/attention-6.jpg'

export default function ModalDeleteAnnouncement({
  loader,
  announcement,
  setAnnouncement,
  handleDeleteAnnouncement,
}) {
  return (
    <div>
      {announcement?._id && (
        <div className=" ">
          <div className="relative flex  items-center justify-center text-center py-5 w-[390px] bg-white border border-[rgba(53,53,53,0.1)] rounded-md ">
            <div className="flex flex-col items-center">
              <div>
                <img
                  src={Attention}
                  alt="Erreur image"
                  className="h-[20px] w-[20px]"
                />
              </div>
              <div className="font-bold text-[rgba(0,0,0,0.8)] pt-2">
                {'Confirmez la suppression'}
              </div>
              <div className="text-[15px] font-roboto text-[rgba(0,0,0,0.6)] pt-1">
                {'Voulez-vous vraiment supprimer cette annonce ?'}
              </div>
              <div className="flex gap-2 pt-2">
                <div
                  className=" text-black px-5 text-[15px  rounded-[3px] font-medium py-1 border border-[rgba(53,53,53,0.35)] cursor-pointer"
                  onClick={() => {
                    setAnnouncement(null)
                  }}
                >
                  Annuler
                </div>
                {loader ? (
                  <div className=" object-cover font-medium bg-red-500 text-white px-5 text-[15px]  rounded-[3px] py-1 border-none outline-none cursor-pointer">
                    En cours...
                  </div>
                ) : (
                  <div
                    className=" object-cover font-medium bg-red-500 text-white px-5 text-[15px]  rounded-[3px] py-1 border-none outline-none cursor-pointer"
                    onClick={() => {
                      handleDeleteAnnouncement(announcement)
                    }}
                  >
                    Confimer
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
