import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const createDeliver = async (data) => {
  console.log('data createDeliver : ', data)
  const response = await axios.post(
    `${BASE_URL}/api/delivers/create-deliver`,
    data
  )
  try {
    return response
  } catch (error) {
    console.log(error)
  }
}

export { createDeliver }
