import EmptyImage from '../../assets/blank-profile.png'

const culumns = (navigate) => {
  return [
    { name: 'Name', selector: (row) => row.name, sortable: true },
    {
      name: 'Image',

      selector: (row) => (
        <img
          src={row.icon ? row.icon : EmptyImage}
          alt={row.name}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '100%',
            margin: '7px 0',
            objectFit: 'cover',
          }}
        />
      ),
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description || '-',
      sortable: true,
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          <div
            className="bg-primary text-white px-2 py-1 border-none rounded-[3px] outline-none cursor-pointer"
            onClick={() => navigate(`/Account/edit-category/${row._id}`)}
          >
            Modifier
          </div>
          <div
            className="bg-secondary text-primary  px-2 py-1 border-none rounded-[3px] outline-none cursor-pointer"
            onClick={() => navigate(`/Account/detail-category/${row._id}`)}
          >
            Detail
          </div>
        </div>
      ),
      div: true,
    },
  ]
}

export default culumns
