import React from 'react'
import banner from '../assets/banner.jpeg'

export default function Banner() {
  return (
    <div className="block px-[16px] mb-2 pt-[135px] md:pt-2 lg:hidden">
      <img className="rounded-[0.5rem]" src={banner} alt="" />
    </div>
  )
}
