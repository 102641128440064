import React, { useEffect, useState } from 'react'
import { getDeliversValidated } from '../../../services/delivers/get-delivers-validated'
import DataTableComponent from '../../../components/datatable/DataTable'
import columns from '../../../helpers/admins/delivers/colums-show-deliversvalidated'
import RequestDelivers from '../../../components/admin/delivers/request-delivers'
import RequestRefused from '../../../components/admin/delivers/request-refused'
import { refuseRequestDeliver } from '../../../services/delivers/refused-request-deliver'
import ModalRefusedRequest from '../../../components/admin/modals/modal-validate-refused'
import { options } from '../../../helpers/admins/list/optionsmodal'
import MenuDelivers from '../../../components/admin/delivers/menu-delivers'
import ModalDetailDeliver from '../../../components/admin/modals/modal-detail-deliver'

function ListDeliverAccepted() {
  const [delivers, setDelivers] = useState([])
  const [loader, setLoader] = useState(null)
  const [active, setActive] = useState('validated')

  const [isLoader, setIsLoader] = useState(0)
  const [user, setUser] = useState(null)
  const [showModalDecline, setShowModalDecline] = useState(false)
  const [showModalDetail, setShowModalDetail] = useState(false)

  // get list of deliver validate or accepted
  useEffect(() => {
    getDeliversValidated(setDelivers)
    setLoader(delivers)
  }, [isLoader, active])

  console.log('isLoader : ', isLoader)

  const columnsConfig = columns(
    setShowModalDecline,
    setShowModalDetail,
    setUser
  )

  const handleClick = (data) => {
    setActive(data.action)
  }

  // The function to refuse the request of deliver
  // const handleRefuse = () => {
  //   setShowModalDecline(false)
  //   refuseRequestDeliver(user?._id, isLoader, setIsLoader)
  // }

  return (
    <div>
      {/* This modal is showing the detail of deliver man */}
      <ModalDetailDeliver
        showModal={showModalDetail}
        setShowModal={setShowModalDetail}
      />

      {/* This modal is use for refusing the request of deliver */}
      <ModalRefusedRequest
        showModal={showModalDecline}
        setShowModal={setShowModalDecline}
        userId={user?._id}
        setIsLoader={setIsLoader}
        isLoader={isLoader}
        // handleClick={handleRefuse}
      />
      <div className="pt-[60px] md:pt-0">
        <div className="card">
          {/* menu list options delivers */}
          <MenuDelivers
            options={options}
            handleClick={handleClick}
            active={active}
          />

          {/* Here we are passing the to finaly component data table */}
          <DataTableComponent
            data={delivers}
            columns={columnsConfig}
            name={'Livreur'}
            loader={loader}
            active={active}
            validated={'validated'}
          />

          {/* the list of requests to become a delivery person */}
          <RequestDelivers active={active} request={'request'} />

          {/* the list of requests refused */}
          <RequestRefused active={active} refused={'refused'} />
        </div>
      </div>
    </div>
  )
}

export default ListDeliverAccepted
