// getProductBrands

import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getProductBrands = async (setState, setLoader) => {
  await axios.get(`${BASE_URL}/api/product-brands`).then((response) => {
    try {
      if (response?.data?.status === true) {
        setState(response?.data?.productBrands)
        setLoader(true)
      } else {
        setState([])
      }
    } catch (error) {
      console.error(error)
    }
  })
}

export { getProductBrands }
