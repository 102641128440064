import { IoCallOutline } from 'react-icons/io5'

export default function CallUser({ user, title, isDeliver, color }) {
  if (!user?.phone && !user?.verified_phone) return null
  console.log(
    'phone:',
    user?.verified_phone ? user?.verified_phone : user?.phone
  )
  let callphone = (e) => {
    e.preventDefault()
    let phone = user?.verified_phone ? user?.verified_phone : user?.phone
    window.location.href = `tel:${phone}`
  }
  return (
    <button
      className={`flex items-center text-[12.5px] gap-1 py-1 px-2 rounded-[0.5rem] ${isDeliver ? `${color}` : `${color}`} `}
      onClick={(e) => callphone(e)}
    >
      <IoCallOutline /> {title}
    </button>
  )
}
