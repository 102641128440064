function ControlePhone(contact, setPhoneMobileMoney) {
  const isNumeric = /^[0-9]+$/.test(contact)
  if (
    (contact[0] === '9' || contact[0] === '8') &&
    contact.length === 9 &&
    isNumeric
  ) {
    setPhoneMobileMoney(`243${contact}`)
  } else {
    setPhoneMobileMoney(null)
  }
}

export default ControlePhone
