import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const addLikeAnnouncement = async (userId, announcementId) => {
  let data = await axios.post(
    `${BASE_URL}/api/like-announcement/${userId}/${announcementId}`
  )
  try {
    return data
  } catch (err) {
    console.error(err)
  }
}

export { addLikeAnnouncement }
