function updateSetBrand(brands, category) {
  let result = brands
    ?.filter((item) =>
      item?.categories?.some((categ) => categ?._id === category?._id)
    )
    ?.map((item) => ({ name: item?.name, _id: item._id }))
  return result
}

function updateSetBrandWithId(brands, category) {
  let result = brands
    ?.filter((item) =>
      item?.categories?.some((categ) => categ?._id === category)
    )
    ?.map((item) => ({ name: item?.name, _id: item._id }))
  return result
}

export { updateSetBrand, updateSetBrandWithId }
