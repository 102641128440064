import React from 'react'
import { fistLetterToUpparcase } from '../../helpers/firstLetterToUpperCase'
import { NumericFormat } from 'react-number-format'

function InfoProduct({ announcement }) {
  return (
    <>
      <div className="flex flex-col">
        <h2 className="pt-5 pb-2 text-2xl font-bold text-gray-900 md:text-xl lg:pt-5 lg:text-xl font-roboto">
          {fistLetterToUpparcase(announcement?.name)}
        </h2>
        <div className="flex gap-2 pb-3 md:pb-4 lg:pb-3">
          <span className="text-xl text-gray-800 md:pt-2 font-roboto">
            <NumericFormat
              value={announcement?.price}
              displayType={'text'}
              thousandSeparator=" "
            />
            {announcement?.currency?.shortName || 'fc'}
          </span>
        </div>
      </div>
      <div>
        <div className="flex items-center pb-2">
          <div
            className={`text-gray-700 font-normal 
                      font-openSans`}
          >
            {/* ${!seemoreDescription && 'w-72 whitespace-nowrap overflow-hidden text-ellipsis'}  */}
            {fistLetterToUpparcase(announcement?.description)}
          </div>
          {/* {announcement?.description?.length > 30 && (
                    <div
                      onClick={() =>
                        setSeemoreDescription(!seemoreDescription)
                      }
                      className={`${seemoreDescription && 'hidden'} ml-1 pt-1 text-sm text-gray-800 font-normal cursor-pointer font-roboto`}
                    >
                      plus
                    </div>
                  )} */}
        </div>
        <div className="flex items-center pb-2">
          <div>
            {typeof announcement?.seller?.address === 'string' && (
              <div
                className={`text-gray-700 font-normal 
                          font-openSans`}
                // ${!seemoreAdress && 'w-72 whitespace-nowrap overflow-hidden text-ellipsis'}
              >
                {fistLetterToUpparcase(announcement?.seller?.address)}
              </div>
            )}
          </div>
          {/* {announcement?.seller?.address?.length > 30 && (
                    <div
                      onClick={() => setSeemoreAdress(!seemoreAdress)}
                      className={`${seemoreAdress && 'hidden'} ml-2 text-sm text-gray-900 font-normal cursor-pointer font-roboto`}
                    >
                      plus
                    </div>
                  )} */}
        </div>
        <div className="pb-2 font-normal text-gray-700 font-openSans">
          {announcement?.seller?.user?.phone && (
            <div className="text-gray-600">
              +243{announcement?.seller?.user?.phone}
            </div>
          )}
          {announcement?.user?.phone && (
            <div className="font-normal text-gray-700">
              +243{announcement?.user?.phone}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default InfoProduct
