import React, { useState } from 'react'
import { TextInput, Label, Textarea } from 'flowbite-react'
import AddImage from '../AddImage'
import { useCreateShopMutation } from '../../services/shopApi'
import { useSelector, useDispatch } from 'react-redux'
import { ModalLocation } from '../location/ModalLocation'
import { setLocation, setMyShop } from '../../features/user/user'
// import AddressName from '../location/AddressName'
import DisplayAddress from '../location/DisplayAddress'
import { IoCreateOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

export default function MainForm() {
  const navigate = useNavigate()
  const [createShop, { isLoading }] = useCreateShopMutation()

  const dispatch = useDispatch()
  const location = useSelector((state) => state.user.location)
  const currentUser = useSelector((state) => state.user.currentUser)

  const phoneRegex = /^0(8[1-5|8-9]|9[0-5|7-9])[0-9]{7}$/

  const [image, setImage] = useState(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [phone, setPhone] = useState('')
  const [openMap, setOpenMap] = useState(false)
  const [errors, setErrors] = useState({})

  const modifyPhone = (phone) => {
    return '243' + phone.slice(1)
  }

  const validateForm = () => {
    const newErrors = {}

    if (!name) {
      newErrors.name = "Le nom de l'enseigne est obligatoire"
    }

    if (!phone) {
      newErrors.phone = 'Le numéro de téléphone est obligatoire'
    } else if (!phoneRegex.test(phone)) {
      newErrors.phone =
        'Le numéro de téléphone doit être au format 082XXXXXXX, 083XXXXXXX, ...'
    }

    if (!description) {
      newErrors.description = "La description de l'enseigne est obligatoire"
    }

    // You can add more validation rules here for description, etc.

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0 // Return true if no errors
  }

  const shop = {
    name: name,
    description: description,
    phone: modifyPhone(`${phone}`),
    location: location,
    logo: image,
    user: currentUser,
  }

  console.log('data')

  const handleCreate = async () => {
    if (validateForm()) {
      await createShop(shop).then((response) => {
        console.log(response.data)
        dispatch(setMyShop(response.data))
        navigate(`/shop/me/${response?.data?._id}`, {
          state: { key: response?.data },
        })
      })
    }
  }

  return (
    <div className="flex flex-col gap-3 pb-5">
      <div className="flex flex-col gap-2">
        <Label value="Ajoutez le logo de votre enseigne" />
        <AddImage image={image} setImage={setImage} />
      </div>

      <div className="flex flex-col gap-2">
        <Label value="Votre addresse liée à votre actuelle localisation" />
        <div className="flex gap-5">
          <DisplayAddress location={location} />
          <button
            onClick={() => setOpenMap(true)}
            className="flex items-center gap-1 p-2 rounded-[0.5rem] shadow text-sm"
          >
            <IoCreateOutline />
            Modifier
          </button>
        </div>
      </div>

      {/* name */}
      <div className="">
        <div className="mb-2 block">
          <Label htmlFor="name" value="Entrez le nom de votre enseigne" />
        </div>
        <TextInput
          id="name"
          type="text"
          onChange={(e) => {
            e.preventDefault()
            setName(e.target.value)
          }}
          placeholder=""
          required
        />
        {errors.name && <p className="text-red-500">{errors.name}</p>}
      </div>
      {/* phone */}
      <div className="">
        <div className="mb-2 block">
          <Label
            htmlFor="phone"
            value="Entrez le numéro de téléphone de votre enseigne"
          />
        </div>
        <TextInput
          id="phone"
          type="text"
          onChange={(e) => {
            e.preventDefault()
            setPhone(e.target.value)
          }}
          placeholder="0823456777"
          required
        />
        {errors.phone && <p className="text-red-500">{errors.phone}</p>}
      </div>
      <div className="">
        {/* <div className="mb-2 block">
          <Label htmlFor="name" value="Decrivez votre enseigne" />
        </div> */}
        <Textarea
          id="description"
          type="text"
          onChange={(e) => {
            e.preventDefault()
            setDescription(e.target.value)
          }}
          placeholder="Decrivez votre enseigne. Services et autres details"
          required
        />
        {errors.description && (
          <p className="text-red-500">{errors.description}</p>
        )}
      </div>
      <div>
        <button
          className="bg-green-400 p-2 rounded-[0.5rem] text-white"
          onClick={(e) => {
            e.preventDefault()
            handleCreate()
          }}
        >
          {isLoading ? 'creation en cours...' : "Ajouter l'enseigne"}
        </button>
      </div>

      {openMap && (
        <ModalLocation
          openModal={openMap}
          setOpenModal={setOpenMap}
          setLocation={setLocation}
          dispatch={dispatch}
        />
      )}
    </div>
  )
}
