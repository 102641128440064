import { Modal, Accordion } from 'flowbite-react'
import { legalList } from '../utils/LegalListsContent'

export default function Legals({ openModal, setOpenModal }) {
  return (
    <>
      <Modal
        show={openModal}
        className="lg:px-72"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body className="p-5">
          <Accordion>
            {legalList.map((l, index) => {
              return (
                <Accordion.Panel key={index}>
                  <Accordion.Title>{l.title}</Accordion.Title>
                  <Accordion.Content>
                    {l.content.map((c) => {
                      return (
                        <div
                          className="mb-2 text-gray-500 dark:text-gray-400"
                          key={c}
                        >
                          <p className="font-black">{c.title}</p>
                          <p>{c.text}</p>
                        </div>
                      )
                    })}
                  </Accordion.Content>
                </Accordion.Panel>
              )
            })}
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  )
}
