import React from 'react'
import { GrStatusGood } from 'react-icons/gr'

function RequestAccepted({ success, requestDeliverAccepted }) {
  return (
    <div>
      {success === true && requestDeliverAccepted && (
        <div className=" flex flex-col items-center justify-center">
          <h2 className=" font-roboto text-center text-[17px] md:text-[20px] lg:text-[20px] text-[#707070]">
            Felicitation, votre demande est acceptée!
          </h2>
          <div className="w-[100px] h-[100px] md:w-[110px] md:h-[110px] lg:w-[130px] lg:h-[130px] my-4">
            <GrStatusGood className="w-[100px] h-[100px] md:w-[110px] md:h-[110px] lg:w-[130px] lg:h-[130px] text-green-400" />
          </div>
          <div className="text-center text-[#5a5a5a]">
            Cliquez-ici pour commencer la delivraison
          </div>
        </div>
      )}
    </div>
  )
}

export default RequestAccepted
