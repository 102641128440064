import React from 'react'
import Rating from 'react-rating'
import { FaRegStar, FaStar } from 'react-icons/fa6'
import _ from 'lodash'
import UserAddress from '../location/UserAddress'
import { useNavigate } from 'react-router-dom'

const ShopDashInfo = ({ shop }) => {
  const rating = _.mean(shop?.ratings)
  const navigate = useNavigate()

  return (
    <div
      className="relative p-8 md:p-16 text-white rounded-md bg-center bg-cover bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(to right, #108dc7, #ef8e38)`, // Example gradient, customize as you like
      }}
    >
      <div className="relative z-10 flex flex-col md:flex-row items-start md:items-center justify-between">
        {/* Shop Info */}
        <div className="mb-4 md:mb-0 flex items-center">
          <img
            src={shop?.logo}
            alt={`${shop?.name} logo`}
            className="w-16 h-16 md:w-24 md:h-24 rounded-full mr-4"
          />
          <div>
            <h2 className="text-2xl md:text-4xl font-bold">{shop?.name}</h2>
            <div className="flex items-center mt-1">
              <p className="text-sm md:text-base mr-2">
                <Rating
                  value={rating}
                  emptySymbol={
                    <FaRegStar className="text-[30px] text-white font-light" />
                  }
                  fullSymbol={
                    <FaStar className="text-yellow-300 text-[30px]" />
                  }
                  readonly={true}
                />
              </p>
              <p className="text-sm md:text-base">
                {shop?.followers?.length || 0} followers
              </p>
            </div>
            <p className="mt-2 text-sm md:text-base">{shop?.description}</p>
            <UserAddress location={shop?.location?.coordinates} />
          </div>
        </div>

        {/* Buttons */}
        <div className="flex space-y-0 space-x-4">
          <button
            onClick={() =>
              navigate(`/shop/update/${shop?._id}`, {
                state: { key: shop },
              })
            }
            className="bg-white text-black hover:bg-blue-700 hover:text-white font-bold py-2 px-4 rounded"
          >
            Modifier la boutique
          </button>

          {/* <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Voir sur la carte
          </button> */}
        </div>
      </div>
    </div>
  )
}

export default ShopDashInfo
