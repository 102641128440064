import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import _ from 'lodash'
import ItemProduct from '../../components/ItemProduct'
import Nav from '../../components/layout/Nav'
import { useGetAllAnnouncementsQuery } from '../../services/announcementApi'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import LottieLoader from '../../components/LottieLoader'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { useWindowSize } from 'react-use'
import { PaginationLayout, TablePagination } from '../../components/Pagination'

const itemsPerPage = 12
export default function SearchResults() {
  const params = useParams()
  const navigate = useNavigate()

  const { data: announcements, isFetching } = useGetAllAnnouncementsQuery()

  let results = _.filter(announcements, (product) =>
    product.name.toLowerCase().includes(params.id.toLowerCase())
  )

  let totalPages = Math.ceil(results.length / itemsPerPage)

  const [currentPage, setCurrentPage] = useState(0)

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const { width } = useWindowSize()

  const siblingCount = width > 1024 ? 2 : 1

  const PaginationComponent = width > 768 ? PaginationLayout : TablePagination

  if (isFetching)
    return (
      <div className="flex flex-col items-center justify-center sm:h-screen">
        <div>
          <LottieLoader animation={indexLoader} />
        </div>
        <div>Chargement de mes annonces... </div>
      </div>
    )

  if (results.length === 0) {
    return (
      <div>
        <Nav />
        <div className="flex flex-col gap-5 justify-center items-center pt-40">
          <IoAlertCircleOutline className="text-[50px] text-slate-500" />
          <div>{'Aucune correspondance trouvée'}</div>
          <div>
            <button
              className="bg-primary md:mt-0 py-[5px] px-2 md:py-2 md:px-4 text-light text-white text-sm rounded-lg flex items-center"
              onClick={() => {
                navigate('/categories')
              }}
            >
              <span>Voir les produits disponibles</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const items = results.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  )
  return (
    <div>
      <div>
        <Nav />
      </div>
      <div className="pt-32">
        <div className="lg:px-32 md:px-13 px-5 py-5">
          <div className="mb-3">
            <p>
              <span>{_.size(results) + ' '}</span>resultats correspondant à :{' '}
              <span>{params.id}</span>
            </p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {items && <ItemProduct data={items} loader={!isFetching} />}
          </div>
        </div>
        <div className="flex md:overflow-x-auto sm:justify-center mb-7">
          {totalPages > 1 ? (
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages - 1}
              onPageChange={handlePageChange}
              siblingCount={siblingCount}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}
