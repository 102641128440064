import { BASE_URL } from '../../helpers/Root'

const getMeansTransport = async (setTransports) => {
  const response = await fetch(`${BASE_URL}/api/transport/get-means-transport`)
  const transport = await response.json()

  try {
    if (transport.message === 'success') {
      setTransports(transport?.data)
      // setLoader(true)
    }
  } catch (error) {
    console.error(error)
  }
}

export { getMeansTransport }
