import TopBar from './nav-bar/TopBar'
import MainBar from './nav-bar/MainBar'

function Nav() {
  return (
    <div className="fixed top-0 left-0 right-0 z-50">
      <TopBar />
      <MainBar />
    </div>
  )
}

export default Nav
