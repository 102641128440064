import React, { useEffect, useRef, useState } from 'react'
import Footer from '../components/layout/Footer'
// import Nav from '../components/layout/Nav'
import Products from '../components/Products'
// import Hr from '../components/Hr'
import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'
import { setToast } from '../features/toast/toast'
import ToastSuccess from '../components/modal/ToastSuccess'
import axios from 'axios'
import { BASE_URL } from '../helpers/Root'
import { useNavigate, useParams } from 'react-router-dom'
import { setCurrentUser } from '../features/user/user'
import Banner from '../components/Banner'
import { Helmet } from 'react-helmet'
import NavHome from '../components/layout/NavHome'
import ElevatedButton from '../components/home/ElevatedButton'
import ScrollTopPage from '../components/home/ScrollTopPage'
import BannerUty from '../components/home/BannerUty'
import AnnouncementsMoreView from '../components/home/AnnouncementsMoreView'
// import CategorySection from '../components/CategorySection'
import Shops from './Shops'

function Home() {
  const [verify, setVerify] = useState(false)
  const [fixedCall, setFixedCall] = useState(false)
  const [fixedShop, setFixShopCall] = useState(false)
  const bannerUtyRef = useRef(null)

  const dispatch = useDispatch()
  const infoUserRef = useRef(null)

  const currentUser = useSelector((state) => state.user.currentUser)
  const toast = useSelector((state) => state.toast.toastInfo)
  const params = useParams()
  const navigate = useNavigate()

  ReactGA.send({
    hitType: 'pageview',
    page: '/home',
    title: 'home page',
  })

  const message = 'Félicitaions! vous êtes connectés à UTY!'

  const getUser = async (params) => {
    await axios
      .get(`${BASE_URL}/api/auth/getUser/${params.id}`)
      .then((response) => {
        localStorage.setItem('currentUser', JSON.stringify(response.data.user))
        dispatch(setCurrentUser(response.data.user))
        dispatch(setToast(true))

        if (response.data.seller !== null) {
          localStorage.setItem('seller', JSON.stringify(response.data.seller))
        }
        navigate('/')
        window.location.reload()
      })
  }

  useEffect(() => {
    setTimeout(() => {
      setVerify(true)
    }, 800)

    if (verify) {
      if (toast) {
        setTimeout(() => {
          dispatch(setToast(false))
        }, 6000)
      }
    }
  }, [currentUser, toast, verify])

  useEffect(() => {
    if (params.id) {
      getUser(params)
    }
  }, [params])

  useEffect(() => {
    const handleScroll = () => {
      const infoUserElement = infoUserRef.current
      if (infoUserElement) {
        const rect = infoUserElement.getBoundingClientRect()
        if (rect.top < 24 && !fixedCall) {
          setFixedCall(true)
        } else if (window.scrollY === 0 && fixedCall) {
          setFixedCall(false)
        }

        if (rect.top < -600 && !fixedShop) {
          setFixShopCall(true)
        } else if (rect.top >= -600 && fixedShop) {
          setFixShopCall(false)
        }

        if (rect.top < -1550) {
          setFixShopCall(false)
        }
      }
    }

    const debouncedHandleScroll = debounce(handleScroll, 100) // Debounce à 100ms
    window.addEventListener('scroll', debouncedHandleScroll)
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll)
    }
  }, [fixedCall, fixedShop])

  //Clean effect scroll
  const debounce = (func, wait) => {
    let timeout
    return function (...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }

  const executeScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div className="">
      <Helmet>
        <title>Accueil - uty</title>
        <meta
          name="description"
          content="Bienvenue sur la page d'accueil de uty"
        />
      </Helmet>
      <div className="hidden">
        <ToastSuccess toast={toast} verify={verify} message={message} />
      </div>
      <div className="flex flex-col">
        <ElevatedButton />

        <div className={`flex flex-col`} ref={infoUserRef}>
          <div className="w-full bg-inherit">
            <NavHome
              ref={null}
              infoUserRef={infoUserRef}
              fixedCall={fixedCall}
            />
          </div>
        </div>
        <div className="home__body md:-mt-12 md:pt-0 md:pb-0">
          <div className="lg:hidden sm:hidden md:block mt-52">
            <Banner />
          </div>
          <div className="md:hidden sm:block">
            <Banner />
          </div>
          {/* <div className="">
            <CategorySection />
          </div> */}
          <div className="px-[16px] md:px-12 lg:px-16">
            <Shops />
          </div>
          <div>
            <ScrollTopPage
              infoUserRef={infoUserRef}
              fixedCall={fixedCall}
              executeScroll={executeScroll}
            />
          </div>
          <div>
            <Products
              fixedShop={fixedShop}
              ref={null}
              infoUserRef={infoUserRef}
            />
          </div>
          <div ref={bannerUtyRef} className=" lg:pb-10">
            <BannerUty />
          </div>
          <div>
            <AnnouncementsMoreView />
          </div>
          {/* <Hr /> */}
        </div>

        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Home
