import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const updateToken = async (user, data) => {
  console.log(user)
  console.log(data)
  let current_user = Object.assign({}, user)
  if (user && data) {
    console.log(user._id)
    Object.defineProperty(current_user, 'haveTokenFirebase', {
      writable: true,
    })
    await axios
      .patch(`${BASE_URL}/api/users/token-firebase/${user._id}`, {
        tokenFirebase: data,
      })
      .then((response) => {
        console.log(response)

        response.data.tokenFirebase
          ? (current_user.haveTokenFirebase = 'true')
          : ''

        localStorage.setItem('currentUser', JSON.stringify(current_user))
      })
  }
}

export { updateToken }
