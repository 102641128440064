import React from 'react'
import { IoPerson, IoMailOpenOutline } from 'react-icons/io5'
import {
  BsCollection,
  BsBasket3,
  BsCartPlus,
  BsPersonGear,
} from 'react-icons/bs'
import { IoHomeOutline } from 'react-icons/io5'
import { TbSpeakerphone } from 'react-icons/tb'
import { TbCategory2 } from 'react-icons/tb'
import { MdOutlineCategory } from 'react-icons/md'
import { TbUsersGroup } from 'react-icons/tb'
import { FaRegMoneyBill1 } from 'react-icons/fa6'
import { GrDeliver } from 'react-icons/gr'

// import { TiHomeOutline } from "react-icons/ti";

export const SidebarSeller = [
  {
    title: 'Nouvelles commandes',
    path: '/Account/products-wanted',
    icon: <BsCartPlus />,
  },
  {
    title: 'Propositions',
    path: '/Account/propositions',
    icon: <BsCollection />,
  },
]

export const SidebarData = [
  {
    title: 'Accueil',
    path: '/',
    icon: <IoHomeOutline />,
  },
  {
    title: 'Mon profil',
    path: '/Account/profile',
    icon: <BsPersonGear />,
  },
  {
    title: 'Devenir vendeur',
    path: '/Account/seller',
    icon: <IoPerson />,
  },
  {
    title: 'Offres reçus',
    path: '/Account/offers',
    icon: <BsBasket3 />,
  },

  {
    title: 'Conversation',
    path: '/Account/chat',
    icon: <IoMailOpenOutline />,
  },
  {
    title: 'Annonces',
    path: '/Account/announcements',
    icon: <TbSpeakerphone />,
  },
]

export const SidebarAdmin = [
  {
    title: 'Categories',
    path: '/Account/list-categories',
    icon: <TbCategory2 />,
  },
  {
    title: 'Sous categories',
    path: '/Account/list-subcategories',
    icon: <MdOutlineCategory />,
  },
  {
    title: 'Devices',
    path: '/Account/list-currencies',
    icon: <FaRegMoneyBill1 />,
  },
  {
    title: 'Moyens des transports',
    path: '/Account/list-meanstransports',
    icon: <FaRegMoneyBill1 />,
  },
  {
    title: 'Livreurs',
    path: '/Account/list-delivers',
    icon: <GrDeliver />,
  },
  {
    title: 'Utilisateurs',
    path: '/Account/list-users',
    icon: <TbUsersGroup />,
  },
  {
    title: 'Clients et vendeurs en contact',
    path: '/Account/tracker-contacts',
    icon: <TbUsersGroup />,
  },
  {
    title: 'Marques de produits',
    path: '/Account/product-brands',
    icon: <TbUsersGroup />,
  },
  {
    title: 'Types de produits',
    path: '/Account/product-types',
    icon: <TbUsersGroup />,
  },
]
