import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const postCurrency = async (
  data,
  setLoading,
  navigate,
  setErrorMessageResponse
) => {
  await axios
    .post(`${BASE_URL}/api/currencies/add-currency`, data)
    .then(function (response) {
      if (response.data.message === 'success') {
        setLoading(false)
        navigate('/Account/list-currencies')
      }
    })
    .catch(function (error) {
      setLoading(false)
      if (error.response.status === 500) {
        setErrorMessageResponse(error.response.data.message)
      } else {
        setErrorMessageResponse(null)
      }
      console.log(error)
    })
}

export { postCurrency }
