import React from 'react'
import { useSelector } from 'react-redux'
import PhoneInput from './PhoneInput'
import OtpInput from './Otp-input'
import UsernameInput from './UsernameInput'

function Register() {
  const status = useSelector((state) => state.phoneLogin.status)
  //const currentUser = useSelector((state) => state.phoneLogin.user)

  console.log('status', status)
  return (
    <div className="w-[100%] flex flex-col items-center self-center">
      {status == '' && <PhoneInput />}
      {status == 'SENT' && <OtpInput />}
      {status == 'VALID' && <UsernameInput />}
      {status == '' && (
        <div className="py-2">
          <span>ou</span>
        </div>
      )}
    </div>
  )
}

export default Register
