import React from 'react'
import { TextInput, Label } from 'flowbite-react'
import { useCheckCodeMutation } from '../../services/promoApi'
import _ from 'lodash'
export default function PassForm({ setStatus }) {
  const [checkCode, { isLoading }] = useCheckCodeMutation()

  const [code, setCode] = React.useState('')

  const check = async (code) => {
    await checkCode(code).then((response) => {
      if (!_.isEmpty(response.data)) setStatus('checked')
    })
    // if (isSuccess) {
    //   setStatus('checked')
    // }
  }
  return (
    <div className="w-[100%] flex flex-col gap-3">
      <div className="">
        <div className="mb-2 block">
          <Label htmlFor="name" value="Entrez le pass vendeur" />
        </div>
        <TextInput
          id="name"
          type="text"
          onChange={(e) => {
            e.preventDefault()
            setCode(e.target.value)
          }}
          placeholder=""
          required
        />
      </div>
      <button
        className="bg-green-400 p-2 rounded-[0.5rem] text-white w-[95%] md:w-[30%]"
        onClick={() => check(code)}
        disabled={!code || _.isEmpty(code)}
      >
        {isLoading ? 'Verification...' : 'Continuer'}
      </button>
    </div>
  )
}
