import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getAnnouncementsPublished = async (announcementId, setState) => {
  console.log('announcementId : ', announcementId)
  if (announcementId) {
    await axios
      .get(`${BASE_URL}/api/announcements/seller/${announcementId}`)
      .then((response) => {
        setState(response.data)
      })
  }
}

export { getAnnouncementsPublished }
