import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

export default function ImageCarroussel({ images, announcement }) {
  return (
    <>
      {announcement ? (
        <div>
          <Carousel autoPlay infiniteLoop showThumbs={false}>
            {images?.map((image, index) => (
              <div className="item h-[320px] md:h-[400px] " key={index}>
                <img
                  src={image}
                  className="h-[320px] md:h-[300px] rounded object-cover"
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </div>
      ) : (
        // <div className="h-[20px] w-[200px] bg-gray-200"></div>

        <div className="item h-[320px] md:h-[400px] image animated-background bg-gray-200"></div>
      )}
    </>
  )
}
