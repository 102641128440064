import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  onlineusers: [],
}

export const onlineUsersSlice = createSlice({
  name: 'onlineusers',
  initialState,
  reducers: {
    setOnlineUsers: (state, action) => {
      state.onlineusers = action.payload
    },
  },
})

export const { setOnlineUsers } = onlineUsersSlice.actions

export default onlineUsersSlice.reducer
