import React, { useEffect, useState } from 'react'
import UserChat from '../../assets/profil.png'
import { FaArrowLeft } from 'react-icons/fa'
import { getUser } from '../../services/Users/get-user'

function HeaderChat({ currentUser, setChat, onlineUser, chat }) {
  const [user, setUser] = useState(null)
  const userId = chat.members.find((id) => id !== currentUser)

  useEffect(() => {
    if (userId) {
      getUser(userId, setUser)
    }
  }, [userId])

  return (
    <div className=" bg-[#e9e9e9] items-center py-3 px-3 flex gap-4">
      <div className="cursor-pointer text-black" onClick={() => setChat(null)}>
        <FaArrowLeft />
      </div>
      <div className="w-[45px] h-[45px] lg:w-[35px] lg:h-[35px] relative rounded-full ">
        {onlineUser?.find((user) => user?.userId === userId) && (
          <div
            className={
              'h-[8px] w-[8px] bg-green-500  rounded-full absolute left-[38px] bottom-7 lg:left-[31px] lg:bottom-[30px]'
            }
          ></div>
        )}
        <img
          src={user?.image ? user?.image : UserChat}
          alt=""
          className="w-[45px] h-[45px] lg:w-[35px] lg:h-[35px] object-cover object-center rounded-full"
        />
      </div>
      <div>
        <div className="text-[15px] text-md text-[#2b2b2b]">
          {user?.username?.charAt(0).toUpperCase() +
            user?.username?.substring(1)}
        </div>
        <div className="text-[11px] font-light text-green-700">
          {onlineUser?.find((user) => user?.userId === userId) ? (
            <div className=" font-medium text-green-500 text-[11px]">
              {'En line'}
            </div>
          ) : (
            <div className=" font-medium text-[#4b4b4b]">{'Deconnecté(e)'}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeaderChat

/*
<div className="text-[11px] font-light text-[white]">
          {onlineUser?.find((users) => users?.userId === userId) ? (
            <div className="text-green-200 font-medium">{'En lige'}</div>
          ) : (
            <div className="">{'Deconnecté'}</div>
          )}
        </div>
        */
