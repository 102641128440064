import React from 'react'
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaYoutube,
  FaTiktok,
  FaSnapchatGhost,
  FaPinterestP,
} from 'react-icons/fa'
import { FaXTwitter, FaThreads } from 'react-icons/fa6'

function SocialsUty() {
  const socialLinks = [
    {
      icon: <FaWhatsapp />,
      url: '  https://wa.me/243999403012',
    },
    {
      icon: <FaFacebookF />,
      url: 'https://www.facebook.com/profile.php?id=100085612274815',
    },
    {
      icon: <FaXTwitter />,
      url: 'https://x.com/UtyApp/status/1808874453855609118',
    },
    {
      icon: <FaInstagram />,
      url: 'https://www.instagram.com/p/C9ATMX_K5Ub/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
    },
    {
      icon: <FaLinkedinIn />,
      url: 'https://www.linkedin.com/posts/uty-app-703a83315_vieux-pablo-tika-makelele-moyi-eza-makasi-activity-7214637757950750720-Lz-5?utm_source=share&utm_medium=member_desktop ',
    },
    { icon: <FaYoutube />, url: 'https://www.youtube.com/@UtyApp' },
    { icon: <FaTiktok />, url: 'https://www.tiktok.com/@uty.cd' }, // Assuming a standard TikTok link
    { icon: <FaSnapchatGhost />, url: 'https://www.snapchat.com' }, // Assuming a standard Snapchat link
    {
      icon: <FaPinterestP />,
      url: 'https://www.pinterest.com/contactutyapp/_self-profile/',
    },
    { icon: <FaThreads />, url: 'https://www.threads.net/@uty.cd' },
  ]
  return (
    <div className="h-[5vh] bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white flex items-center justify-between px-[16px] lg:px-16">
      <div className="items-center hidden space-x-2 lg:flex">
        <FaWhatsapp className="text-lg" />
        <span className="  text-[16px] font-semibold md:text-base">
          +243 999 403 012
        </span>
      </div>
      <div className="flex justify-between w-full lg:space-x-4">
        {socialLinks.map((link) => (
          <a
            key={link.label} // Add key for better performance
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 hover:text-gray-300"
          >
            {link.icon}
            {link.label && (
              <span className="text-sm font-semibold md:text-base">
                {link.label}
              </span>
            )}
          </a>
        ))}
      </div>
    </div>
  )
}

export default SocialsUty
