import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { createSubcategory } from '../../../services/Subcategories/create-subcategory'

function CreateSubCategory() {
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [icon, setIcon] = useState(null)
  const [errMsgName, setErrMsgName] = useState(null)
  // const [errMsgdescription, setErrMsgdescription] = useState(null)
  const [selectedImg, setSelectedImg] = useState(null)
  const [loading, setLoading] = useState(false)

  const currentUser = useSelector((state) => state.user.currentUser)

  const navigate = useNavigate()

  useEffect(() => {
    if (selectedImg) {
      const reader = new FileReader()
      reader.readAsDataURL(selectedImg)
      reader.onloadend = () => {
        setIcon(reader.result)
      }
    }
  }, [selectedImg])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    console.log('icon : ', icon)

    if (!name) {
      setErrMsgName('Ce champ est obligatoire')
      setLoading(false)
    } else {
      const response = await createSubcategory(
        name,
        icon || null,
        description,
        currentUser?.isAdmin
      )
      if (response.data.message === 'success') {
        setName(null)
        setIcon(null)
        setDescription(null)
        setSelectedImg(null)
        navigate('/Account/list-subcategories')
      }
    }
  }

  return (
    <div className="px-[16px] md:px-0 md:w-[60%] m-auto">
      <h2 className="font-medium text-[17px] pt-[65px] md:pt-0 pb-3">
        Ajouter une sous categorie
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col mb-2">
          <label
            htmlFor=""
            className="text-[rgba(0,0,0,0.8)] text-[13px] font-light flex gap-1"
          >
            Nom
            <sapn className="text-[red] font-medium">*</sapn>
          </label>
          <input
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder=""
            className="outline-none w-full px-2 py-3 md:py-2 border-[rgba(90,86,86,0.08)] border-[1px] mr-1 rounded-[4px] focusInput"
          />
          {!name && (
            <div className="text-[red] font-medium text-[11px] py-0 my-0">
              {errMsgName}
            </div>
          )}
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor=""
            className="text-[rgba(0,0,0,0.8)] text-[13px] font-light flex gap-1"
          >
            Description
            <sapn className="text-[red] font-medium">*</sapn>
          </label>
          <input
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            placeholder=""
            className="outline-none w-full px-2 py-3 md:py-2 border-[rgba(90,86,86,0.08)] border-[1px] mr-1 rounded-[4px] focusInput"
          />
          {/* {!description && (
            <div className="text-[red] font-medium text-[11px] py-0 my-0">
              {errMsgdescription}
            </div>
          )} */}
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor=""
            className="text-[rgba(0,0,0,0.8)] text-[13px] font-light flex gap-1"
          >
            Image
            <sapn className="text-[red] font-medium"></sapn>
          </label>
          <input
            onChange={(e) => setSelectedImg(e.target.files[0])}
            className="block outline-none px-2 py-2 md:py-1 border-[rgba(90,86,86,0.08)] border-[1px] rounded-[4px] focusInput w-full text-sm text-gray-900  border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            id="file"
            type="file"
          />
        </div>
        <div className="">
          <button className="bg-[#3b5998]  text-[15px] hover:bg-[#3b5998] focus:ring-4 focus:outline-none cursor-pointer py-2 md:py-2 px-8 md:px-10 mt-2 rounded-[4px] text-white">
            {loading ? (
              <div className="">Operation...</div>
            ) : (
              <div className="">Valider</div>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default CreateSubCategory
