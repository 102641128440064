import { useState } from 'react'
import Register from '../pages/authentification/Register'
import RegisterModal from './modal/RegisterModal'
import BannerHeader from '../assets/banner/banner.jpeg'

function BannerCategories() {
  const [connecting, setConnecting] = useState(false)
  const [registering, setRegistering] = useState(false)

  return (
    <div className="relative">
      <header className="relative sm:hidden md:hidden lg:block">
        <div
          className="header-container"
          style={{ backgroundImage: `url(${BannerHeader})` }}
        >
          <div className="header-overlay"></div>
          <div className="justify-center px-16 text-center pt-28 header-content">
            <div className="flex flex-col w-full">
              <h1 className="mb-4 text-4xl font-bold">
                Toutes les catégories et leurs annonces
              </h1>
              <p className="text-lg ">Faites vos achats.</p>
            </div>
          </div>
        </div>
        {connecting && (
          <Register
            openModal={connecting}
            setOpenModal={setConnecting}
            setRegistering={setRegistering}
          />
        )}
        {registering && (
          <RegisterModal
            openModal={registering}
            setOpenModal={setRegistering}
          />
        )}
      </header>
    </div>
  )
}

export default BannerCategories
