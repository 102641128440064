import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getChats = async (setUsersChats, currentUser) => {
  const dataChats = await axios.get(`${BASE_URL}/api/chat/find/${currentUser}`)
  try {
    if (dataChats.data.message === 'success') {
      setUsersChats(dataChats.data)
    }
  } catch (err) {
    console.log(err)
  }
}

export { getChats }
