import React, { useEffect, useState } from 'react'
import UpdateFormStart from '../../components/announcement/UpdateFormStart'
import UpdateFormNext from '../../components/announcement/UpdateFromNext'
import { useGetCategoriesQuery } from '../../services/categoryApi'
// import { useGetCurrenciesQuery } from '../../services/currencyApi'
//import { useCreateAnnouncementMutation } from '../../services/announcementApi'
import { useUpdateAnnouncementMutation } from '../../services/announcementApi'
import { useGetAnnouncementQuery } from '../../services/announcementApi'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import LottieLoader from '../../components/LottieLoader'
import Nav from '../../components/layout/Nav'
import { DetailModal } from '../../components/announcement/DetailModal'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { getSubCategories } from '../../services/Subcategories/get-subcategories'
import { getProductBrands } from '../../services/productBrands/get-product-brands'
import { getProductTypes } from '../../services/productTypes/get-product-types'
import { updateSetBrandWithId } from '../../helpers/update_setBrands'
import { updateSetTypeWithId } from '../../helpers/update_setTypes'
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

function UpdateAnnouncement() {
  const params = useParams()

  const {
    data: announcement,
    isFetching,
    isSuccess,
  } = useGetAnnouncementQuery(params.id)

  const { data: categories } = useGetCategoriesQuery()
  // const { data: currencies } = useGetCurrenciesQuery()

  const [name, setName] = useState(null)
  const [price, setPrice] = useState(0)
  const [description, setDescription] = useState(null)
  const [category, setCategory] = useState(null)
  const [currency, setCurrency] = useState(null)
  const [images, setImages] = useState([])
  const [quantity, setQuantity] = useState(0)
  const [newImages, setNewImages] = useState([])
  const [picClick, setPicClick] = useState(false)
  const [imgClicked, setImgClicked] = useState()
  const [imagesToDelete, setImagesToDelete] = useState([])
  const [newcategoryselected, setNewcategoryselected] = useState(null)
  const [newsubcategoryselected, setNewsubcategoryselected] = useState(null)
  const [weightRange, setWeightRange] = useState(null)
  const [rent, setRent] = useState(null)

  const [subcategories, setSubcategories] = useState(null)
  const [subcategory, setSubcategory] = useState(null)
  const [types, setTypes] = useState([])
  const [type, setType] = useState(null)
  const [brands, setBrands] = useState([])
  const [brand, setBrand] = useState(null)

  const [open, setOpen] = useState(false)
  const [announcementId, setAnnouncementId] = useState(null)
  const [next, setNext] = useState(false)
  const [updating, setUpdating] = useState(true)
  const [currencies, setCurrencies] = useState([])

  const [updateAnnouncement, { isLoading }] = useUpdateAnnouncementMutation()

  let newSubCategories = categories?.filter((item) => item?._id === category)[0]
    ?.subcategories

  const getCurrencies = async () => {
    await axios
      .get(`${BASE_URL}/api/currencies`)
      .then((response) => setCurrencies(response.data))
  }

  useEffect(() => {
    getSubCategories(setSubcategories)
    getProductBrands(setBrands)
    getProductTypes(setTypes)
    getCurrencies()
  }, [])

  useEffect(() => {
    setSubcategories(newSubCategories)
  }, [category])

  let newBrands = updateSetBrandWithId(brands, category)
  let newTypes = updateSetTypeWithId(types, subcategory)

  React.useEffect(() => {
    if (isSuccess) {
      setName(announcement?.name || '')
      setPrice(
        _.toNumber(announcement?.price) || _.toNumber(announcement?.rentPrice)
      )
      setDescription(announcement?.description || '')
      setCategory(announcement?.category?._id || '')
      setCurrency(announcement?.currency?._id)
      setImages(announcement?.images || [])
      setQuantity(_.toNumber(announcement?.quantity) || 0)
      setWeightRange(announcement?.weightRange)
      setRent(announcement?.rent)
      setSubcategory(announcement?.subcategory)
      setType(announcement?.type)
      setBrand(announcement?.brand)
    }
  }, [announcement, isSuccess])

  if (isFetching)
    return (
      <div className="flex flex-col items-center justify-center sm:h-screen">
        <div>
          <LottieLoader animation={indexLoader} />
        </div>
        <div>Chargement de mes annonces... </div>
      </div>
    )

  const handleSubmit = async () => {
    const announcementData = {
      name,
      price,
      description,
      category,
      subcategory,
      currency,
      images,
      quantity,
      newImages: newImages,
      imagesToDelete: imagesToDelete,
      weightRange,
      rent,
    }
    try {
      const result = await updateAnnouncement({
        announcement: announcementData,
        id: params.id,
      })
      // console.log('Announcement created:', result)
      if (result) {
        setAnnouncementId(result.data.announcement._id)
        setOpen(true)
      }
    } catch (error) {
      console.error('Failed to create announcement:', error)
    }
  }

  return (
    <div className="">
      <Nav />
      <div className="md:px-[34vw] pt-36 px-5 mb-5">
        <div className="pb-2 font-roboto">
          <span>
            {"Modification de l'annonce"}
            {/* {announcement?._id}{' '} */}
          </span>
        </div>
        {!next && (
          <UpdateFormStart
            name={name}
            setName={setName}
            setCategory={setCategory}
            category={category}
            categories={categories}
            setImages={setImages}
            images={images}
            setImgClicked={setImgClicked}
            setPicClick={setPicClick}
            picClick={picClick}
            imgClicked={imgClicked}
            setNext={setNext}
            announcement={announcement}
            setImagesToDelete={setImagesToDelete}
            setNewImages={setNewImages}
            newImages={newImages}
            setUpdating={setUpdating}
            updating={updating}
            newcategoryselected={newcategoryselected}
            setNewcategoryselected={setNewcategoryselected}
            subcategories={subcategories}
            setSubcategories={setSubcategories}
            subcategory={subcategory}
            setSubcategory={setSubcategory}
            newSubCategories={newSubCategories}
            setNewsubcategoryselected={setNewsubcategoryselected}
            newsubcategoryselected={newsubcategoryselected}
          />
        )}

        {next && (
          <UpdateFormNext
            name={name}
            category={category}
            price={price}
            currency={currency}
            description={description}
            quantity={quantity}
            currencies={currencies}
            setCurrency={setCurrency}
            setQuantity={setQuantity}
            setDescription={setDescription}
            setPrice={setPrice}
            setNext={setNext}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            announcement={announcement}
            newcategoryselected={newcategoryselected}
            setNewcategoryselected={setNewcategoryselected}
            weightRange={weightRange}
            setWeightRange={setWeightRange}
            setRent={setRent}
            rent={rent}
            newBrands={newBrands}
            newTypes={newTypes}
            setBrand={setBrand}
            setType={setType}
            type={type}
            brand={brand}
          />
        )}
      </div>
      {open && (
        <DetailModal
          setOpenModal={setOpen}
          openModal={open}
          announcementId={announcementId}
        />
      )}
    </div>
  )
}

export default UpdateAnnouncement
