// import EmptyImage from '../../assets/blank-profile.png'

// navigate
const culumns = (
  setShowModal,
  setShowModalDecline,
  setShowModalDetail,
  setUser
) => {
  return [
    {
      name: 'Name',
      selector: (row) => (row.user.username ? row.user.username : '-'),
      sortable: true,
    },
    {
      name: 'Téléphone',
      selector: (row) => (row.user.phone ? `+243${row.user.phone}` : '-'),
      sortable: true,
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          <div
            className="bg-secondary text-primary font-roboto  px-2 py-1 border-none rounded-[3px] outline-none cursor-pointer"
            onClick={() => {
              setShowModalDetail(true)
              setUser(row)
            }}
          >
            <svg
              className="w-[17px] h-[17px] text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeWidth="2"
                d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
              />
              <path
                stroke="currentColor"
                strokeWidth="2"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          </div>
          <div
            className="bg-green-500 text-white font-roboto  px-2 py-1 border-none rounded-[3px] outline-none cursor-pointer"
            onClick={() => {
              setShowModal(true)
              setUser(row)
            }}
          >
            <svg
              className="w-[17px] h-[17px] text-white dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </div>
          <div
            className="bg-[#bb3232] text-white font-roboto  px-2 py-1 border-none rounded-[3px] outline-none cursor-pointer"
            onClick={() => {
              setShowModalDecline(true)
              setUser(row)
            }}
          >
            <svg
              className="w-[17px] h-[17px] text-white dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </div>
        </div>
      ),
      div: true,
    },
  ]
}

export default culumns
