import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const postSeen = async (data) => {
  const seen = await axios.patch(`${BASE_URL}/api/seen/add-seen`, data)

  try {
    return seen
  } catch (err) {
    console.error(err)
  }
}

export { postSeen }
