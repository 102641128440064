import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { formatCreatedToday } from '../../helpers/FormatCreatedToday'

let statusChange = (shipment) => [
  {
    title: 'Livraison commandé',
    cardSubtitle: formatCreatedToday(shipment?.createdAt),
    cardTitle: `Livraison commandé par ${shipment?.order?.seller?.username}`,
    status: 'pending',
  },
  {
    title: 'Livraison acceptée',
    cardSubtitle: formatCreatedToday(shipment?.isAcceptedAt),
    cardTitle: `Livraison acceptée par ${shipment?.deliver?.user?.username}`,
    status: 'accepted',
  },
  {
    title: 'Livraison en cours',
    cardSubtitle: formatCreatedToday(shipment?.isPickUpAt),
    cardTitle: `Livraison en cours par ${shipment?.deliver?.user?.username}`,
    status: 'in delivery',
  },
  {
    title: 'Livraison terminée',
    cardSubtitle: formatCreatedToday(shipment?.updatedAt),
    cardTitle: `Livraison terminé et commande livrée`,
    status: 'delivered',
  },
]

export default function AlternateTimeline({ shipment }) {
  return (
    <Timeline position="alternate">
      {statusChange(shipment)?.map((s, index) => {
        return (
          <TimelineItem key={index}>
            <TimelineSeparator>
              {shipment?.status === s.status && <TimelineDot color="primary" />}
              {shipment?.status !== s.status && <TimelineDot />}
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <div>
                {shipment?.status === s.status ? (
                  <p className="font-roboto">{s.title}</p>
                ) : (
                  <p className="text-gray-400 font-roboto">{s.title}</p>
                )}
                {/* <p className="text-gray-400 font-roboto">{s.title}</p> */}
                <p className="text-[12.5px] font-light">{s.cardTitle}</p>
                <p className="text-[12.5px] font-light">{s.cardSubtitle}</p>
              </div>
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  )
}
