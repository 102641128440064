import { Modal } from 'flowbite-react'
import React, { useState } from 'react'
import RegisterByPhoneNumber from '../../components/user/login/phone-number/Register'
import Login from '../../components/modal/login'
import { useSelector, useDispatch } from 'react-redux'
import ReactGA from 'react-ga4'
import { BASE_URL } from '../../helpers/Root'
import emailLogo from '../../assets/icons8-mail-96.png'
import phoneLogo from '../../assets/icons8-dialing-number-96.png'
import { setStatus } from '../../features/user/login-by-phone-number'
import LoginHeader from '../../components/user/login/LoginHeader'
// import { auth, facebook } from '../../firebase'
// import { signInWithPopup } from 'firebase/auth'
// import axios from 'axios'
import { FcGoogle } from 'react-icons/fc'
// import { FaFacebook } from 'react-icons/fa6'

export default function Register({ openModal, setRegistering, setOpenModal }) {
  const dispatch = useDispatch()
  const [signUpMethod, setSignUpMethod] = useState('phone_number')
  const [modalPlacement, setModalPlacement] = useState('center')

  const status = useSelector((state) => state.phoneLogin.status)

  // const facebookLogin = async (provider) => {
  //   ReactGA.event({
  //     category: 'Main clicks',
  //     action: 'Connect by google',
  //   })

  //   const result = await signInWithPopup(auth, provider)

  //   console.log('face res', result)

  //   if (result) {
  //     await axios.post(`${BASE_URL}/api/auth/facebook`, {})
  //   }
  // }

  const googleLogin = async () => {
    ReactGA.event({
      category: 'Main clicks',
      action: 'Connect by google',
    })
    window.location.href = `${BASE_URL}/api/auth/google`
  }

  let SignUpForm
  switch (signUpMethod) {
    case 'phone_number':
      SignUpForm = RegisterByPhoneNumber
      break
    case 'email':
      SignUpForm = Login
      break
    case 'google':
    default:
      SignUpForm = RegisterByPhoneNumber
      break
  }

  React.useEffect(() => {
    setModalPlacement('center')
  }, [])

  React.useEffect(() => {
    if (status == 'CONNECT') {
      setOpenModal(false)
    }
  }, [status])

  return (
    <>
      <Modal
        className="flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
        show={openModal}
        size="md"
        dismissible
        position={modalPlacement}
        popup
        onClose={() => {
          dispatch(setStatus(''))
          setOpenModal(false)
        }}
      >
        <Modal.Header />
        <Modal.Body className="pb-16">
          <div className="flex flex-col items-center">
            <div className="w-[70%]">
              {' '}
              <LoginHeader />
              <SignUpForm
                setRegistering={setRegistering}
                setOpenModal={setOpenModal}
              />
            </div>

            {status == '' && (
              <div className="flex flex-col gap-2 w-[70%]">
                {signUpMethod !== 'email' && (
                  <button
                    className="bg-slate-300 flex justify-center p-2 py-3 w-[100%] text-[15px] rounded-[0.5rem] items-center pl-7 gap-1"
                    onClick={() => setSignUpMethod('email')}
                  >
                    <img className="h-5 w-5" src={emailLogo} alt="" />
                    <div>Entrer par mot de passe</div>
                  </button>
                )}
                {signUpMethod !== 'phone_number' && (
                  <button
                    className="bg-slate-300 flex justify-center text-[15px] p-2 py-3 w-[100%] rounded-[0.5rem] items-center gap-1 px-5"
                    onClick={() => setSignUpMethod('phone_number')}
                  >
                    <img className="h-5 w-5" src={phoneLogo} alt="" />
                    <div>Entrer par numéro de téléphone</div>
                  </button>
                )}
                {signUpMethod !== 'google' && (
                  <button
                    className="bg-slate-300 flex justify-center text-[15px] p-2 py-3 w-[100%] rounded-[0.5rem] gap-1 items-center px-7"
                    onClick={() => {
                      //setSignUpMethod('google')
                      googleLogin()
                    }}
                  >
                    <FcGoogle className="text-[25px]" />
                    <span>Entrer par google</span>
                  </button>
                )}
                {/* <div>
                  <button
                    className="bg-slate-300 flex justify-center text-[15px] p-2 py-3 w-[100%] rounded-[0.5rem] gap-1 items-center px-7"
                    onClick={() => {
                      //setSignUpMethod('google')
                      facebookLogin(facebook)
                    }}
                  >
                    <FaFacebook className="text-blue-700 text-[25px]" />
                    <span>Entrer par facebook</span>
                  </button>
                </div> */}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
