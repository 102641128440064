import React, { useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import { useSelector } from 'react-redux'

mapboxgl.accessToken =
  'pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw'

export default function AddLocation({ setLocation, dispatch }) {
  const fixedLocation = useSelector((state) => state.user.location)
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: fixedLocation,
      zoom: 15,
    })

    const marker2 = new mapboxgl.Marker({
      draggable: true,
      color: 'red',
    })
      .setLngLat(fixedLocation)
      .addTo(map)

    const popup = new mapboxgl.Popup({
      offset: 25,
    })

    function onDragEnd() {
      const lngLat = marker2.getLngLat()
      dispatch(setLocation([lngLat.lng, lngLat.lat]))
      popup.setLngLat(lngLat)
    }

    marker2.on('dragend', onDragEnd)
    marker2.setPopup(popup)

    map.on('dblclick', function (e) {
      marker2.setLngLat(e.lngLat)
      dispatch(setLocation(marker2.getLngLat().toArray()))
    })

    map.on('moveend', function () {
      const lngLat = map.getCenter() // Get the new map center
      marker2.setLngLat(lngLat) // Update marker position
      dispatch(setLocation([lngLat.lng, lngLat.lat])) // Update location in your state
    })

    return () => map.remove()
  }, [])

  return (
    <div className="w-[100%] rounded-[0.5rem]">
      <div id="map" style={{ width: '100%', height: '400px' }} />
    </div>
  )
}
