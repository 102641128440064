import React from 'react'
import { Dropdown } from 'flowbite-react'
import {
  IoChevronDownOutline,
  IoTrashSharp,
  // IoEyeOutline,
  IoEye,
} from 'react-icons/io5'
import { BsCartX } from 'react-icons/bs'
import { formatCreatedToday } from '../../helpers/FormatCreatedToday'
import { useNavigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'

export default function AllProductInCart({
  cart,
  user,
  handleDeleteProductToCart,
  handleUpdateProductQuantity,
  isLoading,
}) {
  const navigate = useNavigate()

  if (isLoading)
    return (
      <div className="flex flex-col items-center justify-center pt-10">
        <LottieLoader animation={indexLoader} />
        <div>Chargement des produits ajoutés au panier...</div>
      </div>
    )

  if (!cart || cart?.products?.length === 0) {
    return (
      <div className="order-2 flex flex-col gap-5 justify-center items-center pt-20">
        <BsCartX className="text-[50px] text-slate-500" />
        <div>{"Vous n'avez pas de produit dans votre panier"}</div>
        <button
          className="bg-primary py-2 px-4 text-white text-sm rounded-lg flex items-center"
          onClick={() => {
            navigate('/categories')
          }}
        >
          <span>Voir les produits disponibles</span>
        </button>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4 mb-3">
      {cart?.products?.length > 0 &&
        cart?.products?.map((product, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-md p-4 flex items-center"
          >
            {/* Image */}
            <div className="mr-4">
              <img
                className="h-24 w-24 rounded-md object-cover"
                src={product.productId.images[0]}
                alt={product.productId.name}
              />
            </div>

            {/* Nom du produit et détails */}
            <div className="flex-grow">
              <h3 className="text-lg font-medium text-gray-900">
                {product.productId.name}
              </h3>
              <p className="text-gray-600">
                <NumericFormat
                  value={product?.productId?.price}
                  displayType={'text'}
                  thousandSeparator=" "
                />{' '}
                {product.productId.currency
                  ? product?.productId.currency.shortName
                  : 'fc'}
              </p>

              {/* Quantité */}
              <div className="mt-2">
                <Dropdown
                  label="Quantité"
                  dismissOnClick={true}
                  renderTrigger={() => (
                    <div className="flex items-center gap-1 shadow rounded-sm py-2 px-1 w-32">
                      Quantité: {product?.quantity}{' '}
                      <IoChevronDownOutline className="" />
                    </div>
                  )}
                >
                  {[...Array(51).keys()].map((value) => (
                    <Dropdown.Item
                      key={value}
                      onClick={() =>
                        handleUpdateProductQuantity(
                          user,
                          product?.productId._id,
                          value
                        )
                      }
                    >
                      {value}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              </div>
            </div>

            {/* Prix total et actions */}
            <div className="flex flex-col items-end ml-4">
              <p className="text-lg font-medium text-gray-900">
                Total:{' '}
                <span>
                  <NumericFormat
                    value={product?.productId?.price * product?.quantity}
                    displayType={'text'}
                    thousandSeparator=" "
                  />{' '}
                  {product.productId.currency
                    ? product?.productId.currency.shortName
                    : 'CDF'}
                </span>
              </p>
              <p className="text-sm text-gray-500 mt-2">
                {formatCreatedToday(cart?.createdAt)}
              </p>

              {/* Boutons d'action */}
              <div className="mt-4 flex items-center gap-2">
                <button
                  onClick={() =>
                    navigate(
                      `/announcements/${product?.productId?._id}/${product?.productId?.category}`
                    )
                  }
                  className="flex items-center gap-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                >
                  <IoEye className="mr-2" />
                  Voir
                </button>
                <button
                  className="flex items-center gap-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                  onClick={(e) =>
                    handleDeleteProductToCart(e, user, product?.productId?._id)
                  }
                >
                  <IoTrashSharp className="mr-2" />
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
