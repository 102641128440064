import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setLocation } from '../features/user/user'
function useCurrentLocation() {
  const dispatch = useDispatch()
  const location = useSelector((state) => state.user.location)
  const [error, setError] = useState(null)

  useEffect(() => {
    const success = (position) => {
      dispatch(
        setLocation([position.coords.longitude, position.coords.latitude])
      )
    }

    if (navigator.geolocation) {
      console.log('location', success)
      navigator.geolocation.getCurrentPosition(success, error)
    } else {
      setError('Geolocation is not supported by this browser.')
    }
  }, [])
  console.log('location', location)
  return null
}

export default useCurrentLocation
