// validate-request-delivers/:id
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const validateRequestDeliver = async (idDeliver, isLoader, setIsLoader) => {
  const response = await axios.patch(
    `${BASE_URL}/api/delivers/validate-request-delivers/${idDeliver}`
  )
  try {
    if (response.status === 200) {
      setIsLoader(isLoader ? false : true)
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

export { validateRequestDeliver }
