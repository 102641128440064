import React from 'react'
import Nav from '../../components/layout/Nav'
import { useParams } from 'react-router-dom'
import {
  useGetOrdersToDeliverQuery,
  useGetDeliveredOrdersQuery,
} from '../../services/orderApi'
import { useGetSellerShipmentsQuery } from '../../services/shipmentApi'
import { useDispatch } from 'react-redux'
import { setDropOffLocation } from '../../features/user/user'
import OrdersToValidate from '../../components/orders/OrdersToValidate'
import OrdersToDeliver from '../../components/orders/OrdersToDeliver'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import DeliveredOrders from '../../components/orders/DeliveredOrders'

const tabs = (orders, data, deliveredOrders, user, isLoading) => [
  {
    name: 'Les nouvelles commandes',
    component: (
      <OrdersToValidate orders={orders} user={user} isLoading={isLoading} />
    ),
    active: orders !== undefined && orders?.length > 0 ? true : false,
  },
  {
    name: 'Les commandes en livraison',
    component: (
      <OrdersToDeliver
        orders={data?.orders}
        user={user}
        isLoading={isLoading}
      />
    ),
    active: orders === undefined || orders?.length === 0 ? true : false,
  },
  {
    name: 'Les commandes livrées',
    component: (
      <DeliveredOrders
        orders={deliveredOrders}
        user={user}
        isLoading={isLoading}
      />
    ),
    active: false,
  },
]

export default function SellerOrders() {
  const params = useParams()
  const dispatch = useDispatch()
  let currentLocation = JSON.parse(localStorage.getItem('currentLocation'))
  const { data: orders, isLoading } = useGetOrdersToDeliverQuery(params.id)
  const { data } = useGetSellerShipmentsQuery(params.id)
  const { data: deliveredOrders } = useGetDeliveredOrdersQuery(params.id)
  console.log('orders:', orders, data?.orders)

  const [activeTab, setActiveTab] = React.useState(() => {
    return tabs(orders, data, deliveredOrders, params.id, isLoading).findIndex(
      (tab) => tab.active === true
    )
  })
  React.useEffect(() => {
    dispatch(setDropOffLocation(currentLocation))
  }, [])
  if (isLoading)
    return (
      <div>
        <Nav />
        <div className="flex flex-col pt-44 items-center justify-center">
          <div>
            <LottieLoader animation={indexLoader} />
          </div>
          <div>Chargement des nouvelles commandes... </div>
        </div>
      </div>
    )

  return (
    <div>
      <Nav />
      <div className="pt-32 lg:px-48 px-5">
        <div className="py-3 lg:text-[20px] font-roboto">
          Mes ventes et commandes à livrer
        </div>
        <div className="flex items-center gap-5 mb-3 md:text-[15px] sm:text-[12.5px] sm:text-center">
          {tabs(orders, data, deliveredOrders, params.id, isLoading).map(
            (tab, index) => (
              <div
                key={index}
                className={
                  activeTab === index
                    ? 'bg-blue-200 rounded-[1rem] px-2 py-1 mb-2 cursor-pointer'
                    : 'tab mb-2 cursor-pointer'
                }
                onClick={() => {
                  setActiveTab(index)
                  //setActiveComponent(tab.component)
                }}
              >
                {tab.name}
              </div>
            )
          )}
        </div>
        <div className="tab-content">
          {
            tabs(orders, data, deliveredOrders, params.id, isLoading)[activeTab]
              .component
          }
        </div>
      </div>
    </div>
  )
}
