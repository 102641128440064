import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const currencyApi = createApi({
  reducerPath: 'currencyApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getCurrencies: builder.query({
      query: () => `api/currencies`,
    }),
  }),
})

export const { useGetCurrenciesQuery } = currencyApi
