import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import columns from '../../../helpers/categories/colums-show-detail'
import DataTableComponent from '../../../components/datatable/DataTable'
import { detailCategory } from '../../../services/Categories/detail-category'

export default function DetailCatgory() {
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(null)

  const navigate = useNavigate()

  const params = useParams()

  //pass navigate param in column
  const columnsConfig = columns()

  useEffect(() => {
    detailCategory(params.id, setCategories, setLoader)
  }, [])

  return (
    <div className="pt-[60px] md:pt-0">
      <div className="card">
        <div className="px-[16px] md:px-0 flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h2 className=" font-bold text-[17px] pb-5">
              {'Categorie ' + categories?.name}
            </h2>
          </div>
          <div className="flex justify-end text-xs gap-2">
            <button
              className="bg-primary text-white px-3 py-1 border-none rounded-[3px] outline-none cursor-pointer"
              onClick={() => navigate(`/Account/create-category/`)}
            >
              Ajouter une category
            </button>
          </div>
        </div>
        <DataTableComponent
          data={categories?.subcategories}
          columns={columnsConfig}
          name={'sous categorie'}
          loader={loader}
        />
      </div>
    </div>
  )
}
