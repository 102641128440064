import React, { useEffect, useState } from 'react'
import CreateFormStart from '../../components/announcement/CreateFormStart'
import CreateFormNext from '../../components/announcement/CreateFormNext'
import { useGetCategoriesQuery } from '../../services/categoryApi'
// import { useGetCurrenciesQuery } from '../../services/currencyApi'
import { useCreateAnnouncementMutation } from '../../services/announcementApi'
import Nav from '../../components/layout/Nav'
import { DetailModal } from '../../components/announcement/DetailModal'
import { useSelector } from 'react-redux'
import ToastAddPhoneNumber from '../../components/modal/ToastAddPhoneNumber'
import AddPhoneNumber from '../../components/user/AddPhoneNumber'
import { getSubCategories } from '../../services/Subcategories/get-subcategories'
import { getProductBrands } from '../../services/productBrands/get-product-brands.js'
import { updateSetBrand } from '../../helpers/update_setBrands.js'
import { getProductTypes } from '../../services/productTypes/get-product-types.js'
import { updateSetType } from '../../helpers/update_setTypes.js'
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

function CreateAnnouncement() {
  const { data: categories } = useGetCategoriesQuery()
  // const { data: currencies } = useGetCurrenciesQuery()
  const [createAnnouncement, { isLoading, isSuccess }] =
    useCreateAnnouncementMutation()
  // const { data } = useGetCurrenciesQuery()
  // let currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const currentSeller = JSON.parse(localStorage.getItem('seller'))
  const currentUser = useSelector((state) => state.user.currentUser)

  const [name, setName] = useState(null)
  const [price, setPrice] = useState(null)
  const [description, setDescription] = useState(null)
  const [subcategories, setSubcategories] = useState([])
  const [subcategory, setSubcategory] = useState(null)
  const [category, setCategory] = useState(null)
  const [currency, setCurrency] = useState(null)
  const [images, setImages] = useState([])
  const [picClick, setPicClick] = useState(false)
  const [imgClicked, setImgClicked] = useState(null)
  const [quantity, setQuantity] = useState(0)
  const [weightRange, setWeightRange] = useState(null)
  const [rent, setRent] = useState(null)
  const [brands, setbrands] = useState([])
  const [types, setTypes] = useState([])
  const [type, setType] = useState(null)
  const [brand, setBrand] = useState(null)

  const [open, setOpen] = useState(false)
  const [announcementId, setAnnouncementId] = useState(null)
  const [showInputPhoneNumber, setShowInputPhoneNumber] = useState(false)

  const [characteristicsData, setCharacteristicsData] = useState({})
  const [currencies, setCurrencies] = useState([])

  const [next, setNext] = useState(false)

  const getCurrencies = async () => {
    await axios
      .get(`${BASE_URL}/api/currencies`)
      .then((response) => setCurrencies(response.data))
  }

  useEffect(() => {
    getSubCategories(setSubcategories)
    getProductBrands(setbrands)
    getProductTypes(setTypes)
    getCurrencies()
  }, [])

  useEffect(() => {
    setSubcategories(category?.subcategories)
  }, [category])

  let newBrands = updateSetBrand(brands, category)
  let newtypes = updateSetType(types, subcategory)

  console.log('newtypes : ', newtypes)
  console.log('newBrands : ', newBrands)

  let categoryId = category ? String(category?._id) : null

  const announcementData = {
    name,
    price,
    description,
    category: categoryId,
    subcategory: subcategory?._id,
    currency,
    images,
    quantity,
    user: currentUser?._id,
    seller: currentSeller ? currentSeller : null,
    weightRange,
    rent: rent?.value,
    brand: brand?._id,
    type: type?._id,
  }

  const handleSubmit = async () => {
    try {
      const result = await createAnnouncement(announcementData)
      if (result) {
        setAnnouncementId(result.data.announcement._id)
        setOpen(true)
      }
    } catch (error) {
      console.error('Failed to create announcement:', error)
    }
  }

  return (
    <div className="">
      <Nav />
      <AddPhoneNumber
        showInputPhoneNumber={showInputPhoneNumber}
        setShowInputPhoneNumber={setShowInputPhoneNumber}
      />
      <div className="md:px-[34vw] px-5 mb-5 pt-36">
        <ToastAddPhoneNumber
          phoneNumber={currentUser?.phone}
          phoneNumberLogin={currentUser?.verified_phone}
          setShowInputPhoneNumber={setShowInputPhoneNumber}
        />
        <div className="pb-2 font-roboto">
          <span>{"Publication d'une annonce"}</span>
        </div>
        {!next && (
          <CreateFormStart
            name={name}
            setName={setName}
            setCategory={setCategory}
            setSubcategory={setSubcategory}
            subcategory={subcategory}
            category={category}
            categories={categories}
            subcategories={subcategories}
            setImages={setImages}
            images={images}
            setImgClicked={setImgClicked}
            setPicClick={setPicClick}
            picClick={picClick}
            imgClicked={imgClicked}
            setNext={setNext}
            setSubcategories={setSubcategories}
          />
        )}

        {next && (
          <CreateFormNext
            name={name}
            category={category}
            price={price}
            currency={currency}
            description={description}
            quantity={quantity}
            currencies={currencies}
            setCurrency={setCurrency}
            setQuantity={setQuantity}
            setDescription={setDescription}
            setPrice={setPrice}
            setNext={setNext}
            handleSubmit={handleSubmit}
            // characteristics={characteristics(category)}
            setCharacteristicsData={setCharacteristicsData}
            characteristicsData={characteristicsData}
            isLoading={isLoading}
            isSuccess={isSuccess}
            setCategory={setCategory}
            categories={setCategory}
            weightRange={weightRange}
            setWeightRange={setWeightRange}
            setRent={setRent}
            rent={rent}
            brands={newBrands}
            brand={brand}
            setBrand={setBrand}
            setType={setType}
            type={type}
            newtypes={newtypes}
            newBrands={newBrands}
          />
        )}
      </div>
      {open && (
        <DetailModal
          setOpenModal={setOpen}
          openModal={open}
          announcementId={announcementId}
        />
      )}
    </div>
  )
}

export default CreateAnnouncement
