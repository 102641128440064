import React, { useState } from 'react'
import Rating from 'react-rating'
import { FaRegStar, FaStar } from 'react-icons/fa6'
import {
  useFollowShopMutation,
  useUnsuscribeShopMutation,
} from '../../services/shopApi'
import { useSelector } from 'react-redux'
import Register from '../../pages/authentification/Register'
import RegisterModal from '../modal/RegisterModal'
import _ from 'lodash'
import UserAddress from '../location/UserAddress'

const ShopInfo = ({ shop }) => {
  const currentUser = useSelector((state) => state.user.currentUser)
  const [followShop, { isLoading }] = useFollowShopMutation()
  const [unsuscribeShop] = useUnsuscribeShopMutation()
  // const [rateShop] = useRateShopMutation()

  const [connecting, setConnecting] = useState(false)
  const [registering, setRegistering] = useState(false)

  const [thisShop, setThisShop] = React.useState(shop)
  const [isFollowed, setIsFollowed] = React.useState(() => {
    if (currentUser && shop?.followers?.includes(currentUser?._id)) {
      return true
    } else {
      return false
    }
  })

  const rating = _.mean(shop?.ratings)

  const follow = async () => {
    let data = {
      id: shop?._id,
      user: currentUser?._id,
    }
    currentUser
      ? await followShop(data).then((response) => {
          console.log('followed:', response.data)
          setThisShop(response?.data)
          setIsFollowed(true)
        })
      : setConnecting(true)
  }

  const stopFollow = async () => {
    let data = {
      id: shop?._id,
      user: currentUser?._id,
    }
    currentUser
      ? await unsuscribeShop(data).then((response) => {
          console.log('followed:', response.data)
          setThisShop(response?.data)
          setIsFollowed(false)
        })
      : setConnecting(true)
  }

  const contact = () => {
    if (currentUser) {
      window.location.href = `tel:${shop?.phone}`
    } else {
      setConnecting(true)
    }
  }
  console.log('shop phone', shop?.phone)
  return (
    <div
      className="relative p-8 md:p-16 text-white rounded-md bg-center bg-cover bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(to right,#108dc7, #ef8e38)`, // Example gradient, customize as you like
      }}
    >
      <div className="relative z-10 flex flex-col md:flex-row items-start md:items-center justify-between">
        {/* Shop Info */}
        <div className="mb-4 md:mb-0 flex md:flex-row flex-col items-center">
          <img
            src={shop?.logo}
            alt={`${shop?.name} logo`}
            className="w-16 h-16 md:w-24 md:h-24 rounded-full mr-4"
          />
          <div>
            <h2 className="text-2xl md:text-4xl font-bold">{shop?.name}</h2>
            <div className="flex items-center mt-1">
              <p className="text-sm md:text-base mr-2">
                <Rating
                  value={rating}
                  emptySymbol={
                    <FaRegStar className="text-[30px] text-white font-light" />
                  }
                  fullSymbol={
                    <FaStar className="text-yellow-300 text-[30px]" />
                  }
                  readonly={true}
                />
              </p>
              <p className="text-sm md:text-base">
                {thisShop?.followers?.length || 0} followers
              </p>
            </div>
            <p className="mt-2 text-sm md:text-base">{shop?.description}</p>
            <UserAddress location={shop?.location?.coordinates} />
          </div>
        </div>

        {/* Buttons */}
        <div className="flex space-y-0 space-x-4">
          <button
            onClick={contact}
            className="bg-white text-black hover:bg-blue-700 hover:text-white font-bold py-2 px-4 rounded"
          >
            Contacter le proprietaire
          </button>
          {isFollowed ? (
            <button
              onClick={stopFollow}
              className="bg-red-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded"
            >
              {isLoading ? 'Se desabonner...' : 'Se desabonner'}
            </button>
          ) : (
            <button
              onClick={follow}
              className="bg-blue-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded"
            >
              {isLoading ? 'Suivre...' : 'Suivre'}
            </button>
          )}

          {/* <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Voir sur la carte
          </button> */}
        </div>
      </div>
      {connecting && (
        <Register
          openModal={connecting}
          setOpenModal={setConnecting}
          setRegistering={setRegistering}
        />
      )}
      {registering && (
        <RegisterModal openModal={registering} setOpenModal={setRegistering} />
      )}
    </div>
  )
}

export default ShopInfo
