import React from 'react'
import { FiSend } from 'react-icons/fi'
import { IoCameraOutline } from 'react-icons/io5'
import InputEmoji from 'react-input-emoji'
import { FiUpload } from 'react-icons/fi'

function SpaceMessage({ setText, text, sendMessage, selectFilesHandler }) {
  // selectFilesHandler
  return (
    <>
      <div className="bg-green-500 hidden">
        <IoCameraOutline className=" " />
      </div>
      <div className="flex px-1 items-center justify-center mt-1 bg-white py-2 lg:py-0 ">
        <InputEmoji
          value={text}
          onChange={setText}
          cleanOnEnter
          onEnter={sendMessage}
          placeholder="Message..."
          keepOpened
        />
        <div className=" mr-5 bg-gary-100 p-[4px] rounded-full hover:bg-gray-200 cursor-pointer relative">
          <FiUpload className="" />
          <input
            type="file"
            className="w-[23px] h-[23px] absolute top-[1px] left-[1px] rounded-full opacity-0 overflow-hidden"
            onChange={selectFilesHandler}
          />
        </div>
        <button
          className="bg-primary  mr-3 px-[12px] rounded-[8px] text-white text-[13px] h-[30px] my-auto"
          type="submit"
          onClick={sendMessage}
        >
          <FiSend />
        </button>
      </div>
    </>
  )
}

export default SpaceMessage
