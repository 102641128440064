import { useEffect } from 'react'
import { useUpdateUserLocationMutation } from '../services/userApi'
import { useDispatch } from 'react-redux'
import { setLocation } from '../features/user/user'

const useLocationUpdate = (user) => {
  const dispatch = useDispatch()
  const [updateUserLocation] = useUpdateUserLocationMutation()

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords

        console.log('Nouvelle latitude:', latitude)
        console.log('Nouvelle longitude:', longitude)

        if (longitude && latitude) {
          dispatch(setLocation([longitude, latitude]))
          updateUserLocation({ user, location: [longitude, latitude] })
          localStorage.setItem(
            'currentLocation',
            JSON.stringify([longitude, latitude])
          )
        }
      },
      (error) => {
        console.error('Erreur de géolocalisation:', error.message)
      }
    )

    return () => {
      navigator.geolocation.clearWatch(watchId)
    }
  }, [])

  return null
}

export default useLocationUpdate
