import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FiMail } from 'react-icons/fi'
import 'react-toastify/dist/ReactToastify.css'
import { IoArrowBack } from 'react-icons/io5'
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

function MailForReset() {
  const navigate = useNavigate()

  const [email, setEmail] = useState(null)
  const [errMsgpassword, setErrMsgpassword] = useState(null)
  const [user, setUser] = useState(false)
  const [loader, setLoader] = useState(false)
  const [verifyEmail, setVerifyEmail] = useState(false)

  const handleSubmit = async (event) => {
    setLoader(true)
    event.preventDefault()

    if (email) {
      console.log('email : ', email)
      await axios
        .post(`${BASE_URL}/api/forgot-password`, {
          email: email,
        })
        .then((response) => {
          console.log('response : ', response)
          if (response.data.message === 'success') {
            setVerifyEmail(true)
            setLoader(false)
            setUser(false)
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            setUser(true)
            setErrMsgpassword(error.response.data.message)
            setLoader(false)
          }
        })
    } else {
      setErrMsgpassword("L'adresse Email est obligatoire")
      setLoader(false)
    }
  }

  return (
    <>
      <div className=" flex bg-uty-signup justify-center h-[100vh] md:justify-center items-center gap-1">
        <div className="px-6 md:px-10 rounded-lg items-center bg-[#f8fafc] pb-10 pt-3 shadow-3xl">
          <div className="flex items-center gap-2 text-center text-primary text-[13px] py-6">
            <IoArrowBack className="text-primary" />
            <Link to={'/home'}>{'Accueil'}</Link>
          </div>
          <h2 className="text-center text-primary font-medium text-2xl">
            {'Mot de passe oublié'}
          </h2>

          <form
            action=""
            onSubmit={(event) => handleSubmit(event)}
            className="px-3"
          >
            <div className="relative flex flex-col mt-10 gap-3 md:mb-5 md:mt-8">
              <div className="relative flex md:bg-white rounded-[2px]">
                <input
                  className="border-[1px] pl-2 pr-6 border-[rgba(90,86,86,0.08)] py-[10px] w-full outline-none text-[15px] text-[rgba(0,0,0,0.67)] md:py-[7px] rounded-[2px]"
                  type={'email'}
                  placeholder="Email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FiMail className="absolute text-primary top-[13px] right-0 text-[12px] md:top-[14px] mr-2" />
              </div>
              {verifyEmail && (
                <div className="text-green-600 text-[15px]">
                  {'Verifiez votre adresse Email'}
                </div>
              )}

              {!email || user ? (
                <div className="text-red-400 text-[12px] text-center">
                  {errMsgpassword}
                </div>
              ) : (
                <div className="text-red-400 text-[13px] text-center"></div>
              )}
              {!verifyEmail ? (
                <div
                  onClick={(event) => handleSubmit(event)}
                  className=" py-[10px] text-white text-[15px] bg-primary text-center md:py-[7px] rounded-[2px]"
                >
                  <button className="" type="submit">
                    {loader ? (
                      <div className="">Vérification...</div>
                    ) : (
                      <div className="">{'Envoyer'}</div>
                    )}
                  </button>
                </div>
              ) : (
                <div
                  onClick={(event) => handleSubmit(event)}
                  className="bg-green-600 py-[10px] text-white text-[15px]  text-center md:py-[7px] rounded-[2px]"
                >
                  <button className="bg-green-600" type="submit" disabled>
                    <div className="">{'Verifiez Email'}</div>
                  </button>
                </div>
              )}
              <Link
                to="/sign-up"
                className="text-end text-blue-600 text-[13px] hover:underline"
              >
                Créer un nouveau compte
              </Link>
              <div className="mt-1 md:mt-8 text-[15px] text-center">
                <p>
                  <span
                    onClick={() => navigate('/sign-up')}
                    className="text-primary mr-1 cursor-pointer underline"
                  >
                    Se connecter
                  </span>
                  <span className="text-[#363434]">si vous avez un compte</span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default MailForReset
