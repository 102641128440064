import { useSelector } from 'react-redux'
import { setIsShowComment } from '../../features/comment/show-comment'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { getAnnouncement } from '../../services/Announcements/getAnnouncement'
import { getCommentByAnnouncement } from '../../services/Comments/getCommentByAnnouncement'
import Moment from 'react-moment'
import InputEmoji from 'react-input-emoji'

import 'moment-timezone'
import Profil from '../../assets/profil.png'
import { IoArrowBack } from 'react-icons/io5'
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'
import { FiSend } from 'react-icons/fi'
// import { IoArrowBack } from 'react-icons/io5'

function CommentModal({ scrolRef }) {
  const [announcement, setAnnouncement] = useState()
  const [comments, setComments] = useState([])
  const [comment, setComment] = useState('')

  let dispatch = useDispatch()

  const showModal = useSelector((state) => state.isshowcomment.isShowComment)
  const id_announcement = useSelector(
    (state) => state.idnnouncement.announcementId
  )
  const currentUser = useSelector((state) => state.user.currentUser)

  useEffect(() => {
    if (id_announcement) {
      getAnnouncement(id_announcement, setAnnouncement)
    }
  }, [id_announcement])

  useEffect(() => {
    if (announcement?._id) {
      getCommentByAnnouncement(announcement?._id, setComments)
    }
  }, [announcement?._id])

  const handleComment = async () => {
    const commentData = {
      user: currentUser?._id,
      announcement: id_announcement,
      comment: comment,
    }

    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/comment/add-comment`,
        commentData
      )
      if (data) {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
    }

    setComment('')
  }

  return (
    <div
      className={
        showModal
          ? ' flex   h-[100vh] overflow-y-scroll w-full  ml-0 px- px-4 py-3 lg:ml-[500px] lg:mt-[110px] bg-[white] lg:w-[400px] lg:h-[430px] lg:m-0 transition-all ease-out delay-700 duration-300 text-[#ffffff] rounded-[4px] absolute mt-[0px] top-0 z-50'
          : ' flex   h-[100vh] overflow-y-scroll  w-full  ml-200px px-4 py-3 transition-all ease-out delay-700 duration-300  text-[#ffffff] top-0 rounded-[4px] absolute mt-[-750px] z-50'
      }
    >
      <div className="w-[100%]">
        <div className="flex ">
          <div
            className={`flex items-center py-1 lg:py-0 text-black lg:bg-inherit lg:text-black lg:pb-2 mb-1 font-bold w-[100%]`}
          >
            <IoArrowBack
              onClick={() => dispatch(setIsShowComment(false))}
              className=" ml-4 text-[18px] lg:mr-2 cursor-pointer bg-[rgba(128,128,128,0.1)] hover:bg-[rgba(128,128,128,0.2)] transition-all ease-out delay-200 duration-300  top-0 rounded-full p-[2px]"
            />

            {comments?.length > 1 && (
              <span className="px-3 lg:px-0 text-[14px]">
                {comments?.length + ' ' + 'Commentaires'}
              </span>
            )}

            {comments?.length === 1 && (
              <span className="px-3 lg:px-0 text-[14px]">
                {' '}
                {comments?.length + ' ' + 'Commentaire'}
              </span>
            )}

            {comments?.length < 1 && (
              <span className="px-3 lg:px-0 text-[14px]">
                {' '}
                0 {'Commentaire'}
              </span>
            )}
          </div>
        </div>
        <div className=" pb-[70px] lg:pb-[10px] w-[100%] text-black shadow-[0_2px_10px_1px_rgba(0,0,0,0.04)]  overflow-y-scroll lg:h-[50vh]">
          {comments?.map((comment) => (
            <div className="flex flex-col pt-5 lg:pt-0" key={comment?._id}>
              {comment?.comment && (
                <div className="flex gap-3" ref={scrolRef}>
                  <div className="w-[30px] h-[30px] rounded-full">
                    <img
                      src={comment?.user?.image ? comment?.user?.image : Profil}
                      alt=""
                      className="w-[30px] h-[30px] rounded-full"
                    />
                  </div>
                  <div className=" rounded-[15px]  ">
                    <div className="text-[12px] font-bold pb-1">
                      {comment?.user?.username}
                    </div>
                    <p className="px-4 py-2 bg-[#D9D9D9] text-[rgba(0,0,0,0.76)] text-[13px] rounded-[25px]">
                      {comment?.comment}
                    </p>
                    <div className="text-end text-[11px] py-1 font-light px-2">
                      <Moment format="HH:mm:ss" date={comment?.createdAt} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="relative">
          {!currentUser ? (
            <div className="text-[13px] font-light">
              Connectez-vous pour pouvoir commenter cette publication.{' '}
              <span
                className="underline cursor-pointer  text-primary"
                // onClick={() => setConnecting(true)}
              >
                {' '}
                Connectez-vous!
              </span>
            </div>
          ) : (
            showModal && (
              <div className="fixed bottom-0 left-0 right-0 flex items-center justify-center py-2 mt-1 bg-white lg:py-0 lg:static">
                <InputEmoji
                  value={comment}
                  onChange={setComment}
                  cleanOnEnter
                  onEnter={setComment}
                  placeholder="Commentaire"
                />
                <button
                  className="bg-primary mt-[11px] mr-3 px-[12px] rounded-[8px] text-white text-[13px]  h-[30px] mb-auto"
                  type="submit"
                  onClick={handleComment}
                >
                  <FiSend />
                </button>
              </div>
            )
          )}
        </div>
      </div>
      {/* <div className="w-[100%] lg:w-[40%]"></div> */}
    </div>
  )
}

export default CommentModal
