import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { FaSearch } from 'react-icons/fa'

function DataTableComponent({ data, columns, name, validated, active }) {
  const [records, setRecords] = useState([])

  useEffect(() => {
    setRecords(data)
  }, [data])

  function handleFilter(event) {
    const newData = data?.filter((row) => {
      return (
        row?.name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.username
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row?.email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.shortName
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row?.user?.username
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row?.user?.email
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row?.user?.phone
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row?.phone?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.category?.name
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row?.price
          ?.toString()
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      )
    })
    setRecords(newData)
  }

  return (
    active === validated && (
      <div>
        <div className="flex items-center mt-7 md:mt-0 mx-[16px] md:mx-0">
          <div className="w-[50%] md:w-[75%]">
            <span className=" font-medium">
              {data?.length} <>{data?.length < 2 ? `${name} ` : `${name}s`}</>
            </span>
          </div>
          <div className="w-[50%] md:w-[25%] flex relative rounded-2xl">
            <FaSearch className="absolute text-sm top-[21px] left-3 opacity-30 font-light" />
            <input
              type="text"
              placeholder="Recherche..."
              className="w-[100%] pl-8 my-2 text-[14px] outline-none border-none rounded-2xl focus:outline-none focus:border-transparent"
              onChange={handleFilter}
            />
          </div>
        </div>
        <DataTable
          columns={columns}
          data={records}
          selectableRows
          fixedHeader
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[6, 10, 15]}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
        ></DataTable>
      </div>
    )
  )
}

export default DataTableComponent
