import React from 'react'
import { Button, Label, TextInput } from 'flowbite-react'
import { register } from '../../../../features/user/login-by-phone-number'
import { useDispatch, useSelector } from 'react-redux'

function UsernameInput() {
  const dispatch = useDispatch()
  const registering = useSelector((state) => state.phoneLogin.registering)
  const phone = useSelector((state) => state.phoneLogin.phone)
  const currentUser = useSelector((state) => state.phoneLogin.user)

  const [username, setUsername] = React.useState()

  const handleSubmit = (e, username, phone) => {
    e.preventDefault()
    dispatch(register({ username, phone }))
  }

  React.useEffect(() => {
    if (currentUser !== null) {
      localStorage.setItem('currentUser', JSON.stringify(currentUser))
      window.location.reload()
    }
  }, [currentUser])
  return (
    <div className="flex max-w-md flex-col gap-4 w-[100%]">
      <div>
        <div className="mb-2 block">
          <Label htmlFor="username" value="Votre nom d'utilisateur" />
        </div>
        <TextInput
          id="username"
          type="text"
          placeholder="my username"
          onChange={(e) => {
            e.preventDefault()
            setUsername(e.target.value)
          }}
          required
        />
      </div>
      <Button
        className="bg-primary"
        onClick={(e) => handleSubmit(e, username, phone)}
      >
        {registering ? 'Connexion...' : 'Terminer'}
      </Button>
    </div>
  )
}

export default UsernameInput
