import React from 'react'

function InputForm({
  seSstate,
  valueState,
  nameLabel,
  errorMessage,
  requiredInput,
  nameInput,
}) {
  return (
    <div className="flex flex-col mb-2">
      <label
        htmlFor=""
        className="text-[rgba(0,0,0,0.8)] text-[13px] font-light flex gap-1"
      >
        {nameLabel}
        {requiredInput && <sapn className="font-medium text-[red]">*</sapn>}
      </label>
      <input
        onChange={(e) => seSstate(e.target.value)}
        type="text"
        placeholder={nameInput}
        value={valueState}
        className={`outline-none w-full px-2 py-3 md:py-2 border-[rgba(90,86,86,0.08)] border-[1px] mr-1 rounded-[4px] focusInput`}
      />
      {!valueState && (
        <div className="text-[red] font-medium text-[12px] py-0 my-0">
          {errorMessage}
        </div>
      )}
    </div>
  )
}

export default InputForm
