import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Moment from 'react-moment'
import DataTableComponent from '../../../components/datatable/DataTable'
import { getUser } from '../../../services/Users/get-user'
import { getAnnouncementsPublished } from '../../../services/Announcements/get-announcements-published'
import { FaRegUser } from 'react-icons/fa'
// import { FaPhone } from "react-icons/fa";
import { MdOutlineEmail } from 'react-icons/md'
import { FiPhone } from 'react-icons/fi'
import EmptyImage from '../../../assets/blank-profile.png'
import { deleteAnnouncement } from '../../../services/Announcements/delete-announcement'
import ModalDeleteAnnouncement from '../../../components/admin/modal-delete-announcement'

export default function DetailUser() {
  const [user, setUser] = useState(null)
  //   const sellerId = JSON.parse(localStorage.getItem('seller')) || null
  const [announcements, setAnnouncements] = React.useState([])
  const [announcement, setAnnouncement] = React.useState(null)
  const [loader, setloader] = useState(false)
  const [count, setCount] = useState(0)

  //   const navigate = useNavigate()
  const params = useParams()

  // pass navigate param in calumn
  //   const columnsConfig = columns(navigate)

  useEffect(() => {
    getUser(params.id, setUser)
  }, [])

  useEffect(() => {
    if (user) {
      getAnnouncementsPublished(user?._id, setAnnouncements)
    }
  }, [user, count])

  const handleDeleteAnnouncement = async (announcement) => {
    setloader(true)
    if (announcement?._id) {
      let response = await deleteAnnouncement(announcement?._id)
      if (response.status === 200) {
        setloader(false)
        setCount(count + 1)
        setAnnouncement(null)
      }
    }
  }

  const columns = [
    {
      name: 'Produit',
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: 'Images',
      selector: (row) => (
        <img
          src={row?.images ? row?.images[0] : EmptyImage}
          alt={row.name}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '100%',
            margin: '7px 0',
            objectFit: 'cover',
          }}
        />
      ),
    },
    {
      name: 'Categorie',
      selector: (row) => row?.category?.name || '-',
      sortable: true,
    },
    {
      name: 'Prix',
      selector: (row) =>
        row?.price + `${row?.currency?.shortName === 'fc' ? 'fc' : '$'}` || '-',
      sortable: true,
    },

    {
      name: 'Date',
      selector: (row) => (
        <Moment
          format=" DD-MM-YYYY"
          date={row?.updatedAt}
          className="text-[11px] italic"
        />
      ),
      sortable: true,
    },
    {
      cell: (row) => (
        <div>
          {
            <div
              className="bg-red-500 text-white px-2 rounded-[3px] py-1 border-none outline-none cursor-pointer"
              onClick={() => {
                setAnnouncement(row)
              }}
            >
              Sup.
            </div>
          }
        </div>
      ),
      div: true,
    },
  ]

  return (
    <div
      className={`pt-[60px] md:pt-0 relative  ${announcement?._id && ' flex justify-center '}`}
    >
      <ModalDeleteAnnouncement
        loader={loader}
        announcement={announcement}
        setAnnouncement={setAnnouncement}
        handleDeleteAnnouncement={handleDeleteAnnouncement}
      />
      <div className={`${announcement?._id ? 'hidden' : 'card relative z-10'}`}>
        <div className="px-[16px] md:px-0 flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col gap-1 ">
            <div className="flex gap-2 items-center ">
              <FaRegUser className="text-[13px] pt-[2px]" />
              <h2 className=" font-medium text-[14px] ">{user?.username}</h2>
            </div>
            <div className="flex gap-2 items-center ">
              <MdOutlineEmail className="text-[13px] pt-[2px]" />
              <h2 className=" font-medium text-[14px] pb-[2px]">
                {user?.email}
              </h2>
            </div>
            {user?.phone && (
              <div className="flex gap-2 items-center">
                <FiPhone className="text-[13px] pt-[2px]" />
                <h2 className=" font-medium text-[13px]">
                  {' '}
                  {'+243' + user?.phone}
                </h2>
              </div>
            )}
          </div>
        </div>
        <DataTableComponent
          data={announcements}
          columns={columns}
          name={
            announcements.length > 1 ? 'Annonces publiée' : 'Annonce publiée'
          }
        />
      </div>
    </div>
  )
}
