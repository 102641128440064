import React from 'react'
import emptyImage from '../../assets/emptyImage.png'
import { NumericFormat } from 'react-number-format'
import { FaRegHeart } from 'react-icons/fa6'
import { AiOutlineEye } from 'react-icons/ai'
import { motion } from 'framer-motion'
import 'aos/dist/aos.css'

function ItemDetailCategory({ announce }) {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      // data-aos="zoom-in"
      // data-aos-duration="400"
      className="relative flex flex-col  pb-2 rounded-[10px] border border-gray-300 cursor-pointer shadow-lg hover:shadow-2xl"
    >
      <div className="relative">
        <img
          className="h-[150px] w-[100%] rounded-t-[10px] object-cover object-center"
          src={announce.images[0] ? announce.images[0] : emptyImage}
          alt={announce.name || 'Image'}
        />
        <div className="absolute flex space-x-2 top-2 right-2">
          <div className="p-2 bg-white rounded-full shadow-md">
            <FaRegHeart className="text-red-500" />
          </div>
          <div className="p-2 bg-white rounded-full shadow-md">
            <AiOutlineEye className="text-blue-500" />
          </div>
        </div>
      </div>
      {/* <div className="px-3 pb-3">
        <div className="pt-3 font-bold truncate">{announce?.name}</div>
        <div className="flex items-center justify-between">
          <div className="text-[rgba(0,0,0,0.65)] text-[15px]">
            <div className="flex gap-2">
              <span className="text-[rgba(0,0,0,0.65)] pt-2 md:pt-2 text-md font-black">
                <NumericFormat
                  value={announce?.price}
                  displayType={'text'}
                  thousandSeparator=" "
                />
                {announce?.currency?.shortName || 'fc'}
              </span>
              <span className="text-[rgba(0,0,0,0.65)] md:pt-2 text-[10px] font-black">
                {announce?.convert}
                {announce?.currency?.shortName === '$' ? 'fc' : '$'}
              </span>
            </div>
          </div>
        </div>
      </div> */}

      <div className="px-4 pt-1">
        <div className="mb-1 font-semibold truncate text-md">
          {announce.name}
        </div>
        <div className="flex items-center justify-between">
          <div className="text-sm font-bold text-gray-800">
            <NumericFormat
              value={announce?.price}
              displayType={'text'}
              thousandSeparator=" "
              suffix={` ${announce?.currency?.shortName || 'fc'}`}
            />
          </div>
        </div>
        <div className="mt-2">
          <button
            className="px-4 py-1 text-white transition-colors duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
            // onClick={() => hanleAddSeen(announce)}
          >
            Détail
          </button>
        </div>
      </div>
      {/* <div className="flex items-center justify-end">
        <span className="flex gap-3 bg-[rgba(0,0,255,0.012)] px-2 mx-2 mb-3"> 
          <span className="flex text-[11px] pt-[2px] gap-1">
            <span className="">{announce?.likes?.length}</span>
            <FaRegHeart className="text-[17px] font-light text-[rgb(48,48,49)]" />
          </span>
          <span className="flex text-[11px] pt-[2px] gap-1">
            <span className="">{announce?.seen?.length}</span>
            <AiOutlineEye className="text-[17px] font-light text-[rgb(48,48,49)]" />
          </span>
        </span>
      </div> */}
    </motion.div>
  )
}

export default ItemDetailCategory
