const _showInputQuantity = (category) => {
  console.log('category : ', category)

  let result =
    category?.name !== 'Service' &&
    category?.name !== 'Immobilier' &&
    category?.name !== 'Santé'

  return result
}

const _showWeightRange = (category) => {
  let result =
    category?.name === 'Service' ||
    category?.name === 'Immobilier' ||
    category?.name === 'Santé'

  return result
}

const _showInputRent = (category) => {
  let result =
    category?.name === 'Automobile' || category?.name === 'Immobilier'

  return result
}

export { _showWeightRange, _showInputQuantity, _showInputRent }
