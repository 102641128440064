function updateSetType(types, subcategory) {
  let result = types
    ?.filter((item) =>
      item?.subcategories?.some((categ) => categ?._id === subcategory)
    )
    ?.map((item) => ({ name: item?.name, _id: item._id }))

  return result
}

function updateSetTypeWithId(types, subcategory) {
  console.log('subcategory test : ', subcategory)
  let result = types
    ?.filter((item) =>
      item?.subcategories?.some((categ) => categ?._id === subcategory)
    )
    ?.map((item) => ({ name: item?.name, _id: item._id }))
  return result
}

export { updateSetType, updateSetTypeWithId }
