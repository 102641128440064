import React, { useEffect, useState } from 'react'
import ItemProduct from './ItemProduct'
import { useGetAllAnnouncementsQuery } from '../services/announcementApi'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

function Products({ infoUserRef }) {
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const productsPerPage = 12

  const { data: announcements, isLoading } = useGetAllAnnouncementsQuery()

  useEffect(() => {
    if (announcements) {
      setLoader(true)
    } else {
      setLoader(false)
    }
  }, [announcements])

  const indexOfLastProduct = currentPage * productsPerPage
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage
  const currentProducts = announcements
    ?.slice(indexOfFirstProduct, indexOfLastProduct)
    ?.reverse()

  const totalPages = Math.ceil(announcements?.length / productsPerPage)

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // Determine the range of pages to display
  const pageNumbers = []
  const maxPagesToShow = 4
  const halfRange = Math.floor(maxPagesToShow / 4)

  let startPage = Math.max(1, currentPage - halfRange)
  let endPage = Math.min(totalPages, currentPage + halfRange)

  // Adjust the range to ensure we always have maxPagesToShow pages
  if (endPage - startPage + 1 < maxPagesToShow) {
    if (startPage === 1) {
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1)
    } else if (endPage === totalPages) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1)
    }
  }

  // Add page numbers to array
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i)
  }

  return loader ? (
    <div className="flex flex-col lg:flex-row relative gap-3 px-[16px] pb-7 md:px-12 lg:px-16 md:pt-8 lg:pt-16 lg:pb-24">
      <div className="">
        <h2 className="text-[#21344e] text-[16px] pt-4 font-bold pb-2 lg:pt-0 capitalize md:text-[18px]">
          Les annonces récement publiées
        </h2>

        <div className="grid grid-cols-2 gap-3 lg:gap-7 md:grid-cols-4 lg:grid-cols-6">
          <ItemProduct
            data={currentProducts}
            loader={isLoading}
            infoUserRef={infoUserRef}
          />
        </div>

        {/* Pagination Controls */}
        <div className="flex items-center justify-between mt-6 mb-6 md:mb-7 lg:mb-2">
          <button
            onClick={handlePrev}
            className={`px-2 py-1 lg:px-4 lg:py-2 rounded bg-blue-500 text-white ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <div className="flex gap-2 text-sm">
            {startPage > 1 && (
              <>
                <button
                  onClick={() => handlePageChange(1)}
                  className={`px-2 py-1 lg:px-4 lg:py-2 rounded ${currentPage === 1 ? 'bg-blue-700 text-white' : 'bg-gray-300 text-black'}`}
                >
                  1
                </button>
                {startPage > 2 && (
                  <span className="px-2 py-1 text-black bg-gray-300 rounded lg:px-4 lg:py-2">
                    ...
                  </span>
                )}
              </>
            )}
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => handlePageChange(number)}
                className={`px-2 py-1 lg:px-4 lg:py-2 rounded ${currentPage === number ? 'bg-blue-700 text-white' : 'bg-gray-300 text-black'}`}
              >
                {number}
              </button>
            ))}
            {endPage < totalPages && (
              <>
                {endPage < totalPages - 1 && (
                  <span className="px-2 py-1 text-black bg-gray-300 rounded lg:px-4 lg:py-2">
                    ...
                  </span>
                )}
                <button
                  onClick={() => handlePageChange(totalPages)}
                  className={`px-2 py-1 lg:px-4 lg:py-2 rounded ${currentPage === totalPages ? 'bg-blue-700 text-white' : 'bg-gray-300 text-black'}`}
                >
                  {totalPages}
                </button>
              </>
            )}
          </div>
          <button
            onClick={handleNext}
            className={`px-2 py-1 lg:px-4 lg:py-2 rounded bg-blue-500 text-white ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  )
}

export default Products

function Loader() {
  const [loaderCount, setLoaderCount] = useState([])

  useEffect(() => {
    const updateLoaderCount = () => {
      const width = window.innerWidth

      if (width >= 1024) {
        setLoaderCount([1, 2, 3, 4, 5, 6])
      } else if (width >= 768) {
        setLoaderCount([1, 2, 3, 4])
      } else {
        setLoaderCount([1, 2])
      }
    }

    updateLoaderCount()

    window.addEventListener('resize', updateLoaderCount)

    return () => {
      window.removeEventListener('resize', updateLoaderCount)
    }
  }, [])

  return (
    <div className="flex flex-col gap-4 px-[16px] pb-10 lg:flex-row md:px-16 lg:px-16 lg:pt-24 lg:pb-24">
      <div className="w-full ">
        <div className="grid grid-cols-2 gap-2 md:grid-cols-4 lg:grid-cols-6">
          {loaderCount?.map((index) => (
            <div
              key={index}
              className=" rounded-[5px] py-2 my-2 cursor-pointer w-full mb-1 border-[0.001rem] pb-2 shadow-[0_0_0_rgba(48, 48, 49, 0.3)] hover:shadow-[0_0_15px_rgba(20,_20,_20,_0.2)] transition-all ease-in-out delay-150 md:h-[auto] "
            >
              <div className="flex flex-col items-center gap-2 px-2">
                <div
                  alt=""
                  className="flex-shrink-0 mt-[6px] w-full lg:mt-0 h-[60px] rounded-[5px]  md:h-[85px] hover:cursor-pointer  image animated-background"
                />
                <div
                  alt=""
                  className="h-[20px] w-[70px] md:w-[100%] animated-background hover:cursor-pointer"
                />
                <div
                  alt=""
                  className="h-[20px] w-[70px] md:w-[100%] animated-background hover:cursor-pointer"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export { Loader, Products }
