import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const postTrackerContact = async (customerId, sellerId, data) => {
  const response = await axios.post(
    `${BASE_URL}/api/tracker-contacts/post/${customerId}/${sellerId}`,
    data
  )
  try {
    if (response.status === 200) return true
  } catch (error) {
    console.error(error)
  }
}

export { postTrackerContact }
