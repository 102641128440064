import moment from 'moment'

export const formatCreatedToday = (date) => {
  if (moment(date).isSame(new Date(), 'day')) {
    return `Ajouté aujourd'hui à ${moment(date).format('HH:mm')}`
  } else if (
    moment(date).isSame(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      'day'
    )
  ) {
    return `Ajouté hier à ${moment(date).format('HH:mm')}`
  } else {
    return `Ajouté le ${moment(date).format('DD/MM/YYYY')} à ${moment(date).format('HH:mm')}`
  }
}
