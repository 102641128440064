import React from 'react'

function ProfilSeller({ announcement, Profil, fistLetterToUpparcase }) {
  return (
    <div className="flex items-center gap-2">
      <div className="w-8 h-8 rounded-full border-2 border-blue-500 overflow-hidden">
        <img
          src={
            announcement?.seller?.user?.image ||
            announcement?.user?.image ||
            Profil
          }
          alt="Seller"
          className="w-full h-full object-cover"
        />
      </div>
      <div>
        <div className="text-gray-800 font-roboto text-sm">
          {fistLetterToUpparcase(
            announcement?.seller?.store || announcement?.user?.username
          )}
        </div>
      </div>
    </div>
  )
}

export default ProfilSeller
