import React, { useEffect, useState } from 'react'
import { getCurrencies } from '../../../services/currencies/get-currencies'
import { useNavigate } from 'react-router-dom'
import DataTableComponent from '../../../components/datatable/DataTable'
import columns from '../../../helpers/categories/colums-show-currencies'

function ListDevises() {
  const [currencies, setCurrencies] = useState([])
  const [loader, setLoader] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    getCurrencies(setCurrencies)
    setLoader(currencies)
  }, [])

  const columnsConfig = columns()

  console.log('currencies : ', currencies)

  return (
    <div className="pt-[60px] md:pt-0">
      <div className="card">
        <div className="px-[16px] md:px-0 flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h2 className=" font-bold text-[17px] pb-5">
              {/* {'Categorie ' + categories?.name} */}
            </h2>
          </div>
          <div className="flex justify-end text-xs gap-2">
            <button
              className="bg-primary text-white px-3 py-2 border-none rounded-[3px] outline-none cursor-pointer"
              onClick={() => navigate(`/Account/create-currency`)}
            >
              Ajouter une devise
            </button>
          </div>
        </div>
        <DataTableComponent
          data={currencies}
          columns={columnsConfig}
          name={'devise'}
          loader={loader}
        />
      </div>
    </div>
  )
}

export default ListDevises
