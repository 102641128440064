import { Button, Modal } from 'flowbite-react'
import React from 'react'

import { FaTruckFast } from 'react-icons/fa6'
import { FaRegStar, FaStar } from 'react-icons/fa6'
import { useRateMutation } from '../../services/deliverApi'
import Rating from 'react-rating'

export default function RatingShipment({ openModal, setOpenModal, shipment }) {
  const [rate, { isLoading, isSuccess }] = useRateMutation()

  const [rating, setRating] = React.useState(0)

  const rateDeliver = (rating, deliver, shipment) => {
    rate({ rate: rating, deliver, shipment })
  }

  React.useEffect(() => {
    if (isSuccess) window.location.reload()
  }, [isSuccess])
  return (
    <>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center p-5">
            <FaTruckFast className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="text-lg font-normal text-gray-500 dark:text-gray-400">
              A quel point etes-vous satisfait de la livraison?
            </h3>
            <div className="py-5">
              {' '}
              <Rating
                value={rating}
                onChange={(value) => {
                  console.log('rate:', typeof value)
                  setRating(value)
                }}
                emptySymbol={
                  <FaRegStar className="text-[37.5px] text-gray-500 font-light" />
                }
                fullSymbol={
                  <FaStar className="text-yellow-300 text-[37.5px]" />
                }
              />
            </div>

            <div className="flex justify-center gap-4">
              <button
                className="rounded-[0.5rem] p-2 text-sm text-white bg-primary"
                onClick={() =>
                  rateDeliver(rating, shipment?.deliver?._id, shipment?._id)
                }
              >
                {isLoading ? 'Notation...' : ' Valider la note'}
              </button>
              <Button color="gray" onClick={() => setOpenModal(false)}>
                Annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
