import React from 'react'
import UpdateForm from '../../components/shops/UpdateForm'
import Nav from '../../components/layout/Nav'
import { useLocation } from 'react-router-dom'

export default function UpdateShop() {
  let location = useLocation()

  let shop = location?.state?.key
  return (
    <div>
      <Nav />
      <div className="pt-40 px-10 lg:px-96 flex flex-col">
        <div className="pb-5 text-semiBold">Mise à jour de ma boutique</div>
        <div>
          <UpdateForm shop={shop} />
        </div>
      </div>
    </div>
  )
}
