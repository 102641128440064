import React, { useEffect, useState } from 'react'
import FormContainer from '../../../components/admin/formComponent/FormContainer'
import InputForm from '../../../components/admin/formComponent/InputForm'
import ButtonForm from '../../../components/admin/formComponent/ButtonForm'
import { postProductbrand } from '../../../services/productBrands/post-product-brand'
import { useNavigate } from 'react-router-dom'
import { Label } from 'flowbite-react'
import { useSelector } from 'react-redux'
import { getCategories } from '../../../services/Categories/get-categories'

function CreateProductBrand() {
  const navigate = useNavigate()

  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [categories, setcategories] = useState([])
  const [subcgr, setSubcgr] = useState([])

  const [loading, setLoading] = useState(false)
  const [errMsgName, setErrMsgName] = useState(null)
  const [errMsgSubCategory, setErrMsgSubCategory] = useState(null)
  const [errorMessageRosponse, setErrorMessageResponse] = useState(null)

  const currentUser = useSelector((state) => state.user.currentUser)

  useEffect(() => {
    getCategories(setSubcgr)
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      name,
      description,
      categories,
      isAdmin: currentUser?.isAdmin,
    }
    console.log('data:', data)
    if (!name) {
      setErrMsgName('Ce champs est obligatoire')
    } else if (categories.length < 1) {
      setErrMsgSubCategory('Selectionner au moins une sous categorie')
    } else {
      setLoading(true)
      if (data) {
        postProductbrand(data, setLoading, navigate, setErrorMessageResponse)
      }
    }
  }

  const handleAddSubCategory = (sc) => {
    let idSubc = sc?._id
    if (!categories.includes(idSubc)) {
      setcategories([...categories, idSubc])
    } else {
      setcategories(categories.filter((subcat) => subcat !== idSubc))
    }
  }

  return (
    <div className="px-[16px] md:px-0 md:w-[60%] m-auto">
      <h2 className="font-medium text-[17px] pt-[65px] md:pt-0 pb-3">
        Ajouter une marque de produit
      </h2>
      <FormContainer onSubmit={handleSubmit}>
        <InputForm
          seSstate={setName}
          valueState={name}
          nameLabel={'Marque de produit'}
          errorMessage={errMsgName}
          requiredInput
        />
        <InputForm
          seSstate={setDescription}
          valueState={description}
          nameLabel={'Description'}
        />
        <div className="">
          <div className="block mb-2">
            <Label
              htmlFor="weightRange"
              value="Sélectionner une ou plusieurs sous-catégories"
            />
          </div>
          <div className="">
            <div className="grid grid-cols-3">
              {subcgr?.map((sc, index) => (
                <div key={sc?._id} className="flex items-center mb-2">
                  <input
                    onChange={() => handleAddSubCategory(sc)}
                    checked={categories.includes(sc?._id) && true}
                    value={sc?._id}
                    id={`checkbox-${index}`}
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor={`checkbox-${index}`}
                    className="text-sm font-medium text-gray-900 ms-2 dark:text-gray-300"
                  >
                    {sc?.name}
                  </label>
                </div>
              ))}
            </div>
            {categories?.length < 1 && (
              <div className="text-[red] font-medium text-[12px] py-0 my-0">
                {errMsgSubCategory}
              </div>
            )}
          </div>
        </div>
        <ButtonForm loadingButton={loading} />
      </FormContainer>
      {errorMessageRosponse && (
        <div className="text-[red] font-medium text-[12px] py-0 my-2">
          {errorMessageRosponse}
        </div>
      )}
    </div>
  )
}

export default CreateProductBrand
