import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const updateDeliver = async (data, navigate) => {
  console.log('data updateDeliver : ', data)
  let id = data?.deliverId
  const response = await axios.patch(
    `${BASE_URL}/api/delivers/update-deliver/${id}`,
    data
  )
  console.log('response : ', response)
  try {
    if (response?.status === 200) {
      navigate('/delivery/create')
    }
  } catch (error) {
    console.log(error)
  }
}

export { updateDeliver }
