import React from 'react'

export default function LoginHeader() {
  return (
    <div className="flex justify-center items-center flex-col gap-3">
      <div>
        <h2 className="page__title font-bold text-[37.5px] leading-5 pt-16 pb-3 font-payton lowercase">
          <span className="text-primary mr-[2px]">U</span>
          <span className="text-secondary mr-[3px]">T</span>
          <span className="text-primary mr-[3px]">Y</span>
        </h2>
      </div>
      <span className="text-[19px] font-medium text-gray-500 pb-3">
        Kota, somba, pe teka na uty
      </span>
    </div>
  )
}
