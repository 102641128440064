import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { updateCategory } from '../../../services/Categories/update-category'
import { getCategory } from '../../../services/Categories/get-category'

function UpdateCategory() {
  const [name, setName] = useState(null)
  const [icon, setIcon] = useState(null)
  const [description, setDescription] = useState(null)

  const [errMsgName, setErrMsgName] = useState(null)
  const [loading, setLoading] = useState(false)

  const currentUser = useSelector((state) => state.user.currentUser)

  const [category, setCategory] = useState(null)
  const [selectedImg, setSelectedImg] = useState(null)

  const [characteristics, setCharacteristics] = useState(null)

  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    getCategory(params?.id, setCategory)
  }, [params?.id])

  useEffect(() => {
    setName(category?.category?.name)
    setDescription(category?.category?.description)
    setIcon(category?.category?.icon)
    setCharacteristics(category?.category?.characteristics)
  }, [category])

  useEffect(() => {
    if (selectedImg) {
      const reader = new FileReader()
      reader.readAsDataURL(selectedImg)
      reader.onloadend = () => {
        setIcon(reader.result)
      }
    }
  }, [selectedImg])

  const handleEdit = async (e) => {
    e.preventDefault()
    setLoading(true)

    if (!name) {
      setErrMsgName('Ce champ est obligatoire')
      setLoading(false)
    } else {
      const response = await updateCategory(
        name,
        icon,
        description,
        params?.id,
        currentUser?.isAdmin,
        characteristics
      )
      if (response.data.message === 'success') {
        navigate('/Account/list-categories')
      }
    }
  }

  const handleCharacteristicChange = (key, value) => {
    setCharacteristics((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  return (
    <div className="px-[16px] md:px-0 md:w-[60%] m-auto">
      <h2 className=" font-medium text-[17px] pt-[65px] md:pt-0 pb-3">
        Modifier la device
      </h2>
      <form onSubmit={handleEdit}>
        <div className="flex flex-col mb-2">
          <label
            htmlFor=""
            className="text-[rgba(0,0,0,0.8)] text-[13px] font-light flex gap-1"
          >
            Nom
            <sapn className="text-[red] font-medium">*</sapn>
          </label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder=""
            className="outline-none w-full px-2 py-3 md:py-2 border-[rgba(90,86,86,0.08)] border-[1px] mr-1 rounded-[4px] focusInput"
          />
          {!name && (
            <div className="text-[red] font-medium text-[11px] py-0 my-0">
              {errMsgName}
            </div>
          )}
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor=""
            className="text-[rgba(0,0,0,0.8)] text-[13px] font-light flex gap-1"
          >
            Description
            <sapn className="text-[red] font-medium"></sapn>
          </label>
          <input
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            type="text"
            placeholder=""
            className="outline-none w-full px-2 py-3 md:py-2 border-[rgba(90,86,86,0.08)] border-[1px] mr-1 rounded-[4px] focusInput"
          />
          {/* {!description && (
            <div className="text-[red] font-medium text-[11px] py-0 my-0">
              {errMsgDescription}
            </div>
          )} */}
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor=""
            className="text-[rgba(0,0,0,0.8)] text-[13px] font-light flex gap-1"
          >
            Image
            <sapn className="text-[red] font-medium"></sapn>
          </label>
          <input
            onChange={(e) => setSelectedImg(e.target.files[0])}
            className="block outline-none px-2 py-2 md:py-1 border-[rgba(90,86,86,0.08)] border-[1px] rounded-[4px] focusInput w-full text-sm text-gray-900  border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            id="file"
            type="file"
          />
        </div>

        <div>
          <label
            htmlFor=""
            className="text-[rgba(0,0,0,0.8)] text-[13px] font-light flex gap-1 pb-3"
          >
            Choisir les caractéristiques des annonces de cette catégorie
          </label>

          <div className="grid grid-cols-2 gap-2">
            {characteristics &&
              Object.keys(characteristics).map((key) => (
                <div key={key} className="flex gap-1">
                  <div>
                    <input
                      type="checkbox"
                      checked={characteristics[key]}
                      onChange={(e) =>
                        handleCharacteristicChange(key, e.target.checked)
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor={key}>{key}</label>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="">
          <button className="bg-[#3b5998]  text-[15px] hover:bg-[#3b5998] focus:ring-4 focus:outline-none cursor-pointer py-2 md:py-2 px-8 md:px-10 mt-2 rounded-[4px] text-white">
            {loading ? (
              <div className="">Operation...</div>
            ) : (
              <div className="">Valider</div>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default UpdateCategory
