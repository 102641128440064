import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

export const addPhoneNumber = createAsyncThunk(
  'user/addPhoneNumber',
  async ({ user, phone }) => {
    console.log(user, phone)
    let response = await axios.patch(
      `${BASE_URL}/api/users/add-telephone/${user}`,
      {
        phone: phone,
      }
    ) // Remplacez ceci par l'URL de votre API
    return response.data
  }
)

const initialState = {
  currentUser: null,
  location: [15.2738282, -4.3195318],
  myShop: null,
  currentDeliver: null,
  deliverLocation: [],
  logging: false,
  signing: false,
  contacting: false,
  loading: false,
  dropOffLocation: [],
  addressName: '',
  isDeliver: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    setCurrentDeliver: (state, action) => {
      state.currentDeliver = action.payload
    },
    setLogging: (state, action) => {
      state.logging = action.payload
    },
    setSigning: (state, action) => {
      state.signing = action.payload
    },
    setContacting: (state, action) => {
      state.signing = action.payload
    },
    setLocation: (state, action) => {
      state.location = action.payload
    },
    setMyShop: (state, action) => {
      state.myShop = action.payload
    },
    setDropOffLocation: (state, action) => {
      state.dropOffLocation = action.payload
    },
    setAddressName: (state, action) => {
      state.addressName = action.payload
    },
    setIsDeliver: (state, action) => {
      state.isDeliver = action.payload
    },
    setDeliverLocation: (state, action) => {
      state.deliverLocation = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPhoneNumber.pending, (state) => {
        state.loading = true
      })
      .addCase(addPhoneNumber.fulfilled, (state, action) => {
        console.log('phoneOwner', action.payload)

        state.currentUser = action.payload.user
        state.loading = false
        window.location.reload()
      })
  },
})

export const {
  setCurrentUser,
  setCurrentDeliver,
  setLogging,
  setSigning,
  setContacting,
  setDropOffLocation,
  setIsDeliver,
  setDeliverLocation,
  setMyShop,
  setLocation,
} = userSlice.actions

export const userState = (state) => state.user

export default userSlice.reducer
