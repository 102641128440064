import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const addPhoneNumber = async (id, phone) => {
  console.log('id : ', id)
  console.log('phone : ', phone)
  const data = await axios.patch(`${BASE_URL}/api/users/add-telephone/${id}`, {
    phone,
  })
  try {
    return data
  } catch (error) {
    console.error(error)
  }
  return data
}

export { addPhoneNumber }
