import { Modal } from 'flowbite-react'
import mapboxgl from 'mapbox-gl'
import { useSelector, useDispatch } from 'react-redux'
import { setDropOffLocation } from '../../features/user/user'
import AddLocationOnMap from './AddLocationOnMap'
import AddressName from './AddressName'
// import MySearchBox from './SearchBox'
// import _ from 'lodash'

mapboxgl.accessToken =
  'pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw'

export default function SearchAddress({ openModal, setOpenModal }) {
  const dispatch = useDispatch()
  const dropOffLocation = useSelector((state) => state.user.dropOffLocation)

  console.log('drop off location:', dropOffLocation)

  return (
    <>
      <Modal
        className="lg:px-96"
        show={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header>
          Votre addresse de livraison <AddressName />
        </Modal.Header>
        <Modal.Body>
          <div className="p-5">
            <div className="-mt-2 mb-2 text-sm">
              Modifier votre addresse en glissant le marqueur sur la carte ou
              avec un double click sur la carte
            </div>
            <div className="">
              <AddLocationOnMap
                dispatch={dispatch}
                setLocation={setDropOffLocation}
              />
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={() => setOpenModal(false)}>I accept</Button>
          <Button color="gray" onClick={() => setOpenModal(false)}>
            Decline
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}
