import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getMessages = async (setMessages, chat) => {
  const dataMessage = await axios.get(
    `${BASE_URL}/api/message/get-messages/${chat}`
  )
  try {
    if (dataMessage.status === 200) {
      setMessages(dataMessage?.data?.messages)
    }
  } catch (err) {
    console.log(err)
  }
}
export { getMessages }
