import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import columns from '../../../helpers/meanstransports/colums-show-transports'
import DataTableComponent from '../../../components/datatable/DataTable'
import { getMeansTransport } from '../../../services/meansTransports/get-means-transports'

function ListMeansTransports() {
  const [meanstransports, setMeanstransports] = useState([])
  const [loader, setLoader] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    getMeansTransport(setMeanstransports)
    setLoader(meanstransports)
  }, [])

  const columnsConfig = columns(navigate)

  return (
    <div className="pt-[60px] md:pt-0">
      <div className="card">
        <div className="px-[16px] md:px-0 flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h2 className=" font-bold text-[17px] pb-5">
              {/* {'Categorie ' + categories?.name} */}
            </h2>
          </div>
          <div className="flex justify-end text-xs gap-2">
            <button
              className="bg-primary text-white px-3 py-2 border-none rounded-[3px] outline-none cursor-pointer"
              onClick={() => navigate(`/Account/create-meanstransport`)}
            >
              Ajouter un moyen de transport
            </button>
          </div>
        </div>
        <DataTableComponent
          data={meanstransports}
          columns={columnsConfig}
          name={'Transport'}
          loader={loader}
        />
      </div>
    </div>
  )
}

export default ListMeansTransports
