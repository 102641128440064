import React from 'react'
import { Modal, Label, TextInput } from 'flowbite-react'

export function PhoneNumberModal({
  openModal,
  setOpenModal,
  user,
  addPhoneNumber,
  loading,
  dispatch,
}) {
  const [phone, setPhone] = React.useState()
  const [error, setError] = React.useState(false)

  const handleChange = (e) => {
    e.preventDefault()
    setPhone(e.target.value)
  }

  return (
    <>
      <Modal
        show={openModal}
        size="md"
        popup
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="p-5 flex flex-col gap-3">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Ajoutez un numéro à fin de rester joignable pour le processus de
              livraison
            </h3>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="phone"
                  value="Entrez votre numero de telephone"
                />
              </div>
              <div className="flex gap-2 mx-[-5px] w-[100%]">
                <div className="">
                  <Label htmlFor="phone" />
                </div>
                <TextInput
                  id="phone"
                  className="w-[100%]"
                  onChange={(e) => handleChange(e)}
                  type="number"
                  placeholder="813388777"
                  addon="+243"
                  required
                />
              </div>
              {error && (
                <div className="text-red-500">
                  Veuillez saisir votre numero de telephone
                </div>
              )}
            </div>
            <div className="w-full">
              <button
                className="bg-primary p-2 rounded-[0.5rem] text-white"
                onClick={(e) =>
                  addPhoneNumber(e, user, phone, setError, loading, dispatch)
                }
              >
                {loading ? 'Ajout en cours...' : ' Ajouter le téléphone'}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
