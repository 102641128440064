import React from 'react'
import InputEmoji from 'react-input-emoji'
import { FiSend } from 'react-icons/fi'

function FormCommnent({ comment, setComment, handleComment }) {
  return (
    <div className="flex items-center justify-between p-2 bg-white rounded-md fixed bottom-0 left-0 right-0 lg:static lg:rounded-none">
      <div className="flex-grow">
        <InputEmoji
          value={comment}
          onChange={setComment}
          cleanOnEnter
          onEnter={setComment}
          placeholder="Écrire un commentaire..."
          borderColor="#D1D5DB"
          borderRadius="8px"
          fontFamily="Arial, sans-serif"
          fontSize="14px"
          padding="10px"
        />
      </div>
      <button
        className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white text-lg p-2 rounded-full ml-2 transition duration-300 ease-in-out"
        type="submit"
        onClick={handleComment}
        aria-label="Envoyer le commentaire"
      >
        <FiSend className="w-5 h-5" />
      </button>
    </div>
  )
}

export default FormCommnent
