import React from 'react'
import { Modal } from 'flowbite-react'
import { IoTrashSharp, IoEyeOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'

export default function NextOrderDetail({
  openModal,
  setOpenModal,
  data,
  user,
  handleDeleteProductToCart,
}) {
  const navigate = useNavigate()
  // const [datas, setDatas] = React.useState(data?.products)

  // const deleteProduct = (productId) => {
  //   let toDeleted = data?.products?.filter((product) => {
  //     product?.productId?._id === productId
  //   })

  //   console.log('toDeleted', toDeleted)
  //   setDatas(toDeleted)
  //   return toDeleted
  // }
  // console.log('datas:', data?.products)

  // React.useEffect(() => {
  //   setDatas(data?.products)
  // }, [])
  return (
    <>
      <Modal
        dismissible
        size="md"
        show={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header>Commande à {data?.seller?.username}</Modal.Header>
        <Modal.Body>
          <div className="p-5">
            <div className="flex flex-col gap-3">
              {data?.products?.map((product) => {
                return (
                  <div
                    key={product?.productId?._id}
                    className="flex gap-3 items-center shadow p-3 rounded-[0.5rem]"
                  >
                    <div>
                      <img
                        src={product?.productId?.images[0]}
                        alt=""
                        className="h-20 w-20"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <div>{product?.productId?.name}</div>
                      <div className="flex items-center">
                        <span>{product?.quantity} x </span>
                        <span className="flex gap-2 font-medium text-[20px]">
                          <NumericFormat
                            value={product?.productId?.price}
                            displayType={'text'}
                            thousandSeparator=" "
                          />
                          <span>
                            {product?.productId?.currency
                              ? product?.productId?.currency?.name
                              : 'CDF'}
                          </span>
                        </span>
                      </div>
                      <div className="flex gap-2">
                        <button
                          className="text-primary shadow text-[12.5px] p-2 md:px-5 rounded-[0.5rem]"
                          onClick={() =>
                            navigate(
                              `/announcements/${product?.productId?._id}/${product?.productId?.category}`
                            )
                          }
                        >
                          <IoEyeOutline />
                        </button>
                        <button
                          className="hidden text-red-700 shadow text-[12.5px] p-2 md:px-5 rounded-[0.5rem]"
                          onClick={(e) => {
                            handleDeleteProductToCart(
                              e,
                              user,
                              product?.productId?._id
                            )
                            // deleteProduct(product?.productId?._id)
                          }}
                        >
                          <IoTrashSharp />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={() => setOpenModal(false)}>I accept</Button>
          <Button color="gray" onClick={() => setOpenModal(false)}>
            Decline
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}
