import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getAnnouncement } from '../../services/Announcements/getAnnouncement'
import ImageCarroussel from '../../components/ImageCarroussel'
import { NumericFormat } from 'react-number-format'
import ReactGA from 'react-ga4'
import { FaRegComment } from 'react-icons/fa6'
import { FaRegHeart } from 'react-icons/fa6'
import { FaHeart } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { LiaMehRollingEyes } from 'react-icons/lia'
import { setIsShowComment } from '../../features/comment/show-comment'
import { useDispatch } from 'react-redux'
import { setIdAnnouncement } from '../../features/comment/get-params'

export default function Detail() {
  let dispatch = useDispatch()

  const showModal = useSelector((state) => state.isshowcomment.isShowComment)

  const params = useParams()
  const [announcement, setAnnouncement] = useState()
  const [loader, setLoader] = useState(false)

  const currentUser = useSelector((state) => state.user.currentUser)

  const navigate = useNavigate()

  useEffect(() => {
    getAnnouncement(params.id, setAnnouncement)
    !announcement && setLoader(true)
    dispatch(setIdAnnouncement(params?.id))
  }, [])

  //Length Comment of announcement
  const commentLength = announcement?.comment?.filter(
    (cm) => cm?.announcement === params?.id
  )

  const generateCodeForNoUser = () => {}

  return (
    <div
      className={`${showModal && 'blur-md'} relative  lg:h-auto px-[16px] flex justify-center lg:px-5`}
    >
      <div className="">
        {loader ? (
          <div className="flex w-[100%] pt-[50px]  lg:h-[300px]  lg:pt-0 lg:flex-row sm:flex-col">
            <div className="flex lg:w-[50%] sm:w-[100%] flex-col">
              <div className="  mb-3 lg:mb-0 lg:pb-5 rounded-lg shadow-[10px 10px 5px silver] shadow-slate-500 flex justify-center items-center">
                <ImageCarroussel
                  images={announcement?.images}
                  announcement={announcement}
                />
              </div>
            </div>

            <div className="lg:flex lg:flex-col lg:justify-center lg:pl-10 lg:px-10 text-slate-500">
              <div className="">
                categorie -- <span>{announcement?.category.name}</span>
              </div>

              <div className="py-2">
                <div className="text-3xl text-slate-900">
                  <h3>{announcement?.name}</h3>
                </div>
                <div className="text-sm pt-2">
                  <p>{announcement?.description}</p>
                </div>
              </div>

              <div className="flex gap-2">
                <span className="text-[rgba(0,0,0,0.65)] pt-2 lg:pt-2 text-[30px] font-black">
                  <NumericFormat
                    value={announcement?.price}
                    displayType={'text'}
                    thousandSeparator=" "
                  />
                  {announcement?.currency?.shortName
                    ? announcement?.currency?.shortName
                    : 'fc'}
                </span>
                <span className="text-[rgba(0,0,0,0.65)] lg:pt-2 text-[16px] font-black">
                  {announcement?.convert}
                  {announcement?.currency?.shortName === '$' ? 'fc' : '$'}
                </span>
              </div>

              <div className="flex lg:justify-between gap-7 lg:pt-0 pt-4 lg:gap-0">
                <div className="text-[12px] flex gap-1 items-center font-semibold">
                  <div className="text-[12px] flex lg:flex">
                    {announcement?.likes?.length}
                  </div>
                  {announcement?.likes?.includes(currentUser?._id) ||
                  announcement?.likes?.includes(generateCodeForNoUser) ? (
                    <FaHeart className="text-[22px] lg:text-[19px] font-light text-red-300" />
                  ) : (
                    <FaRegHeart
                      className={`text-[22px] lg:text-[19px] font-semibold text-[rgb(48,48,49)] ${
                        announcement?.likes?.includes(currentUser?._id) &&
                        'text-primary'
                      }`}
                    />
                  )}
                </div>

                <div className=" flex lg:flex font-semibold hover:opacity-90 gap-1 items-center ">
                  <div className="text-[12px]">
                    {announcement?.seen?.length}
                  </div>
                  <LiaMehRollingEyes className="text-[22px] lg:text-[19px] font-light text-[rgb(48,48,49)]" />
                  <div className="text-[12px]"></div>
                </div>

                <div
                  className="flex cursor-pointer font-semibold hover:opacity-90 gap-1 items-center"
                  onClick={() => dispatch(setIsShowComment(true))}
                >
                  <div className="text-[12px] flex lg:flex">
                    {commentLength?.length}
                  </div>
                  <div>
                    <FaRegComment className="text-[22px] lg:text-[19px] font-light text-[rgb(48,48,49)]" />
                  </div>
                </div>
              </div>
              {showModal ? (
                ''
              ) : (
                <div className="pt-5">
                  <button
                    className="bg-primary text-sm text-white p-3 rounded-lg"
                    onClick={() => {
                      {
                        ReactGA.event({
                          category: 'Announcement',
                          action: 'Want to modify',
                        })
                        navigate(`/Account/announcements/edit/${params.id}`)
                      }
                    }}
                  >
                    Modifier l{"'"}annonce
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div>
              <div className="flex justify-between items-center pt-4">
                <h3 className="font-poppins text-lg font-medium">
                  Details de l{"'"}annonce
                </h3>
              </div>
            </div>
            <div className="h-1/2 flex justify-center items-end p-10">
              <div className=" loading-spinner"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
