import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  IoAdd,
  IoArrowBack,
  IoArrowForward,
  IoMegaphoneOutline,
} from 'react-icons/io5'
import { FaMoneyBillTrendUp } from 'react-icons/fa6'
import DeleteModal from '../../components/DeleteModal'
import AnnouncemmentCard from '../../components/announcement/MyAnnouncementCard'
import Register from '../../pages/authentification/Register'
import RegisterModal from '../../components/modal/RegisterModal'
//import { getAnnouncementsBySeller } from '../../services/Announcements/get-announcementsBySeller'

import ReactGA from 'react-ga4'
//import { getAnnouncementsByUser } from '../../services/Announcements/get-announcements-by-user'
import { useGetSellerAnnouncementsQuery } from '../../services/announcementApi'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import LottieLoader from '../../components/LottieLoader'
import Nav from '../../components/layout/Nav'
import { DetailModal } from '../../components/announcement/DetailModal'

const itemsPerPage = 12
export default function PublishedAnnouncements() {
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [deleting, setDeleting] = useState(false)
  const [selected, setSelected] = useState()

  const [connecting, setConnecting] = useState(false)
  const [registering, setRegistering] = useState(false)

  const [open, setOpen] = useState(false)

  let currentUser = JSON.parse(localStorage.getItem('currentUser'))

  const { data: announcements, isLoading } = useGetSellerAnnouncementsQuery(
    currentUser?._id
  )

  console.log('announcements', announcements)

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (!currentUser) {
      setConnecting(true)
    }
  }, [])

  useEffect(() => {
    setTotalPages(Math.ceil(announcements?.length / itemsPerPage))
  }, [announcements])

  if (isLoading)
    return (
      <div>
        <Nav />
        <div className="flex pt-10 flex-col items-center justify-center sm:h-screen">
          <div>
            <LottieLoader animation={indexLoader} />
          </div>
          <div>Chargement de mes annonces... </div>
        </div>
      </div>
    )

  if (announcements?.length === 0) {
    return (
      <div className="">
        <Nav />
        <div className="flex flex-col gap-5 justify-center items-center pt-40">
          <FaMoneyBillTrendUp className="text-[50px] text-slate-500" />
          <div>{"Vous n'avez pas d'annonces pour le moment"}</div>
          <div>
            <button
              className="bg-primary md:mt-0 py-[5px] px-2 md:py-2 md:px-4 text-light text-white text-sm rounded-lg flex items-center"
              onClick={() => {
                ReactGA.event({
                  category: 'Announcement',
                  action: 'Want to post',
                })
                navigate('/announcements/create')
              }}
            >
              <span className="pr-1">
                <IoMegaphoneOutline />
              </span>
              <span>Publiez une annonce</span>
            </button>
          </div>
        </div>
      </div>
    )
  }
  //if user is seller get all annoucements published else get annoucement published when it was user
  const items = currentUser
    ? announcements
    : announcements?.slice(
        currentPage * itemsPerPage,
        currentPage * itemsPerPage + itemsPerPage
      )

  return (
    <div className=" ">
      <Nav />
      <div className="px-[16px] lg:px-16 w-[100%] pt-[150px]">
        <div className="flex justify-between items-center pb-5 md:justify-between md:pt-0 md:items-center w-[100%]">
          <div className="">
            <h3 className="text-[17.5px] md:text-[25px] lg:text-[20px]">
              Annonces publiées
            </h3>
            <p className="font-light">
              {
                "Avec uty, élargissez votre champs d'action et vos gains en publiant vos produits et services"
              }
            </p>
          </div>
          <div>
            <button
              className="bg-primary md:mt-0 py-[5px] px-2 md:py-2 md:px-4 text-light text-white text-sm rounded-lg flex items-center"
              onClick={() => {
                ReactGA.event({
                  category: 'Announcement',
                  action: 'Want to post',
                })
                navigate('/announcements/create')
              }}
            >
              <span className="pr-1">
                <IoAdd />
              </span>
              <span className="block lg:hidden">Ajouter</span>
              <span className="lg:block sm:hidden md:hidden">
                Publier une annonce
              </span>
            </button>
          </div>
        </div>
        {announcements?.length > 0 || announcements?.length > 0 ? (
          <div className="grid gap-5">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              {items?.map((announcement) => {
                return (
                  <AnnouncemmentCard
                    key={announcement?._id}
                    announcement={announcement}
                    setSelected={setSelected}
                    setDeleting={setDeleting}
                    setOpen={setOpen}
                  />
                )
              })}
            </div>
            <div>
              {itemsPerPage < 12 && (
                <div className="flex items-center justify-center w-[60vw] gap-3">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 0}
                  >
                    <IoArrowBack />
                  </button>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      className={
                        currentPage !== index
                          ? 'text-white bg-primary rounded-full px-2'
                          : 'text-white bg-secondary rounded-full px-2'
                      }
                      key={index}
                      onClick={() => handlePageChange(index)}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages - 1}
                  >
                    <IoArrowForward />
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="text-[17px] font-medium">
            {/* {"Pas d' annonces trouvées."} */}
            {"Vous n'avez pas encore publié des annonces pour le moment"}
          </div>
        )}
      </div>
      {deleting && (
        <DeleteModal
          setOpenModal={setDeleting}
          selected={selected}
          openModal={deleting}
        />
      )}
      {connecting && (
        <Register
          openModal={connecting}
          setOpenModal={setConnecting}
          setRegistering={setRegistering}
        />
      )}
      {registering && (
        <RegisterModal openModal={registering} setOpenModal={setRegistering} />
      )}
      {open && (
        <DetailModal
          setOpenModal={setOpen}
          openModal={open}
          announcementId={selected}
        />
      )}
    </div>
  )
}
