import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const updateSubCategory = async (name, icon, description, params, isAdmin) => {
  console.log('Updating')
  const data = await axios.put(
    `${BASE_URL}/api/subcategories/update-subcategory/${params}`,
    {
      name,
      icon,
      description,
      isAdmin,
    }
  )
  return data
}

export { updateSubCategory }
