import Moment from 'react-moment'
import EmptyImage from '../../assets/blank-profile.png'

const columns = (navigate) => {
  // navigate
  return [
    {
      name: 'Nom utilisateur',
      selector: (row) => row?.username,
      sortable: true,
    },
    {
      name: 'Adresse Email',
      selector: (row) => row?.email || '-',
      sortable: true,
    },
    {
      name: 'Telephone',
      selector: (row) => `${row?.phone ? '+243' + row?.phone : '-'} `,
      sortable: true,
    },
    {
      name: "Piece d'identitée",
      selector: (row) => (
        <img
          src={row?.identity ? row?.image : EmptyImage}
          alt={row.name}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '100%',
            margin: '7px 0',
            objectFit: 'cover',
          }}
        />
      ),
    },
    {
      name: 'Date',
      selector: (row) => (
        <Moment
          format=" DD-MM-YYYY HH:mm"
          date={row?.updatedAt}
          className="text-[11px] italic"
        />
      ),
      sortable: true,
    },
    // {
    //   name: 'Vendeur',
    //   selector: (row) => `${row?.isSeller ? 'Oui' : 'Non'}`,
    //   sortable: true,
    // },
    // {
    //   name: 'Livreur',
    //   selector: (row) => `${row?.isDeliver ? 'Oui' : 'Non'}`,
    //   sortable: true,
    // },
    // {
    //   name: 'Admin',
    //   selector: (row) => `${row?.isAdmin ? 'Oui' : 'Non'}`,
    //   sortable: true,
    // },
    {
      cell: (row) => (
        <div
          className="bg-primary text-white px-2 rounded-[3px] py-1 border-none outline-none cursor-pointer"
          onClick={() => navigate(`/Account/detail-user/${row._id}`)}
        >
          Detail
        </div>
      ),
      div: true,
    },
  ]
}

export default columns
