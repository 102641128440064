import { Button, Label, TextInput, Select } from 'flowbite-react'
import AddImages from '../AddImages'
import { useEffect, useState } from 'react'

export default function UpdateFormStart({
  setName,
  categories,
  setCategory,
  category,
  setNext,
  name,
  images,
  setImages,
  setImgClicked,
  setPicClick,
  setImagesToDelete,
  setNewImages,
  newImages,
  updating,
  newcategoryselected,
  setNewcategoryselected,
  setNewsubcategoryselected,
  newsubcategoryselected,
  setSubcategory,
  subcategory,
  newSubCategories,
}) {
  const [nameError, setNameError] = useState('')
  const [subcategoryError, setSubcategoryError] = useState('')
  const [categoryError, setCategoryError] = useState('')
  const [imagesError, setImagesError] = useState('')

  const validateForm = () => {
    let isValid = true
    if (!name.trim()) {
      setNameError('Le nom du produit est requis.')
      isValid = false
    } else {
      setNameError('')
    }

    if (!category) {
      setCategoryError('La catégorie est requise.')
      isValid = false
    } else {
      setCategoryError('')
    }

    if (images.length === 0 && newImages.length === 0) {
      setImagesError('Au moins une image est requise.')
      isValid = false
    } else {
      setImagesError('')
    }

    if (!subcategory) {
      setSubcategoryError('Au moins une image est requise.')
      isValid = false
    } else {
      setSubcategoryError('')
    }

    return isValid
  }

  const handleContinue = () => {
    if (validateForm()) {
      setNext(true)
    }
  }

  useEffect(() => {
    // Initialiser newcategoryselected avec l'objet correspondant dans categories si category contient un identifiant
    if (category && categories) {
      const currentCategory = categories.find((cat) => cat._id === category)
      setNewcategoryselected(currentCategory)
    }
  }, [category, categories])

  useEffect(() => {
    // Initialiser newsubcategoryselected avec l'objet correspondant dans subcategories si subcategory contient un identifiant
    if (subcategory && newSubCategories) {
      const currentSubCategory = newSubCategories.find(
        (subcat) => subcat._id === subcategory
      )
      setNewsubcategoryselected(currentSubCategory)
    }
  }, [subcategory, newSubCategories])

  return (
    <div className="flex flex-col max-w-md gap-4">
      <div>
        <div className="block mb-2">
          <Label htmlFor="name" value="Nom du produit" />
        </div>
        <TextInput
          id="name"
          type="text"
          placeholder="mon produit"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
        />
        {nameError && (
          <p className="text-xs italic text-red-500">{nameError}</p>
        )}
      </div>
      <div>
        <div className="block mb-2">
          <Label htmlFor="" value="Les photos de votre produit" />
        </div>
        <AddImages
          setImages={setImages}
          images={images}
          setImgClicked={setImgClicked}
          setPicClick={setPicClick}
          setImagesToDelete={setImagesToDelete}
          setNewImages={setNewImages}
          newImages={newImages}
          updating={updating}
        />
        {imagesError && (
          <p className="text-xs italic text-red-500">{imagesError}</p>
        )}
      </div>

      {/* category choice */}
      <div className="max-w-md">
        <div className="block mb-2">
          <Label
            htmlFor="categories"
            value="Choisir la catégorie de votre produit"
          />
        </div>

        <Select
          id="categories"
          onChange={(e) => {
            const value = JSON.parse(e.target.value)
            setNewcategoryselected(value.category)
            setCategory(value.current_category)
          }}
          value={
            newcategoryselected
              ? JSON.stringify({
                  current_category: newcategoryselected._id,
                  category: newcategoryselected,
                })
              : ''
          }
          required
        >
          <option value="">Choisissez une catégorie</option>
          {categories?.map((category) => (
            <option
              key={category._id}
              value={JSON.stringify({
                current_category: category._id,
                category: category,
              })}
            >
              {category.name}
            </option>
          ))}
        </Select>

        {categoryError && (
          <p className="text-xs italic text-red-500">{categoryError}</p>
        )}
      </div>
      <div className="max-w-md">
        <div className="block mb-2">
          <Label
            htmlFor="categories"
            value="Choisir la sous catégorie de votre produit"
          />
        </div>
        <Select
          id="subcategories"
          onChange={(e) => {
            const value = JSON.parse(e.target.value)
            setNewsubcategoryselected(value.subcategory)
            setSubcategory(value.current_subcategory)
          }}
          value={
            newsubcategoryselected
              ? JSON.stringify({
                  current_subcategory: newsubcategoryselected._id,
                  subcategory: newsubcategoryselected,
                })
              : ''
          }
          required
        >
          <option value="">Choisissez une sous catégorie</option>
          {newSubCategories?.map((subcategory) => (
            <option
              key={subcategory._id}
              // value={JSON.stringify(subcategory) || null}
              value={JSON.stringify({
                current_subcategory: subcategory._id,
                subcategory: subcategory,
              })}
            >
              {subcategory.name}
            </option>
          ))}
        </Select>
        {subcategoryError && (
          <p className="text-xs italic text-red-500">{subcategoryError}</p>
        )}
      </div>
      <Button
        className="bg-primary"
        onClick={handleContinue}
        disabled={
          !name || !category || (images.length === 0 && newImages.length === 0)
        }
      >
        Continuer
      </Button>
    </div>
  )
}
