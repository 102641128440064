import React from 'react'
import { FaChevronUp } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

function ScrollTopPage({ fixedCall, executeScroll }) {
  // let icons = ["face", "data", "kop"]

  const navigate = useNavigate()

  console.log(navigate)

  return (
    fixedCall && (
      <div className="relative bg-black">
        <div
          onClick={executeScroll}
          className="fixed z-50 flex items-center justify-center w-10 h-10 font-bold text-white transition-transform transform rounded-md shadow-lg cursor-pointer right-9 bottom-40 bg-gradient-to-r from-blue-500 to-yellow-400 hover:scale-110"
        >
          <FaChevronUp className="text-2xl cursor-pointer" />
        </div>
      </div>
    )
  )
}

export default ScrollTopPage
