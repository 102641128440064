let weightRanges = [
  {
    valueMin: 1,
    valueMax: 5,
  },
  {
    valueMin: 5,
    valueMax: 10,
  },
  {
    valueMin: 10,
    valueMax: 20,
  },
  {
    valueMin: 20,
    valueMax: 30,
  },
  {
    valueMin: 30,
    valueMax: 50,
  },
  {
    valueMin: 50,
    valueMax: 75,
  },
  {
    valueMin: 75,
    valueMax: 90,
  },
  {
    valueMin: 90,
    valueMax: 0,
  },
]

export { weightRanges }
