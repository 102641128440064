import React from 'react'
import Nav from '../../components/layout/Nav'
import { useParams } from 'react-router-dom'
import { useGetCurrentCartQuery } from '../../services/cartApi'
import {
  useUpdateProductQuantityMutation,
  useDeleteProductToCartMutation,
} from '../../services/cartApi'
import { useDispatch } from 'react-redux'
import { setDropOffLocation } from '../../features/user/user'
import AllProductInCart from '../../components/cart/AllProductInCart'
import CartSummary from '../../components/cart/CartSummary'
import _ from 'lodash'
import distance from '../../helpers/Distance'
import LocationHandler from '../../components/location/LocationHandler'

export default function Cart() {
  const params = useParams()
  const dispatch = useDispatch()
  let currentLocation = JSON.parse(localStorage.getItem('currentLocation'))
  const { data: cart, isLoading } = useGetCurrentCartQuery(params.id)
  const [updateProductQuantity] = useUpdateProductQuantityMutation()
  const [deleteProductToCart] = useDeleteProductToCartMutation()

  const convertPrice = (price, currency) => {
    if (currency && currency.shortName === '$') {
      return price * 2800 // Conversion du prix en dollar en franc congolais
    }
    return price
  }

  const groupProductsBySellerOrUser = (cart) => {
    const groupedProducts = {}
    cart?.products?.forEach((product) => {
      const user = product?.productId?.user
      const seller = product?.productId?.seller

      if (user || (seller && seller?.user)) {
        const sellerOrUser = user?._id || seller?.user?._id
        const userOrSeller = user || seller?.user

        if (!groupedProducts[sellerOrUser]) {
          groupedProducts[sellerOrUser] = {
            products: [],
            seller: userOrSeller,
            images: [],
            prices: [],
          }
        }
        product?.status === 'not payed' &&
          groupedProducts[sellerOrUser]?.products.push(product)
        groupedProducts[sellerOrUser]?.images.push(product?.productId.images[0])
        groupedProducts[sellerOrUser]?.prices.push(
          convertPrice(
            product?.productId?.price * product?.quantity,
            product?.productId?.currency
          )
        )
        groupedProducts[sellerOrUser].shipmentFees =
          distance(
            userOrSeller?.location?.coordinates
              ? userOrSeller?.location?.coordinates
              : [15.2854934, -4.4251149],
            currentLocation
          ) * 1500

        groupedProducts[sellerOrUser].distance = distance(
          userOrSeller?.location?.coordinates
            ? userOrSeller?.location?.coordinates
            : [15.2854934, -4.4251149],
          currentLocation
        )
      }
    })
    return groupedProducts
  }
  let data = groupProductsBySellerOrUser(cart)

  console.log('prices:', data)

  let prices = _.sum(_.flatten(_.values(_.mapValues(data, (d) => d.prices))))

  let shipmentFees = _.sum(_.values(_.mapValues(data, (d) => d.shipmentFees)))

  const handleDeleteProductToCart = async (e, userId, productId) => {
    e.preventDefault()

    await deleteProductToCart({ product: productId, user: userId })
  }

  const handleUpdateProductQuantity = async (userId, productId, quantity) => {
    // e.preventDefault()
    let productData = {
      userId: userId,
      productId: productId,
      quantity: quantity,
    }
    await updateProductQuantity(productData)
  }

  console.log('cart:', cart, params.id)

  React.useEffect(() => {
    dispatch(setDropOffLocation(currentLocation))
  }, [])

  return (
    <div>
      <Nav />
      <main className="pt-32 lg:px-48 px-5">
        {' '}
        {/* Espacement plus aéré */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          {' '}
          {/* Conteneur pour le titre et la location */}
          <h2 className="text-2xl font-semibold text-gray-800">
            Mon panier
          </h2>{' '}
          {/* Titre plus visible */}
          <div className="mt-2">
            <LocationHandler />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:gap-8">
          {' '}
          {/* Espacement entre les colonnes */}
          {cart && cart?.products?.length > 0 && (
            <div className="lg:w-[40vw]">
              {' '}
              {/* Largeur de la colonne du résumé */}
              <CartSummary
                cart={cart}
                totalPrice={prices}
                totalShipFees={shipmentFees}
                data={_.mapValues(data, function (o) {
                  return o.products
                })}
                toPay={data}
              />
            </div>
          )}
          <div className="lg:w-2/3">
            {' '}
            {/* Largeur de la colonne des produits */}
            <AllProductInCart
              cart={cart}
              user={params?.id}
              handleDeleteProductToCart={handleDeleteProductToCart}
              handleUpdateProductQuantity={handleUpdateProductQuantity}
              isLoading={isLoading}
            />
          </div>
        </div>
      </main>
    </div>
  )
}
