import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Order', 'Orders'],
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query: (order) => ({
        url: 'api/orders/create',
        method: 'POST',
        body: order,
      }),
      invalidatesTags: ['Order'],
    }),
    acceptOrder: builder.mutation({
      query: ({ order, location }) => ({
        url: `api/orders/order/accept`,
        method: 'POST',
        body: { order: order, pickUpAddress: location },
      }),
      invalidatesTags: ['Orders'],
    }),
    refuseOrder: builder.mutation({
      query: (order) => ({
        url: `api/orders/order/refuse`,
        method: 'POST',
        body: { order: order },
      }),
      invalidatesTags: ['Orders'],
    }),
    deleteProductInOrder: builder.mutation({
      query: ({ order, product }) => ({
        url: `api/orders/delete/${order}`,
        method: 'POST',
        body: { productId: product },
      }),
      invalidatesTags: ['Orders'],
    }),
    // deleteProductToCart: builder.mutation({
    //   query: ({ product, user }) => ({
    //     url: `api/cart/delete-product/${user}`,
    //     method: 'POST',
    //     body: { productId: product },
    //   }),
    //   invalidatesTags: ['Cart'],
    // }),
    getOrdersToDeliver: builder.query({
      query: (seller) => `api/orders/seller/${seller}`,
      providesTags: ['Orders'],
    }),
    getCustomerOrders: builder.query({
      query: (user) => `api/orders/me/${user}`,
    }),
    getDeliveredOrders: builder.query({
      query: (seller) => `api/orders/delivered/${seller}`,
    }),
    getCustomerDeliveredOrders: builder.query({
      query: (user) => `api/orders/me/delivered/${user}`,
    }),
  }),
})

export const {
  useCreateOrderMutation,
  useGetOrdersToDeliverQuery,
  useAcceptOrderMutation,
  useRefuseOrderMutation,
  useDeleteProductInOrderMutation,
  useGetCustomerOrdersQuery,
  useGetDeliveredOrdersQuery,
  useGetCustomerDeliveredOrdersQuery,
} = orderApi
