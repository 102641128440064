import React from 'react'
import { PiImageLight } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'
import { setOpenCategories } from '../../../features/mainbar/open-categories'
import { useDispatch } from 'react-redux'
import { NumericFormat } from 'react-number-format'
import BlurPage from '../../blur/Blur'

function ListCategories({
  categories,
  filtercannouncements,
  setCategory,
  category,
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <div>
      <BlurPage />
      <div className="absolute bottom-0 left-0 right-0 bg-black md:static lg:static">
        <div
          className={`absolute shadow-delete h-[416px]  flex text-black w-[347px] md:w-[752px] lg:w-[1068px] bg-white`}
        >
          <div
            className={`w-[300px] md:w-[200px]  lg:[300px]  overflow-y-auto  ${category && 'hidden'} md:block`}
          >
            {categories?.map((category) => {
              return (
                <div
                  onMouseEnter={() => setCategory(category)}
                  key={category?._id}
                  className="flex items-center justify-between cursor-pointer py-2 px-2 hover:bg-[#F2F2F2] hover:font-semibold"
                >
                  <span className="flex items-center gap-2 lg:flex-none text-[16px] md:text-[16px]  lg:text-[17px]  text-[#222222] px-3">
                    <div className="w-[14px] h-[14px] rounded-[2px]">
                      <img
                        src={category?.icon}
                        className="w-[14px] h-[14px] rounded-[3px]"
                        alt=""
                      />
                    </div>
                    <div>{category?.name}</div>
                  </span>
                </div>
              )
            })}
            <div className="absolute text-black md:hidden top-2 right-2 lg:hidden">
              <IoMdClose
                className="text-[rgba(82,82,82,0.89)] text-[20px] text-red-500"
                title="Quitter"
                onClick={() => {
                  setCategory(null)
                }}
              />
            </div>
            <div
              className="absolute text-black top-2 right-2 lg:hidden"
              onClick={() => dispatch(setOpenCategories(false))}
            >
              <IoMdClose
                className="text-[rgba(82,82,82,0.89)] text-[20px] text-red-500"
                title="Quitter"
              />
            </div>
          </div>
          {filtercannouncements?.length > 0 ? (
            <div
              className={`pt-2 relative flex-grow overflow-y-auto ${category ? 'block' : 'hidden'}  md:block`}
            >
              <span className="px-2 pb-4 font-medium md:px-10">
                {category?.name?.charAt(0).toUpperCase() +
                  category?.name?.substring(1)}
              </span>
              <div className="grid grid-cols-3 m-8 md:w-auto md:pt-2 md:grid-cols-3 lg:grid-cols-5">
                {filtercannouncements?.map((announcement) => {
                  return (
                    <div
                      key={announcement?._id}
                      className="flex flex-col items-center justify-center py-2 rounded-sm cursor-pointer md:flex md:flex-col md:items-center"
                    >
                      <div className="w-[60px] h-[60px] md:w-[70px] md:h-[70px] lg:w-[75px] lg:h-[75px] lg:hover:w-[80px]">
                        <img
                          onClick={() =>
                            navigate(
                              `/announcements/${announcement?._id}/${announcement?.category?._id}`
                            )
                          }
                          src={announcement?.images[0]}
                          alt=""
                          className="w-[6Opx] h-[60px] md:w-[70px] md:h-[70px] lg:w-[75px] lg:h-[75px] rounded-full object-cover lg:hover:w-[80px] lg:hover:h-[80px] hover:shadow-delete"
                        />
                      </div>
                      <span
                        className={`text-[13px] text-[#222222] pt-2 inline-block ${announcement?.name?.length > 18 && 'w-[100px] whitespace-nowrap overflow-hidden text-ellipsis'} `}
                      >
                        {announcement?.name}
                      </span>
                      <div className="flex gap-2">
                        <span className="text-[rgba(0,0,0,0.65)] md:pt-2 text-sm font-black">
                          <NumericFormat
                            value={announcement?.price}
                            displayType={'text'}
                            thousandSeparator=" "
                          />
                          {announcement?.currency?.shortName === '$'
                            ? '$'
                            : 'fc'}
                        </span>
                      </div>
                    </div>
                  )
                })}
                <div className="absolute text-black md:hidden top-2 right-2 lg:hidden">
                  <IoMdClose
                    className="text-[rgba(82,82,82,0.89)] text-[20px]"
                    title="Quitter"
                    onClick={() => {
                      dispatch(setOpenCategories(false))
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="items-center justify-center flex-grow hidden md:flex">
              <div className="flex flex-col items-center text-[16px] ">
                <span className="text-[20px] font-semibold text-[#424242]"></span>
                {category ? (
                  <span className="text-[#727272]">
                    <div className="flex flex-col">
                      <div className="text-center">
                        {category?.name?.charAt(0).toUpperCase() +
                          category?.name?.substring(1)}
                      </div>
                      {"Pas d'annonces pour cette categorie."}
                    </div>
                  </span>
                ) : (
                  ''
                )}

                <PiImageLight className="text-[140px] text-[#727272]" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ListCategories
