import '../../src/styles/carosel.css'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import '../pages/Carosel.css'
import { useGetShopsQuery } from '../services/shopApi'
import { useNavigate } from 'react-router-dom'

const Shops = () => {
  const navigate = useNavigate()
  const { data: shops } = useGetShopsQuery()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getSliderSettings = () => {
    let slidesToShow = 5
    if (screenWidth >= 1024) {
      slidesToShow = Math.min(5, shops.length)
    } else if (screenWidth >= 768) {
      slidesToShow = Math.min(3, shops.length)
    } else {
      slidesToShow = Math.min(2, shops.length)
    }

    return {
      dots: false,
      infinite: true,
      speed: 700,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: Math.min(3, shops.length),
            slidesToScroll: Math.min(3, shops.length),
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: Math.min(2, shops.length),
            slidesToScroll: Math.min(2, shops.length),
          },
        },
      ],
    }
  }

  const shouldDisplaySlider = () => {
    if (screenWidth >= 1024 && shops?.length > 5) {
      return true
    } else if (screenWidth >= 768 && shops?.length > 3) {
      return true
    } else if (screenWidth < 768 && shops?.length > 2) {
      return true
    }
    return false
  }

  const getGridCols = () => {
    if (screenWidth >= 1024) {
      return 'grid-cols-5'
    } else if (screenWidth >= 768) {
      return 'grid-cols-3'
    } else {
      return 'grid-cols-2'
    }
  }

  const [loaderCount, setLoaderCount] = useState([])

  useEffect(() => {
    const updateLoaderCount = () => {
      const width = window.innerWidth

      if (width >= 1024) {
        setLoaderCount([1, 2, 3, 4, 5])
      } else if (width >= 768) {
        setLoaderCount([1, 2, 3])
      } else {
        setLoaderCount([1, 2])
      }
    }

    updateLoaderCount()

    window.addEventListener('resize', updateLoaderCount)

    return () => {
      window.removeEventListener('resize', updateLoaderCount)
    }
  }, [])

  return shops?.length > 0 ? (
    <div className="pt-9 md:block lg:pt-6">
      <div className="carousel-container">
        <h2 className="md:block lg:block text-[#21344e] text-[16px] font-bold md:pt-7 md:text-[18px]">
          Les enseignes
        </h2>
        {shouldDisplaySlider() ? (
          <Slider {...getSliderSettings()}>
            {shops?.map((shop, index) => (
              <div
                key={shop?._id}
                className="carousel-item relative rounded-[15px]"
              >
                <div className="p-[6px] rounded-[12px] pb-9 relative bg-blue-100">
                  <div className="bg-white rounded-[20px]">
                    <img
                      src={shop?.logo}
                      onClick={() =>
                        navigate(`/shop/${shop?._id}/products`, {
                          state: { key: shop },
                        })
                      }
                      className="w-[100px] hover:cursor-pointer h-[150px] md:h-[200px] object-cover origin-center"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                  <span className="text-sm absolute left-0 pb-2 text-center right-0 bottom-0 font-poppins text-[#272727]">
                    {shop?.name}
                  </span>
                  {/* <div></div> */}
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div className={`grid ${getGridCols()} gap-4`}>
            {shops?.map((shop, index) => (
              <div
                key={shop?._id}
                className="carousel-item relative rounded-[15px]"
              >
                <div className="p-[6px] rounded-[12px] pb-12 relative bg-blue-100">
                  <div className="bg-white rounded-[20px]">
                    <img
                      src={shop?.logo}
                      onClick={() =>
                        navigate(`/shop/${shop?._id}/products`, {
                          state: { key: shop },
                        })
                      }
                      className="w-[100px] hover:cursor-pointer h-[150px] md:h-[200px] object-cover origin-center"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                  <span className="text-sm absolute left-0 pb-2 text-center right-0 bottom-0 font-poppins text-[#272727]">
                    {shop?.name}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="pb-8 md:pt-10 lg:pt-36 lg:carousel-container">
      <h2 className="pt-10 md:block pb-3 lg:block text-[#21344e] text-[16px] font-bold md:pt-7 md:text-[18px]"></h2>
      <div className={`grid ${getGridCols()} gap-4`}>
        {loaderCount?.map((index) => (
          <div
            key={index}
            className="cursor-pointer w-full mb-1 border-[0.001rem] rounded-[8px] pb-2 shadow-[0_0_0_rgba(48, 48, 49, 0.3)] hover:shadow-[0_0_15px_rgba(20,_20,_20,_0.2)] transition-all ease-in-out delay-150 md:h-[auto] "
          >
            <div
              alt=""
              className="hover:cursor-pointer h-[65px] md:h-[80px] image animated-background"
            />
            <div className="px-3 mt-2">
              <div className="flex">
                <span className="h-[20px] w-[70px] md:w-[100%] animated-background"></span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Shops
