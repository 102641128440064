import React, { useEffect } from 'react'
import { SidebarAdmin } from './SidebarData'
import SubMenu from './SubMenu'
import { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import Profil from '../../assets/profil.jpg'
import { useNavigate } from 'react-router-dom'

export default function Sidebar({ active, setActive }) {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const [links, setLinks] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser?.isAdmin) {
      navigate('/')
    } else {
      setLinks(SidebarAdmin)
    }
  }, [currentUser])

  return (
    <div
      className="h-100vh px-[16px]  bg-gray-100 "
      onClick={() => setActive(false)}
    >
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <div className="flex flex-row py-2 items-center">
            <IoClose className="text-[35px] md:hidden" />
            <div className="text-[18px] font-light pl-2">Mon espace</div>
          </div>
          <div className="h-[35px] w-[35px] bg-red-400 rounded-full">
            <img
              src={currentUser?.image ? currentUser?.image : Profil}
              alt="Profil"
              className="rounded-full h-[35px] w-[35px] object-cover"
            />
          </div>
        </div>
        <hr />
      </div>
      <div className="flex flex-col ">
        <div className="py-4"></div>
        {links.map((item, index) => {
          return (
            <SubMenu
              item={item}
              active={active}
              setActive={setActive}
              key={index}
            />
          )
        })}
      </div>
    </div>
  )
}
