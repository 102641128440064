import React, { useState } from 'react'
import { LuBadgeInfo } from 'react-icons/lu'
import { IoClose } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

function RequestRefused({ success, requestDeliverRefused, data }) {
  const [showModal, setShowModal] = useState(false)
  // const [noToService, setNoToService] = useState(true)

  const navigate = useNavigate()

  console.log('reason refused : ', data)
  return (
    <div>
      {success === true && requestDeliverRefused && (
        <div className=" flex flex-col items-center justify-center">
          <h2 className=" font-roboto text-center text-[17px] md:text-[20px] lg:text-[20px] text-[#707070]">
            Votre demande est declinée
          </h2>
          <div className="w-[100px] h-[100px] md:w-[110px] md:h-[110px] lg:w-[130px] lg:h-[130px] my-4">
            <LuBadgeInfo className="w-[100px] h-[100px] md:w-[110px] md:h-[110px] lg:w-[130px] lg:h-[130px] text-red-400" />
          </div>
          <div className="text-center text-[#5a5a5a]">
            Cliquez-ici pour voir la(les) raison(s) pour laquelle votre demande
            a été declinée.
          </div>
          <div
            onClick={() => setShowModal(true)}
            className=" flex items-center text-center text-white cursor-pointer bg-primary hover:bg-blue-700 font-roboto px-3 py-1 rounded-[3px] text-[15px] mt-3"
          >
            Voir le detail
          </div>
        </div>
      )}
      {showModal && (
        <div className=" absolute top-0 right-0 z-[100] h-[100vh] w-[100wh] flex justify-center items-center  left-0 bottom-0 bg-[rgba(46,46,46,0.6)]">
          <div className="flex flex-col bg-white relative w-[400px] text-center py-2 px-2 rounded-[5px]">
            <div className="flex justify-end">
              <div
                onClick={() => setShowModal(false)}
                className="bg-red-500 hover:bg-red-400 text-white cursor-pointer px-[2px] py-[2px] rounded-[5px]"
              >
                <IoClose />
              </div>
            </div>
            {data?.decline?.slice(-1)?.map((message, index) => (
              <div key={index} className="py-2 flex flex-col">
                <h1 className=" font-righteous text-xl pb-2 text-[rgba(46,46,46,0.87)]">
                  {message?.object}
                </h1>
                <span className=" font-medium text-[rgba(46,46,46,0.47)] ">
                  {message?.reason}
                </span>
                {index !== data.length - 1 && (
                  <div className="h-[1px] mt-5 opacity-10 bg-[#838383]"></div>
                )}
                <div
                  onClick={() => navigate(`/delivery/edit/${data?._id}`)}
                  className=" block text-center text-white cursor-pointer bg-blue-800 hover:bg-blue-700 font-medium px-3 py-1 rounded-[3px] text-[15px] mt-3"
                >
                  Modifier vos informations
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default RequestRefused
