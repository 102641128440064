// import { FaCheck } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import { FiAlertTriangle } from 'react-icons/fi'

function ToastToCreateAccountSeller({ verify, setVerify, message }) {
  const navigate = useNavigate()

  return (
    <div
      className={
        verify
          ? 'flex justify-center items-center  ml-0 px- px-4 py-3 w-[285px] bg-[#2a2929] transition-all ease-out delay-700 duration-300 text-[#ffffff] rounded-[4px] absolute mt-[80px] mr-6 top-0 right-0 z-50'
          : 'flex justify-center items-center  ml-200px px-4 py-3 transition-all ease-out delay-700 duration-300 w-[285px] bg-[#2a2929] text-[#ffffff] top-0 rounded-[4px] absolute mt-[-150px] mr-6 right-[0px] z-50'
      }
    >
      <div className=" bg-white-500 rounded-full p-2 mr-2 border">
        <FiAlertTriangle className=" text-white" />
      </div>
      <div className="text-[14px]">
        <div>{message}</div>
        <div className="italic text-[12px] pt-2">
          {
            "Vous n'avez droit qu'à publier 10 announces avec un compte utilisateur"
          }
        </div>
        <div className="flex justify-end gap-3 mt-2">
          <div
            className="bg-[rgba(255,0,0,0.37)] hover:cursor-pointer px-[7px] py-[2px] rounded-[4px]"
            onClick={() => setVerify(false)}
          >
            Plus tard
          </div>
          <div
            className="bg-[rgba(0,128,0,0.55)] hover:cursor-pointer px-[7px] py-[2px] rounded-[4px]"
            onClick={() => navigate('/Account/seller')}
          >
            Devenir vendeur
          </div>
        </div>
      </div>

      <div className="h-[3px] bg-green-500 absolute bottom-0 left-0 right-0 z-20"></div>
    </div>
  )
}

export default ToastToCreateAccountSeller
