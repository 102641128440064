import React from 'react'
import { Modal } from 'flowbite-react'
import OrderShipmentMap from '../orders/OrderShipmentMap'
import { formatDistance } from '../../helpers/FormatDistance'
import { secondsToHms } from '../../helpers/FormatDuration'
import StartShipment from './StartShipment'

export default function Shipment({ openModal, setOpenModal, shipment }) {
  const [trajectory, setTrajectory] = React.useState({
    distance: '',
    duration: '',
  })
  console.log('ship order', shipment?.order)
  return (
    <>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>
          <div>
            Suivi de livraison de la commande {shipment?.order?._id?.slice(6)}
          </div>
          <div className="flex gap-2 py-3 text-sm">
            <div>
              Distance à parcourir:{' '}
              <span>{formatDistance(trajectory?.distance)}</span>
            </div>
            <div>
              Durée du trajet: <span>{secondsToHms(trajectory?.duration)}</span>
            </div>
            <div>
              Cout du trajet:{' '}
              <span>{parseInt(trajectory?.distance) / 2} fc</span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="py-2 pl-5">
            {shipment?.deliver && <StartShipment shipment={shipment} />}
          </div>
          <div className="px-5 pb-10">
            <OrderShipmentMap
              pickUpLocation={shipment?.order?.pickUpLocation?.coordinates}
              dropOffAddress={shipment?.order?.dropOffLocation?.coordinates}
              deliverLocation={shipment?.deliver?.user?.location?.coordinates}
              setTrajectory={setTrajectory}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
