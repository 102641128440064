import React, { useEffect, useState } from 'react'
import UserChat from '../../assets/profil.png'
import { getUser } from '../../services/Users/get-user'

function Conversation({
  chats,
  currentUser,
  selectedUserIndex,
  onlineUser,
  searchUsers,
}) {
  const [user, setUser] = useState(null)

  const userId = chats.members.find((id) => id !== currentUser)
  const userSelected = selectedUserIndex?.members.find(
    (id) => id !== currentUser
  )
  useEffect(() => {
    if (userId) {
      getUser(userId, setUser)
    }
  }, [currentUser, chats])

  const filteredUser = user?.username.toLowerCase().includes(searchUsers)
    ? user
    : null

  const data = searchUsers.length > 0 ? filteredUser : user

  return (
    <>
      {data != null && (
        <div
          className={userSelected === userId ? 'bg-[#e9e6e6]' : 'bg-[#fafafa] '}
        >
          <div className="px-3 flex py-4 lg:py-3 relative md:px-4 lg:px-12">
            <div className={`w-[42px] h-[42px] lg:w-[40px] lg:h-[40px] mr-2`}>
              <img
                src={data?.image ? data?.image : UserChat}
                alt="profil user"
                className={`w-[42px] h-[42px] lg:w-[40px] lg:h-[40px] object-cover object-center rounded-full ${onlineUser?.find((user) => user?.userId === userId) && 'border-[3px] border-green-400 p-1'}`}
              />
            </div>
            <div>
              <div className="text-[15px] lg:text-[14px]">{data?.username}</div>
              <div className="text-[11px] font-light">
                {onlineUser?.find((user) => user.userId === userId) ? (
                  <div className=" text-[11px] font-medium text-green-500">
                    En ligne
                  </div>
                ) : (
                  <div className=" text-[11px] font-medium">UTY</div>
                )}
              </div>
            </div>
          </div>
          <hr className=" opacity-30"></hr>
        </div>
      )}
    </>
  )
}

export default Conversation
