import React from 'react'
import { Helmet } from 'react-helmet'

import { MdWhatsapp } from 'react-icons/md'
import { messageWhatsapp } from '../../helpers/calls/text-whatsapp'
import { postTrackerContact } from '../../services/trackers/post-tracker-contact'

function CallPhoneWhatsapp({
  phoneNumber,
  imageProduct,
  nameProduct,
  descriptionProduct,
  customerId,
  sellerId,
  announcementId,
}) {
  if (phoneNumber && !phoneNumber.startsWith('243')) {
    phoneNumber = '+243' + phoneNumber
  }

  const link = `https://uty.cd${window.location.pathname}${window.location.search}`

  const isDesktop = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    return /windows|macintosh|linux/i.test(userAgent)
  }

  let data = {
    typetracker: 'whatsapp',
    announcement: announcementId,
  }

  const handleWhatsAppClick = () => {
    postTrackerContact(customerId, sellerId, data)
    let message = messageWhatsapp()
    const combinedMessage = `${message}\n${link}`
    const encodedMessage = encodeURIComponent(combinedMessage)

    const whatsappUrl = isDesktop()
      ? `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`
      : `https://wa.me/${phoneNumber}?text=${encodedMessage}`

    if (phoneNumber) {
      setTimeout(() => {
        window.location.href = whatsappUrl
      }, 100)
    } else {
      alert('Numéro de téléphone non disponible')
    }
  }

  const handleWhatsAppClickForMobile = () => {
    postTrackerContact(customerId, sellerId, data)
    let message = messageWhatsapp()
    const combinedMessage = `${message}\n${link}`
    const encodedMessage = encodeURIComponent(combinedMessage)

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`

    if (phoneNumber) {
      setTimeout(() => {
        window.location.href = whatsappUrl
      }, 100)
    } else {
      alert('Numéro de téléphone non disponible')
    }
  }

  return (
    phoneNumber && (
      <div>
        {/* <Helmet>
          <meta
            id="meta-description"
            name="description"
            content="Some description."
          />
          <meta property="og:title" content={nameProduct} />
          <meta property="og:description" content={descriptionProduct} />
          <meta property="og:image" id="og-image" content={imageProduct} />
          <meta
            property="og:url"
            content={`https://uty.cd${window.location.pathname + window.location.search}`}
          />
        </Helmet> */}
        <Helmet>
          <title>UTY</title>
          <meta property="og:title" content={nameProduct} />
          <meta property="description" content={descriptionProduct} />
          <meta property="og:description" content={descriptionProduct} />
          <meta property="og:image" content={imageProduct} />
          <meta property="og:url" content={link} />
        </Helmet>
        <div
          className="hidden gap-1 cursor-pointer  lg:block"
          onClick={handleWhatsAppClick}
        >
          <div className="flex flex-row px-3 md:px-3 lg:px-3 bg-[#27a154] rounded-[12px] md:flex-row lg:flex-row items-center gap-1 text-2xl text-[22px] md:text-[19px] font-light text-[#747d88]">
            <MdWhatsapp className="text-white " />
            <div className="text-[12px] md:text-[13px] lg:text-[13px] font-medium text-white">
              Whatsapp
            </div>
          </div>
        </div>
        <div
          className="block gap-1 cursor-pointer  lg:hidden"
          onClick={handleWhatsAppClickForMobile}
        >
          <div className="flex flex-row px-3 md:px-3 lg:px-3 bg-[#27a154] rounded-[12px] md:flex-row lg:flex-row items-center gap-1 text-2xl text-[22px] md:text-[19px] font-light text-[#747d88]">
            <MdWhatsapp className="text-white " />
            <div className="text-[12px] md:text-[13px] lg:text-[13px] font-medium text-white">
              Whatsapp
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default CallPhoneWhatsapp
