import { useState } from 'react'
import Register from '../pages/authentification/Register'
import RegisterModal from './modal/RegisterModal'
import BannerHeader from '../assets/banner/banner.jpeg'
import { useNavigate } from 'react-router-dom'
// import { FaMoneyBill } from 'react-icons/fa'
import { MdOutlinePublish } from 'react-icons/md'
import { useSelector } from 'react-redux'
import ScrollListCategories from './chats/ScrollListCategories'

function HomeHeader() {
  const [connecting, setConnecting] = useState(false)
  const [registering, setRegistering] = useState(false)
  let navigate = useNavigate()
  let currentUser = useSelector((state) => state.user.currentUser)
  let shop = useSelector((state) => state.user.myShop)

  const handleCommand = (e) => {
    e.preventDefault()
    currentUser ? navigate(`/categories`) : setConnecting(true)
  }

  const handleSell = (e) => {
    e.preventDefault()
    currentUser
      ? shop
        ? navigate(`/shop/me/${shop._id}`)
        : navigate(`/announcements/me/${currentUser._id}`)
      : setConnecting(true)
  }

  return (
    <div className="relative">
      <header className="relative z-30 sm:hidden md:hidden lg:block">
        <div
          className="header-container"
          style={{ backgroundImage: `url(${BannerHeader})` }}
        >
          <div className="header-overlay"></div>
          <div className="flex flex-col items-center justify-center px-16 pt-28 header-content">
            <div className="flex items-center justify-between w-full">
              <div className=" lg:col-span-3 md:col-span-5">
                {/* Ajoutez votre contenu ici */}
                {/* Exemple de contenu */}
                <h1 className="mb-4 text-4xl font-bold">Bienvenue sur uty</h1>
                <p className="text-lg ">
                  Commandez et recevez vos achats directement à votre porte.
                </p>
                <div className="flex gap-5 mt-4">
                  <button
                    className="px-4 py-2 text-white transition border border-white rounded hover:bg-white hover:text-black"
                    onClick={(e) => handleCommand(e)}
                  >
                    Commandez
                  </button>
                  <button
                    className="px-4 py-2 text-white transition border border-white rounded hover:bg-white hover:text-black"
                    onClick={(e) => handleSell(e)}
                  >
                    Vendez
                  </button>
                </div>
              </div>
              <button
                className="flex items-center gap-3 px-4 py-2 font-semibold text-[rgba(0,0,0,0.78)] transition bg-[#f7f7f7] border border-white rounded hover:opacity-50 hover:bg-white hover:text-black"
                onClick={() => navigate('/categories')}
              >
                Tous les produits <MdOutlinePublish />
              </button>
            </div>
          </div>
        </div>
        {connecting && (
          <Register
            openModal={connecting}
            setOpenModal={setConnecting}
            setRegistering={setRegistering}
          />
        )}
        {registering && (
          <RegisterModal
            openModal={registering}
            setOpenModal={setRegistering}
          />
        )}
      </header>
      <div className="relative h-32 ">
        <ScrollListCategories />
      </div>
    </div>
  )
}

export default HomeHeader

// import { useState } from 'react'
// // import { useNavigate } from 'react-router-dom'
// // import AccueilPhoto from '../assets/backheader.png'
// // import ReactGA from 'react-ga4'
// import Register from '../pages/authentification/Register'
// import RegisterModal from './modal/RegisterModal'
// // import backgroundImage from '../assets/uty-bg-local.jpg'
// import BannerHeader from '../assets/banner/banner.jpeg'

// function HomeHeader() {
//   // const navigate = useNavigate()
//   const [connecting, setConnecting] = useState(false)
//   const [registering, setRegistering] = useState(false)
//   // const seller = localStorage.getItem('seller')
//   // let currentUser = JSON.parse(localStorage.getItem('currentUser'))

//   // const handleCommand = () => {
//   //   ReactGA.event({
//   //     category: 'Main clicks',
//   //     action: 'Click to sell button',
//   //   })
//   //   if (!currentUser && !seller) {
//   //     setConnecting(true)
//   //   } else if (seller) {
//   //     navigate(`/announcements/me/${currentUser._id}`)
//   //   } else {
//   //     navigate(`/announcements/me/${currentUser._id}`)
//   //   }
//   // }

//   return (
//     <header className="sm:hidden md:hidden lg:block">
//       <div className="absolute inset-0 bg-[rgba(21,23,44,0.65)] opacity-80 h-[62vh]"></div>
//       <div
//         className="px-[16px] object-bottom bg-no-repeat bg-cover bg-center md:px-16 grid items-center pt-14 md:pt-24 md:grid-cols-5 lg:h-[62vh] md:h-[100vh]"
//         style={{ backgroundImage: `url(${BannerHeader})` }}
//       >
//         <div className=" w-[100%] text-white z-10 lg:col-span-3 md:col-span-5">
//           {/* <div className="text-[24px] md:text-[50px] mb-3 font-poppins text-white leading-tight">
//             <strong className="font-extrabold">Commandez</strong> depuis chez
//             vous avec{' '}
//             <strong className="text-secondary font-extrabold bg-white py-[0.5px] px-[7.5px] rounded-[2rem]">
//               u<strong className="font-extrabold text-primary">t</strong>y
//             </strong>{' '}
//             et recevez vos achats.
//           </div> */}
//           {/* <div className="mb-3 text-sm leading-7 tracking-wide text-white">
//             Rejoignez Uty aujourd’hui et découvrez le meilleur de ce que votre
//             communauté a à offrir.
//           </div> */}
//           <div className="flex gap-5">
//             {/* <div
//               className="w-[50%] md:w-[35%] px-0 border-[1px] border-white text-center md:inline-block font-normal cursor-pointer text-white text-[15px] mt-4 md:px-12 py-[10px] md:py-[7px] hover:rounded-[0.5rem] rounded-[1rem] transition-all delay-200 ease-in-out"
//               onClick={() => {
//                 ReactGA.event({
//                   category: 'Main clicks',
//                   action: 'Click to order button on home page',
//                 })
//                 navigate('/categories')
//               }}
//             >
//               Commandez
//             </div> */}
//             {/* <div
//               className="w-[50%] md:w-[35%] px-0 border-[1px] border-white text-center md:inline-block font-normal cursor-pointer text-white text-[15px] mt-4 md:px-16 py-[10px] md:py-[7px] hover:rounded-[0.5rem] rounded-[1rem] transition-all delay-200 ease-in-out"
//               onClick={handleCommand}
//             >
//               Vendez
//             </div> */}
//           </div>
//         </div>
//         {/* <div className=" hidden md:block md:w-[50] ">
//           <img
//             src={AccueilPhoto}
//             alt=""
//             className="hidden md:w-[70%] md:block md:ml-auto"
//           />
//         </div> */}
//       </div>
//       {connecting && (
//         <Register
//           openModal={connecting}
//           setOpenModal={setConnecting}
//           setRegistering={setRegistering}
//         />
//       )}
//       {registering && (
//         <RegisterModal openModal={registering} setOpenModal={setRegistering} />
//       )}
//     </header>
//   )
// }

// export default HomeHeader

// // const containerRef = useRef(null)
// // const [announceProvider, setAnnounceProvider] = useState()
// // const [loader, setLoader] = useState(false)

// /*
//   useEffect(() => {
//     axios
//       .get('https://uty-ti30.onrender.com/api/provider/get-sellers')
//       .then((response) => {
//         setAnnounceProvider(response.data)
//         setTimeout(() => {
//           setLoader(true)
//         }, 1000)
//       })
//       .catch((error) => {
//         console.log(error)
//       })
//   }, [])
//   */

// // const limiText = 'w-12 text-ellipsis overflow-hidden whitespace-nowrap'
// // #efefef

// /* Commandez depuis chez vous avec{' '}
//             <strong className="text-primary">UTY</strong> et recevez vos achats
//             directement a votre porte.
//   */

// /*
// <div className="flex justify-center items-center px-[10px]  h-[100vh] w-[100%] bg-center bg-no-repeat bg-cover :bg-center :bg-no-repeat :bg-cover  md:flex-none md:p-16 md:h-[100vh] dasableImage">
//       <div className="flex justify-between flex-col w-[100%] pt-20">
//         {loader ? (
//           <div className="relative z-10">
//             <div className="flex">
//               <div
//                 id="slider"
//                 ref={containerRef}
//                 className="flex justify-between w-full h-full overflow-hidden overflow-x-auto text-ellipsis whitespace-nowrap"
//               >
//                 <div className="absolute top-6 left-0 z-20 sm:top-9 text-[20px] rounded-full hover:bg-[#dfd9d9] transition ease-in-out delay-150 bg-[#ffffff]">
//                   <ScrollRight containerRef={containerRef} />
//                 </div>
//                 <AnnounceUser data={announceProvider} loader={loader} />
//                 <div className="absolute top-6 right-0 z-20 sm:top-9 text-[20px] rounded-full hover:bg-[#dfd9d9] transition ease-in-out delay-150 bg-[#ffffff] ">
//                   <ScrollLeft containerRef={containerRef} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <div className="grid grid-cols-6 gap-4">
//             <div className="loading-spinner"></div>
//             <div className="loading-spinner"></div>
//             <div className="loading-spinner"></div>
//             <div className="loading-spinner"></div>
//             <div className="loading-spinner"></div>
//             <div className="loading-spinner"></div>
//           </div>
//         )}
//         </div>
//         </div>
//         */
