import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  //addProduct,
  removeProduct,
  updateQuantity,
  substractQuantity,
  clearBasket,
} from '../features/cart/cart'
import Nav from '../components/layout/Nav'
import { IoAddSharp, IoRemove } from 'react-icons/io5'
import { NumericFormat } from 'react-number-format'

// Créer un sélecteur pour calcdiver le montant total du panier
const selectTotal = (state) =>
  state.basket.reduce(
    (acc, product) => acc + product.price * product.quantite,
    0
  )

// Créer le composant Panier
export default function Cart() {
  // Utiliser le hook useDispatch pour accéder au dispatch du magasin Redux
  const dispatch = useDispatch()

  // Utiliser le hook useSelector pour accéder à l'état du panier
  const basket = useSelector((state) => state.basket)

  // Utiliser le hook useSelector pour accéder au montant total du panier
  const total = useSelector(selectTotal)

  // Créer une fonction pour supprimer un produit du panier
  const handleRemove = (code) => {
    // Dispatcher l'action removeProduct avec le code du produit
    dispatch(removeProduct(code))
  }

  // Créer une fonction pour modifier la quantité d'un produit dans le panier
  //const handleChange = (code, quantity) => {
  // Dispatcher l'action updateQuantity avec le code du produit et la nouvelle quantité
  //dispatch(updateQuantity({ code, quantity }))
  //}

  // Créer une fonction pour passer la commande
  const handleOrder = () => {
    // Afficher un message de confirmation
    alert(`Merci pour votre commande de ${total} euros !`)
    // Dispatcher l'action clearBasket pour vider le panier
    dispatch(clearBasket())
  }

  React.useEffect(() => {}, [])

  return (
    <div>
      <div>
        <Nav />
      </div>
      <div className="py-20 px-16">
        <h2 className="text-xl font-poppins">Votre panier</h2>
        {basket.length > 0 ? (
          <div className="pt-3">
            <div>
              {basket.map((product) => (
                <div key={product._id} className="flex md:flex-col w-[20vw]">
                  <div>
                    <img src={product.images[0]} className="" />
                  </div>
                  <div>
                    <div>{product.name}</div>
                    <div className="truncate">{product.description}</div>
                  </div>
                  <div className="flex gap-7">
                    <div>
                      <NumericFormat
                        value={product?.price * product.quantite}
                        displayType={'text'}
                        thousandSeparator=" "
                      />
                      {' FC'}
                    </div>
                    <div className="flex items-center gap-2">
                      <IoAddSharp
                        onClick={() => dispatch(updateQuantity(product._id))}
                      />
                      <span>{product.quantite}</span>
                      <IoRemove
                        onClick={() => dispatch(substractQuantity(product._id))}
                      />
                    </div>
                  </div>
                  <button onClick={() => handleRemove(product._id)}>
                    Supprimer
                  </button>
                </div>
              ))}
            </div>
            <p>Montant total : {total} FC</p>
            <button onClick={handleOrder}>Passer la commande</button>
          </div>
        ) : (
          <p>Votre panier est vide.</p>
        )}
      </div>
    </div>
  )
}
