import React from 'react'
import Moment from 'react-moment'
import 'moment-timezone'

function MessageOnlyText({ message, owner }) {
  return (
    <div
      className={
        owner
          ? 'max-w-[250px]  md:max-w-[450px] lg:max-w-[450px] shadow-message bg-[#8ba1ee] shadow-white-500/50 my-[1px] md:my-[1px] py-1 px-2 flex  rounded-[11px]'
          : 'max-w-[250px]  md:max-w-[450px] lg:max-w-[450px] shadow-message bg-yellow-300 shadow-white-500/50 my-[1px] px-2 py-1 md:my-[1px] flex rounded-[11px] '
      }
    >
      <div className="flex justify-between relative">
        <div className=" text-[rgba(0,0,0,0.76)] text-[14px] relative flex">
          <div className="pr-[3px]">{message.text}</div>
          <div className="flex flex-col justify-end ">
            <Moment
              format="HH:mm"
              date={message?.createdAt}
              className="text-[10px] text-[rgba(0,0,0,0.5)] "
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageOnlyText
