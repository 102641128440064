import React from 'react'

function NoChat() {
  return (
    <div className=" flex flex-col justify-center items-center ">
      <div className="text-[23px] text-center text-primary">
        Aucune discussion selectionnée.
      </div>
      <div className="text-center pt-4 text-[rgba(0,0,0,0.76)]">
        Pour pouvoir ouvrir une discussion, vous devez cliquer sur un
        utilisateur.
      </div>
    </div>
  )
}

export default NoChat
