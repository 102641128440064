import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import 'tailwindcss/tailwind.css'
import TagManager from 'react-gtm-module'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './app/store'

// import 'bootstrap/dist/css/bootstrap.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
const tagManagerArgs = {
  gtmId: 'G-CEJ3BQ6H38',
}

TagManager.initialize(tagManagerArgs)

TagManager.dataLayer({
  dataLayer: {
    event: 'conversion',
    send_to: 'AW-11454440255/VwuMCLn85qkZEL_G89Uq',
    value: 1.0,
    currency: 'USD',
  },
})
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
serviceWorkerRegistration.register()

// Dans votre fichier JavaScript principal

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
