export const legalList = [
  {
    title: 'Termes et conditions',
    content: [
      {
        title: 'Acceptation des Termes',
        text: ' En utilisant les services de Uty, vous acceptez de vous conformer à ces termes et conditions. Uty se réserve le droit de modifier ces termes à tout moment, et les utilisateurs seront informés des changements par les moyens de communication disponibles.',
      },
      {
        title: 'Utilisation du Service',
        text: "Uty permet aux vendeurs de publier des annonces, de discuter avec les acheteurs et de répondre aux demandes. Les échanges sont limités aux participants d'une même ville.",
      },
      {
        title: 'Inscription et Identité',
        text: "Pour s'inscrire, les vendeurs doivent confirmer leur identité en joignant une pièce d'identité valide.",
      },
      {
        title: 'Confidentialité et Sécurité des Données',
        text: ' Les données personnelles des utilisateurs sont stockées en toute sécurité et ne seront pas divulguées à des tiers.',
      },
      {
        title: 'Utilisation des Données',
        text: 'Uty peut utiliser les données des utilisateurs pour des annonces ciblées et des communications concernant les nouveautés et promotions.',
      },
      {
        title: 'Contenu Interdit',
        text: "La publication de produits illégaux ou illicites est strictement interdite et entraînera l'exclusion de la plateforme.",
      },
    ],
  },
  {
    title: 'Conditions de vente',
    content: [
      {
        title: 'Paiement',
        text: 'Les paiements peuvent être effectués par carte bancaire, mobile money et autres fintech populaires dans la ville spécifique.',
      },
      {
        title: 'Livraison',
        text: "La livraison est assurée par des livreurs indépendants utilisant l'application Uty.",
      },
      {
        title: 'Annulation et Retour',
        text: 'Les annulations et retours seront remboursés à hauteur de la valeur du produit. Les frais de livraison et de service ne sont pas remboursables.',
      },
      {
        title: 'Assurance',
        text: "Les produits perdus ou endommagés lors de la livraison seront couverts par l'assurance souscrite par Uty.",
      },
    ],
  },
  {
    title: 'Politique de confidentialité',
    content: [
      {
        text: "Uty est un produit de la société *GBH sarl, située au n°44 de l'avenue Sendwe, commune de Kalamu, Kinshasa. Le capital virtuel de Uty est de **1.000.000$* pré-money.",
      },
      {
        text: "Uty s'engage à protéger la vie privée de ses utilisateurs. Les données collectées sont utilisées exclusivement dans le cadre des services proposés par Uty et ne seront pas partagées sans consentement explicite",
      },
    ],
  },
]
