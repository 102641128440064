import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getDeliversValidated = async (setDelivers) => {
  // setDelivers
  const data = await axios.get(
    `${BASE_URL}/api/delivers/get-validated-delivers`
  )
  try {
    if (data?.status === 200) setDelivers(data?.data?.delivers)
  } catch (error) {
    console.error(error)
  }
  //   setDelivers(data?.data?.delivers)
}

export { getDeliversValidated }
