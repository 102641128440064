import React from 'react'
// import { AiOutlineMessage } from 'react-icons/ai'
import { TbMessage2 } from 'react-icons/tb'

function SendMessageUty({
  currentUser,
  announcement,
  handleConversation,
  loaderBtn,
}) {
  return (
    <div>
      <div className="font-semibold cursor-pointer">
        {(currentUser?._id === announcement?.user?._id ||
          currentUser?._id === announcement?.seller?.user?._id) &&
        currentUser ? (
          ''
        ) : (
          <div
            className="flex flex-row px-3 md:px-3 lg:px-3 bg-[#bb1a28] rounded-[12px] md:flex-row lg:flex-row items-center gap-1 text-2xl text-[22px] md:text-[19px] font-light text-[#747d88]"
            onClick={handleConversation}
          >
            <TbMessage2 className="text-white" />
            <span className="text-[10px] md:text-[13px] lg:text-[13px] font-medium text-white block md:hidden lg:hidden">
              {loaderBtn ? 'En cours...' : 'Message'}
            </span>
            <span className="text-[12px] md:text-[13px] lg:text-[13px] font-medium text-white hidden md:block lg:block">
              {loaderBtn ? 'En cours...' : 'Envoyer un message'}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default SendMessageUty
