import React from 'react'
import { Pagination } from 'flowbite-react'
import { IoArrowBack, IoArrowForward } from 'react-icons/io5'

export function PaginationLayout({
  currentPage,
  totalPages,
  onPageChange,
  siblingCount,
}) {
  return (
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      previousLabel="Précédent"
      nextLabel="Suivant"
      showIcons
      siblingCount={siblingCount}
    />
  )
}

export function TablePagination({ currentPage, totalPages, onPageChange }) {
  return (
    <div className="flex justify-center items-center gap-3">
      <button
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
        className="p-2 bg-slate-200 rounded-[0.5rem] flex items-center gap-2"
      >
        <IoArrowBack />
        Précédent
      </button>
      <span>
        Page {currentPage} sur {totalPages}
      </span>
      <button
        onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
        className="p-2 bg-slate-200 rounded-[0.5rem] flex items-center gap-2"
      >
        Suivant
        <IoArrowForward />
      </button>
    </div>
  )
}
