import React from 'react'
import { formatDistance } from '../../helpers/FormatDistance'
import { secondsToHms } from '../../helpers/FormatDuration'

function ShipmentDatas({ trajectory }) {
  let datas = [
    {
      title: 'Distance: ',
      value: formatDistance(trajectory?.distance),
    },
    { title: 'Durée: ', value: secondsToHms(trajectory?.duration) },
    {
      title: 'Cout du trajet: ',
      value: parseInt(trajectory?.distance) / 2 + ' CDF',
    },
  ]

  return (
    <div className="flex gap-3 py-3 text-sm">
      {datas.map((data, index) => {
        return (
          <div
            key={index}
            className="flex flex-col justify-center gap-2 bg-stone-100 p-2 w-[30%] rounded-[0.5rem]"
          >
            <span>{data.title}</span>
            <span className=" font-bold text-[17.5px]">{data.value}</span>
          </div>
        )
      })}
    </div>
  )
}

export default ShipmentDatas
