import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  blurpage: false,
}

export const blurpageSlice = createSlice({
  name: 'blurpage',
  initialState,
  reducers: {
    setblurpage: (state, action) => {
      state.blurpage = action.payload
    },
  },
})

export const { setblurpage } = blurpageSlice.actions

export default blurpageSlice.reducer
