import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { Button, Modal } from 'flowbite-react'
import { HiOutlineExclamationCircle } from 'react-icons/hi'
import axios from 'axios'
import { BASE_URL } from '../helpers/Root'

function DeleteModal({ setOpenModal, selected, openModal }) {
  //let navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const handleClick = async () => {
    setLoading(true)
    await axios
      .delete(`${BASE_URL}/api/announcements/${selected}`)
      .then((res) => {
        console.log(res)
        window.location.reload()
        setOpenModal(false)
      })
  }
  return (
    <>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        dismissible
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Vous voulez vraiment supprimer cette annonce ?
            </h3>
            <div className="flex justify-center gap-4 mb-5">
              <Button className="bg-red-500 text-white" onClick={handleClick}>
                {loading ? 'Suppression en cours...' : 'Supprimer'}
              </Button>
              <Button color="gray" onClick={() => setOpenModal(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DeleteModal
