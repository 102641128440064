import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IoEyeOutline } from 'react-icons/io5'
import { useParams } from 'react-router-dom'

import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'
// import { BASE_URL } from '../../helpers/Root'

function ChangePassword() {
  const navigate = useNavigate()

  const [password, setPassword] = useState(null)
  const [errMsgpassword, setErrMsgpassword] = useState(null)
  const [isHide, setIsHide] = useState(false)
  const [loader, setLoader] = useState(false)

  const hidePassword = () => {
    if (isHide) {
      setIsHide(false)
    } else {
      setIsHide(true)
    }
  }

  const { id } = useParams()

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoader(true)
    if (password) {
      console.log('email : ', password)
      const user = await axios.patch(`${BASE_URL}/api/forgot-password/${id}`, {
        password: password,
      })
      try {
        if (user.data.message === 'success') {
          console.log('data : ', user.data)
          navigate('/sign-in')
          setLoader(false)
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      setErrMsgpassword('Le mot de passe est obligatoire')
      setLoader(false)
    }
  }

  return (
    <>
      <div className=" flex bg-uty-signup justify-center  h-[100vh] md:justify-center items-center gap-1">
        <div className="px-6 md:px-10 rounded-lg items-center bg-[#f8fafc] pb-10 pt-3 shadow-3xl">
          <h2 className="text-center px-4 pt-8 text-primary font-medium text-2xl">
            {'Nouveau mot de passe'}
          </h2>

          <form action="" onSubmit={(event) => handleSubmit(event)}>
            <div className="relative flex flex-col mt-10 gap-3 md:mb-5 md:mt-8">
              <div className="flex text-[14px] flex-col text-[rgba(0,0,0,0.67)]">
                <label htmlFor="password">Entrer un nouveau mot de passe</label>
                <div className="relative flex md:bg-white rounded-[2px]">
                  <input
                    className="border-[1px] pl-2 pr-6 border-[rgba(90,86,86,0.08)] py-[10px] w-full outline-none text-[15px] text-[rgba(0,0,0,0.67)] md:py-[7px] rounded-[2px]"
                    type={isHide ? 'text' : 'password'}
                    id="password"
                    name="email"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <IoEyeOutline
                    className="absolute text-primary top-[13px] right-0 text-[12px] md:top-[14px] mr-2"
                    onClick={hidePassword}
                  />
                </div>
              </div>
              <div className=" py-[10px] text-white text-[15px] bg-primary text-center md:py-[7px] rounded-[2px]">
                <button className="" type="submit">
                  {loader ? (
                    <div className="">Vérification...</div>
                  ) : (
                    <div className="">{'Envoyer'}</div>
                  )}
                </button>
              </div>
              {!password && (
                <div className="text-red-400 text-[14px] text-center mb-2">
                  {errMsgpassword}
                </div>
              )}
              <Link
                to="/sign-up"
                className="text-end text-blue-600 text-[13px] hover:underline"
              >
                Créer un nouveau compte
              </Link>
              <div className="mt-1 md:mt-8 text-[15px] text-center">
                <p>
                  <span
                    onClick={() => navigate('/sign-up')}
                    className="text-primary mr-1 cursor-pointer underline"
                  >
                    Se connecter
                  </span>
                  <span className="text-[#363434]">si vous avez un compte</span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ChangePassword
