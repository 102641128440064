import React from 'react'
import Moment from 'react-moment'
import 'moment-timezone'
import Profil from '../../assets/profil.png'
import FormCommnent from '../../components/Comments/FormCommnent'
import { IoArrowBack } from 'react-icons/io5'

function CommentsAnnouncement({
  comments,
  scrolRef,
  currentUser,
  handleComment,
  announcement,
  setComment,
  setIsShowComment,
  setConnecting,
}) {
  return (
    <div className="flex flex-col lg:ml-3 lg:flex-row pb-16 lg:pb-0">
      <div className="w-full">
        <div className="flex w-full bg-blue-600 text-white lg:bg-white lg:text-blue-600 fixed top-0 left-0 right-0 lg:static font-semibold py-3 lg:p-1 px-4 lg:px-0 shadow-md lg:shadow-none">
          <IoArrowBack
            className="block lg:hidden text-xl cursor-pointer"
            onClick={() => setIsShowComment(false)}
          />
          <span className="ml-4 lg:ml-0 pl-3 text-gray-800">
            {comments?.length === 0
              ? '0 Commentaire'
              : `${comments?.length} Commentaire${comments?.length > 1 ? 's' : ''}`}
          </span>
        </div>

        <div className="px-4 py-2 overflow-y-scroll font-medium lg:h-[50vh] mt-[4rem] lg:mt-0">
          {comments?.map((comment) => (
            <div className="flex flex-col pt-2" key={comment?._id}>
              {comment?.comment && (
                <div className="flex gap-3" ref={scrolRef}>
                  <div className="w-8 h-8 rounded-full overflow-hidden">
                    <img
                      src={comment?.user?.image || Profil}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="bg-blue-50 p-3 rounded-xl max-w-[250px] lg:max-w-[180px]">
                    <div className="text-blue-600 text-sm font-bold">
                      {comment?.user?.username}
                    </div>
                    <p className="text-gray-500 font-normal text-sm">
                      {comment?.comment}
                    </p>
                    <div className="text-right text-xs text-blue-400 font-light mt-1">
                      <Moment format="HH:mm:ss" date={comment?.createdAt} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="fixed bottom-0 left-0 w-full bg-white lg:bg-transparent p-4 lg:static">
          {!currentUser ? (
            <div className="text-sm text-blue-800">
              Connectez-vous pour pouvoir commenter cette publication.{' '}
              <span
                className="underline cursor-pointer text-blue-600"
                onClick={() => setConnecting(true)}
              >
                Connectez-vous!
              </span>
            </div>
          ) : (
            <FormCommnent
              handleComment={handleComment}
              announcement={announcement}
              setComment={setComment}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CommentsAnnouncement
