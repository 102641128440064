import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import columns from '../../../helpers/users/show-users'
import { getUsers } from '../../../services/Users/get-users'
import DataTableComponent from '../../../components/datatable/DataTable'

export default function ShowUsers() {
  const [users, setUsers] = useState([])

  const navigate = useNavigate()

  // pass navigate param in calumn
  const columnsConfig = columns(navigate)

  useEffect(() => {
    getUsers(setUsers)
  }, [])

  return (
    <div className="pt-[60px] md:pt-0">
      <div className="card">
        <div className="px-[16px] md:px-0 flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h2 className=" font-bold text-[17px] pb-5">
              Tous les utilisateurs
            </h2>
          </div>
        </div>
        <DataTableComponent
          data={users}
          columns={columnsConfig}
          name={'utitisateur'}
        />
      </div>
    </div>
  )
}
