import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Nav from '../../components/layout/Nav'
import { useGetCategoriesQuery } from '../../services/categoryApi'
import _ from 'lodash'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import LottieLoader from '../../components/LottieLoader'
import BannerCategories from '../../components/BannerCategories'
import { useGetAllAnnouncementsQuery } from '../../services/announcementApi'
import emptyImage from '../../assets/emptyImage.png'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa' // Importation des icônes pour les boutons de défilement
import { postSeen } from '../../services/seens/add-seen'
import { useSelector } from 'react-redux'

export default function Index() {
  const navigate = useNavigate()
  const { data: categories, isLoading } = useGetCategoriesQuery()
  const { data: announcements, loader } = useGetAllAnnouncementsQuery()
  const currentUser = useSelector((state) => state.user.currentUser)

  const search = window.location.search
  const params = new URLSearchParams(search)
  const id_category = params.get('id')

  // State for selected category
  const [selectedCategoryId, setSelectedCategoryId] = useState(id_category)

  // Ref pour le conteneur des catégories
  const categoriesRef = useRef(null)

  //Order categories by categories which has more announcements
  let ordered_categories = _.sortBy(categories, [
    function (category) {
      return category.announcements.length
    },
  ]).reverse()

  // Filtered announcements based on selected category
  const filteredAnnouncements = selectedCategoryId
    ? announcements?.filter(
        (announcement) => announcement?.category?._id === selectedCategoryId
      )
    : announcements

  console.log(loader)

  // Get the category with the most announcements
  const categoryToFilter = categories?.reduce((maxCat, currentCat) => {
    return (currentCat?.announcements?.length || 0) >
      (maxCat?.announcements?.length || 0)
      ? currentCat
      : maxCat
  }, {})

  console.log('categoryToFilterAnnouncement : ', categoryToFilter)

  if (isLoading)
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div>
          <LottieLoader animation={indexLoader} />
        </div>
        <div>Chargement des catégories sur uty...</div>
      </div>
    )

  // Fonction pour faire défiler la liste des catégories
  const scroll = (direction) => {
    const scrollAmount = direction === 'left' ? -300 : 300
    if (categoriesRef.current) {
      categoriesRef.current.scrollLeft += scrollAmount
    }
  }

  const hanleAddSeen = async (announce) => {
    const data = {
      announcementId: announce._id,
      userId: currentUser?._id,
    }

    if (currentUser) {
      await postSeen(data)
      navigate(`/announcements/${announce._id}/${announce?.category?._id}`)
    } else {
      navigate(`/announcements/${announce._id}/${announce?.category?._id}`)
    }
  }

  return (
    <div>
      <Nav />
      <div className="relative">
        <BannerCategories />
        <div className="relative h-32 ">
          <div className="flex justify-center">
            <div className=" absolute py-5 lg:pt-3 lg:pb-3 top-[200px] sm:top-[120px] lg:-top-[50px] z-40 w-full sm:w-full md:w-full lg:w-[82%] bg-white lg:shadow-md rounded-lg">
              <h2 className="pb-3 lg:pb-5 text-sm sm:text-md font-bold uppercase text-center text-[rgba(31,31,31,0.81)]">
                Toutes les catégories
              </h2>
              <div className="h-[1px] bg-[rgba(59,59,59,0.06)]"></div>

              {/* Ajout des boutons de défilement et de la liste des catégories */}
              <div className="relative flex items-center lg:pt-0">
                <button
                  onClick={() => scroll('left')}
                  className="absolute lg:top-[2px] left-0 z-10 hidden p-2 text-white transform bg-blue-300 rounded-full shadow-lg lg:block -translate-x-2/3 hover:bg-blue-400 focus:outline-none"
                >
                  <FaChevronLeft />
                </button>
                <div
                  ref={categoriesRef}
                  className="flex w-full p-2 overflow-x-auto rounded-lg md:px-10 sm:gap-2 lg:gap-4 sm:p-4"
                >
                  {ordered_categories?.map((category) => (
                    <div
                      key={category?._id}
                      className={`flex-shrink-0 cursor-pointer hover:bg-blue-600 hover:text-white transition-all ease-in-out delay-200 rounded-full ${
                        selectedCategoryId === category?._id
                          ? 'bg-blue-300 text-white'
                          : 'bg-blue-100 text-gray-500'
                      }`}
                      onClick={() => {
                        setSelectedCategoryId(category?._id)
                      }}
                    >
                      <div className="p-2 lg:py-[10px] lg:px-2 lg:text-sm font-semibold text-center border-gray-300 rounded-lg sm:p-3">
                        {category?.name}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => scroll('right')}
                  className="absolute right-0 z-10 lg:top-[2px] hidden p-2 text-white transform bg-blue-300 rounded-full shadow-lg lg:block translate-x-2/3 hover:bg-blue-400 focus:outline-none"
                >
                  <FaChevronRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section des Annonces */}
      <div className="pt-16 pb-10   px-[16px] md:px-10 lg:px-16 sm:pt-16 lg:pt-0">
        <h2 className="pb-5 text-xl sm:text-2xl font-bold text-[rgba(31,31,31,0.81)]">
          {selectedCategoryId
            ? `Les annonces | ${
                categories.find((cat) => cat._id === selectedCategoryId)?.name
              }`
            : 'Toutes les annonces'}
        </h2>
        {filteredAnnouncements.length > 0 ? (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 md:gap-8">
            {filteredAnnouncements?.map((announcement) => (
              <div
                onClick={() => {
                  hanleAddSeen(announcement)
                }}
                key={announcement?._id}
                className="overflow-hidden bg-white rounded-lg shadow-md lg:cursor-pointer"
              >
                {announcement?.images[0] ? (
                  <img
                    src={announcement?.images[0]}
                    alt=""
                    className="object-cover w-full h-32 rounded-t-lg sm:h-40"
                  />
                ) : (
                  <img
                    src={emptyImage}
                    alt=""
                    className="object-cover w-full h-32 rounded-t-lg sm:h-40"
                  />
                )}
                <div className="p-4">
                  <h3 className="w-full mb-1 overflow-hidden text-sm font-semibold text-gray-800 truncate text-md whitespace-nowrap text-ellipsis">
                    {announcement?.name}
                  </h3>
                  <p className="font-bold text-gray-800 text-md sm:text-md">
                    {announcement?.price} $
                  </p>
                  <button className="w-full px-4 py-1 mt-2 font-bold text-white transition duration-300 bg-blue-600 rounded-lg hover:bg-blue-500">
                    Détail
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="pb-5 text-gray-900 ">
            Cette catégorie ne contient aucune annonce
          </div>
        )}
      </div>
    </div>
  )
}
