import React, { useEffect, useState } from 'react'
// import { NumericFormat } from 'react-number-format'
// import Profil from '../../assets/profil.png'
// import { useDispatch } from 'react-redux'
// import { addProduct } from '../../features/cart/cart'
import CallPhone from '../call/CallPhone'
import CallPhoneWhatsapp from '../call/CallWhatsapp'
import SendMessageUty from '../call/SendMessageUty'
import { FaHeart, FaRegHeart } from 'react-icons/fa6'

function InfoUser({
  announcement,
  navigate,
  params,
  handleConversation,
  currentUser,
  infoUserRef,
  fixedCall,
  handleLikeAnnouncement,
  generateCodeForNoUser,
}) {
  // const dispatch = useDispatch()
  const [telephoneNumber, setTelephoneNumber] = useState(null)

  useEffect(() => {
    if (announcement?.seller?.user?.phone) {
      setTelephoneNumber(announcement?.seller?.user?.phone)
    } else if (announcement?.user?.verified_phone) {
      setTelephoneNumber(announcement?.user?.verified_phone)
    } else if (announcement?.user?.phone) {
      setTelephoneNumber(announcement?.user?.phone)
    }
  }, [announcement])

  return (
    <div className="">
      <div className="flex flex-col md:flex-row justify-between pt-3 lg:pt-0">
        <div className="flex flex-col gap-4">
          {!fixedCall ? (
            <div
              className="flex gap-2 transition-all duration-300 ease-out delay-200"
              ref={infoUserRef}
            >
              <CallPhone
                phoneNumber={telephoneNumber}
                customerId={currentUser?._id}
                sellerId={announcement?.user?._id}
                announcementId={announcement?._id}
              />
              <CallPhoneWhatsapp
                phoneNumber={telephoneNumber}
                imageProduct={announcement?.images[0]}
                nameProduct={announcement?.name}
                priceProduct={announcement?.price}
                descriptionProduct={announcement?.description}
                customerId={currentUser?._id}
                sellerId={announcement?.user?._id}
                announcementId={announcement?._id}
              />
              <SendMessageUty
                email={announcement?.user?.email}
                navigate={navigate}
                params={params}
                handleConversation={handleConversation}
                announcement={announcement}
                currentUser={currentUser}
              />
            </div>
          ) : (
            <div
              className="fixed flex z-50 left-0 right-0 md:right-[875px] lg:left-4 transition-all ease-out delay-200 duration-300 rounded-lg px-2 lg:px-3 py-2 gap-2 bg-gray-200 top-[123px]"
              ref={infoUserRef}
            >
              <CallPhone
                phoneNumber={telephoneNumber}
                customerId={currentUser?._id}
                sellerId={announcement?.user?._id}
                announcementId={announcement?._id}
              />
              <CallPhoneWhatsapp
                phoneNumber={telephoneNumber}
                imageProduct={announcement?.images[0]}
                nameProduct={announcement?.name}
                priceProduct={announcement?.price}
                descriptionProduct={announcement?.description}
                customerId={currentUser?._id}
                sellerId={announcement?.user?._id}
                announcementId={announcement?._id}
              />
              <SendMessageUty
                email={announcement?.user?.email}
                navigate={navigate}
                params={params}
                handleConversation={handleConversation}
                announcement={announcement}
                currentUser={currentUser}
              />
              <div
                className="text-xs flex gap-2 items-center font-medium cursor-pointer"
                onClick={() => handleLikeAnnouncement(announcement)}
              >
                {announcement?.likes?.includes(currentUser?._id) ||
                announcement?.likes?.includes(generateCodeForNoUser) ? (
                  <FaHeart className="text-2xl bg-white w-8 h-8 p-1 rounded-full text-red-500" />
                ) : (
                  <FaRegHeart
                    className={`text-2xl bg-white w-8 h-8 p-1 rounded-full text-gray-600 ${
                      announcement?.likes?.includes(currentUser?._id) &&
                      'text-primary'
                    }`}
                  />
                )}
                <span
                  className={`${
                    announcement?.likes?.includes(currentUser?._id) &&
                    'font-bold'
                  }`}
                ></span>
              </div>
            </div>
          )}
          <div className="lg:pl-4">
            {/* <div
              className="text-xl font-semibold text-gray-800 mb-2 cursor-pointer"
              onClick={() => dispatch(addProduct(announcement))}
            >
              {announcement?.name || ''}
            </div> */}

            {/* <div className="text-sm text-gray-700 mb-2">
              {announcement?.description}
            </div>

            {typeof announcement?.seller?.address === 'string' && (
              <div className="text-sm text-gray-600">
                Adresse : {announcement?.seller?.address}
              </div>
            )} */}
          </div>
        </div>
      </div>
      {/*
      <div className="flex items-center justify-between pt-4 bg-white shadow-sm rounded-lg lg:p-4">
         <div className="flex items-center gap-4">
          <div className="flex w-14 h-14 rounded-full border-4 border-blue-400 overflow-hidden">
            <img
              src={
                announcement?.seller?.user?.image ||
                announcement?.user?.image ||
                Profil
              }
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex flex-col">
            {announcement?.seller?.store && (
              <div className="text-blue-800 font-semibold text-lg">
                {announcement?.seller?.store}
              </div>
            )}
            {announcement?.user && (
              <div className="text-lg  mt-1 font-semibold text-gray-700">
                {announcement?.user?.username}
              </div>
            )}
          </div>
        </div> 
        <div className="flex gap-6 items-center">
          <span className="text-blue-900 text-3xl font-bold">
            <NumericFormat
              value={announcement?.price}
              displayType={'text'}
              thousandSeparator=" "
              suffix={` ${announcement?.currency?.shortName || 'fc'}`}
            />
          </span>
        </div>
      </div>
        */}
    </div>
  )
}

export default InfoUser
