import React from 'react'
import ItemProduct from '../ItemProduct'

function OtherAnnouncementsSeller({
  // announcement,
  announcementsByCategory,
  loader,
  announcement,
}) {
  return (
    <div className="pb-10 pt-1">
      <div className="flex items-center pb-4 md:pt-10 gap-2">
        <h2 className="text-[#21344e] text-[16px] font-bold    capitalize md:text-[21px]">
          Autres produits
        </h2>
        <div>/</div>
        <h2 className="italic font-bold opacity-70">
          {announcement?.category.name}
        </h2>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6 md:gap-7 lg:gap-7 lg:grid-cols-5">
        <ItemProduct data={announcementsByCategory} loader={loader} />
      </div>
    </div>
  )
}

export default OtherAnnouncementsSeller
