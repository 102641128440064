import React, { useEffect, useState } from 'react'
import FormContainer from '../../../components/admin/formComponent/FormContainer'
import InputForm from '../../../components/admin/formComponent/InputForm'
import ButtonForm from '../../../components/admin/formComponent/ButtonForm'
import { useNavigate, useParams } from 'react-router-dom'
import { getOneMeansTransport } from '../../../services/meansTransports/get-meanstransport'
import { updateMeanstransport } from '../../../services/meansTransports/update-meanstransport'

function EditMeansTransport() {
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)

  const [meanstransport, setMeanstransport] = useState(null)

  const [loading, setLoading] = useState(false)
  const [errMsgName, setErrMsgName] = useState(null)
  const [errorMessageRosponse, setErrorMessageResponse] = useState(null)

  const params = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    getOneMeansTransport(params.id, setMeanstransport)
  }, [params.id])

  useEffect(() => {
    setName(meanstransport?.name ? meanstransport?.name : null)
    setDescription(
      meanstransport?.description ? meanstransport?.description : null
    )
  }, [meanstransport])

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = { name, description, id: params.id }

    if (!name) {
      setErrMsgName('Ce champs est obligatoire')
    } else {
      setLoading(true)
      if (data) {
        updateMeanstransport(
          data,
          setLoading,
          navigate,
          setErrorMessageResponse
        )
      }
    }
  }

  return (
    <div className="px-[16px] md:px-0 md:w-[60%] m-auto">
      <h2 className="font-medium text-[17px] pt-[65px] md:pt-0 pb-3">
        Modifier un moyen de transport
      </h2>
      <FormContainer onSubmit={handleSubmit}>
        <InputForm
          seSstate={setName}
          valueState={name}
          nameLabel={'Name'}
          errorMessage={errMsgName}
          requiredInput
        />
        <InputForm
          seSstate={setDescription}
          valueState={description}
          nameLabel={'Description'}
        />
        <ButtonForm loadingButton={loading} />
      </FormContainer>
      {errorMessageRosponse && (
        <div className="text-[red] font-medium text-[11px] py-0 my-2">
          {errorMessageRosponse}
        </div>
      )}
    </div>
  )
}

export default EditMeansTransport

// import React from 'react'

// function EditMeansTransport() {
//   return <div>EditMeansTransport</div>
// }

// export default EditMeansTransport
