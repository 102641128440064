import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getSubCategories = async (setState, setLoader) => {
  await axios.get(`${BASE_URL}/api/subcategories`).then((response) => {
    // console.log("response : ", response?.data)
    setState(response?.data?.listsubcategories)
    console.log(response?.data)
    if (setLoader) {
      setLoader(true)
    }
  })
}

export { getSubCategories }
