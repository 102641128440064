import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

export const startRegisterByPhone = createAsyncThunk(
  'phoneLogin/startRegisterByPhone',
  async (phone) => {
    console.log(phone)
    const response = await axios.post(`${BASE_URL}/api/auth/otp`, {
      phone: phone,
    })
    return response.data
  }
)

export const validatePhoneNumber = createAsyncThunk(
  'phoneLogin/validatePhoneNumber',
  async ({ otp, phone }) => {
    console.log(phone, otp.join(''))
    const response = await axios.post(`${BASE_URL}/api/auth/verify-otp`, {
      otp: otp.join(''),
      phone: phone,
    })
    return response.data
  }
)

export const register = createAsyncThunk(
  'phoneLogin/register',
  async ({ username, phone }) => {
    console.log(phone, username)
    const response = await axios.post(`${BASE_URL}/api/auth/register/phone`, {
      username: username,
      phone: phone,
    })
    return response.data
  }
)

const initialState = {
  starting: false,
  validating: false,
  registering: false,
  status: '',
  phone: '',
  user: null,
}

export const PhoneLoginSlice = createSlice({
  name: 'phoneLogin',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startRegisterByPhone.pending, (state, action) => {
        console.log(action.payload)
        state.starting = true
      })
      .addCase(startRegisterByPhone.fulfilled, (state, action) => {
        console.log(action.payload)
        state.starting = false
        if (action.payload.user) {
          localStorage.setItem(
            'currentUser',
            JSON.stringify(action.payload.user)
          )
          window.location.reload()
        } else {
          state.status = action.payload.message.status
          state.phone = action.payload.phone
        }
      })

      //validate otp code
      .addCase(validatePhoneNumber.pending, (state, action) => {
        console.log(action.payload)
        state.validating = true
      })
      .addCase(validatePhoneNumber.fulfilled, (state, action) => {
        console.log(action.payload)
        state.validating = false

        if (action.payload.user) {
          state.user = action.payload.user
          state.status = 'CONNECT'
          localStorage.setItem(
            'currentUser',
            JSON.stringify(action.payload.user)
          )
          window.location.reload()
        } else {
          state.status = action.payload.message.statusOTP
          state.phone = action.payload.phone
        }
      })

      //register user
      .addCase(register.pending, (state, action) => {
        console.log(action.payload)
        state.registering = true
      })
      .addCase(register.fulfilled, (state, action) => {
        console.log(action.payload)
        if (state.status == 'VALID') {
          state.registering = false
          state.user = action.payload.user
        }
      })
  },
})

export const { setStatus } = PhoneLoginSlice.actions

export default PhoneLoginSlice.reducer
