import React from 'react'
import StatusBadge from '../Badge'
import { useAcceptShipmentMutation } from '../../services/shipmentApi'
import { useSelector } from 'react-redux'
import BlankImg from '../../assets/blank-profile.png'
import CallUser from './CallUser'
import { formatCreatedToday } from '../../helpers/FormatCreatedToday'
import { BsCalendar3 } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

function ShipmentItem({ shipment }) {
  const [acceptShipment, { isLoading }] = useAcceptShipmentMutation()
  const currentDeliver = useSelector((state) => state.user.currentDeliver)
  const navigate = useNavigate()

  const handleAcceptShipment = async (deliver, shipmentId) => {
    await acceptShipment({ deliver, shipment: shipmentId }).then(() =>
      navigate(`/shipments/${shipment?._id}`)
    )
  }

  return (
    <div className="rounded-[0.5rem] shadow p-5">
      <div className="flex justify-between">
        <span>Livraison {shipment?._id?.slice(-10)}</span>
        <StatusBadge status={shipment?.status} />
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-2 items-center">
          <img
            className="rounded-full w-8 h-8"
            src={shipment?.order?.customer?.image || BlankImg}
            alt=""
          />
          <div>
            <p>
              Destiné à <span>{shipment?.order?.customer?.username}</span>
            </p>
            <p className="flex items-center gap-1 text-[12.5px]">
              <BsCalendar3 />
              {formatCreatedToday(shipment?.createdAt)}
            </p>
          </div>
        </div>
        <div className="flex gap-2 text-sm">
          {shipment?.status === 'pending' && (
            <button
              className="bg-primary p-2 rounded-[0.5rem] text-white"
              onClick={(e) => {
                e.preventDefault()
                handleAcceptShipment(currentDeliver, shipment?._id)
              }}
            >
              {isLoading ? 'Accepter...' : 'Accepter la livraison'}
            </button>
          )}
          {shipment?.status === 'accepted' && (
            <CallUser
              user={shipment?.order?.seller}
              title={'le vendeur'}
              isDeliver={true}
            />
          )}
          {shipment?.status === 'in delivery' && (
            <CallUser
              user={shipment?.order?.customer}
              title={"l'acheteur"}
              isDeliver={false}
            />
          )}
          <button
            onClick={() => navigate(`/shipments/${shipment?._id}`)}
            className="shadow p-2 rounded-[0.5rem]"
          >
            Voir le detail
          </button>
        </div>
      </div>
    </div>
  )
}

export default ShipmentItem
