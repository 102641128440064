import React from 'react'
import NextOrderDetail from './NextOrderDetail'
import _ from 'lodash'
import { NumericFormat } from 'react-number-format'
import { IoEyeOutline, IoCloseOutline } from 'react-icons/io5'
import { formatDistance } from '../../helpers/FormatDistance'
import {
  FaCartShopping,
  FaTruckFast,
  FaCircleDollarToSlot,
} from 'react-icons/fa6'
// import PayOrder from '../payment/PayOrder'
import { Modal } from 'flowbite-react'

export default function ShipmentCosts({
  user,
  data,
  handleDeleteProductToCart,
  handleUpdateProductQuantity,
  openModal,
  setOpenModal,
}) {
  const [openDetail, setOpenDetail] = React.useState(false)
  const [selected, setSelected] = React.useState()

  return (
    <div>
      <Modal
        show={openModal}
        onClose={() => setOpenModal(false)}
        dismissible
        popup
      >
        <div className="flex flex-col gap-2 text-[15px] sm:text-[10.5px] p-5">
          <div className="flex justify-between">
            <h5>Details du frais de livraison</h5>
            <IoCloseOutline
              onClick={() => setOpenModal(false)}
              className="text-[20px] cursor-pointer"
            />
          </div>
          {Object.keys(data).map((key) => (
            <div key={key} className="shadow rounded-[1rem] p-5">
              <div className="">
                <div className="w-full flex flex-col gap-2">
                  <div>
                    {' '}
                    Vendus par <span>{data[key]?.seller?.username} </span>
                    situé à <span>{formatDistance(data[key].distance)}</span>
                  </div>
                  <div className="font-light">
                    Produits dans la commande:{' '}
                    <span>{data[key].products.length}</span>
                  </div>

                  <div className="flex gap-2">
                    <div className="flex gap-2">
                      <div className="flex items-center gap-1">
                        <FaCartShopping className="text-gray-500" /> Cout de la
                        livraison:{' '}
                      </div>
                      <span className="font-roboto">
                        <NumericFormat
                          value={_.round(data[key].shipmentFees, -2)}
                          displayType={'text'}
                          thousandSeparator=" "
                        />{' '}
                        CDF
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <div className="flex items-center gap-1">
                        {' '}
                        <FaTruckFast className="text-gray-500" />
                        Prix total des produits:{' '}
                      </div>

                      <span className="font-roboto">
                        <NumericFormat
                          value={_.sum(data[key].prices)}
                          displayType={'text'}
                          thousandSeparator=" "
                        />{' '}
                        CDF
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="flex items-center gap-1">
                      <FaCircleDollarToSlot className="text-gray-500" />
                      Total:
                    </div>
                    <span className="font-roboto">
                      <NumericFormat
                        value={_.round(
                          _.sum(data[key].prices) + data[key].shipmentFees,
                          -2
                        )}
                        displayType={'text'}
                        thousandSeparator=" "
                      />{' '}
                      CDF
                    </span>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        setSelected(data[key])
                        setOpenDetail(true)
                      }}
                      className="flex items-center p-2 gap-1 shadow text-[10.5px] text-primary rounded-[0.5rem]"
                    >
                      <IoEyeOutline />
                      Voir les produits
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal>

      <NextOrderDetail
        openModal={openDetail}
        setOpenModal={setOpenDetail}
        user={user}
        data={selected}
        handleDeleteProductToCart={handleDeleteProductToCart}
        handleUpdateProductQuantity={handleUpdateProductQuantity}
      />
    </div>
  )
}
