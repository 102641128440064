import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { openDB } from 'idb'

export const checkTokenFirebase = createAsyncThunk(
  'tokenFirebase/checkTokenFirebase',
  async () => {
    const db = await openDB('firebase-messaging-database', 1)

    console.log('indexdb:', db)

    const transaction = db.transaction(
      'firebase-messaging-database',
      'readonly'
    )
    const store = transaction.objectStore('firebase-messaging-database')
    const allData = await store.getAll()

    console.log('data firebase:', allData)

    return allData
  }
)

export const tokenFirebaseSlice = createSlice({
  name: 'tokenFirebase',
  initialState: { items: [], token: '', status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkTokenFirebase.pending, (state) => {
        state.status = 'loading'
        console.log('checking start')
      })
      .addCase(checkTokenFirebase.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.items = action.payload
        console.log('idb', action.payload)
      })
      .addCase(checkTokenFirebase.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export default tokenFirebaseSlice.reducer
