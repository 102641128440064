import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IoEyeOutline, IoTrashOutline } from 'react-icons/io5'
import { BsPencilSquare } from 'react-icons/bs'
import { NumericFormat } from 'react-number-format'
import ReactGA from 'react-ga4'

export default function AnnouncementCard({
  announcement,
  setDeleting,
  setSelected,
}) {
  const navigate = useNavigate()

  const handleDelete = (e) => {
    e.preventDefault()
    ReactGA.event({
      category: 'Announcement',
      action: 'Want to delete',
    })
    setSelected(announcement._id)
    setDeleting(true)
  }

  return (
    <div className="flex text-sm bg-white border-[1px] border-[rgba(90,86,86,0.08)] h-[18.75vh]">
      <div className="">
        <img
          className="h-[18.5vh] lg:w-[7.5vw] w-[35vw] object-cover"
          src={announcement?.images[0]}
          alt=""
        />
      </div>
      <div className="p-5 lg:w-[70%] sm:w-[70%]">
        <div>
          <div className="truncate font-light lg:w-[100%] sm:w-[100%]">
            <span>{announcement?.name}</span>
          </div>
          <div className="truncate font-extralight text-sm">
            <span className="">{announcement?.description}</span>
          </div>
        </div>

        <div className="flex items-center font-black justify-between w-[100%]">
          <div>
            <span className="font-semibold">
              <NumericFormat
                value={announcement?.price}
                displayType={'text'}
                thousandSeparator=" "
              />{' '}
              fc
            </span>
          </div>
        </div>
        <div className="flex">
          <div className="pr-2 text-[25px]">
            <IoEyeOutline
              onClick={() => {
                ReactGA.event({
                  category: 'Announcement',
                  action: 'View detail',
                })
                navigate(`/Account/announcements/${announcement._id}`)
              }}
            />
          </div>

          <div className="text-[25px] pr-2">
            <BsPencilSquare
              onClick={() => {
                ReactGA.event({
                  category: 'Announcement',
                  action: 'Want to modify',
                })
                navigate(`/Account/announcements/edit/${announcement._id}`)
              }}
            />
          </div>

          <div
            className="text-[25px] text-red-500"
            onClick={(e) => handleDelete(e)}
          >
            <IoTrashOutline />
          </div>
        </div>
      </div>
    </div>
  )
}
