import React from 'react'

import { IoCallOutline } from 'react-icons/io5'
import { postTrackerContact } from '../../services/trackers/post-tracker-contact'

function CallPhone({ phoneNumber, customerId, sellerId, announcementId }) {
  if (phoneNumber && !phoneNumber.startsWith('243')) {
    phoneNumber = '+243' + phoneNumber
  }

  let data = {
    typetracker: 'phonecall',
    announcement: announcementId,
  }

  const callPhone = () => {
    postTrackerContact(customerId, sellerId, data)

    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`
    } else {
      alert('Numéro de téléphone non disponible')
    }
  }
  return (
    phoneNumber && (
      <div className="flex gap-1 cursor-pointer" onClick={callPhone}>
        <div className="flex flex-row px-3 md:px-3 lg:px-3 bg-[#3434d3] rounded-[12px] md:flex-row lg:flex-row items-center gap-1 text-2xl text-[22px] md:text-[19px] font-light text-[#747d88]">
          <IoCallOutline className="text-white" />
          <div className="text-[12px] md:text-[13px] lg:text-[13px] font-medium text-white">
            Appelez
          </div>
        </div>
      </div>
    )
  )
}

export default CallPhone
