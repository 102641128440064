import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const postProductType = async (
  data,
  setLoading,
  navigate,
  setErrorMessageResponse
) => {
  await axios
    .post(`${BASE_URL}/api/product-types/create`, data)
    .then(function (response) {
      console.log('brand response : ', response)
      if (response.status === 200) {
        setLoading(false)
        navigate('/Account/product-types')
      }
    })
    .catch(function (error) {
      setLoading(false)
      if (error.response.status === 400) {
        setErrorMessageResponse(error.response.data.message)
      } else {
        setErrorMessageResponse(null)
      }
      console.log(error)
    })
}

export { postProductType }
