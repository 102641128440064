import React from 'react'
import OrderCustomerItem from './OrderCustomerItem'
import { BsBagX } from 'react-icons/bs'

export default function CustomerOrdersHistory({ orders }) {
  if (!orders || orders?.length === 0) {
    return (
      <div>
        <div className="flex flex-col gap-5 justify-center items-center pt-20">
          <BsBagX className="text-[50px] text-slate-500" />
          <div>{"Vous n'avez encore rien conclu!!"}</div>
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-3">
      {orders
        ?.map((order) => {
          return (
            <div key={order?._id} className="shadow rounded-[0.5rem]">
              <OrderCustomerItem order={order} />
            </div>
          )
        })
        .reverse()}
    </div>
  )
}
