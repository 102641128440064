import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const createSubcategory = async (name, icon, description, isAdmin) => {
  const data = await axios.post(
    `${BASE_URL}/api/subcategories/create-subcategory`,
    {
      name,
      icon,
      description,
      isAdmin,
    }
  )

  return data

  //   setData(data)
}

export { createSubcategory }
