import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isShowComment: false,
}

export const isShowCommentSlice = createSlice({
  name: 'isshowcomment',
  initialState,
  reducers: {
    setIsShowComment: (state, action) => {
      state.isShowComment = action.payload
    },
  },
})

export const { setIsShowComment } = isShowCommentSlice.actions

export default isShowCommentSlice.reducer
