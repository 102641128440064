import { Button, Label, TextInput, Select } from 'flowbite-react'
import AddImages from '../AddImages'
import { useState } from 'react'

export default function CreateFormStart({
  setName,
  categories,
  setCategory,
  category,
  setSubcategory,
  subcategory,
  subcategories,
  setNext,
  name,
  images,
  setImages,
  setImgClicked,
  setPicClick,
  // subcategories,
  // subcategory,
  // setSubcategory,
}) {
  const [nameError, setNameError] = useState('')
  const [categoryError, setCategoryError] = useState('')
  // const [subcategoryError, setSubcategoryError] = useState('')
  const [imagesError, setImagesError] = useState('')

  console.log('subcat', subcategories)

  const validateForm = () => {
    let isValid = true
    if (!name.trim()) {
      setNameError('Le nom du produit est requis.')
      isValid = false
    } else {
      setNameError('')
    }

    if (!category) {
      setCategoryError('La catégorie est requise.')
      isValid = false
    } else {
      setCategoryError('')
    }

    // if (!subcategory) {
    //   setSubcategoryError('La sous catégorie est requise.')
    //   isValid = false
    // } else {
    //   setSubcategoryError('')
    // }

    if (images.length === 0) {
      setImagesError('Au moins une image est requise.')
      isValid = false
    } else {
      setImagesError('')
    }

    return isValid
  }

  const handleContinue = () => {
    if (validateForm()) {
      setNext(true)
    }
  }

  const newLocal = (
    <Select
      id="categories"
      onChange={(e) => {
        setSubcategory(e.target.value)
      }}
      value={subcategory}
      required
    >
      <option value="">Choisissez une catégorie</option>
      {subcategories?.map((category) => (
        <option key={category._id} value={category._id}>
          {category.name}
        </option>
      ))}
    </Select>
  )
  return (
    <div className="flex flex-col max-w-md gap-4">
      <div>
        <div className="block mb-2">
          <Label htmlFor="name" value="Nom du produit" />
        </div>
        <TextInput
          id="name"
          type="text"
          placeholder="mon produit"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
        />
        {nameError && (
          <p className="text-xs italic text-red-500">{nameError}</p>
        )}
      </div>
      <div>
        <div className="block mb-2">
          <Label htmlFor="" value="Les photos de votre produit" />
        </div>
        <AddImages
          setImages={setImages}
          images={images}
          setImgClicked={setImgClicked}
          setPicClick={setPicClick}
        />
        {imagesError && (
          <p className="text-xs italic text-red-500">{imagesError}</p>
        )}
      </div>

      {/* category choice */}
      <div className="max-w-md">
        <div className="block mb-2">
          <Label
            htmlFor="categories"
            value="Choisir la catégorie de votre produit"
          />
        </div>
        <Select
          id="categories"
          onChange={(e) => {
            let value = e.target.value
            if (value) {
              const selectedCategory = JSON.parse(value)
              setCategory(selectedCategory)
            } else {
              setCategory(null)
            }
          }}
          value={category ? JSON.stringify(category) : null}
          required
        >
          <option value="">Choisissez une catégorie</option>
          {categories?.map((category) => (
            <option key={category._id} value={JSON.stringify(category) || null}>
              {category.name}
            </option>
          ))}
        </Select>
        {categoryError && (
          <p className="text-xs italic text-red-500">{categoryError}</p>
        )}
      </div>
      {/* category choice */}
      {/* <div className="max-w-md">
        <div className="block mb-2">
          <Label
            htmlFor="categories"
            value="Choisir la sous catégorie de votre produit"
          />
        </div>
        <Select
          id="subcategories"
          onChange={(e) => {
            let value = e.target.value
            if (value) {
              const selectedSubcategory = JSON.parse(value)
              setSubcategory(selectedSubcategory)
            } else {
              setSubcategory(null)
            }
          }}
          value={subcategory ? JSON.stringify(subcategory) : null}
          required
        >
          <option value="">Choisissez une sous catégorie</option>
          {subcategories?.map((subcategory) => (
            <option
              key={subcategory._id}
              value={JSON.stringify(subcategory) || null}
            >
              {subcategory.name}
            </option>
          ))}
        </Select>
        {subcategoryError && (
          <p className="text-xs italic text-red-500">{subcategoryError}</p>
        )}
      </div> */}

      {subcategories?.length !== 0 && (
        <div className="max-w-md">
          <div className="mb-2 block">
            <Label
              htmlFor="categories"
              value="Choisir une sous catégorie de votre produit"
            />
          </div>
          {newLocal}
          {categoryError && (
            <p className="text-red-500 text-xs italic">{categoryError}</p>
          )}
        </div>
      )}
      <Button
        className="bg-primary"
        onClick={handleContinue}
        disabled={!name || !category || images.length === 0}
      >
        Continuer
      </Button>
    </div>
  )
}
