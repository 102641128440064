import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  announcement: null,
  query: null,
}

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    setAnnouncement: (state, action) => {
      state.announcement = action.payload
    },
    setQuery: (state, action) => {
      state.query = action.payload
    },
  },
})

export const { setAnnouncement, setQuery } = announcementSlice.actions

export default announcementSlice.reducer
