import React from 'react'
import { Badge } from 'flowbite-react'

function StatusBadge({ status }) {
  let thisStatus = {
    badgeColor: '',
    name: '',
  }

  switch (status) {
    case 'pending':
      thisStatus.badgeColor = 'bg-yellow-100 text-yellow-600'
      thisStatus.name = 'En attente'
      break
    case 'refused':
      thisStatus.badgeColor = 'bg-red-300 text-red-600'
      thisStatus.name = 'Refusé'
      break
    case 'accepted':
      thisStatus.badgeColor = 'bg-yellow-300 text-yellow-600'
      thisStatus.name = 'Accepté'
      break
    case 'awaiting delivery':
      thisStatus.badgeColor = 'bg-blue-300 text-blue-600'
      thisStatus.name = 'En attente de livraison'
      break
    case 'in pickup':
      thisStatus.badgeColor = 'bg-blue-300 text-blue-600'
      thisStatus.name = 'En cours de récuperation'
      break
    case 'in delivery':
      thisStatus.badgeColor = 'bg-yellow-300 text-blue-600'
      thisStatus.name = 'En cours de livraison'
      break
    case 'delivered':
      thisStatus.badgeColor = 'bg-green-300 text-green-600'
      thisStatus.name = 'Livraison terminée'
      break
    default:
      thisStatus.badgeColor = 'gray'
      thisStatus.name = 'En attente'
  }

  return (
    <Badge className={thisStatus.badgeColor + ' p-2'}>{thisStatus.name}</Badge>
  )
}

export default StatusBadge
