import React from 'react'
import Rating from 'react-rating'
import { FaRegStar, FaStar } from 'react-icons/fa6'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import CallUser from './CallUser'
import thisUser from './ShipmentContact'

const UserRating = ({ rating }) => {
  return (
    <Rating
      initialRating={rating}
      emptySymbol={
        <FaRegStar className="text-[15px] text-gray-500 font-light" />
      }
      fullSymbol={<FaStar className="text-yellow-300 text-[15px]" />}
      readonly={true}
    />
  )
}

function ShipmentHeader({ shipment }) {
  const currentUser = useSelector((state) => state.user.currentUser)
  // const currentDeliver = useSelector((state) => state.user.currentDeliver)
  // console.log("shipment:", shipment)

  let contact = thisUser(shipment, currentUser)
  return (
    <div>
      <div className="flex gap-10 items-center">
        <div className="flex items-center gap-1">
          <img
            src={shipment?.deliver?.picture}
            className="h-8 w-8 rounded-full"
            alt=""
          />
          <div className="flex flex-col">
            <span>Livrée par {shipment?.deliver?.user?.username}</span>
            <UserRating rating={_.mean(shipment?.deliver?.rating)} />{' '}
          </div>
        </div>
        <div>
          {thisUser && (
            <div className="flex gap-3">
              {contact?.map((item, index) => {
                return (
                  <div key={index}>
                    <CallUser
                      user={item?.contact}
                      title={item?.title}
                      color={item?.color}
                    />
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ShipmentHeader
