// import { FaCheck } from 'react-icons/fa'
import { FiAlertTriangle } from 'react-icons/fi'
import { useEffect, useState } from 'react'

function ToastAddPhoneNumber({
  message,
  phoneNumber,
  phoneNumberLogin,
  setShowInputPhoneNumber,
}) {
  const [verify, setVerify] = useState(false)

  useEffect(() => {
    phoneNumber || phoneNumberLogin ? setVerify(false) : setVerify(true)
  }, [phoneNumber, phoneNumberLogin])
  return (
    <div
      className={
        verify
          ? ' flex justify-center items-center  ml-0 px- px-4 py-3 w-[285px] bg-[#f0f0f0] transition-all ease-out delay-700 duration-300 text-[#ffffff] rounded-[4px] absolute mt-[80px] mr-6 top-0 right-0 z-50'
          : ' flex justify-center items-center  ml-200px px-4 py-3 transition-all ease-out delay-700 duration-300 w-[285px] bg-[#2a2929] text-[#ffffff] top-0 rounded-[4px] absolute mt-[-150px] mr-6 right-[0px] z-50'
      }
    >
      <div className=" bg-black-500 rounded-full p-2 mr-2 border-black">
        <FiAlertTriangle className=" text-black" />
      </div>
      <div className="text-[14px]">
        <div>{message}</div>
        <div className="italic font-roboto text-[rgba(0,0,0,0.72)] text-[13px]  pt-2">
          {
            "Vous n'avez pas un numero de telephone lié à votre compte, veillez ajouter car c'est important afin que vous soyez contacté."
          }
        </div>
        <div className="flex font-medium justify-end gap-3 mt-2">
          <div
            className="bg-[rgba(255,0,0,0.37)] hover:cursor-pointer text-[13px] px-[7px] py-[2px] rounded-[4px]"
            onClick={() => setVerify(false)}
          >
            Plus tard
          </div>
          <div
            className="bg-[rgba(0,128,0,0.55)] hover:cursor-pointer text-[13px] px-[7px] py-[3px] rounded-[4px]"
            onClick={() => {
              setShowInputPhoneNumber(true)
              setVerify(false)
            }}
          >
            Ajouter un numero
          </div>
        </div>
      </div>

      <div className="h-[3px] bg-green-500 absolute bottom-0 left-0 right-0 z-20"></div>
    </div>
  )
}

export default ToastAddPhoneNumber
