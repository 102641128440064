import {
  MdHome,
  MdCategory,
  MdAttachMoney,
  MdFavoriteBorder,
  // MdMessage,
  MdAccountCircle,
} from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenCategories } from '../../../features/mainbar/open-categories'
import { setblurpage } from '../../../features/mainbar/blurpage'

export const links = (
  setOpenMenu,
  openMenu,
  setConnecting,
  setName,
  currentUser,
  navigate,
  setCategory,
  setCloseCategory,
  shop
) => {
  const checkCategory = useSelector(
    (state) => state?.opencategories?.opencategories
  )
  // const user = useSelector((state) => state.user.currentUser)
  const blurpage = useSelector((state) => state.blurpage.blurpage)

  const dispatch = useDispatch()

  return [
    {
      name: 'Accueil',
      icon: <MdHome />,
      action: () => navigate('/'),
      ref: '/',
    },
    {
      name: (
        <div>
          <span className="hidden md:block">Liste des catégories</span>
          <span className="block md:hidden">Catégories</span>
        </div>
      ),
      icon: <MdCategory />,
      action: () => {
        setName(name)
        if (checkCategory) {
          dispatch(setOpenCategories(false))

          setCategory(null)
          setCloseCategory(true)
        } else {
          setOpenMenu(false)
          dispatch(setOpenCategories(true))
        }
        dispatch(setblurpage(false))

        if (blurpage) {
          dispatch(setblurpage(false))
        } else {
          dispatch(setblurpage(true))
        }
      },
      isHover: true,
      isCateories: true,
      ref: '/Empty',
    },
    {
      name: (
        <div>
          <span className="hidden md:block">Créer une annonce</span>
          <span className="block md:hidden">Vendre</span>
        </div>
      ),
      icon: <MdAttachMoney />,
      action: currentUser
        ? () => {
            dispatch(setblurpage(false))
            setOpenMenu(false)
            !shop
              ? navigate(`/announcements/me/${currentUser?._id}`)
              : navigate(`/shop/me/${shop?._id}`, {
                  state: { key: shop },
                })
          }
        : () => {
            setConnecting(true)
          },
      isHover: false,
      ref: '/announcements/me',
    },
    {
      name: (
        <div>
          <span className="hidden md:block">Liste de mes favoris</span>
          <span className="block md:hidden">Favoris</span>
        </div>
      ),
      icon: <MdFavoriteBorder />,
      action: currentUser
        ? () => {
            dispatch(setblurpage(false))
            setOpenMenu(false)
            navigate(`/announcements/liked`)
          }
        : () => setConnecting(true),
      isHover: false,
      ref: '/announcements/liked',
    },
    // {
    //   name: (
    //     <div>
    //       <span className="hidden md:block">Ma messagerie</span>
    //       <span className="block md:hidden">Messages</span>
    //     </div>
    //   ),
    //   icon: <MdMessage />,
    //   action: currentUser
    //     ? () => {
    //         dispatch(setblurpage(false))
    //         setOpenMenu(false)
    //         navigate(`/conversations/${user?._id}?user=${user?.username}`)
    //       }
    //     : () => {
    //         setConnecting(true)
    //       },
    //   isHover: false,
    //   ref: '/conversations',
    // },
    {
      name: currentUser ? 'Compte' : 'Se connecter',
      icon: <MdAccountCircle />,
      action: currentUser
        ? () => {
            setOpenMenu(!openMenu)
            dispatch(setblurpage(false))

            if (blurpage) {
              dispatch(setblurpage(false))
            } else {
              dispatch(setblurpage(true))
            }
          }
        : () => {
            setConnecting(true)
          },
      isHover: false,
      ref: '/Empty',
    },
  ]
}

// import {
//   IoGrid,
//   IoMail,
//   IoHeartCircleOutline,
//   IoPersonCircleOutline,
//   IoHome,
// } from 'react-icons/io5'
// import { FaMoneyBill } from 'react-icons/fa6'
// import { useDispatch, useSelector } from 'react-redux'
// import { setOpenCategories } from '../../../features/mainbar/open-categories'
// import { setblurpage } from '../../../features/mainbar/blurpage'

// export const links = (
//   setOpenMenu,
//   openMenu,
//   setConnecting,
//   setName,
//   currentUser,
//   navigate,
//   setCategory,
//   setCloseCategory,
//   shop
// ) => {
//   const checkCategory = useSelector(
//     (state) => state?.opencategories?.opencategories
//   )
//   const user = useSelector((state) => state.user.currentUser)
//   const blurpage = useSelector((state) => state.blurpage.blurpage)

//   const dispatch = useDispatch()

//   return [
//     {
//       name: 'Accueil',
//       icon: <IoHome />,
//       action: () => navigate('/'),
//       ref: '/',
//     },
//     {
//       name: (
//         <div>
//           <span className="hidden md:block">Liste des catégories</span>
//           <span className="block md:hidden">Catégories</span>
//         </div>
//       ),
//       icon: <IoGrid />,
//       // name: 'Categories',

//       action: () => {
//         setName(name)
//         if (checkCategory) {
//           dispatch(setOpenCategories(false))

//           setCategory(null)
//           setCloseCategory(true)
//         } else {
//           setOpenMenu(false)
//           dispatch(setOpenCategories(true))
//         }
//         dispatch(setblurpage(false))

//         if (blurpage) {
//           dispatch(setblurpage(false))
//         } else {
//           dispatch(setblurpage(true))
//         }
//       },
//       isHover: true,
//       isCateories: true,
//       ref: '/Empty',
//     },
//     {
//       name: (
//         <div>
//           <span className="hidden md:block">Créer une annonce</span>
//           <span className="block md:hidden">Vendre</span>
//         </div>
//       ),
//       icon: <FaMoneyBill />,
//       action: currentUser
//         ? () => {
//             dispatch(setblurpage(false))
//             setOpenMenu(false)
//             !shop
//               ? navigate(`/announcements/me/${currentUser?._id}`)
//               : navigate(`/shop/me/${shop?._id}`, {
//                   state: { key: shop },
//                 })
//           }
//         : () => {
//             setConnecting(true)
//           },
//       isHover: false,
//       ref: '/announcements/me',
//     },
//     {
//       name: (
//         <div>
//           <span className="hidden md:block">Liste de mes favoris</span>
//           <span className="block md:hidden">Favoris</span>
//         </div>
//       ),
//       icon: <IoHeartCircleOutline />,
//       action: currentUser
//         ? () => {
//             dispatch(setblurpage(false))
//             setOpenMenu(false)
//             navigate(`/announcements/liked`)
//           }
//         : () => setConnecting(true),
//       isHover: false,
//       ref: '/announcements/liked',
//     },
//     {
//       name: (
//         <div>
//           <span className="hidden md:block">Ma messagerie</span>
//           <span className="block md:hidden">Messages</span>
//         </div>
//       ),
//       icon: <IoMail />,
//       action: currentUser
//         ? () => {
//             dispatch(setblurpage(false))
//             setOpenMenu(false)
//             navigate(`/conversations/${user?._id}?user=${user?.username}`)
//           }
//         : () => {
//             setConnecting(true)
//           },
//       isHover: false,
//       ref: '/conversations',
//     },
//     {
//       name: currentUser ? 'Compte' : 'Se connecter',
//       icon: <IoPersonCircleOutline />,
//       action: currentUser
//         ? () => {
//             setOpenMenu(!openMenu)
//             dispatch(setblurpage(false))

//             if (blurpage) {
//               dispatch(setblurpage(false))
//             } else {
//               dispatch(setblurpage(true))
//             }
//           }
//         : () => {
//             setConnecting(true)
//           },
//       isHover: false,
//       ref: '/Empty',
//     },
//     // {
//     //   name: <span className="md:hidden">Menu</span>,
//     //   icon: <IoMenu className="md:text-[38px]" />,
//     //   action: () => {
//     //     setOpenMenu(!openMenu)
//     //   },
//     //   isHover: false,
//     // },
//   ]
// }
