import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  toastInfo: false,
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.toastInfo = action.payload
    },
  },
})

export const { setToast } = toastSlice.actions

export default toastSlice.reducer
