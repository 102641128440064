import React, { useRef } from 'react'
import Webcam from 'react-webcam'

function GetPicture({ setPicture, picture, msgErrPicture }) {
  const webcamRef = useRef(null)

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot()
    setPicture(imageSrc)
  }

  const retake = () => {
    setPicture(null)
  }

  return (
    <>
      <div className="relative md:w-[400px] mb-3 m-auto">
        <div className="md:w-[400px] m-auto">
          {picture ? (
            <img
              src={picture}
              className="w-[100%] md:w-[400px]"
              alt="webcam"
              onClick={retake}
            />
          ) : (
            <Webcam
              ref={webcamRef}
              onClick={capture}
              className="md:w-[400px]"
            />
          )}
          <div className="text-center text-white py-2 absolute bottom-0 left-0 right-0">
            {picture ? (
              <button onClick={retake}>Cliquez pour reprendre</button>
            ) : (
              <button onClick={capture}>Cliquez pour Capturer photo</button>
            )}
          </div>
        </div>
      </div>
      <div>
        {!picture && (
          <div className="text-[red] font-medium text-[11px] py-2 my-0">
            {msgErrPicture}
          </div>
        )}
      </div>
    </>
  )
}

export default GetPicture
