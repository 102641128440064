import React from 'react'
import Nav from '../../components/layout/Nav'
import { useParams } from 'react-router-dom'
import {
  useGetCustomerOrdersQuery,
  useGetCustomerDeliveredOrdersQuery,
} from '../../services/orderApi'
// import { useGetSellerShipmentsQuery } from '../../services/shipmentApi'
import { useDispatch } from 'react-redux'
import { setDropOffLocation } from '../../features/user/user'
import OrdersTracking from '../../components/orders/OrderTracking'
import CustomerOrdersHistory from '../../components/orders/CustomerOrdersHistory'
// import OrdersToDeliver from '../../components/orders/OrdersToDeliver'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'

const tabs = (orders, deliveredOrders, user, isLoading) => [
  {
    name: 'Suivi des commandes',
    component: (
      <OrdersTracking orders={orders} user={user} isLoading={isLoading} />
    ),
  },
  {
    name: 'Historiques des achats',
    component: <CustomerOrdersHistory orders={deliveredOrders} />,
  },
]

export default function CustomerOrders() {
  const params = useParams()
  const dispatch = useDispatch()
  let currentLocation = JSON.parse(localStorage.getItem('currentLocation'))
  const { data: orders, isLoading } = useGetCustomerOrdersQuery(params.id)
  // const { data } = useGetSellerShipmentsQuery(params.id)
  const { data: deliveredOrders } = useGetCustomerDeliveredOrdersQuery(
    params.id
  )
  console.log('orders:', orders)

  const [activeTab, setActiveTab] = React.useState(0)

  React.useEffect(() => {
    dispatch(setDropOffLocation(currentLocation))
  }, [])

  if (isLoading)
    return (
      <div>
        <Nav />
        <div className="flex flex-col pt-44 items-center justify-center">
          <div>
            <LottieLoader animation={indexLoader} />
          </div>
          <div>Chargement de mes commandes en cours... </div>
        </div>
      </div>
    )

  return (
    <div>
      <Nav />
      <div className="pt-32 lg:px-48 px-5">
        <div className="py-3 lg:text-[20px] font-roboto">
          Mes commandes et achats
        </div>
        <div className="flex items-center gap-5 mb-3 md:text-[15px] sm:text-[12.5px] sm:text-center">
          {tabs(orders, deliveredOrders, params.id, isLoading).map(
            (tab, index) => (
              <div
                key={index}
                className={
                  activeTab === index
                    ? 'bg-blue-200 rounded-[1rem] px-2 py-1 mb-2 cursor-pointer'
                    : 'tab mb-2 cursor-pointer'
                }
                onClick={() => {
                  setActiveTab(index)
                  //setActiveComponent(tab.component)
                }}
              >
                {tab.name}
              </div>
            )
          )}
        </div>
        <div className="tab-content">
          {
            tabs(orders, deliveredOrders, params.id, isLoading)[activeTab]
              .component
          }
        </div>
      </div>
    </div>
  )
}
