import React from 'react'
import { useParams } from 'react-router-dom'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import OrderShipmentMap from '../../components/orders/OrderShipmentMap'
import StartShipment from '../../components/Shipments/StartShipment'
import EndShipment from '../../components/Shipments/EndShipment'
import { useGetShipmentQuery } from '../../services/shipmentApi'
import Nav from '../../components/layout/Nav'

import ShipmentDatas from '../../components/Shipments/ShipmentDatas'
import { IoChevronDown, IoChevronUpSharp, IoMapOutline } from 'react-icons/io5'
import axios from 'axios'

import RatingShipment from '../../components/Shipments/Rating'
import { useSelector } from 'react-redux'
// import UserOrDeliverCard from '../../components/Shipments/UserOrDeliverCard'

// import CallUser from '../../components/Shipments/CallUser'
import AlternateTimeline from '../../components/Shipments/AlternateTimeLine'
import ShipmentHeader from '../../components/Shipments/ShipmentHeader'

export default function Shipment() {
  const params = useParams()
  const currentUser = useSelector((state) => state.user.currentUser)
  const { data: shipment, isLoading } = useGetShipmentQuery(params.id, {
    pollingInterval: 30000,
    refetchOnMountOrArgChange: true,
    skip: false,
  })

  const [direction, setDirection] = React.useState({})
  const [trajectory, setTrajectory] = React.useState({
    distance: '',
    duration: '',
  })
  const [showMap, setShowMap] = React.useState(false)
  const [rating, setRating] = React.useState(false)

  const getDirection = async (shipment) => {
    let pickUpLocation = shipment?.order?.pickUpLocation?.coordinates
    let dropOffAddress = shipment?.order?.dropOffLocation?.coordinates
    let deliverLocation = shipment?.deliver?.user?.location?.coordinates

    await axios
      .get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${pickUpLocation[0]},${pickUpLocation[1]};${dropOffAddress[0]},${dropOffAddress[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
      )
      .then((response) => setDirection(response))

    let result =
      shipment?.status !== 'in delivery'
        ? await axios
            .get(
              `https://api.mapbox.com/directions/v5/mapbox/driving/${pickUpLocation[0]},${pickUpLocation[1]};${dropOffAddress[0]},${dropOffAddress[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
            )
            .then((response) => setDirection(response))
        : await axios.get(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${dropOffAddress[0]},${dropOffAddress[1]};${deliverLocation[0]},${deliverLocation[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
          )

    return result
  }

  console.log('ship order', shipment)

  React.useEffect(() => {
    getDirection(shipment)
    if (
      shipment?.status === 'delivered' &&
      !shipment?.isRated &&
      currentUser?._id === shipment?.order?.customer?._id
    )
      setRating(true)
  }, [shipment])

  React.useEffect(() => {
    setTrajectory({
      distance: direction?.data?.routes[0]?.distance,
      duration: direction?.data?.routes[0]?.duration,
    })
  }, [direction])

  if (isLoading)
    return (
      <div>
        <Nav />
        <div className="flex flex-col pt-44 items-center justify-center">
          <div>
            <LottieLoader animation={indexLoader} />
          </div>
          <div>Chargement des nouvelles livraisons disponibles... </div>
        </div>
      </div>
    )
  return (
    <>
      <div>
        <Nav />
        <div className="pt-36 flex flex-col px-5 lg:px-32">
          <div className="flex flex-col gap-2">
            <div>
              Livraison de la commande {shipment?.order?._id?.slice(-6)}
            </div>

            <div className="flex gap-3 items-center">
              <ShipmentHeader shipment={shipment} />

              {shipment?.deliver && <StartShipment shipment={shipment} />}
              {shipment?.status === 'in delivery' && (
                <EndShipment shipment={shipment} />
              )}
            </div>

            <ShipmentDatas trajectory={trajectory} />
          </div>

          <div className="flex flex-col gap-3 lg:flex-row lg:justify-between">
            <div className="w-[95%] lg:w-[30%]">
              <div
                className="flex gap-1 items-center shadow p-3 mb-3 rounded-[0.5rem] lg:w-[50%] w-[50%] text-[10.5px]"
                onClick={() => setShowMap(!showMap)}
              >
                <IoMapOutline />
                <span>Voir le trajet sur la carte</span>
                {showMap ? <IoChevronUpSharp /> : <IoChevronDown />}
              </div>
              <div className="w-[100%] lg:w-[70%]">
                {' '}
                {showMap && (
                  <OrderShipmentMap
                    pickUpLocation={
                      shipment?.order?.pickUpLocation?.coordinates
                    }
                    dropOffAddress={
                      shipment?.order?.dropOffLocation?.coordinates
                    }
                    deliverLocation={
                      shipment?.deliver?.user?.location?.coordinates
                    }
                    setTrajectory={setTrajectory}
                    direction={direction}
                    shipmentStatus={shipment?.status}
                    shipment={shipment}
                  />
                )}
              </div>
            </div>
            <div className="w-[95%] lg:w-[67%]">
              <AlternateTimeline shipment={shipment} />
            </div>
          </div>
        </div>
        <RatingShipment
          openModal={rating}
          setOpenModal={setRating}
          shipment={shipment}
        />
      </div>
    </>
  )
}
