// import EmptyImage from '../../assets/blank-profile.png'
// navigate
const culumns = (navigate) => {
  return [
    { name: 'Name', selector: (row) => row.name, sortable: true },
    {
      name: 'description',
      selector: (row) => (row.description ? row.description : '-'),
      sortable: true,
    },

    {
      cell: (row) => (
        <div className="flex gap-2">
          <div
            className="bg-primary text-white px-2 py-1 border-none rounded-[3px] outline-none cursor-pointer"
            onClick={() => navigate(`/Account/edit-meanstransports/${row._id}`)}
          >
            Modifier
          </div>
          {/* <div
            className="bg-secondary text-primary  px-2 py-1 border-none rounded-[3px] outline-none cursor-pointer"
            onClick={() => navigate(`/Account/edit-meanstransports/${row._id}`)}
          >
            Detail
          </div> */}
        </div>
      ),
      div: true,
    },
  ]
}

export default culumns
