import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const paymentApi = createApi({
  reducerPath: 'currencyApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    payOrders: builder.mutation({
      query: (order) => ({
        url: 'api/payments/pay',
        method: 'POST',
        body: order,
      }),
    }),
    payProduct: builder.mutation({
      query: ({
        product,
        quantity,
        customer,
        seller,
        dropOffLocation,
        paymentData,
      }) => ({
        url: 'api/payments/product',
        method: 'POST',
        body: {
          product: product,
          quantity: quantity,
          customer: customer,
          seller: seller,
          dropOffLocation: dropOffLocation,
          paymentData: paymentData,
        },
      }),
    }),
    getPayment: builder.query({
      query: (id) => `api/payments/${id}`,
    }),
  }),
})

export const {
  useGetPaymentQuery,
  usePayProductMutation,
  usePayOrdersMutation,
} = paymentApi
