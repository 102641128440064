import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  opencategories: false,
}

export const openCategoriesSlice = createSlice({
  name: 'opencategories',
  initialState,
  reducers: {
    setOpenCategories: (state, action) => {
      state.opencategories = action.payload
    },
  },
})

export const { setOpenCategories } = openCategoriesSlice.actions

export default openCategoriesSlice.reducer
