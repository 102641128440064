import React from 'react'
import { usePayProductMutation } from '../../services/paymentApi'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Nav from '../../components/layout/Nav'
import { TextInput, Label } from 'flowbite-react'
import SearchAddress from '../../components/location/SearchAddress'
import AddressName from '../../components/location/AddressName'
import { BsPencilSquare } from 'react-icons/bs'

function PayProduct() {
  let location = useLocation()
  let currentUser = useSelector((state) => state.user.currentUser)
  let dropOffLocation = useSelector((state) => state.user.dropOffLocation)
  let announcement = location.state.key
  let [payProduct] = usePayProductMutation()

  const [phone, setPhone] = React.useState('')
  const [quantity, setQuantity] = React.useState(1)
  const [openMap, setOpenMap] = React.useState(false)
  // setQuantity()
  // setPhone()
  let seller = announcement?.seller
    ? announcement?.seller?.user?._id
    : announcement?.user?._id

  let paymentData = {
    merchant: 'GBHOLDING',
    type: '1',
    phone: `243${phone}`,
    reference: 'PAY_ORDER',
    amount: `${announcement?.currency?.shortName === '$' ? announcement?.price * 28500 : announcement?.price}`,
    currency: 'CDF',
    callbackUrl: 'https://localhost:3000',
    description: 'Paiement sur uty par mobile money',
  }

  const pay = async (
    product,
    quantity,
    seller,
    customer,
    paymentData,
    dropOffLocation
  ) => {
    await payProduct({
      product: product,
      quantity: quantity,
      customer: customer,
      seller: seller,
      paymentData: paymentData,
      dropOffLocation: dropOffLocation,
    })
  }
  console.log('datas', announcement, paymentData, seller)
  return (
    <div>
      <div>
        <Nav />
      </div>
      <div className="pt-36 px-80 flex flex-col gap-2">
        <div className="text-[20px] font-roboto">
          Paiement du produit {announcement?._id.slice(-6)}
        </div>
        <div className="flex items-center gap-2">
          <div>
            <img
              className="h-10 w-10 rounded-[0.25rem]"
              src={announcement?.images[0]}
              alt=""
            />
          </div>
          <div>
            <span className="text-[15px] font-roboto">
              {announcement?.name}
            </span>
            <p className="font-light text-sm">{announcement?.description}</p>
          </div>
        </div>
        <div className="flex flex-col gap-2 justify-center w-[100%]">
          <div className="flex flex-col text-[15px]">
            <div>Votre addresse de livraison</div>
            <div className="flex gap-2">
              <AddressName />
              <button
                onClick={() => {
                  setOpenMap(true)
                }}
                className="flex items-center text-[10px] gap-1 p-2 shadow rounded-[1rem]"
              >
                <BsPencilSquare /> Modifier l{"'"}addresse
              </button>
            </div>
          </div>
          <div>
            {' '}
            <div className="mb-2 block">
              <Label
                htmlFor="phone"
                value="Entrer votre numéro de téléphone mobile money"
              />
            </div>
            <TextInput
              id="phone"
              className="w-[50%]"
              onChange={(e) => {
                e.preventDefault()
                setPhone(e.target.value)
                setQuantity()
              }}
              placeholder="Bonnie Green"
              addon="+243"
              required
            />
          </div>
        </div>
        <div>
          <button
            className="bg-primary px-2 py-1 rounded-[0.5rem] text-white text-[15px] mt-3"
            onClick={() =>
              pay(
                announcement?._id,
                quantity,
                seller,
                currentUser?._id,
                paymentData,
                dropOffLocation
              )
            }
          >
            Payer le produit
          </button>
        </div>
      </div>
      <SearchAddress openModal={openMap} setOpenModal={setOpenMap} />
    </div>
  )
}

export default PayProduct
