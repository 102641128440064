import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { IoCamera, IoEyeOutline } from 'react-icons/io5'
import { FiPhone } from 'react-icons/fi'
// import { FiMail } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { FiUser } from 'react-icons/fi'
import { BASE_URL } from '../../helpers/Root'
import ImgDRC from '../../assets/cd.svg'
import ControlePhone from '../../helpers/ControlePhone'
import { setCurrentUser } from '../../features/user/user'
import { useDispatch } from 'react-redux'
import { setToast } from '../../features/toast/toast'
import ReactGA from 'react-ga4'
import { Modal } from 'flowbite-react'
import { IoCloseSharp } from 'react-icons/io5'

export default function RegisterModal({ openModal, setOpenModal }) {
  const [selectedImg, setSelectedImg] = useState(null)
  const [picUrl, setPicUrl] = useState(null)
  const [url, setUrl] = useState(null)
  const [isLoad, setIsLoad] = useState(false)
  const [isHide, setIsHide] = useState(false)
  const [contact, setContact] = useState('')

  const navigate = useNavigate()
  let dispatch = useDispatch()

  dispatch(setCurrentUser(null))
  dispatch(setToast(false))

  //Controle phone number
  //Controle telephone
  useEffect(() => {
    ControlePhone(contact, setPhone)
  }, [contact])

  const hidePassword = () => {
    if (isHide) {
      setIsHide(false)
    } else {
      setIsHide(true)
    }
  }

  const toastOptions = {
    position: 'bottom-right',
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  }

  const [username, setUsername] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')

  const handleValidation = () => {
    if (username.length <= 3) {
      toast.error(
        "Le nom d'utilisateur doit avoir au moins 3 caracteres",
        toastOptions
      )
      setIsLoad(false)
      return false
    } else if (password.length < 8) {
      toast.error(
        'Le mot de passe doit avoir au moins 8 caracteres',
        toastOptions
      )
      setIsLoad(false)
      return false
    }
    setIsLoad(false)
    return true
  }
  const handleSubmit = async (event) => {
    setIsLoad(true)
    event.preventDefault()
    ReactGA.event({
      category: 'Main clicks',
      action: 'Sign-up by password',
    })
    if (handleValidation()) {
      // const { email, username, phone, password } = values
      if (contact.length === 0) {
        CreateUser()
      } else if (contact.length > 0 && phone) {
        CreateUser()
      } else {
        toast.error('Le numero de tephone incorrect', toastOptions)
        setIsLoad(false)
        return false
      }
    }
  }

  const CreateUser = async () => {
    const data = {
      username,
      phone,
      password,
      image: url,
    }

    setIsLoad(true)

    await axios
      .post(`${BASE_URL}/api/auth/register`, data)
      .then((response) => {
        if (response.data.status === true) {
          dispatch(setCurrentUser(response.data.user))
          dispatch(setToast(true))
          setIsLoad(false)

          localStorage.setItem(
            'currentUser',
            JSON.stringify(response.data.user)
          )
          navigate('/')
          setIsLoad(false)
          window.location.reload()
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setIsLoad(false)
          toast.error(error.response.data.msg, toastOptions)
          setIsLoad(false)
        }
      })
  }

  useEffect(() => {
    if (selectedImg) {
      setPicUrl(URL.createObjectURL(selectedImg))
      const reader = new FileReader()
      reader.readAsDataURL(selectedImg)
      reader.onloadend = () => {
        setUrl(reader.result)
      }
    }
  }, [selectedImg])

  return (
    <>
      <Modal
        className="flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
        show={openModal}
        popup
        size="md"
        dismissible
        onClose={() => openModal}
      >
        <div className="flex flex-col rounded-lg items-center pt-10 pb-6 ">
          <IoCloseSharp
            className="self-end mr-10 text-[25px] cursor-pointer"
            onClick={() => setOpenModal(false)}
          />
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="md:mb-5">
              <div className="flex justify-center md:justify-center relative pb-5">
                <div className="self-center">
                  <input
                    type="file"
                    onChange={(e) => setSelectedImg(e.target.files[0])}
                    className="absolute opacity-0 md:top-[-30px] self-center mt-[5vh] bd-primary w-[100px] overflow-hidden py-8"
                  />
                  <div className="flex justify-center items-center bg-white h-[15vh] w-[15vh] md:w-[7.5vw] rounded-[100%] text-6xl">
                    <label className="self-center text-primary" htmlFor="file">
                      {!picUrl ? (
                        <IoCamera />
                      ) : (
                        <img
                          src={picUrl}
                          alt=""
                          className="rounded-[100%] h-[15vh] w-[15vh] object-cover"
                        />
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="relative flex flex-col gap-3">
                <div className="md:bg-white rounded-[2px]">
                  <input
                    type="text"
                    className="border-[1px] pl-2 pr-6 border-[rgba(90,86,86,0.08)] py-[10px] w-full outline-none text-[15px] text-[rgba(0,0,0,0.67)] md:py-[7px] rounded-[2px]"
                    placeholder="Username"
                    name="username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <FiUser className="absolute text-primary top-[13px] right-0 text-[12px] md:top-[14px] mr-2" />
                </div>
                {/* <div className="relative flex md:bg-white rounded-[2px]">
                  <input
                    className="border-[1px] pl-2 pr-6 border-[rgba(90,86,86,0.08)] py-[10px] w-full outline-none text-[15px] text-[rgba(0,0,0,0.67)] md:py-[7px] rounded-[2px]"
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <FiMail className="absolute text-primary top-[13px] right-0 text-[12px] md:top-[14px] mr-2" />
                </div> */}
                <div className="relative flex md:bg-white rounded-[2px]">
                  <input
                    className="border-[1px] w-[100%] border-[rgba(90,86,86,0.08)] py-[10px] outline-none text-[15px] text-[rgba(0,0,0,0.67)] pl-[66px] pr-4 md:py-[7px] rounded-[2px] md:w-[100%]"
                    type="text"
                    placeholder="Téléphone"
                    name="phone"
                    onChange={(e) => setContact(e.target.value)}
                  />
                  <img
                    src={ImgDRC}
                    alt="ImgDRC"
                    className="w-[23px] h-[16px] absolute top-[12px] md:top-[10px] ml-1"
                  />
                  <div className="w-[23px] text-[15px] h-[16px] absolute left-6 top-[10px] md:top-[8px] ml-1">
                    +243
                  </div>
                  <FiPhone className="absolute text-primary top-[13px] right-0 text-[12px] md:top-[14px] mr-2" />
                </div>
                <div className="relative flex md:bg-white rounded-[2px]">
                  <input
                    className="border-[1px] w-[100%] border-[rgba(90,86,86,0.08)] py-[10px] outline-none text-[15px] text-[rgba(0,0,0,0.67)] px-4 md:py-[7px] rounded-[2px] md:w-[100%]"
                    type={isHide ? 'text' : 'password'}
                    placeholder="Password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <IoEyeOutline
                    className="absolute text-primary top-[13px] right-0 text-[12px] md:top-[14px] mr-2"
                    onClick={hidePassword}
                  />
                </div>
                <div className=" py-[10px] text-white text-[15px] bg-primary text-center md:py-[7px] rounded-[2px]">
                  <button className="" type="submit">
                    {isLoad ? (
                      <div className="">En cours...</div>
                    ) : (
                      <div className="">{"S'inscrire"}</div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer />
      </Modal>
    </>
  )
}
