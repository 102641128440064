import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  announcementId: null,
}

export const idAnnouncementSlice = createSlice({
  name: 'idnnouncement',
  initialState,
  reducers: {
    setIdAnnouncement: (state, action) => {
      state.announcementId = action.payload
    },
  },
})

export const { setIdAnnouncement } = idAnnouncementSlice.actions

export default idAnnouncementSlice.reducer
