import React from 'react'
import TextAreaForm from '../../../components/admin/formComponent/TextAreaForm'
import { useState } from 'react'
import { refuseRequestDeliver } from '../../../services/delivers/refused-request-deliver'
import InputForm from '../formComponent/InputForm'

function ModalRefusedRequest({
  showModal,
  setShowModal,
  handleClick,
  userId,
  isLoader,
  setIsLoader,
}) {
  console.log('handleClick : ', handleClick)
  const [reason, setReason] = useState(null)
  const [object, setObject] = useState(null)
  const [errMsgReason, setErrMsgReason] = useState('')
  const [errMsgObject, setErrMsgObject] = useState('')

  console.log('setObject : ', setObject)
  console.log('userId : ', userId)

  const handleRefuse = (e) => {
    e.preventDefault()
    let data = { object, reason }
    if (!object) {
      setErrMsgObject('Ce champs est obligatoire')
    } else if (!reason) {
      setErrMsgReason('Ce champs est obligatoire')
    } else {
      console.log('reason : ', reason)
      setShowModal(false)
      refuseRequestDeliver(userId, isLoader, setIsLoader, data)
    }
  }

  // The function to refuse the request of deliver
  // const handleRefuse = () => {
  //   setShowModalDecline(false)
  //   refuseRequestDeliver(user?._id, isLoader, setIsLoader)
  // }

  return (
    showModal && (
      <div className=" absolute top-0 right-0 z-[100] h-[100vh] w-[100wh] flex justify-center items-center  left-0 bottom-0 bg-[rgba(46,46,46,0.6)]">
        <div
          id="popup-modal"
          tabIndex="-1"
          className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative flex justify-center items-center p-4  w-full h-full ">
            <div className="relative w-[500px] h-[auto] bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="popup-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-4 md:p-5 text-center">
                <svg
                  className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <h3 className="mb-2 text-md font-normal text-gray-500 dark:text-gray-400">
                  Voulez-vous decliner cette damande ?
                </h3>
                <div className="flex flex-col mb-2">
                  <InputForm
                    nameLabel={'Objet de declinaison'}
                    requiredInput
                    seSstate={setObject}
                    valueState={object}
                    errorMessage={errMsgObject}
                  />
                  <TextAreaForm
                    nameLabel={'Raison de declinaison'}
                    requiredInput
                    seSstate={setReason}
                    valueState={reason}
                    errorMessage={errMsgReason}
                  />
                </div>
                <button
                  onClick={handleRefuse}
                  data-modal-hide="popup-modal"
                  type="button"
                  className="text-white bg-red-500 hover:bg-red-400 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                >
                  Oui, je suis sûr
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  data-modal-hide="popup-modal"
                  type="button"
                  className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default ModalRefusedRequest
