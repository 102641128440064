import { createSlice } from '@reduxjs/toolkit'

// Définir l'état initial du panier, un tableau vide
const initialState = []

// Créer le slice du panier
const basketSlice = createSlice({
  name: 'basket', // Le nom du slice
  initialState, // L'état initial
  reducers: {
    // Les réducteurs, qui modifient l'état en fonction des actions
    // Chaque réducteur génère automatiquement une action correspondante
    addProduct: (state, action) => {
      // Ajouter un produit au panier
      // Utiliser Immer pour modifier l'état de manière immuable
      // action.payload contient le produit à ajouter
      const product = action.payload
      // Vérifier si le produit existe déjà dans le panier
      const index = state.findIndex((item) => item._id === product._id)
      if (index !== -1) {
        // Si oui, augmenter sa quantité de 1
        state[index].quantite++
      } else {
        // Sinon, ajouter le produit au panier avec une quantité de 1
        state.push({ ...product, quantite: 1 })
      }
    },
    removeProduct: (state, action) => {
      // Supprimer un produit du panier
      // action.payload contient le code du produit à supprimer
      const code = action.payload
      // Filtrer le panier pour ne garder que les produits dont le code est différent
      return state.filter((item) => item.code !== code)
    },
    updateQuantity: (state, action) => {
      // Modifier la quantité d'un produit dans le panier
      // action.payload contient le code du produit et la nouvelle quantité
      const code = action.payload
      // Trouver l'index du produit dans le panier
      const index = state.findIndex((item) => item._id === code)
      if (index !== -1) {
        // Si le produit existe, modifier sa quantité
        state[index].quantite++
      }
    },
    substractQuantity: (state, action) => {
      // Modifier la quantité d'un produit dans le panier
      // action.payload contient le code du produit et la nouvelle quantité
      const code = action.payload
      // Trouver l'index du produit dans le panier
      const index = state.findIndex((item) => item._id === code)
      if (index !== -1) {
        // Si le produit existe, modifier sa quantité
        state[index].quantite--
      }
    },
    clearBasket: (state, action) => {
      // Vider le panier
      console.log(state, action)
      return []
    },
  },
})

// Exporter les actions générées par le slice
export const {
  addProduct,
  removeProduct,
  updateQuantity,
  substractQuantity,
  clearBasket,
} = basketSlice.actions

// Exporter le réducteur du slice
export default basketSlice.reducer
