import React, { useEffect, useRef, useState } from 'react'
import Conversation from '../../components/chats/Conversation'
import Message from '../../components/chats/Message'
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'
import NoChat from '../../components/chats/NoChat'
// import io from 'socket.io-client'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getChats } from '../../services/Chats/getChats'
import { getMessages } from '../../services/Messages/getMessages'
import { cloudinary } from '../../services/cloudinary/cloudinary'
import ReactGA from 'react-ga4'
// import HeaderConversation from '../../components/chats/HeaderConversation'
import HeaderChat from '../../components/chats/HeaderChat'
import ModalImage from '../../components/chats/chatImage/ModalImage'
// import FirstMessageDefault from '../../components/chats/chatImage/FirstMessageDefault'
import SpaceMessage from '../../components/chats/spaceMessage/SpaceMessage'
import BackgroundImage from '../../assets/bg/bg-8.jpg'
import Nav from '../../components/layout/Nav'
import { IoSearchSharp } from 'react-icons/io5'
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

function Chat() {
  const [usersChats, setUsersChats] = useState([])
  const [chat, setChat] = useState(null)
  const [messages, setMessages] = useState([])
  const [arrivalMessage, setArrivalMessage] = useState([])
  const [text, setText] = useState('')
  const [images, setImages] = useState([])
  const [imageUTLs, setImageUTLs] = useState([])
  const [showUploadImage, setShowUploadImage] = useState(false)
  const [selectedUserIndex, setSelectedUserIndex] = useState(null)
  const [loader, setLoader] = useState(false)
  const [searchUsers, setSearchUsers] = useState('')

  const scrolRef = useRef(null)

  const currentUser = useSelector((state) => state.user.currentUser)
  const socketIo = useSelector((state) => state?.socket?.socket)
  const onlineUser = useSelector((state) => state?.onlineusers?.onlineusers)

  const navigate = useNavigate()

  useEffect(() => {
    const chatAnnounce = JSON.parse(localStorage.getItem('chat'))
    if (chatAnnounce) {
      setChat(chatAnnounce)
    }
  }, [])

  useEffect(() => {
    if (images?.length > 0) {
      const newImageURLs = []
      images.forEach((image) => newImageURLs?.push(URL.createObjectURL(image)))
      setImageUTLs(newImageURLs)
    }
  }, [images])

  const readFileHandler = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
  }

  //Upload images
  const selectFilesHandler = (e) => {
    const imagesData = []
    setImages([...e.target.files])
    setShowUploadImage(true)
    _.forEach(e.target.files, (file) => {
      imagesData.push(readFileHandler(file))
    })
  }

  useEffect(() => {
    arrivalMessage &&
      chat?.members?.includes(arrivalMessage?.senderId) &&
      setMessages((prev) => [...prev, arrivalMessage])
  }, [arrivalMessage, chat])

  useEffect(() => {
    socketIo.on('receiveMessage', (data) => {
      setArrivalMessage({
        senderId: data.senderId,
        text: data.text,
        images: data.images,
        announcementId: data.announcementId,
        createdAt: Date.now(),
      })
      return () => {
        socketIo.off('receiveMessage')
      }
    })
  }, [socketIo, arrivalMessage])

  const dataMessage = {
    chatId: chat?._id,
    senderId: currentUser?._id,
    text: text || null,
    date: new Date().toUTCString(),
  }

  const receiverId = chat?.members.find((id) => id !== currentUser?._id)

  //Use cloudinary
  let fromData = new FormData()
  fromData.append('file', images[0])
  fromData.append('upload_preset', 'yp1zbtgx')

  const sendMessage = async () => {
    ReactGA.event({
      category: 'Main clicks',
      action: 'Send a message',
    })
    if (text.length > 0 || imageUTLs.length > 0) {
      setShowUploadImage(false)
      setLoader(true)

      let imgs = []
      if (imageUTLs.length > 0) {
        await cloudinary(fromData)
          .then((res) => {
            imgs.push(res.data.secure_url)
            dataMessage.images = imgs
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const message = {
        text: text || null,
        senderId: currentUser?._id,
        receiverId: receiverId,
        announcementId: null,
        images: imgs || [],
      }
      socketIo.emit('sendMessage', message)

      try {
        const { data } = await axios.post(
          `${BASE_URL}/api/message/send`,
          dataMessage
        )

        if (data.message === 'success ') {
          setLoader(true)
          setTimeout(() => {
            setLoader(false)
            setMessages([...messages, data.result])
          }, 1000)
        }
      } catch (err) {
        console.log(err)
        setLoader(false)
      }
      setText('')
      setImageUTLs([])
      setImages([])
      //send message in socket
    }
  }

  // scroll auto message
  useEffect(() => {
    scrolRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  useEffect(() => {
    getChats(setUsersChats, currentUser?._id)
  }, [currentUser?._id])

  useEffect(() => {
    getMessages(setMessages, chat?._id)
  }, [chat])

  const removeImageSelected = () => {
    setShowUploadImage(false)
    setImageUTLs([])
  }

  return (
    <div className="">
      <div className="hidden md:block lg:block">
        <Nav />
      </div>

      <div className="hidden md:h-[12vh] md:block lg:h-[20vh]  lg:bg-[#ececec]"></div>

      <div className="flex flex-col md:bg-none h-[100vh] md:h-[88vh] lg:h-[80vh] md:flex  md:flex-row lg:flex lg:flex-row ">
        <div
          className={
            !chat
              ? 'h-[100vh] md:h-[88vh] lg:h-[80vh] flex flex-col md:w-[30%] lg:w-[30%] '
              : 'h-[100vh] md:h-[88vh] lg:h-[80vh] md:flex lg:flex md:flex-col md:w-[30%] lg:w-[30%]  hidden '
          }
        >
          <div className="px-3 md:px-4 lg:px-12 mb-12 md:mb-16 lg:mb-16 bg-primary md:bg-white lg:bg-white">
            <h1 className="text-xl font-bold text-[#d8d8d8] md:text-black lg:text-black py-4">
              {' '}
              <div className="flex items-center gap-4">
                <div
                  className="cursor-pointer text-white md:hidden lg:hidden"
                  onClick={() => {
                    navigate('/')
                    setChat(null)
                  }}
                >
                  <FaArrowLeft />
                </div>
                <div>Discussion</div>
              </div>
            </h1>
            <div className="relative ">
              <input
                onChange={(e) => setSearchUsers(e.target.value)}
                type="text"
                placeholder="Rechercher..."
                className="w-full px-4 absolute top-[3px] h-[#636363] placeholder:text-[#686868] bg-[#e9e9e9] focus:outline-none rounded-xl focus:ring-0 focus:border-transparent border-transparent"
              />
              <IoSearchSharp className="absolute right-3 top-4" />
            </div>
          </div>

          <div
            onClick={() => {
              localStorage.removeItem('chat')
            }}
            className="md:cursor-pointer lg:cursor-pointer flex flex-col p-0 overflow-y-scroll md:overflow-y-scroll lg:overflow-y-scroll relative"
          >
            {usersChats?.chat?.map((chats, index) => (
              <div
                key={(chats?._id, index)}
                onClick={() => {
                  setChat(chats), setSelectedUserIndex(chats)
                }}
              >
                <Conversation
                  chats={chats}
                  onlineUser={onlineUser}
                  messages={messages}
                  currentUser={currentUser?._id}
                  selectedUserIndex={selectedUserIndex}
                  searchUsers={searchUsers}
                />
              </div>
            ))}
          </div>
        </div>
        {/* ici */}
        <div className="flex flex-col w-[100%] md:w-[70%] lg:w-[70%] relative">
          {chat && (
            <div className=" fixed top-0 left-0 right-0 z-[10] pt-0 md:pt-[3px] lg:pt-0 md:static lg:static">
              <HeaderChat
                setChat={setChat}
                chat={chat}
                data={chat}
                currentUser={currentUser?._id}
                onlineUser={onlineUser}
                userChat={chat}
              />
            </div>
          )}
          <div
            className={
              chat
                ? `overflow-y-auto h-[100vh]  ${imageUTLs.length && 'flex flex-col justify-center'}  z-[9] md:w-[100%] md:h-[88vh] lg:h-[80vh]  relative`
                : 'h-auto md:h-[88vh] lg:h-[80vh]'
            }
            style={{
              backgroundImage: `url(${chat && BackgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundAttachment: 'fixed', // Optionnel, pour un fond fixe
              // filter: 'blur(8px)', // Ajoute un flou
            }}
          >
            <div className={!chat ? 'w-[100%] ' : 'w-[100%]'}>
              {images?.length > 0 && showUploadImage && (
                <ModalImage
                  imageUTLs={imageUTLs}
                  removeImageSelected={removeImageSelected}
                />
              )}
              {chat && (
                <div className={showUploadImage ? 'hidden' : ' md:pt-0 '}>
                  {messages ? (
                    <div className="pt-20 pb-10 md:pt-3 md:pb-0 lg:pt-3 lg:pb-0">
                      {messages?.map((msg) => (
                        <div key={msg?._id}>
                          <div ref={loader ? null : scrolRef}>
                            <Message
                              message={msg}
                              currentUser={currentUser}
                              owner={msg?.senderId === currentUser?._id}
                            />
                          </div>
                        </div>
                      ))}
                      <div ref={scrolRef} className=" mb-[45px] px-3">
                        {loader && (
                          <div className="0 px-2 py-4 w-[20%] rounded-[20px] animated-background-msg"></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="pt-16 text-center ">
                      Chargement des messages...
                    </div>
                  )}
                </div>
              )}
            </div>
            {!chat && (
              <div className="hidden h-[100vh] md:h-[88vh] lg:h-[80vh] w-[100%] md:flex md:justify-center md:items-center md:bg-[#fafafa] ">
                <NoChat />
              </div>
            )}
          </div>
          {chat && (
            <div className="fixed bottom-0 left-0 right-0 z-[100] md:static lg:static">
              <SpaceMessage
                setText={setText}
                text={text}
                selectFilesHandler={selectFilesHandler}
                sendMessage={sendMessage}
              />
            </div>
          )}
        </div>

        {/* ici */}
      </div>
    </div>
  )
}

export default Chat
