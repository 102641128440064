import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const createCategory = async (
  name,
  icon,
  description,
  isAdmin,
  characteristics
) => {
  const data = await axios.post(`${BASE_URL}/api/categories/create-category`, {
    name,
    icon,
    description,
    isAdmin,
    characteristics,
  })

  return data

  //   setData(data)
}

export { createCategory }
