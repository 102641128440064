import React from 'react'
import OrderItem from './OrderItem'
import {
  useAcceptOrderMutation,
  useRefuseOrderMutation,
} from '../../services/orderApi'
//import { useNavigate } from 'react-router-dom'
import { BsBagX } from 'react-icons/bs'

function OrdersToValidate({ orders }) {
  const [acceptOrder] = useAcceptOrderMutation()
  const [refuseOrder] = useRefuseOrderMutation()
  // const navigate = useNavigate()

  const validateOrder = async (order, location) => {
    await acceptOrder({ order, location })
  }

  const declineOrder = async (order) => {
    await refuseOrder(order)
  }

  if (!orders || orders?.length === 0) {
    return (
      <div>
        <div className="flex flex-col gap-5 justify-center items-center pt-20">
          <BsBagX className="text-[50px] text-slate-500" />
          <div>{"Vous n'avez pas de nouvelles commandes!!"}</div>
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-3">
      {orders
        ?.map((order) => {
          return (
            <div key={order?._id} className="shadow rounded-[0.5rem]">
              <OrderItem
                order={order}
                acceptOrder={validateOrder}
                refuseOrder={declineOrder}
              />
            </div>
          )
        })
        .reverse()}
    </div>
  )
}

export default OrdersToValidate
