// decline-request-delivers/:id
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const refuseRequestDeliver = async (idDeliver, isLoader, setIsLoader, data) => {
  console.log('data: ', data)
  console.log('isLoader: ', isLoader)
  const response = await axios.patch(
    `${BASE_URL}/api/delivers/decline-request-delivers/${idDeliver}`,
    data
  )
  try {
    if (response.status === 200) {
      setIsLoader(isLoader ? false : true)
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

export { refuseRequestDeliver }
