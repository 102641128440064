import React from 'react'
import { useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa' // Import de l'icône pour "voir plus d'informations"
import { MdAttachMoney, MdMessage } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function ElevatedButton() {
  // let icons = ["face", "data", "kop"]
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()

  console.log(navigate)
  const user = useSelector((state) => state.user.currentUser)

  return (
    <div className="relative bg-black">
      <div
        onClick={() => setOpen(open ? false : true)}
        className="fixed z-50 flex items-center justify-center w-12 h-12 font-bold text-white transition-transform transform rounded-full shadow-lg cursor-pointer right-8 bottom-8 bg-gradient-to-r from-red-500 to-yellow-400 hover:scale-110"
      >
        <FaInfoCircle className="text-2xl cursor-pointer" />
        <div
          onClick={() => {
            open && navigate(`/conversations/${user?._id}?user=evodie`)
          }}
          className={`fixed block p-2  transform rounded-full shadow-lg bg-gradient-to-r from-blue-500 to-yellow-400 hover:scale-105 transition-all delay-500 ease-out ${open ? '-top-16 transition-all delay-300 ease-out opacity-100' : 'opacity-0 transition-all delay-300 ease-out'} `}
        >
          <MdMessage className="text-2xl" />
        </div>
        <div
          onClick={() => {
            open && navigate('/announcements/create')
          }}
          className={`fixed block p-2  transform rounded-full shadow-lg bg-gradient-to-r from-blue-500 to-yellow-400 hover:scale-105 transition-all delay-300 ease-ou ${open ? 'right-20 transition-all delay-300 ease-out opacity-100' : 'opacity-0 transition-all delay-300 ease-out'} `}
        >
          <MdAttachMoney className="text-2xl" />
        </div>
      </div>
    </div>
  )
}

export default ElevatedButton
