import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const detailCategory = async (id, setState, setLoader) => {
  await axios
    .get(`${BASE_URL}/api/categories/detail/${id}`)
    .then((response) => {
      try {
        setState(response?.data?.category)
        console.log('category:', response.data.category)
        if (setLoader) {
          setLoader(true)
        }
      } catch (err) {
        console.error(err)
      }
    })
}

export { detailCategory }
