import React from 'react'
import { useNavigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'
import AnnouncementPopOver from './AnnouncementPopOver'

export default function MyAnnouncementCard({
  announcement,
  setDeleting,
  setSelected,
  setOpen,
}) {
  const navigate = useNavigate()

  return (
    <div className="flex bg-white border-[1px] border-silver-500 rounded-[0.5rem] p-3 items-center">
      <div className="">
        <img
          className="h-[18.5vh] md:h-[15vh] lg:w-[7.5vw] md:w-[17.5vw] w-[35vw] object-cover rounded-[0.5rem] shadow"
          src={announcement?.images[0]}
          alt=""
        />
      </div>
      <div className="p-5 lg:w-[70%] sm:w-[70%]">
        <div>
          <div className="truncate font-medium lg:w-[100%] sm:w-[100%]">
            <span>{announcement?.name}</span>
          </div>
          <div className="truncate font-extralight text-sm">
            <span className="">{announcement?.description}</span>
          </div>
        </div>

        <div className="flex gap-2">
          <span className="text-[rgba(0,0,0,0.65)] pt-2 lg:pt-2 text-[16px] font-black">
            <NumericFormat
              value={announcement?.price}
              displayType={'text'}
              thousandSeparator=" "
            />
            {announcement?.currency?.shortName
              ? announcement?.currency?.shortName
              : 'fc'}
          </span>
          <span className="text-[rgba(0,0,0,0.65)] lg:pt-2 text-[10px] font-black">
            {announcement?.convert}
            {announcement?.currency?.shortName === '$' ? 'fc' : '$'}
          </span>
        </div>
      </div>
      <div className="self-start cursor-pointer">
        <AnnouncementPopOver
          setSelected={setSelected}
          setDeleting={setDeleting}
          navigate={navigate}
          announcement={announcement}
          setOpen={setOpen}
        />
      </div>
    </div>
  )
}
