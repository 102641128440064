import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => `api/categories`,
    }),
    getCategory: builder.query({
      query: (category) => `api/categories/${category}`,
    }),
  }),
})

export const { useGetCategoriesQuery, useGetCategoryQuery } = categoryApi
