// import EmptyImage from '../../assets/blank-profile.png'
// navigate
const culumns = () => {
  return [
    { name: 'Name', selector: (row) => row.name, sortable: true },
    {
      name: 'description',
      selector: (row) => (row.description ? row.description : '-'),
      sortable: true,
    },
    {
      name: 'Sous categories',
      selector: (row) => (row.categories ? row.categories.length : '-'),
      sortable: true,
    },

    {
      cell: () => (
        // row
        <div className="flex gap-2">
          <div
            className="bg-primary text-white px-2 py-1 border-none rounded-[3px] outline-none cursor-pointer"
            //   onClick={() => navigate(`/Account/edit-meanstransports/${row._id}`)}
          >
            Détail
          </div>
        </div>
      ),
      div: true,
    },
  ]
}

export default culumns
