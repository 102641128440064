import React from 'react'
import axios from 'axios'
// import { useSelector } from 'react-redux'
import { BsFillGeoFill } from 'react-icons/bs'

function DisplayAddress({ location }) {
  const [address, setAddress] = React.useState()
  const getAddress = async () => {
    const res = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${location[0]},${location[1]}.json?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw`
    )
    setAddress(res.data.features[0].place_name)
  }
  React.useEffect(() => {
    getAddress()
  }, [location])
  console.log(address)
  return (
    <div className="font-light text-sm  flex items-center gap-1">
      {' '}
      <BsFillGeoFill /> {address}
    </div>
  )
}

export default DisplayAddress
