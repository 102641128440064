import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getSubCategories } from '../../../services/Subcategories/get-subcategories'
import { getCategories } from '../../../services/Categories/get-categories'
import { joinSubcategoryToCategory } from '../../../services/Categories/joinsubcategory-tocategory'

function JoinSubCategoryToCategory() {
  // const [errMsgdescription, setErrMsgdescription] = useState(null)
  const [loading, setLoading] = useState(false)
  // const [loader, setLoader] = useState(false)
  const [subcategories, setSubcategories] = useState([])
  const [subcategory, setSubcategory] = useState(null)

  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState(null)

  const currentUser = useSelector((state) => state.user.currentUser)

  const navigate = useNavigate()

  useEffect(() => {
    getSubCategories(setSubcategories)
    getCategories(setCategories)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    if (!category && subcategory) {
      setLoading(false)
    } else {
      const response = await joinSubcategoryToCategory(
        category,
        subcategory,
        currentUser?.isAdmin
      )

      if (response.status === 200) {
        navigate('/Account/list-categories')
      }
    }
  }

  return (
    <div className="px-[16px] md:px-0 md:w-[60%] m-auto">
      <h2 className="font-medium text-[17px] pt-[65px] md:pt-0 pb-3 md:pb-5">
        Ajouter une sous categorie à une categorie
      </h2>
      <form onSubmit={handleSubmit}>
        <div className=" flex flex-col gap-2">
          <label
            htmlFor=""
            className="text-[rgba(0,0,0,0.8)] text-[13px] font-light flex gap-1"
          >
            Selectionner une categorie
            <sapn className="text-[red] font-medium">*</sapn>
          </label>

          <select
            className="outline-none px-3 py-[11px] md:py-[10px]  border-[1px]  rounded-[4px] text-[13px] focusInput"
            onChange={(e) => {
              setCategory(e.target.value)
            }}
          >
            <option value="">Selectionner une categorie</option>
            {categories.map((category) => (
              <option key={category?._id} value={category?._id}>
                {category?.name}
              </option>
            ))}
          </select>
          {/* {errors.category && (
                  <p className="text-red-500">{errors.category}</p>
                )} */}
        </div>

        <div className=" flex flex-col gap-2">
          <label
            htmlFor=""
            className="text-[rgba(0,0,0,0.8)] text-[13px] font-light flex gap-1"
          >
            Selectionner une sous categorie
            <sapn className="text-[red] font-medium">*</sapn>
          </label>
          <select
            className="outline-none px-3 py-[11px] md:py-[10px]  border-[1px]  rounded-[4px] text-[13px] focusInput"
            onChange={(e) => {
              setSubcategory(e.target.value)
            }}
          >
            <option value="">Selectionner une sous categorie</option>
            {subcategories.map((subcategory) => (
              <option key={subcategory?._id} value={subcategory?._id}>
                {subcategory?.name}
              </option>
            ))}
          </select>

          {/* {errors.category && (
                  <p className="text-red-500">{errors.category}</p>
                )} */}
        </div>

        <div className="">
          <button className="bg-[#3b5998]  text-[15px] hover:bg-[#3b5998] focus:ring-4 focus:outline-none cursor-pointer py-2 md:py-2 px-8 md:px-10 mt-2 rounded-[4px] text-white">
            {loading ? (
              <div className="">Operation...</div>
            ) : (
              <div className="">Valider</div>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default JoinSubCategoryToCategory
