import React, { useState, useEffect } from 'react'
import { getCategories } from '../../services/Categories/get-categories'
import { updateAnnouncement } from '../../services/Announcements/update-announcement'
import { getAnnouncement } from '../../services/Announcements/getAnnouncement'
import {} from '../../helpers/ReadFileHandler'
import BigImage from '../../components/BigImage'
import _ from 'lodash'
// import { BsFillXCircleFill } from 'react-icons/bs'
import ImagesList from '../../components/ImagesList'
import { useParams } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { getCurrencies } from '../../services/currencies/get-currencies'

export default function edit() {
  const params = useParams()
  // const currentSeller = JSON.parse(localStorage.getItem('seller'))

  const [name, setName] = useState()
  const [price, setPrice] = useState()
  const [description, setDescription] = useState()
  const [category, setCategory] = useState()
  const [images, setImages] = useState([])
  const [picClick, setPicClick] = useState(false)
  const [imgClicked, setImgClicked] = useState()
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [dataImgs, setDataImgs] = useState([])
  const [imagesToDelete, setImagesToDelete] = useState([])

  const [announcement, setAnnouncement] = useState()
  const [currencies, setCurrencies] = useState([])
  const [currency, setCurrency] = useState(null)

  const [errors, setErrors] = useState({})

  const data = {
    name: name,
    price: price,
    description: description,
    category: category,
    images: dataImgs,
    currency: currency ? currency : announcement?.currency?._id,
    newImages: images,
    imagesToDelete: imagesToDelete,
  }

  const readFileHandler = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setImages((curr) => [...curr, reader.result])
      return reader.result
    }
  }

  const selectFilesHandler = async (e) => {
    const imagesData = []
    _.forEach(e.target.files, (file) => {
      imagesData.push(readFileHandler(file))
    })
    console.log(images)
  }

  useEffect(() => {
    getCurrencies(setCurrencies)
  }, [])

  const validateForm = () => {
    let errors = {}

    if (!name) errors.name = 'Le nom du produit est requis.'
    if (!price) errors.price = 'Le prix du produit est requis.'
    if (!description)
      errors.description = 'La description du produit est requise.'
    if (!category) errors.category = 'La catégorie du produit est requise.'
    if (!currency) errors.currency = 'La device est requise.'
    if (images.length === 0 && dataImgs.length === 0)
      errors.images = 'Au moins une image est requise.'

    setErrors(errors)

    // Si l'objet errors est vide, le formulaire est valide
    return Object.keys(errors).length === 0
  }

  const handlePost = (e, id, data) => {
    console.log('data : ', data)

    e.preventDefault()
    ReactGA.event({
      category: 'Announcement',
      action: 'Edit',
    })
    if (validateForm()) {
      setLoading(true)
      updateAnnouncement(id, data)
    }
  }

  useEffect(() => {
    getAnnouncement(params.id, setAnnouncement)
    getCategories(setCategories)
  }, [params])

  useEffect(() => {
    setCategory(announcement?.category._id)
    setName(announcement?.name)
    setDescription(announcement?.description)
    setDataImgs(announcement?.images)
    setPrice(announcement?.price)
    setCurrency(announcement?.currency?._id)
  }, [announcement])

  return (
    <div className="px-[16px]">
      <div className="mt-3 rounded-lg">
        <div className="text-lg font-poppins px-5 md:px-0">
          <h3>Modification d{"'"}annonce </h3>
        </div>

        <div className=" py-2 md:px-0 md:py-0">
          {!_.isEmpty(images) ||
            (!_.isEmpty(dataImgs) && (
              <p className="py-3 text-slate-600 text-sm ">
                Les images du produit
              </p>
            ))}
          <div className="flex">
            <ImagesList
              images={dataImgs}
              setImages={setDataImgs}
              setImgClicked={setImgClicked}
              setPicClick={setPicClick}
              setImagesToDelete={setImagesToDelete}
            />

            <ImagesList
              images={images}
              setImages={setImages}
              setImgClicked={setImgClicked}
              setPicClick={setPicClick}
            />
          </div>
        </div>

        <div className="md:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div className="flex flex-col">
              <label>Nom du produit</label>
              <input
                className=" border-[1px] border-[rgba(90,86,86,0.08)] px-3 py-2 md:py-2 outline-none text-[15px] focusInput text-[rgba(0,0,0,0.67)] rounded-[2px]"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <p className="text-red-500">{errors.name}</p>}
            </div>

            <div className="flex">
              <div className="flex flex-col w-[65%]">
                <label>Prix du produit</label>
                <input
                  className=" border-[1px] border-[rgba(90,86,86,0.08)] px-3 py-2 md:py-2 outline-none text-[15px] focusInput text-[rgba(0,0,0,0.67)] rounded-[2px] "
                  type="text"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                {errors.price && <p className="text-red-500">{errors.price}</p>}
              </div>
              <div className=" w-[35%]">
                <label>Device</label>
                <select
                  value={currency || announcement?.currency?._id}
                  onChange={(e) => setCurrency(e.target.value)}
                  id="device"
                  className="  rounded-[2px] border-[1px] border-[rgba(90,86,86,0.08)] outline-none px-3 py-2 md:py-2 text-[15px] focusInput"
                >
                  {currencies?.map((currency) => (
                    <option key={currency?._id} value={currency._id}>
                      {currency.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <label className="block mb-2">Description</label>
          <textarea
            id="message"
            rows="4"
            value={description}
            className="block p-2.5 w-full text-sm text-gray-900 outline-none rounded-lg focusInput border border-gray-300 "
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Ecrire la description de votre produit ici..."
          ></textarea>
          {errors.description && (
            <p className="text-red-500">{errors.description}</p>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className=" flex flex-col gap-2">
            <label>Catégorie du produit </label>
            <select
              className="outline-none px-3 py-[11px] md:py-[10px] border-[rgba(90,86,86,0.08)] border-[1px]  rounded-[4px] text-[13px] focusInput"
              onChange={(e) => {
                console.log(e.target.value)
                setCategory(e.target.value)
              }}
              value={category}
            >
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            {errors.category && (
              <p className="text-red-500">{errors.category}</p>
            )}
          </div>

          <div className=" flex flex-col gap-2">
            <label htmlFor="file" className="">
              Images du produit
            </label>
            <input
              className="block outline-none px-2 py-2 md:py-[5px] border-[rgba(90,86,86,0.08)] border-[1px] rounded-[4px] focusInput w-full text-sm text-gray-900  border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              type="file"
              onChange={selectFilesHandler}
              accept="image/*"
              id="file"
              multiple
            />
            {errors.images && <p className="text-red-500">{errors.images}</p>}
          </div>
        </div>

        <div className="">
          <button
            className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
            onClick={(e) => handlePost(e, params.id, data)}
          >
            {loading ? (
              <div className="">Publication en cours...</div>
            ) : (
              <div className="">Publier l{"'"}annonce</div>
            )}
          </button>
        </div>
      </div>
      <div className="flex justify-center items-center w-[100%]">
        {picClick && <BigImage imgSrc={imgClicked} setPicClick={setPicClick} />}
      </div>
    </div>
  )
}
