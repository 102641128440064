import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsEnvelopeFill } from 'react-icons/bs'

function ToastMessage({ haveNewMessage, payload, updateHaveNewMessage }) {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      updateHaveNewMessage(false)
    }, 6000)
  }, [])
  return (
    <div
      className={
        haveNewMessage
          ? 'flex justify-center items-center h-[70px] ml-0 px-3 w-[270px] bg-primary transition-all ease-out delay-700 duration-300 text-[#ffffff] rounded-[4px] fixed mt-[80px] mr-6 top-0 right-0 z-50'
          : 'flex justify-center items-center h-[70px] ml-200px px-3 transition-all ease-out delay-700 duration-300 w-[270px] bg-[#121212] text-[#ffffff] top-0 rounded-[4px] fixed mt-[-20px] mr-6 right-[0px] z-50'
      }
      onClick={() => navigate('/Account/chat')}
    >
      <div className="flex align-center p-2">
        <div className="self-center text-white text-2xl -pl-2 pr-3">
          <BsEnvelopeFill />
        </div>
        <div>
          <h5 className="text-sm">
            {payload?.notification
              ? payload?.notification?.title
              : 'Vous avez reçu un nouveau message'}
          </h5>
        </div>
      </div>
      <div className="h-[3px] bg-secondary absolute bottom-0 left-0 right-0 z-20"></div>
    </div>
  )
}

export default ToastMessage
