import React from 'react'

function MenuDelivers({ options, handleClick, active }) {
  return (
    <div className="px-[16px] md:px-0 mb-4 flex flex-col md:flex-row md:items-center md:justify-between">
      <div>
        <h2 className=" font-bold text-[17px] "></h2>
      </div>
      <div className="flex justify-end text-xs gap-2">
        {options?.map((op) => (
          <div
            key={op?.action}
            className="font-roboto pr-3 leading-6 text-[14px] cursor-pointer"
            onClick={() => handleClick(op)}
          >
            {op.name}
            {active === op?.action && (
              <div className="h-[2px] bg-green-500 rounded-full"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default MenuDelivers
