let rentChoice = [
  {
    value: 'Vente',
    title: 'Saisissez le prix',
  },
  {
    value: 'Location',
    title: 'Saisissez le loyer mensuel',
  },
]

export { rentChoice }
