import React from 'react'
import _ from 'lodash'
import { FileInput, Label } from 'flowbite-react'
import { BsFillXCircleFill } from 'react-icons/bs'
import { IoImageOutline } from 'react-icons/io5'
import ErrorModal from './modal/ErrorModal'
function AddImage({ image, setImage }) {
  const [imgSizeError, setImgSizeError] = React.useState(false)
  const deleteImage = () => {
    setImage(null)
  }

  const readFileHandler = (file) => {
    if (file.size > 20000000) {
      setImgSizeError(true)
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        setImage(reader.result)
        return reader.result
      }
    }
  }

  const selectFilesHandler = async (e) => {
    const imagesData = []
    _.forEach(e.target.files, (file) => {
      imagesData.push(readFileHandler(file))
    })
  }

  return (
    <div>
      <div className="grid grid-cols-4 gap-3">
        <Label
          htmlFor="dropzone-file"
          className="flex max-w-100 cursor-pointer p-2 flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50"
        >
          <div className="flex flex-col items-center justify-center">
            <IoImageOutline className="text-[50px]" />
            <p className="mb-2 text-[8px] text-gray-500">
              <span className="font-semibold">Ajouter une photo</span>
            </p>
          </div>
          <FileInput
            id="dropzone-file"
            className="hidden"
            onChange={selectFilesHandler}
            accept=".png, .jpg, .jpeg, .webp"
          />
        </Label>
        {image && (
          <div className="flex">
            <div className="flex shadow-md">
              <img
                src={image}
                className="rounded-[0.25rem] object-cover md:h-40 h-30"
                alt="products-images"
              />
            </div>
            <div
              className="text-red-500 -ml-7 mt-1 p-1"
              onClick={(e) => {
                e.preventDefault()
                deleteImage()
              }}
            >
              <BsFillXCircleFill />
            </div>
          </div>
        )}
      </div>
      <ErrorModal
        openModal={imgSizeError}
        setOpenModal={setImgSizeError}
        text={'Une image ne doit pas depasser 20 megabytes!!'}
      />
    </div>
  )
}

export default AddImage
