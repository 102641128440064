import React from 'react'

function BlurPage() {
  return (
    <div
      className={`absolute shadow-delete left-0 h-[100vh] text-black w-screen bg-[rgba(0,0,0,0.75)]`}
    ></div>
  )
}

export default BlurPage
