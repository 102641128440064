import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const postMeanstransport = async (
  data,
  setLoading,
  navigate,
  setErrorMessageResponse
) => {
  await axios
    .post(`${BASE_URL}/api/transport/create`, data)
    .then(function (response) {
      if (response.data.message === 'success') {
        setLoading(false)
        navigate('/Account/list-meanstransports')
      }
    })
    .catch(function (error) {
      setLoading(false)
      if (error.response.status === 500) {
        setErrorMessageResponse(error.response.data.message)
      } else {
        setErrorMessageResponse(null)
      }
      console.log(error)
    })
}

export { postMeanstransport }
