import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getCurrencies = async (setCurrencies) => {
  const data = await axios.get(`${BASE_URL}/api/currencies`)
  try {
    if (data) {
      setCurrencies(data.data.currencies)
    }
  } catch (err) {
    console.log(err)
  }
}

export { getCurrencies }
