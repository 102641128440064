const options = [
  {
    name: 'Demandes validées',
    action: 'validated',
  },
  {
    name: 'Demandes en cours',
    action: 'request',
  },
  {
    name: 'Demandes declinées',
    action: 'refused',
  },
]

export { options }
