import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  IoArrowBack,
  IoArrowForward,
  IoMegaphoneOutline,
} from 'react-icons/io5'
import DeleteModal from '../../components/DeleteModal'
import AnnouncemmentCard from '../../components/announcement/AnnouncementCard'
import Register from '../../pages/authentification/Register'
import RegisterModal from '../../components/modal/RegisterModal'
//import { getAnnouncementsBySeller } from '../../services/Announcements/get-announcementsBySeller'

import ReactGA from 'react-ga4'
//import { getAnnouncementsByUser } from '../../services/Announcements/get-announcements-by-user'
import { useGetSellerAnnouncementsQuery } from '../../services/announcementApi'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import LottieLoader from '../../components/LottieLoader'

const itemsPerPage = 12
export default function index() {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [deleting, setDeleting] = useState(false)
  const [selected, setSelected] = useState()

  const [connecting, setConnecting] = useState(false)
  const [registering, setRegistering] = useState(false)

  let currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const currentSeller = JSON.parse(localStorage.getItem('seller'))

  console.log('this user:', currentUser)

  // const sellerId = currentSeller ? currentSeller : currentUser?._id
  const { data: announcements, isLoading } = useGetSellerAnnouncementsQuery(
    currentUser?._id
  )

  console.log('this datas:', announcements)
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (!currentUser) {
      setConnecting(true)
    }
  }, [])

  useEffect(() => {
    setTotalPages(Math.ceil(announcements?.length / itemsPerPage))
  }, [announcements])

  if (isLoading)
    return (
      <div className="flex flex-col items-center justify-center sm:h-screen">
        <div>
          <LottieLoader animation={indexLoader} />
        </div>
        <div>Chargement de des annonces... </div>
      </div>
    )
  //if user is seller get all annoucements published else get annoucement published when it was user
  const items = currentSeller
    ? announcements
    : announcements?.slice(
        currentPage * itemsPerPage,
        currentPage * itemsPerPage + itemsPerPage
      )

  return (
    <div>
      <div className="px-[16px] md:px-0 w-[100%]">
        <div className="flex justify-between items-center pb-5 md:justify-between pt-20 md:pt-0 md:items-center w-[100%]">
          <div className="md:text-xl text-sm font-poppins">
            <h3>Mes annonces</h3>
          </div>
          <div>
            <button
              className="bg-primary md:mt-0 py-[5px] px-2 md:py-2 md:px-4 text-light text-white text-sm rounded-lg flex items-center"
              onClick={() => {
                ReactGA.event({
                  category: 'Announcement',
                  action: 'Want to post',
                })
                if (
                  currentSeller ||
                  (currentUser && announcements?.length <= 20)
                ) {
                  navigate('/Account/announcements/create')
                } else {
                  navigate('/Account/announcements/create')
                }
              }}
            >
              <span className="pr-1">
                <IoMegaphoneOutline />
              </span>
              <span>Publiez une annonce</span>
            </button>
          </div>
        </div>
        {announcements?.length > 0 || announcements?.length > 0 ? (
          <div className="grid gap-5">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              {items?.map((announcement) => {
                return (
                  <AnnouncemmentCard
                    key={announcement?._id}
                    announcement={announcement}
                    setSelected={setSelected}
                    setDeleting={setDeleting}
                  />
                )
              })}
            </div>
            <div>
              {itemsPerPage < 12 && (
                <div className="flex items-center justify-center w-[60vw] gap-3">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 0}
                  >
                    <IoArrowBack />
                  </button>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      className={
                        currentPage !== index
                          ? 'text-white bg-primary rounded-full px-2'
                          : 'text-white bg-secondary rounded-full px-2'
                      }
                      key={index}
                      onClick={() => handlePageChange(index)}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages - 1}
                  >
                    <IoArrowForward />
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="text-[17px] font-medium">
            {/* {"Pas d' annonces trouvées."} */}
            {"Vous n'avez pas encore publié des annonces pour le moment"}
          </div>
        )}
      </div>
      {deleting && (
        <DeleteModal
          setOpenModal={setDeleting}
          selected={selected}
          openModal={deleting}
        />
      )}
      {connecting && (
        <Register
          openModal={connecting}
          setOpenModal={setConnecting}
          setRegistering={setRegistering}
        />
      )}
      {registering && (
        <RegisterModal openModal={registering} setOpenModal={setRegistering} />
      )}
    </div>
  )
}
