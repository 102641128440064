import React from 'react'
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaYoutube,
  FaTiktok,
  FaSnapchatGhost,
  FaPinterestP,
} from 'react-icons/fa'
import { FaXTwitter, FaThreads } from 'react-icons/fa6'

export default function Medias() {
  const socialLinks = [
    {
      icon: <FaWhatsapp className="text-lg" />,
      url: 'https://whatsapp.com/channel/0029VaipoC51t90YamUuZ80c ', // Assuming a WhatsApp link
    },
    {
      icon: <FaFacebookF />,
      url: 'https://www.facebook.com/profile.php?id=100085612274815',
    },
    {
      icon: <FaXTwitter />,
      url: 'https://x.com/UtyApp/status/1808874453855609118',
    },
    {
      icon: <FaInstagram />,
      url: 'https://www.instagram.com/p/C9ATMX_K5Ub/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
    },
    {
      icon: <FaLinkedinIn />,
      url: 'https://www.linkedin.com/posts/uty-app-703a83315_vieux-pablo-tika-makelele-moyi-eza-makasi-activity-7214637757950750720-Lz-5?utm_source=share&utm_medium=member_desktop ',
    },
    { icon: <FaYoutube />, url: 'https://www.youtube.com/@UtyApp' },
    { icon: <FaTiktok />, url: 'https://www.tiktok.com/@uty.cd' }, // Assuming a standard TikTok link
    { icon: <FaSnapchatGhost />, url: 'https://www.snapchat.com' }, // Assuming a standard Snapchat link
    {
      icon: <FaPinterestP />,
      url: 'https://www.pinterest.com/contactutyapp/_self-profile/',
    },
    { icon: <FaThreads />, url: 'https://www.threads.net/@uty.cd' },
  ]

  return (
    <div className="flex text-white space-x-4">
      {socialLinks.map((link) => (
        <a
          key={link.label} // Add key for better performance
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-2 hover:text-gray-300"
        >
          {link.icon}
          {link.label}
        </a>
      ))}
    </div>
  )
}
