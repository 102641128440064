import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const deleteAnnouncement = async (id) => {
  const response = await axios.delete(`${BASE_URL}/api/announcements/${id}`)
  if (response.status === 200) {
    return response
  }
}

export { deleteAnnouncement }
