import * as turf from '@turf/turf'
// import _ from 'lodash'

// function formatDistance(distance) {
//   const kilometers = distance
//   const meters = distance * 1000

//   if (kilometers > 0) {
//     return kilometers + ' km'
//   } else {
//     return meters + ' m'
//   }
// }

export default function distance(start, end) {
  console.log('locations', start, end)
  let from = turf.point(start)
  let to = turf.point(end)
  let options = { units: 'kilometers' }

  let distance = turf.distance(from, to, options)

  return distance
}
