import React from 'react'
import { Modal } from 'flowbite-react'
import AddLocation from './AddLocation'
import { useSelector } from 'react-redux'
import DisplayAddress from '../location/DisplayAddress'

export function ModalLocation({
  openModal,
  setOpenModal,
  setLocation,
  dispatch,
}) {
  let location = useSelector((state) => state.user.location)
  // const [selected, setSelected] = React.useState(false)

  return (
    <>
      <Modal
        show={openModal}
        size="md"
        popup
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="p-5 flex flex-col gap-3">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Choisir une addresse sur la carte
            </h3>
            <p className="text-[12.5px]">
              Veuillez glissez le marqueur ou double clickez directement sur
              votre addresse
            </p>
            <div>
              <DisplayAddress location={location} />
              <button
                className="rounded-[0.5rem] shadow p-2 text-[12.5px] mt-3"
                onClick={() => setOpenModal(false)}
              >
                Selectionner
              </button>
            </div>

            <AddLocation setLocation={setLocation} dispatch={dispatch} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
