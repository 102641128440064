import { BASE_URL } from '../../helpers/Root'

const getOneMeansTransport = async (id, setMeanstransport) => {
  const response = await fetch(
    `${BASE_URL}/api/transport/get-means-transports/${id}`
  )
  const transport = await response.json()

  try {
    if (transport.message === 'success') {
      setMeanstransport(transport?.data)
      // setLoader(true)
    }
  } catch (error) {
    console.error(error)
  }
}

export { getOneMeansTransport }
