import React from 'react'
import ShipmentItem from './ShipmentItem'
import { FaTruckFast } from 'react-icons/fa6'

function Deliveries({ deliveries }) {
  console.log('for deliver:', deliveries)
  if (!deliveries || deliveries?.length === 0) {
    return (
      <div>
        <div className="flex flex-col gap-5 justify-center items-center pt-20">
          <FaTruckFast className="text-[50px] text-slate-500" />
          <div>{"Vous n'avez accepté aucune course!!"}</div>
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-3">
      {deliveries?.map((shipment) => {
        return (
          <div key={shipment?._id}>
            <ShipmentItem shipment={shipment} />
          </div>
        )
      })}
    </div>
  )
}

export default Deliveries
