import React from 'react'
import { useSelector } from 'react-redux'
import {
  useCollectMutation,
  // useEntrustMutation,
} from '../../services/shipmentApi'
import { FaShippingFast } from 'react-icons/fa'

function StartShipment({ shipment }) {
  let currentUser = useSelector((state) => state.user.currentUser)
  let currentDeliver = useSelector((state) => state.user.currentDeliver)

  const [collect, { isLoading, data }] = useCollectMutation()
  // const [entrust, result] = useEntrustMutation()
  console.log(
    'datas',
    data,
    currentDeliver,
    shipment?.order?.seller?._id === currentUser?._id
  )

  return (
    <div>
      {/* {shipment?.order?.seller?._id === currentUser?._id && (
        <div>
          {!shipment?.isEntrust && (
            <button
              className="border border-primary rounded-[0.5rem] p-2 text-primary"
              onClick={(e) => {
                e.preventDefault()
                entrust(shipment._id)
              }}
            >
              {isLoading ? 'Récuperation...' : 'Valider la récuperation'}
            </button>
          )}
        </div>
      )} */}
      {shipment?.deliver?._id === currentDeliver?._id && (
        <div>
          {!shipment?.isCollect && (
            <button
              className="bg-primary flex items-center gap-1 rounded-[0.5rem] px-2 py-1 text-white text-[12.5px]"
              onClick={(e) => {
                e.preventDefault()
                collect(shipment._id)
              }}
            >
              <FaShippingFast />
              {isLoading ? 'Lancement...' : 'Lancer la livraison'}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default StartShipment
