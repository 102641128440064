import React, { useEffect, useState } from 'react'
import ImgDRC from '../../assets/cd.svg'
import ControlePhone from '../../helpers/ControlePhone'
import { BiSkipPrevious } from 'react-icons/bi'
import { BiSkipNext } from 'react-icons/bi'
import GetPicture from '../../components/delivers/GetPicture'
import Nav from '../../components/layout/Nav'
import { getMeansTransport } from '../../services/meansTransports/get-means-transports'
import { FileInput, Label, Select, TextInput } from 'flowbite-react'
import { IoImageOutline } from 'react-icons/io5'
import { getRequestDeliver } from '../../services/delivers/get-deliver'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { updateDeliver } from '../../services/delivers/update-deliver'
import { useNavigate } from 'react-router-dom'

function EditDeliver() {
  const method = {
    mobileMoney: true,
    card: false,
  }

  const [picture, setPicture] = useState(null)

  const [identity, setIdentity] = useState(null)
  const [seletedPayment, setSeletedPayment] = useState({})
  const [phoneMobileMoney, setPhoneMobileMoney] = useState(null)
  const [selectedImg, setSelectedImg] = useState(null)
  const [file, setFile] = useState(null)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [contact, setContact] = useState('')
  const [step, setStep] = useState(0)
  const [transport, setTransport] = useState(null)
  const [deliver, setDeliver] = useState(null)

  const navigate = useNavigate()

  const currentUser = useSelector((state) => state.user.currentUser)

  //Message erreur input
  const [msgErrPayment, setMsgErrPayment] = useState('')
  const [msgErridentity, setMsgErridentity] = useState('')
  const [msgErrPicture, setMsgErrModePicture] = useState('')
  const [transports, setTransports] = useState([])
  const [msgErrMeansTransport, setMsgErrMeansTransport] = useState('')
  const [msgErrPhoneMobile, setMsgErrPhoneMobile] = useState('')

  useEffect(() => {
    ControlePhone(contact, setPhoneMobileMoney)
  }, [contact])

  //Get currentUser
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('currentUser'))
    setUser(items)
    getMeansTransport(setTransports)
    if (currentUser) {
      getRequestDeliver(currentUser?._id, setDeliver)
    }
  }, [])

  useEffect(() => {
    setPicture(deliver?.picture ? deliver?.picture : null)
    setContact(
      deliver?.paymentMethod?.phoneMobileMoney
        ? deliver?.paymentMethod?.phoneMobileMoney
        : ''
    )
    setTransport(deliver?.transport ? deliver?.transport?._id : null)
    setIdentity(deliver?.identity ? deliver?.identity : null)
    setFile(deliver?.identity ? deliver?.identity : null)
    setSeletedPayment(
      deliver?.paymentMethod ? deliver?.paymentMethod?.name : null
    )
  }, [deliver])

  // Chate state select method payement
  seletedPayment === 'mobilemoney' &&
    (method.mobileMoney = true) &&
    (method.card = false) &&
    setPhoneMobileMoney(null)

  seletedPayment === 'card' &&
    (method.card = true) &&
    (method.mobileMoney = false)

  //if mode payment isn't seleted return msg error
  seletedPayment === '' && setSeletedPayment({})

  //Selected Image
  useEffect(() => {
    if (selectedImg) {
      const reader = new FileReader()
      reader.readAsDataURL(selectedImg)
      reader.onloadend = () => {
        setIdentity(reader.result)
      }
      setFile(URL.createObjectURL(selectedImg))
    }
  }, [selectedImg])

  //Create a new CreateDeliver
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const paymentMethod = {
      name: seletedPayment,
      phoneMobileMoney: phoneMobileMoney,
    }

    const data = {
      user: user._id,
      identity,
      address: 'adresse adresse',
      paymentMethod,
      picture: picture,
      transport: transport,
      deliverId: deliver?._id,
    }

    updateDeliver(data, navigate)

    // try {
    //   if (response?.data?.message === 'success') {
    //     setLoading(false)
    //     setDeliver(response?.data?.data)
    //     setSuccess(true)
    //   }
    // } catch (err) {
    //   console.log(err)
    // }
  }

  const handleNextStep = () => {
    if (step === 0 && picture) {
      setStep(step + 1)
      setMsgErrModePicture(null)
    } else {
      setMsgErrModePicture('La capture du photo est obligatoire.')
    }
    if (step === 1 && seletedPayment !== 'mobilemoney') {
      setMsgErrPayment('Veillez selectionner un mode de paiement')
    } else {
      setMsgErrPayment('')
    }

    if (step === 1 && !phoneMobileMoney) {
      setMsgErrPhoneMobile('Numero de telephone invalide')
    } else {
      setMsgErrPhoneMobile('')
    }

    if (step === 1 && !transport) {
      setMsgErrMeansTransport('Veillez selectionner un type de transport')
    } else {
      setMsgErrMeansTransport('')
    }

    if (
      step === 1 &&
      transport &&
      seletedPayment === 'mobilemoney' &&
      phoneMobileMoney
    ) {
      setStep(step + 1)
    } else {
      if (step === 1 && seletedPayment !== 'mobilemoney') {
        setMsgErrPayment('Veillez selectionner un mode de paiement')
      }
      if (step === 1 && !phoneMobileMoney) {
        setMsgErrPhoneMobile('Numero de telephone invalide')
      }
      if (step === 1 && !transport) {
        setMsgErrMeansTransport('Veillez selectionner un type de transport')
      }
    }
  }

  const handleNextSecondStep = () => {
    if (step === 2 && !identity) {
      setMsgErridentity("Veillez selectionner une piece d'identitée")
    } else {
      setMsgErridentity('')
      setStep(step + 1)
    }
  }

  const handlePrevStep = () => {
    setStep(step - 1)
  }

  return (
    <div>
      <Nav />

      <div className="">
        <div className=" md:px-[34vw] px-5 mb-5 pt-36">
          <div className="flex justify-between text-lg px-[16px] md:px-0 font-poppins pb-[2.5vh] pt-12 md:pt-0">
            <h3>Modifiez les informations incorrectent</h3>
          </div>
          <form action="#" className="px-[16px] md:px-0">
            {/* Picture */}

            {step === 0 && (
              <div>
                <GetPicture
                  setPicture={setPicture}
                  msgErrPicture={msgErrPicture}
                  picture={picture}
                />
              </div>
            )}

            {/* Mode paiement */}

            {step === 1 && (
              <div>
                <div className="flex flex-col w-[100%] pt-6 mb-4 order-3 md:order-none">
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="transports"
                        value="Choisir un mode de paiement"
                      />
                    </div>
                    <Select
                      className=""
                      id="transports"
                      value={seletedPayment}
                      onChange={(e) => setSeletedPayment(e.target.value)}
                      required
                    >
                      <option value="">--Choisir un mode de paiement--</option>
                      <option value="mobilemoney">Mobile Money</option>
                    </Select>
                  </div>
                </div>

                <div className="">
                  <div className="">
                    {method.mobileMoney && (
                      <div className="flex flex-col w-[100%] mb-4 ">
                        <div className="flex justify-between items-center pb-2">
                          <Label htmlFor="transports" value="Numero mobile" />
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="rounded-[10px]">
                            <img
                              src={ImgDRC}
                              alt="ImgDRC"
                              className="w-[40px] h-[40px] rounded-[10px]"
                            />
                          </div>

                          <div className="flex gap-2">
                            <TextInput
                              id="phone"
                              value={phoneMobileMoney}
                              className="w-[100%] rounded-lg"
                              onChange={(e) => setContact(e.target.value)}
                              type="number"
                              placeholder="825910778"
                              addon="+243"
                              required
                            />
                          </div>
                        </div>
                        {!phoneMobileMoney && (
                          <div className="text-[red] font-medium text-[11px] py-0 my-0">
                            {msgErrPhoneMobile}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {_.isEmpty(seletedPayment) && (
                    <div className="text-[red] font-medium text-[11px] py-0 my-0">
                      {msgErrPayment}
                    </div>
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="transports"
                      value="Choisir un moyen de transport"
                    />
                  </div>
                  <Select
                    className=""
                    id="transports"
                    value={transport}
                    onChange={(e) => setTransport(e.target.value)}
                    required
                  >
                    <option value="">Choisissez un moyen de transport</option>
                    {transports?.map((transport) => (
                      <option key={transport._id} value={transport._id}>
                        {transport?.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="pb-3">
                  {!transport && (
                    <div className="text-[red] font-medium text-[11px] py-0 my-0">
                      {msgErrMeansTransport}
                    </div>
                  )}
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="flex flex-col w-[100%] mb-4 order-5 md:order-none">
                <div className="flex justify-between items-center pb-2">
                  <Label htmlFor="file">
                    {"importer une piece d'indentitée"}
                  </Label>
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <Label
                      htmlFor="dropzone-file"
                      className="flex max-w-100 cursor-pointer p-2 flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50"
                    >
                      <div className="flex flex-col items-center justify-center">
                        <IoImageOutline className="text-[50px]" />
                        <p className="mb-2 text-[8px] text-gray-500">
                          <span className="font-semibold">
                            {" importer une piece d'indentitée"}
                          </span>
                        </p>
                      </div>
                      <FileInput
                        id="dropzone-file"
                        className="hidden"
                        onChange={(e) => setSelectedImg(e.target.files[0])}
                      />
                    </Label>
                  </div>

                  {identity && (
                    <div className="h-[200px] w-[200px] bg-green-400 rounded-[10px]">
                      <img
                        src={file}
                        className="h-[200px] w-[200px] rounded-[10px]"
                      />
                    </div>
                  )}
                </div>
                {!identity && (
                  <div className="text-[red] font-medium text-[11px] py-0 my-0">
                    {msgErridentity}
                  </div>
                )}
              </div>
            )}

            <div className="flex pt-3 md:pt-3 lg:pt-3">
              {step === 0 && picture && (
                <div
                  onClick={handleNextStep}
                  className="flex items-center justify-center gap-1 rounded-[4px] w-[115px] cursor-pointer bg-primary text-white px-5 py-2"
                >
                  Suivant <BiSkipNext />
                </div>
              )}
            </div>

            <div className="flex pt-3 md:pt-3 lg:pt-3">
              {step === 1 && transport && seletedPayment === 'mobilemoney' && (
                <div
                  onClick={handleNextStep}
                  className="flex items-center justify-center gap-1 rounded-[4px] w-[115px] cursor-pointer bg-primary text-white px-5 py-2"
                >
                  Suivant <BiSkipNext />
                </div>
              )}

              {step === 1 &&
                (!transport || seletedPayment !== 'mobilemoney') && (
                  <div className="flex items-center justify-center gap-1 rounded-[4px] w-[115px] disabled bg-[#8080e7] text-white px-5 py-2">
                    Suivant <BiSkipNext />
                  </div>
                )}

              <>
                {step === 1 && (
                  <div
                    onClick={handlePrevStep}
                    className="flex items-center w-[115px] rounded-[5px] cursor-pointer px-3 py-1"
                  >
                    <BiSkipPrevious /> Précédent
                  </div>
                )}
              </>
            </div>

            <div className="flex pt-3 md:pt-3 lg:pt-3">
              {step === 2 && identity && (
                <div
                  onClick={handleNextSecondStep}
                  className="flex items-center justify-center gap-1 rounded-[4px] w-[115px] cursor-pointer bg-primary text-white px-5 py-2"
                >
                  Suivant <BiSkipNext />
                </div>
              )}

              {step === 2 && !selectedImg && !identity && (
                <div className="flex items-center justify-center gap-1 rounded-[4px] w-[115px] disabled bg-[#8080e7] text-white px-5 py-2">
                  Suivant <BiSkipNext />s
                </div>
              )}

              {step === 2 && (
                <div
                  onClick={handlePrevStep}
                  className="flex items-center w-[115px] rounded-[5px] cursor-pointer px-3 py-1"
                >
                  <BiSkipPrevious /> Précédent
                </div>
              )}
            </div>

            {step === 3 && (
              <div className="flex">
                <div className="">
                  <button
                    className="bg-primary  text-[15px] hover:bg-primary focus:ring-4 focus:outline-none cursor-pointer py-2 md:py-2 px-8 md:px-10 mt-2 rounded-[4px] text-white"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {loading ? (
                      <div className="">Operation...</div>
                    ) : (
                      <div className="">Valider la demande</div>
                    )}
                  </button>
                </div>
                {step === 3 && (
                  <div
                    onClick={handlePrevStep}
                    className="flex items-center w-[115px] rounded-[5px] cursor-pointer px-3 py-1"
                  >
                    <BiSkipPrevious /> Précédent
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditDeliver
