import React from 'react'
import { useSelector } from 'react-redux'
import {
  useDropOffDeliveryMutation,
  // useValidateDeliveryMutation,
} from '../../services/shipmentApi'
import RatingShipment from '../../components/Shipments/Rating'

function StartShipment({ shipment }) {
  let currentUser = useSelector((state) => state.user.currentUser)
  let currentDeliver = useSelector((state) => state.user.currentDeliver)

  // const [validateDelivery, { isLoading, data }] = useValidateDeliveryMutation()
  const [dropOffDelivery, result] = useDropOffDeliveryMutation()

  const [rating, setRating] = React.useState(false)
  console.log(
    'datas',
    currentDeliver,
    shipment,
    shipment?.deliver?._id === currentDeliver?._id
  )

  return (
    <div>
      {shipment?.order?.customer?._id === currentUser?._id && (
        <div>
          {/* {!shipment?.isCollected && (
            <button
              className="border border-primary rounded-[0.5rem] p-2 text-primary text-sm"
              onClick={(e) => {
                e.preventDefault()
                validateDelivery(shipment._id)
              }}
            >
              {isLoading ? 'Récuperation...' : 'Valider la livraison'}
            </button>
          )} */}
          {!shipment?.isRated && shipment?.status === 'delivered' && (
            <button
              className="border border-primary rounded-[0.5rem] p-2 text-primary text-sm"
              onClick={(e) => {
                e.preventDefault()
                setRating(true)
              }}
            >
              Noter la livraison
            </button>
          )}
        </div>
      )}
      {shipment?.deliver?._id === currentDeliver?._id && (
        <div>
          {!shipment?.isDropOff && (
            <button
              className="bg-primary flex items-center gap-1 rounded-[0.5rem] px-2 py-1 text-white text-[12.5px]"
              onClick={(e) => {
                e.preventDefault()
                dropOffDelivery(shipment._id)
              }}
            >
              {result?.isLoading
                ? 'Validation du depot...'
                : 'Valider la livraison'}
            </button>
          )}
        </div>
      )}
      <RatingShipment openModal={rating} setOpenModal={setRating} />
    </div>
  )
}

export default StartShipment
