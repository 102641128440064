import React from 'react'
import Nav from '../../components/layout/Nav'
import {
  useGetPendingShipmentsQuery,
  useGetDeliveriesQuery,
  useGetDeliveredShipmentsQuery,
} from '../../services/shipmentApi'
// import { useDispatch } from 'react-redux'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import Shipments from '../../components/Shipments/Shipments'
import Deliveries from '../../components/Shipments/Deliveries'
// import { useSelector } from 'react-redux'
import DeliveredShipment from '../../components/Shipments/DeliveredShipments'

const tabs = (shipments, deliveries, data, currentLocation, isLoading) => [
  {
    name: 'Livraisons en attente',
    component: (
      <Shipments
        shipments={shipments}
        currentLocation={currentLocation}
        isLoading={isLoading}
      />
    ),
    active: shipments !== undefined && shipments?.length > 0 ? true : false,
  },
  {
    name: 'Courses à livrées',
    component: <Deliveries deliveries={deliveries} />,
    active: shipments === undefined || shipments?.length === 0 ? true : false,
  },
  {
    name: 'Livraisons effectuées',
    component: <DeliveredShipment deliveries={data} />,
    active: false,
  },
]

export default function PendingShipments() {
  //   const dispatch = useDispatch()
  let currentLocation = JSON.parse(localStorage.getItem('currentLocation'))
  const currentDeliver = JSON.parse(localStorage.getItem('currentDeliver'))
  const { data: shipments, isLoading } = useGetPendingShipmentsQuery()
  const { data: deliveries } = useGetDeliveriesQuery(currentDeliver?._id)
  const { data } = useGetDeliveredShipmentsQuery(currentDeliver?._id)
  //   const { data } = useGetSellerShipmentsQuery(params.id)
  console.log('shipments:', shipments, deliveries, currentDeliver)

  const [activeTab, setActiveTab] = React.useState(() => {
    return tabs(
      shipments,
      deliveries,
      data,
      currentLocation,
      isLoading
    ).findIndex((tab) => tab.active === true)
  })

  //   React.useEffect(() => {
  //     dispatch(setDropOffLocation(currentLocation))
  //   }, [])

  if (isLoading)
    return (
      <div>
        <Nav />
        <div className="flex flex-col pt-44 items-center justify-center">
          <div>
            <LottieLoader animation={indexLoader} />
          </div>
          <div>Chargement des nouvelles livraisons disponibles... </div>
        </div>
      </div>
    )

  return (
    <div>
      <Nav />
      <div className="pt-32 lg:px-48 px-5">
        <div className="py-3 lg:text-[20px] font-roboto">
          Livraisons à effectuer
        </div>
        <div className="flex items-center gap-5 mb-3 md:text-[15px] sm:text-[12.5px] sm:text-center">
          {tabs(shipments, deliveries, data, currentLocation, isLoading).map(
            (tab, index) => (
              <div
                key={index}
                className={
                  activeTab === index
                    ? 'bg-blue-200 rounded-[1rem] px-2 py-1 mb-2 cursor-pointer'
                    : 'tab mb-2 cursor-pointer'
                }
                onClick={() => {
                  setActiveTab(index)
                  //setActiveComponent(tab.component)
                }}
              >
                {tab.name}
              </div>
            )
          )}
        </div>
        <div className="tab-content">
          {
            tabs(shipments, deliveries, data, currentLocation, isLoading)[
              activeTab
            ].component
          }
        </div>
      </div>
    </div>
  )
}
