import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const shopApi = createApi({
  reducerPath: 'shopApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Shops', 'Shop'],
  endpoints: (builder) => ({
    createShop: builder.mutation({
      query: (data) => ({
        url: 'api/shops/create',
        method: 'POST',
        body: data,
      }),
      //   invalidatesTags: ['Announcements'],
    }),
    updateShop: builder.mutation({
      query: (data) => ({
        url: `api/shops/update/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Shop'],
    }),
    rateShop: builder.mutation({
      query: (data) => ({
        url: `api/shops/rate/${data.id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Shops'],
    }),
    followShop: builder.mutation({
      query: (data) => ({
        url: `api/shops/follow/${data.id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Shops'],
    }),
    unsuscribeShop: builder.mutation({
      query: (data) => ({
        url: `api/shops/unsuscribe/${data.id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Shops'],
    }),

    getShops: builder.query({
      query: () => `api/shops`,
      providesTags: ['Shops'],
    }),
    getUserShop: builder.query({
      query: (user) => `api/shops/me/${user}`,
      providesTags: ['Shop'],
    }),
    getShop: builder.query({
      query: (id) => `api/shops/${id}`,
      // providesTags: ['Shop'],
    }),
  }),
})

export const {
  useCreateShopMutation,
  useGetShopsQuery,
  useGetUserShopQuery,
  useRateShopMutation,
  useFollowShopMutation,
  useUpdateShopMutation,
  useUnsuscribeShopMutation,
  useGetShopQuery,
} = shopApi
