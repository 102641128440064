import React from 'react'
import { RiDeleteBinLine } from 'react-icons/ri'

function ModalImage({ imageUTLs, removeImageSelected }) {
  return (
    <div className="w-[100%] md:w-[100%] lg:w-[100%]  flex-grow  ">
      {imageUTLs?.map((imageSrc, index) => (
        <>
          <div
            key={index}
            className=" h-[auto] mt-[69px] lg:w-[100%] lg:bg-none lg:mt-0"
          >
            <img
              src={imageSrc}
              alt=""
              className="text-center md:h-[60vh] lg:h-[60vh] object-cover w-[100%] md:object-contain lg:object-contain"
            />
          </div>
          <div className="absolute pt-20 md:pt-30 pl-[16px] left-0 right-0 top-0 h-10 z-50 lg:pt-0">
            <div
              className="absolute bg-gray-500 p-2 cursor-pointer rounded-full mt-2 "
              onClick={removeImageSelected}
            >
              <RiDeleteBinLine className="text-[20px]  text-white" />
            </div>
          </div>
        </>
      ))}
    </div>
  )
}

export default ModalImage
