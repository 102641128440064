import React from 'react'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import 'moment-timezone'

function MessageAnnouncement({ message, owner }) {
  return (
    <div
      className={
        owner
          ? 'max-w-[250px] shadow-message shadow-white-500/50 bg-[#8ba1ee] my-2 md:my-2 flex  rounded-[7px]'
          : 'max-w-[250px] shadow-message shadow-white-500/50 bg-yellow-300 my-2 md:my-2 flex rounded-[7px]'
      }
    >
      <div className="relative">
        <div className=" text-[rgba(0,0,0,0.76)] text-[14px] px-1 py-1 ">
          <Link
            to={
              '../../announcements/' +
              message?.announcementId?._id +
              '/' +
              message?.announcementId?.category?._id
            }
          >
            <img
              src={message?.announcementId?.images[0]}
              alt="images sender"
              className="h-[200px] w-[250px] object-cover rounded-t-[3px]"
            />
            <div className="p-1">
              <p className=" font-medium  text-[14px]">
                Categorie {message?.announcementId?.category?.name}
              </p>
              <p className=" ">
                {message?.announcementId?.name ? (
                  <div className="">{message?.announcementId?.name}</div>
                ) : null}
              </p>
              <p className="">{message?.announcementId?.description}</p>
              <p className="font-black text-[15px] ">
                {message?.announcementId?.price ? (
                  <div>
                    Prix : {message?.announcementId?.price}
                    {message?.announcementId?.currency?.shortName === '$'
                      ? '$'
                      : 'fc'}{' '}
                  </div>
                ) : null}
              </p>
            </div>
            <div className="absolute right-0 bottom-0 gradiant py-1 px-2 text-[11px] rounded text-white">
              <Moment
                format="HH:mm"
                className="text-[10px] text-[#d8d8d8]"
                date={message?.createdAt}
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MessageAnnouncement
