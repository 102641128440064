import React from 'react'
import Nav from '../../components/layout/Nav'
import { useGetFavoritesAnnouncementsQuery } from '../../services/announcementApi'
import { NumericFormat } from 'react-number-format'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import LottieLoader from '../../components/LottieLoader'
import { useNavigate } from 'react-router-dom'
import { IoHeartDislikeCircleOutline } from 'react-icons/io5'

const Favorites = () => {
  const navigate = useNavigate()
  let currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const { data: announcements, isFetching } = useGetFavoritesAnnouncementsQuery(
    currentUser._id
  )

  if (isFetching)
    return (
      <div>
        <Nav />
        <div className="flex flex-col items-center justify-center sm:h-screen">
          <div>
            <LottieLoader animation={indexLoader} />
          </div>
          <div>Chargement de mes annonces... </div>
        </div>
      </div>
    )

  if (announcements?.length === 0) {
    return (
      <div>
        <Nav />
        <div className="flex flex-col gap-5 justify-center items-center pt-40">
          <IoHeartDislikeCircleOutline className="text-[50px] text-slate-500" />
          <div>{"Vous n'avez pas aimé d'annonces pour le moment"}</div>
          <div>
            <button
              className="bg-primary md:mt-0 py-[5px] px-2 md:py-2 md:px-4 text-light text-white text-sm rounded-lg flex items-center"
              onClick={() => {
                navigate('/categories')
              }}
            >
              <span>Voir les produits disponibles</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Nav />
      <div className="lg:px-32 px-5 pt-32">
        <div className="py-5">
          <h5 className="text-[17.5px] md:text-[25px] lg:text-[20px]">
            Liste de mes annonces favorites
          </h5>
          <p className="font-light sm:text-[12.5px]">
            Retrouvez les annonces que vous avez aimées et contactez le vendeur
            pour acheter vos produits favoris
          </p>
        </div>
        <div className="grid lg:grid-cols-6 sm:grid-cols-2 md:grid-cols-3 sm:gap-7 md:gap-10">
          {announcements?.map((announcement) => (
            <div
              key={announcement?._id}
              className="cursor-pointer text-[12.5px]"
              onClick={() =>
                navigate(
                  `/announcements/${announcement?._id}/${announcement?.category}`
                )
              }
            >
              <div className="max-w-sm">
                <div className="flex flex-col border rounded-[0.5rem] p-2">
                  <img
                    alt="announcement image"
                    src={announcement?.images[0]}
                    className="mb-3 rounded-[0.5rem] shadow-lg md:h-[20vh] h-[20vh] w-[40vw] object-cover"
                  />
                  <div className="flex flex-col">
                    <span className="text-[15px] truncate">
                      {announcement?.name}
                    </span>
                    <span className="font-light truncate">
                      {announcement?.description}
                    </span>
                  </div>

                  <div className="flex gap-2">
                    <span className="text-[rgba(0,0,0,0.65)] pt-2 lg:pt-2 text-[10px] font-black">
                      <NumericFormat
                        value={announcement?.price}
                        displayType={'text'}
                        thousandSeparator=" "
                      />
                      {announcement?.currency?.shortName
                        ? announcement?.currency?.shortName
                        : 'fc'}
                    </span>
                    <span className="text-[rgba(0,0,0,0.65)] lg:pt-2 text-[10px] font-black">
                      {announcement?.convert}
                      {announcement?.currency?.shortName === '$' ? 'fc' : '$'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Favorites
