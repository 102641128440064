import React from 'react'
import _ from 'lodash'
import { FaCartShopping, FaTruckFast } from 'react-icons/fa6'
import { BsCartCheck } from 'react-icons/bs'
import { NumericFormat } from 'react-number-format'
import { useSelector, useDispatch } from 'react-redux'
import { PhoneNumberModal } from '../PhoneNumberModal'
import { addPhoneNumber } from '../../features/user/user'
import PayOrder from '../payment/PayOrder'
import ShipmentCosts from './ShipmentCosts'

function CartSummary({ cart, totalPrice, totalShipFees, toPay }) {
  const dispatch = useDispatch()
  console.log('total price', totalPrice)

  const currentUser = useSelector((state) => state.user.currentUser)
  let loading = useSelector((state) => state.user.loading)

  const [addingPhone, setAddingPhone] = React.useState(false)
  const [paying, setPaying] = React.useState(false)
  const [showShips, setShowShips] = React.useState(false)

  const addPhone = async (e, user, phone, setError, loading, dispatch) => {
    e.preventDefault()
    if (phone?.length < 9 || phone?.length === 0) {
      setError(true)
    } else {
      dispatch(addPhoneNumber({ user: user?._id, phone: phone }))
    }
  }

  return (
    <aside className="p-3 order-0 lg:order-1 lg:shadow rounded-[1rem] mb-3">
      <h2 className="text-lg font-bold mb-4">Résumé du panier</h2>
      <ul className="font-light text-sm">
        <li className="flex gap-3 items-center mb-2">
          <div className="flex gap-1">
            <FaCartShopping className="text-gray-600" />
            Prix total des produits ajoutés:
          </div>
          <span className="font-bold text-lg text-gray-600">
            <NumericFormat
              value={_.round(parseInt(totalPrice), -2)}
              displayType={'text'}
              thousandSeparator=" "
            />{' '}
            CDF
          </span>
        </li>
        <li className="flex gap-3 items-center mb-2">
          <div className="flex items-center gap-1">
            <FaTruckFast className="text-gray-600" />
            Montant total des livraisons:
          </div>
          <span className="font-bold text-lg text-gray-600">
            <NumericFormat
              value={_.round(totalShipFees, -2)}
              displayType={'text'}
              thousandSeparator=" "
            />{' '}
            CDF
          </span>
          <button
            className="text-blue-500 hover:underline"
            onClick={() => setShowShips(!showShips)}
          >
            {showShips ? 'Masquer les détails' : 'Plus de détails'}
          </button>
        </li>
      </ul>
      {showShips && (
        <details className="mt-4">
          <summary className="font-medium text-sm cursor-pointer">
            Détails des livraisons
          </summary>
          <ShipmentCosts
            data={toPay}
            cart={cart}
            user={currentUser}
            openModal={showShips}
            setOpenModal={setShowShips}
          />
        </details>
      )}
      <button
        className="flex gap-2 items-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-4"
        onClick={(e) => {
          e.preventDefault()
          setPaying(true)
        }}
      >
        <BsCartCheck className="mr-2" />
        Payer le panier
      </button>
      {addingPhone && (
        <PhoneNumberModal
          openModal={addingPhone}
          setOpenModal={setAddingPhone}
          user={currentUser}
          addPhoneNumber={addPhone}
          dispatch={dispatch}
          loading={loading}
        />
      )}
      <PayOrder
        openModal={paying}
        setOpenModal={setPaying}
        toPay={toPay}
        cart={cart}
      />
    </aside>
  )
}

export default CartSummary
