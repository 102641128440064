import React from 'react'
import OrderItem from './OrderItem'
import { BsBagX } from 'react-icons/bs'

export default function OrdersToDeliver({ orders }) {
  if (!orders || orders?.length === 0) {
    return (
      <div>
        <div className="flex flex-col gap-5 justify-center items-center pt-20">
          <BsBagX className="text-[50px] text-slate-500" />
          <div>
            {
              "Vous n'avez pas de commandes à faire livrer ou en cours de livraison!!"
            }
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-3">
      {orders
        ?.map((order) => {
          return (
            <div key={order?._id} className="shadow rounded-[0.5rem]">
              <OrderItem order={order} />
            </div>
          )
        })
        .reverse()}
    </div>
  )
}
