import React from 'react'
import ShipmentItem from './ShipmentItem'
import { FaTruckFast } from 'react-icons/fa6'

function Shipments({ shipments, currentLocation }) {
  console.log('for deliver:', currentLocation, shipments)
  if (!shipments || shipments?.length === 0) {
    return (
      <div>
        <div className="flex flex-col gap-5 justify-center items-center pt-20">
          <FaTruckFast className="text-[50px] text-slate-500" />
          <div>{"Il n'y a pas de courses disponibles!!"}</div>
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-3">
      {shipments?.map((shipment) => {
        return (
          <div key={shipment?._id}>
            <ShipmentItem
              shipment={shipment}
              currentLocation={currentLocation}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Shipments
