import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const promoApi = createApi({
  reducerPath: 'promoApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    checkCode: builder.mutation({
      query: (code) => ({
        url: `/api/promo/${code}`,
        method: 'POST',
        body: {},
      }),
    }),
  }),
})

export const { useCheckCodeMutation } = promoApi
