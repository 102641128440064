import React from 'react'
import { IoSearchSharp } from 'react-icons/io5'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { setQuery } from '../features/announcement/announcement'
import { useSelector, useDispatch } from 'react-redux'
// import { Select } from 'flowbite-react'
// import _ from 'lodash'

export default function SearchBar() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let query = useSelector((state) => state.announcement.query)

  const oldSearch = JSON.parse(localStorage.getItem('oldSearch'))

  const handleSubmitSearch = (event) => {
    event.preventDefault()
    ReactGA.event({
      category: 'Main clicks',
      action: 'Search product',
    })

    // Récupérer l'historique des recherches depuis localStorage ou initialiser un tableau vide
    let searchHistory = JSON.parse(localStorage.getItem('oldSearch')) || []

    // Ajouter la nouvelle recherche si elle n'existe pas déjà dans l'historique
    if (!searchHistory.includes(query)) {
      searchHistory.push(query)
      // Enregistrer l'historique mis à jour dans localStorage
      localStorage.setItem('oldSearch', JSON.stringify(searchHistory))
    }

    // Naviguer vers la page des produits avec la requête
    navigate(`/products/${query}`)
  }
  return (
    <div className="">
      <form onSubmit={(event) => handleSubmitSearch(event)}>
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only"
        >
          Search
        </label>

        <div className="relative px-2">
          <div className="flex">
            {oldSearch && (
              <select
                className="bg-white rounded-s-[0.5rem] text-sm border border-[rgba(90,86,86,0.08)] lg:flex md:flex sm:hidden"
                id="countries"
                onChange={(e) => {
                  dispatch(setQuery(e.target.value))
                  if (e.target.value == '') {
                    navigate('/')
                  } else {
                    navigate(`/products/${e.target.value}`)
                  }
                }}
              >
                <option value="">Recherches récentes</option>
                {oldSearch?.reverse().map((s, index) => {
                  return (
                    <option key={index} value={s}>
                      {s}
                    </option>
                  )
                })}
              </select>
            )}
            <input
              type="search"
              id="default-search"
              className="block sm:w-[45vw] md:w-[30vw] p-2 text-sm border border-[rgba(90,86,86,0.08)] rounded-[1rem]'"
              placeholder="Trouver un produit..."
              onChange={(e) => dispatch(setQuery(e.target.value))}
              value={query}
              required
            />
          </div>

          <button
            type="submit"
            className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-500 transition-all delay-0 ease-out  rounded-e-lg border border-blue-500 hover:transition-all hover:delay-300 hover:ease-out  hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-600 dark:focus:ring-blue-600"
          >
            <IoSearchSharp />
          </button>
        </div>
      </form>
    </div>
  )
}
