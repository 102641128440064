import React from 'react'
import {
  IoBagOutline,
  IoEyeOutline,
  IoCloseOutline,
  IoCheckmark,
} from 'react-icons/io5'
import { NumericFormat } from 'react-number-format'
import StatusBadge from '../Badge'
import { FaTruckFast } from 'react-icons/fa6'
import { DetailModal } from '../../components/announcement/DetailModal'
import { OrderShipment } from './OrderShipment'
import Shipment from '../Shipments/Shipment'
import { useGetOrderShipmentQuery } from '../../services/shipmentApi'
import CallUser from '../Shipments/CallUser'
import { useNavigate } from 'react-router-dom'

function OrderItem({ order, acceptOrder, refuseOrder }) {
  const navigate = useNavigate()
  const [show, setShow] = React.useState(false)
  const [orderingShip, setOrderingShip] = React.useState(false)
  const [selected, setSelected] = React.useState()
  const [openShip, setOpenShip] = React.useState(false)

  const { data: shipment } = useGetOrderShipmentQuery(order?._id)
  console.log('shipment', shipment)

  let currentLocation = JSON.parse(localStorage.getItem('currentLocation'))
  return (
    <div className="p-3 mb-5">
      <div className="flex flex-col gap-3 md:flex-row md:justify-between md:items-center">
        <div className="flex gap-1 items-center">
          <IoBagOutline /> commande {order?._id?.slice(-10)}
        </div>
        <div className="flex">
          <StatusBadge status={order?.status} />
        </div>
        {order?.status === 'pending' && (
          <div className="flex gap-2">
            <button
              onClick={() => acceptOrder(order?._id, currentLocation)}
              className="flex gap-1 items-center text-sm text-white bg-green-600 p-2 rounded-[0.5rem]"
            >
              <IoCheckmark />
              Accepter
            </button>
            <button
              onClick={() => refuseOrder(order?._id)}
              className="flex gap-1 items-center text-sm text-white bg-red-600 p-2 rounded-[0.5rem]"
            >
              <IoCloseOutline />
              Refuser
            </button>
          </div>
        )}

        {order?.status === 'accepted' && (
          <div>
            {' '}
            <button
              onClick={() => setOrderingShip(true)}
              className="flex gap-1 items-center text-sm text-white bg-primary p-2 rounded-[0.5rem]"
            >
              <FaTruckFast />
              Commander la livraison
            </button>
          </div>
        )}
        {(order?.status === 'awaiting delivery' ||
          order?.status === 'in pickup') && (
          <div>
            {' '}
            <button
              onClick={() => {
                navigate(`/shipments/${shipment?._id}`)
              }}
              className="flex gap-1 items-center text-sm shadow text-primary p-2 rounded-[0.5rem]"
            >
              <FaTruckFast />
              Voir la commande de livraison
            </button>
          </div>
        )}
        {(order?.status === 'in delivery' || order?.status === 'delivered') && (
          <div>
            {' '}
            <button
              onClick={() => navigate(`/shipments/${shipment?._id}`)}
              className="flex gap-1 items-center text-sm text-white bg-primary p-2 rounded-[0.5rem]"
            >
              <FaTruckFast />
              Suivre la livraison
            </button>
          </div>
        )}
      </div>

      <div className="flex flex-col gap-3 pt-5">
        {order?.products?.map((product, index) => {
          return (
            <div
              key={index}
              className="flex items-center gap-5 shadow p-2 py-5 rounded-[0.5rem]"
            >
              <div>
                <img
                  className="h-[15vh] w-[17.5vw] rounded-[0.5rem]"
                  src={product?.productId?.images[0]}
                />
              </div>
              <div className="flex flex-col gap-3">
                <div>{product?.productId?.name}</div>

                <div className="w-[100%]">
                  <div className="flex justify-between">
                    Prix du produit:
                    <span>
                      <NumericFormat
                        value={product?.productId?.price}
                        displayType={'text'}
                        thousandSeparator=" "
                      />{' '}
                      {product?.productId?.currency
                        ? product?.productId?.currency?.shortName
                        : 'fc'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    Quantité: <span>{product?.quantity}</span>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {order?.status === 'in pickup' && (
                    <div>
                      <CallUser
                        user={shipment?.deliver?.user}
                        title={'le livreur'}
                        isDeliver={true}
                      />
                    </div>
                  )}
                  <div className="cursor-pointer">
                    <button
                      onClick={() => {
                        setShow(true)
                        setSelected(product?.productId?._id)
                      }}
                      className="flex items-center gap-1 p-2 rounded-[0.5rem] text-sm border border-primary text-primary"
                    >
                      <IoEyeOutline /> Voir le detail du produit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {show && (
        <DetailModal
          openModal={show}
          setOpenModal={setShow}
          announcementId={selected}
        />
      )}
      {orderingShip && (
        <OrderShipment
          openModal={orderingShip}
          setOpenModal={setOrderingShip}
          order={order}
        />
      )}
      {openShip && (
        <Shipment
          openModal={openShip}
          setOpenModal={setOpenShip}
          shipment={shipment}
        />
      )}
    </div>
  )
}

export default OrderItem
