import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isShowCart: false,
}

export const isShowCartSlice = createSlice({
  name: 'isshowcart',
  initialState,
  reducers: {
    setIsShowCart: (state, action) => {
      state.isShowCart = action.payload
    },
  },
})

export const { setIsShowCart } = isShowCartSlice.actions

export default isShowCartSlice.reducer
