import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getCommentByAnnouncement = async (announcementId, setComment) => {
  await axios
    .get(`${BASE_URL}/api/comment/get-comments/${announcementId}`)
    .then((response) => {
      setComment(response?.data?.data)
    })
}

export { getCommentByAnnouncement }
