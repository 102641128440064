import React from 'react'
// import { GrStatusGood } from 'react-icons/gr'

function RequestInProcess({ success, requestDeliverInProccess }) {
  return (
    <div>
      {success && requestDeliverInProccess && (
        <div className=" flex flex-col items-center justify-center">
          <h2 className=" font-roboto text-center text-[17px] md:text-[20px] lg:text-[20px] text-[#707070]">
            Votre demande est envoyée avec succes!!!
          </h2>
          {/* <div className="w-[100px] h-[100px] md:w-[110px] md:h-[110px] lg:w-[130px] lg:h-[130px] my-4">
            <GrStatusGood className="w-[100px] h-[100px] md:w-[110px] md:h-[110px] lg:w-[130px] lg:h-[130px] text-green-400" />
          </div> */}
          <div className="text-center text-[#5a5a5a]">
            {
              "Merci de patienter pendant que l'administrateur verifie vos informations avant que la demande soit acceptée."
            }
          </div>
        </div>
      )}
    </div>
  )
}

export default RequestInProcess
