import { Label, TextInput } from 'flowbite-react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../../features/user/user'
import { addPhoneNumber } from '../../services/Users/add-phoneNumber'

function AddPhoneNumber({ showInputPhoneNumber, setShowInputPhoneNumber }) {
  const [phoneNumber, setPhoneNumber] = useState()

  let currentUser = JSON.parse(localStorage.getItem('currentUser'))
  let dispatch = useDispatch()

  const handleChange = (event) => {
    let value = event.target.value
    while (value[0] === '0') {
      value = value.slice(1)
    }
    setPhoneNumber(`243${value}`)
  }

  const handleAddPhoneNumber = async () => {
    setShowInputPhoneNumber(false)
    const response = await addPhoneNumber(currentUser?._id, phoneNumber)
    if (response?.status === 200) {
      currentUser.phone = phoneNumber
      console.log('currentUser data : ', currentUser)
      dispatch(setCurrentUser(currentUser))
      localStorage.setItem('currentUser', JSON.stringify(currentUser))
    }
  }

  return (
    showInputPhoneNumber && (
      <div className="absolute z-40 flex justify-center h-screen w-screen bg-[rgba(48,48,48,0.67)] ">
        <div className="flex flex-col py-3 justify-center w-[350px] px-5  absolute top-[300px] rounded-[7px]  bg-white z-[20] text-2xl">
          <div className="flex gap-2 flex-col">
            <div className=" text-[13px] text-[rgba(0,0,0,0.76)] block">
              <Label htmlFor="phone" value="Entrez votre numero de telephone" />
            </div>
            <div className="mb-2">
              <TextInput
                id="phone"
                className="w-[100%] rounded-lg"
                onChange={handleChange}
                type="number"
                placeholder="813388777"
                addon="+243"
                required
              />
            </div>
          </div>
          <div
            onClick={handleAddPhoneNumber}
            className="bg-primary mb-2 flex justify-center items-center cursor-pointer text-[16px] py-1 font-medium text-white rounded-[7px]"
          >
            Ajouter
          </div>
        </div>
      </div>
    )
  )
}

export default AddPhoneNumber
