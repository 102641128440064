import React from 'react'
import { Link } from 'react-router-dom'

export default function SubMenu({ item, setActive }) {
  return (
    <Link
      className="flex items-center text-[20px] hover:bg-[rgba(0,35,158,0.1)] hover:rounded-[2px]  py-3 px-3"
      to={item.path}
      onClick={() => setActive(false)}
    >
      <div className="flex   justify-center   items-center">
        <div className="mr-5 md:text-[14px] font-extralight">{item.icon}</div>
        <div className=" text-[18px] font-light md:font-light md:text-[14px] ">
          {item.title}
        </div>
      </div>
    </Link>
  )
}
