import Moment from 'react-moment'
import 'moment-timezone'

const culumns = () => {
  return [
    {
      name: 'Client',
      selector: (row) =>
        row?.userstracker?.map((user) =>
          user?.customerId?.username ? user?.customerId?.username : '-'
        ),
      sortable: true,
    },
    {
      name: 'Vendeur',
      selector: (row) =>
        row?.userstracker?.map((user) => user?.sellerId?.username),
      sortable: true,
    },
    {
      name: 'Canal',
      selector: (row) => (row.typetracker ? row.typetracker : '-'),
      sortable: true,
    },
    {
      name: 'Annonce',
      selector: (row) =>
        row?.announcement?.name ? row.announcement?.name : '-',
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) =>
        row?.createdAt ? (
          <Moment
            format="DD/MM/YYYY"
            date={row?.createdAt}
            className="text-[11px] text-[rgba(0,0,0,8)] "
          />
        ) : (
          '-'
        ),
      sortable: true,
    },
  ]
}

export default culumns
