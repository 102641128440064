import { Button, Label, TextInput, Textarea, Select } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { weightRanges } from '../../helpers/announcements/weight-ranges'
import { rentChoice } from '../../helpers/announcements/rent-choice'
import {
  _showInputQuantity,
  _showInputRent,
  _showWeightRange,
} from '../../helpers/announcements/hiden-and-show-elements'

export default function UpdateFormNext({
  setNext,
  price,
  currency,
  description,
  quantity,
  setPrice,
  currencies,
  setQuantity,
  setCurrency,
  setDescription,
  isLoading,
  handleSubmit,
  newcategoryselected,
  setWeightRange,
  weightRange,
  rent,
  setRent,
  setBrand,
  brand,
  type,
  newTypes,
  newBrands,
  setType,
}) {
  const [priceError, setPriceError] = useState('')
  const [currencyError, setCurrencyError] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  const [quantityError, setQuantityError] = useState('')
  // const [brandError, setBrandError] = useState('')
  // const [typeError, setTypeError] = useState('')

  let showInputQuantity = _showInputQuantity(newcategoryselected)
  let showWeightRange = _showWeightRange(newcategoryselected)
  let showInputRent = _showInputRent(newcategoryselected)

  useEffect(() => {
    setWeightRange(weightRange)
  }, [weightRange])

  const validateForm = () => {
    let isValid = true
    if (!setPrice) {
      setPriceError('Le prix est requis.')
      isValid = false
    } else {
      setPriceError('')
    }

    if (!setCurrency) {
      setCurrencyError('La devise est requise.')
      isValid = false
    } else {
      setCurrencyError('')
    }

    if (!setDescription) {
      setDescriptionError('La description est requise.')
      isValid = false
    } else {
      setDescriptionError('')
    }

    if (!setQuantity) {
      setQuantityError('La quantité est requise.')
      isValid = false
    } else {
      setQuantityError('')
    }

    // if (!brand) {
    //   setBrandError('La La marque est requise.')
    //   isValid = false
    // } else {
    //   setBrandError('')
    // }

    // if (!brand) {
    //   setTypeError('Le type est requise.')
    //   isValid = false
    // } else {
    //   setTypeError('')
    // }

    return isValid
  }

  const handlePublish = (e) => {
    e.preventDefault()
    if (validateForm()) {
      handleSubmit()
    }
  }

  return (
    <form className="flex flex-col max-w-md gap-4">
      <div>
        {newBrands?.length > 0 && (
          <div className="max-w-md mb-3">
            <div className="block mb-2">
              <Label htmlFor="brands" value="Choisir la marque de produit" />
            </div>
            <Select
              id="brands"
              onChange={(e) => {
                setBrand(e.target.value)
              }}
              value={brand}
              required
            >
              <option value="">Choisissez une marque</option>
              {newBrands?.map((brand) => (
                <option key={brand._id} value={brand?._id}>
                  {brand.name}
                </option>
              ))}
            </Select>
            {/* {brandError && (
            <p className="text-xs italic text-red-500">{brandError}</p>
          )} */}
          </div>
        )}

        {/* product Types */}
        {newTypes?.length > 0 && (
          <div className="max-w-md mb-3">
            <div className="block mb-2">
              <Label htmlFor="types" value="Choisir le type de produit" />
            </div>
            <Select
              id="types"
              onChange={(e) => {
                setType(e.target.value)
              }}
              value={type}
              required
            >
              <option value="">Choisissez un type de produit</option>
              {newTypes?.map((type) => (
                <option key={type._id} value={type?._id}>
                  {type.name}
                </option>
              ))}
            </Select>
            {/* {typeError && (
            <p className="text-xs italic text-red-500">{typeError}</p>
          )} */}
          </div>
        )}

        {showInputRent && (
          <div className="">
            <div className="block mb-2">
              <Label
                htmlFor="weightRange"
                value="De quel type proposze-vous ?"
              />
            </div>
            {rentChoice?.map((rc, index) => (
              <div key={rc?.value} className="flex items-center mb-2">
                <input
                  onChange={() => setRent(rc.value)}
                  checked={rent === rc?.value && true}
                  value={rent?.value}
                  id="checkbox"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor={`checkbox-${index}`}
                  className="text-sm font-medium text-gray-900 ms-2 dark:text-gray-300"
                >
                  {rc.value}
                </label>
              </div>
            ))}
          </div>
        )}
        <div className="flex gap-2">
          <div className="flex-grow">
            <div className="block mb-2">
              <Label htmlFor="price" value="Saisissez le prix" />
            </div>
            <TextInput
              className=""
              id="price"
              type="number"
              placeholder="0000"
              onChange={(e) => setPrice(e.target.value)}
              value={price}
              required
            />
            {priceError && (
              <p className="text-xs italic text-red-500">{priceError}</p>
            )}
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="currencies" value="Choisir une devise" />
            </div>
            <Select
              className=""
              id="currencies"
              onChange={(e) => setCurrency(e.target.value)}
              value={currency}
              required
            >
              <option value="">Choisissez une devise</option>
              {currencies?.map((currency) => (
                <option key={currency._id} value={currency._id}>
                  {currency?.name}
                </option>
              ))}
            </Select>
            {currencyError && (
              <p className="text-xs italic text-red-500">{currencyError}</p>
            )}
          </div>
        </div>
      </div>
      <div className="max-w-md">
        <div className="block mb-2">
          <Label
            htmlFor="description"
            value="Ajouter une description de votre produit"
          />
        </div>
        <Textarea
          id="description"
          placeholder="Ajouter la description ici..."
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          required
          rows={4}
        />
        {descriptionError && (
          <p className="text-xs italic text-red-500">{descriptionError}</p>
        )}
      </div>

      <div className="flex flex-col gap-2 md:flex md:flex-row lg:flex lg:flex-row">
        {showInputQuantity && (
          <div className="flex-grow">
            <div className="block mb-2">
              <Label htmlFor="quantity" value="Quantité disponible" />
            </div>
            <TextInput
              id="quantity"
              onChange={(e) => setQuantity(e.target.value)}
              type="number"
              value={quantity}
              required
            />
            {quantityError && (
              <p className="text-xs italic text-red-500">{quantityError}</p>
            )}
          </div>
        )}

        {!showWeightRange && (
          <div className="flex-grow">
            <div className="block mb-2">
              <Label htmlFor="weightRange" value="Tranche de poids" />
            </div>
            <Select
              id="weightRange"
              onChange={(e) => {
                let value = JSON.parse(e.target.value)
                setWeightRange(value)
              }}
              value={JSON.stringify(weightRange)}
              required
            >
              {weightRange && (
                <option key={weightRange} value={JSON.stringify(weightRange)}>
                  {weightRange.valueMin >= 90
                    ? weightRange.valueMin + ' ou plus'
                    : weightRange.valueMin +
                      ' à ' +
                      weightRange.valueMax +
                      'Kg'}
                </option>
              )}
              {weightRanges?.map((wg) => (
                <option key={weightRange} value={JSON.stringify(wg)}>
                  {wg.valueMin >= 90
                    ? wg.valueMin + ' ou plus'
                    : wg.valueMin + ' à ' + wg.valueMax + 'Kg'}
                </option>
              ))}
            </Select>
          </div>
        )}
      </div>

      <div className="flex justify-between">
        <Button
          className="md:w-[12.5vw] w-[40vw] text-black"
          onClick={() => {
            setNext(false)
          }}
        >
          Retour
        </Button>
        <Button
          className="md:w-[12.5vw] w-[40vw] bg-primary"
          onClick={(e) => handlePublish(e)}
          disabled={
            !price ||
            !currency ||
            // !type ||
            !description ||
            (quantity > 0 && !quantity) ||
            (showInputRent && !rent)
          }
        >
          {isLoading ? 'Publication ...' : 'Publier'}
        </Button>
      </div>
    </form>
  )
}
