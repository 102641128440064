import { Modal } from 'flowbite-react'
import { HiOutlineExclamationCircle } from 'react-icons/hi'
import ReactGA from 'react-ga4'

export default function LogOut({ openModal, setOpenModal }) {
  const handleLogout = async () => {
    ReactGA.event({
      category: 'Main clicks',
      action: 'Disconnect',
    })
    localStorage.removeItem('currentUser')
    localStorage.removeItem('seller')
    window.location.assign('/')
  }
  return (
    <>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center p-5">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Voulez-vous vraiment vous deconnecter ?
            </h3>
            <div className="flex justify-center gap-4">
              <button
                className="flex items-center gap-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                onClick={handleLogout}
              >
                {'Oui, certain'}
              </button>
              <button
                className="flex items-center gap-1 border-slate-200 border-2 text-gray-500 font-bold py-2 px-4 rounded-md text-sm"
                onClick={() => setOpenModal(false)}
              >
                Non, Annuler
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
