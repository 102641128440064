import React, { useState } from 'react'
import UserAddress from './UserAddress'
import { useSelector, useDispatch } from 'react-redux'
import { setLocation } from '../../features/user/user'
import { ModalLocation } from './ModalLocation'
import { IoCreateOutline } from 'react-icons/io5'

function LocationHandler() {
  let dispatch = useDispatch()
  let location = useSelector((state) => state.user.location)
  let [openMap, setOpenMap] = useState(false)
  return (
    <div>
      <div>
        <div className="flex gap-5">
          <UserAddress location={location} />
          <button
            onClick={() => setOpenMap(true)}
            className="flex items-center gap-1 p-2 rounded-[0.5rem] shadow text-sm"
          >
            <IoCreateOutline />
            Modifier
          </button>
        </div>
      </div>
      <ModalLocation
        openModal={openMap}
        setOpenModal={setOpenMap}
        setLocation={setLocation}
        dispatch={dispatch}
      />
    </div>
  )
}

export default LocationHandler
