import React, { useState } from 'react'
import { TextInput, Label, Textarea } from 'flowbite-react'
import UpdateImage from '../UpdateImage'
import { useUpdateShopMutation } from '../../services/shopApi'
import { useSelector, useDispatch } from 'react-redux'
import { ModalLocation } from '../location/ModalLocation'
import { setMyShop, setLocation } from '../../features/user/user'
import UserAddress from '../location/UserAddress'
import { IoCreateOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
export default function UpdateForm({ shop }) {
  const navigate = useNavigate()
  const [updateShop, { isLoading }] = useUpdateShopMutation()

  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.user.currentUser)
  let location = useSelector((state) => state.user.location)

  const phoneRegex = /^0(8[1-5|8-9]|9[0-5|7-9])[0-9]{7}$/

  const [image, setImage] = useState(shop?.logo)
  const [newLogo, setNewLogo] = useState()
  const [imageToDelete, setImageToDelete] = useState()
  const [name, setName] = useState(shop?.name)
  const [description, setDescription] = useState(shop?.description)
  const [phone, setPhone] = useState('0' + shop?.phone?.slice(3))
  const [openMap, setOpenMap] = useState(false)
  const [errors, setErrors] = useState({})

  const modifyPhone = (phone) => {
    return '243' + phone.slice(1)
  }

  const shopData = {
    id: shop?._id,
    name: name,
    description: description,
    phone: modifyPhone(`${phone}`),
    location: location,
    logoToDelete: imageToDelete,
    newLogo: newLogo,
    logo: image,
    user: currentUser,
  }

  console.log('data')

  const validateForm = () => {
    const newErrors = {}

    if (!name) {
      newErrors.name = "Le nom de l'enseigne est obligatoire"
    }

    if (!phone) {
      newErrors.phone = 'Le numéro de téléphone est obligatoire'
    } else if (!phoneRegex.test(phone)) {
      newErrors.phone =
        'Le numéro de téléphone doit être au format 082XXXXXXX, 083XXXXXXX, ...'
    }

    if (!description) {
      newErrors.description = "La description de l'enseigne est obligatoire"
    }

    // You can add more validation rules here for description, etc.

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0 // Return true if no errors
  }

  const handleUpdate = async () => {
    if (validateForm()) {
      await updateShop(shopData).then((response) => {
        console.log(response.data)
        dispatch(setMyShop(response.data))
        navigate(`/shop/me/${response?.data?._id}`, {
          state: { key: response?.data },
        })
      })
    }
  }

  return (
    <div className="flex flex-col gap-3 pb-5">
      <div className="flex flex-col gap-2">
        <Label value="Ajoutez le logo de votre enseigne" />
        <UpdateImage
          image={image}
          setImage={setImage}
          setNewImage={setNewLogo}
          newImage={newLogo}
          setImageToDelete={setImageToDelete}
        />
      </div>

      <div className="flex flex-col gap-2">
        <Label value="Votre addresse liée à votre actuelle localisation" />
        <div className="flex gap-5">
          <UserAddress location={location} />
          <button
            onClick={() => setOpenMap(true)}
            className="flex items-center gap-1 p-2 rounded-[0.5rem] shadow text-sm"
          >
            <IoCreateOutline />
            Modifier
          </button>
        </div>
      </div>

      {/* name */}
      <div className="">
        <div className="mb-2 block">
          <Label htmlFor="name" value="Entrez le nom de votre enseigne" />
        </div>
        <TextInput
          id="name"
          type="text"
          value={name}
          onChange={(e) => {
            e.preventDefault()
            setName(e.target.value)
          }}
          placeholder=""
          required
        />
        {errors.name && <p className="text-red-500">{errors.name}</p>}
      </div>
      {/* phone */}
      <div className="">
        <div className="mb-2 block">
          <Label
            htmlFor="phone"
            value="Entrez le numéro de téléphone de votre enseigne"
          />
        </div>
        <TextInput
          id="phone"
          type="text"
          value={phone}
          onChange={(e) => {
            e.preventDefault()
            setPhone(e.target.value)
          }}
          placeholder=""
          required
        />
        {errors.phone && <p className="text-red-500">{errors.phone}</p>}
      </div>
      <div className="">
        {/* <div className="mb-2 block">
          <Label htmlFor="name" value="Decrivez votre enseigne" />
        </div> */}
        <Textarea
          id="description"
          type="text"
          value={description}
          onChange={(e) => {
            e.preventDefault()
            setDescription(e.target.value)
          }}
          placeholder="Decrivez votre enseigne. Services et autres details"
          required
        />
        {errors.description && (
          <p className="text-red-500">{errors.description}</p>
        )}
      </div>
      <div>
        <button
          className="bg-green-400 p-2 rounded-[0.5rem] text-white"
          onClick={(e) => {
            e.preventDefault()
            handleUpdate()
          }}
        >
          {isLoading ? 'Mis à jour...' : "Mettre à jour l'enseigne"}
        </button>
      </div>

      {openMap && (
        <ModalLocation
          openModal={openMap}
          setOpenModal={setOpenMap}
          setLocation={setLocation}
          dispatch={dispatch}
        />
      )}
    </div>
  )
}
