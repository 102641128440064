import React, { useEffect, useState } from 'react'
import DataTableComponent from '../../../components/datatable/DataTable'
import columns from '../../../helpers/trackers/colums-show-trackers'
import { getTrackerContacts } from '../../../services/trackers/get-trackers'

function ListTrackers() {
  const [trackercontacts, setTrackercontacts] = useState([])
  const [loader, setLoader] = useState(null)

  useEffect(() => {
    getTrackerContacts(setTrackercontacts)
    setLoader(trackercontacts)
  }, [])

  const columnsConfig = columns()

  console.log('test')

  return (
    <div className="pt-[60px] md:pt-0">
      <div className="card">
        <div className="px-[16px] md:px-0 flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h2 className=" font-bold text-[17px] pb-5">
              {/* {'Categorie ' + categories?.name} */}
            </h2>
          </div>
          <div className="flex justify-end text-xs gap-2"></div>
        </div>
        <DataTableComponent
          data={trackercontacts}
          columns={columnsConfig}
          name={'Clients qui ont conctactés les vendeur'}
          loader={loader}
        />
      </div>
    </div>
  )
}

export default ListTrackers
