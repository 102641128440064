import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const updateCategory = async (
  name,
  icon,
  description,
  params,
  isAdmin,
  characteristics
) => {
  const data = await axios.put(
    `${BASE_URL}/api/categories/update-category/${params}`,
    {
      name,
      icon,
      description,
      isAdmin,
      characteristics,
    }
  )
  return data
}

export { updateCategory }
