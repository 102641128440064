import React from 'react'
import Moment from 'react-moment'
import 'moment-timezone'

function MessageOnlyImage({ message, owner }) {
  return (
    <div
      className={
        owner
          ? 'max-w-[250px] bg-[#8ba1ee] my-2 md:my-2  flex  rounded-[7px] shadow-message'
          : 'max-w-[250px] bg-yellow-300 my-2 md:my-2 flex rounded-[7px] shadow-message'
      }
    >
      <div className="px-1 py-1">
        <p className=" text-[rgba(0,0,0,0.76)] text-[14px] ">
          {message.text}
          <div className="relative shadow-message">
            {message ? (
              <div>
                {message.images.map((img) => (
                  <img
                    src={img}
                    alt="images sender"
                    className="h-[250px] w-[250px] object-cover rounded-[3px]"
                    key={img._id}
                  />
                ))}
              </div>
            ) : (
              <div className="h-[40px] w-[40px] loading-spinner"></div>
            )}

            <div className="absolute right-0 bottom-0 gradiant py-1 px-2 text-[11px] rounded text-white">
              <Moment
                format="HH:mm"
                className="text-[10px] text-[#d8d8d8]"
                date={message?.createdAt}
              />
            </div>
          </div>
        </p>
      </div>
    </div>
  )
}

export default MessageOnlyImage
