import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAnnouncement } from '../../services/Announcements/getAnnouncement'
// import { FaRegComment } from 'react-icons/fa'
import axios from 'axios'
import { BASE_URL, BASE_URL_SOCKET } from '../../helpers/Root'
import Nav from '../../components/layout/Nav'
import io from 'socket.io-client'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import Profil from '../../assets/profil.png'

import OtherAnnouncementsSeller from '../../components/announcement/OtherAnnouncementsSeller'
import { getCommentByAnnouncement } from '../../services/Comments/getCommentByAnnouncement'
import CommentsAnnouncement from '../../components/Comments/CommentsAnnouncement'
import { addLikeAnnouncement } from '../../services/Announcements/add-like-announcement'

import { getAnnouncementsByCategory } from '../../services/Announcements/get-announcementsByCategory'
import ImageAnnounceCarrousel from '../../components/announcement/image-announce-carrousel'
import Register from '../authentification/Register'
import RegisterModal from '../../components/modal/RegisterModal'
// import Socials from '../../components/announcement/Socials'
import InfoUser from '../../components/announcement/Info-user'
import { postTrackerContact } from '../../services/trackers/post-tracker-contact'
// import { NumericFormat } from 'react-number-format'
import { fistLetterToUpparcase } from '../../helpers/firstLetterToUpperCase'
// import { AiOutlineMessage } from 'react-icons/ai'
// import AddToCart from '../../components/cart/AddToCart'
import ProfilSeller from '../../components/announcement/ProfilSeller'
import CountLikeAndView from '../../components/announcement/CountLikeAndView'
import InfoProduct from '../../components/announcement/InfoProduct'
// import CallPhone from '../../components/announcement/call/CallPhone'

function AnnouncementDetail() {
  const params = useParams()

  //Reference socket
  const socket = useRef()
  const scrolRef = useRef(null)

  const scrolRefContent = useRef(null)

  const [announcement, setAnnouncement] = useState()
  const [announcementsByCategory, setAnnouncementsByCategory] = useState()
  const [arrivalMessage, setArrivalMessage] = useState(null)
  const [loaderBtn, setLoaderBtn] = useState(false)
  const [loader, setLoader] = useState(true)
  const [comments, setComments] = useState([])
  const [comment, setComment] = useState('')
  const [isShowComment, setIsShowComment] = useState(false)
  // const [seemoreDescription, setSeemoreDescription] = useState(false)
  // const [seemoreAdress, setSeemoreAdress] = useState(false)

  const [connecting, setConnecting] = useState(false)
  const [registering, setRegistering] = useState(false)
  console.log('arrivalMessage : ', arrivalMessage)

  let generateCodeForNoUser = 'yeueuizooslzukjsk'

  const navigate = useNavigate()
  const infoUserRef = useRef(null)
  const [fixedCall, setFixedCall] = useState(false)

  const currentUser = useSelector((state) => state.user.currentUser)

  // User socket
  useEffect(() => {
    socket.current = io(`${BASE_URL_SOCKET}`)
    socket.current.on('receiveMessage', (data) => {
      setArrivalMessage({
        senderId: data.senderId,
        announcementId: data.announcementId,
        createdAt: Date.now(),
      })
    })
  }, [socket])

  //Add user connected in socket
  useEffect(() => {
    socket.current.emit('addUser', currentUser?._id)
  }, [currentUser])

  useEffect(() => {
    getAnnouncement(params.id, setAnnouncement)
  }, [params.id])

  useEffect(() => {
    getAnnouncementsByCategory(
      params?.category,
      setAnnouncementsByCategory,
      setLoader
    )
    setLoader(true)
  }, [params])

  useEffect(() => {
    if (announcement?._id) {
      getCommentByAnnouncement(announcement?._id, setComments)
    }
  }, [announcement?._id])

  useEffect(() => {
    scrolRefContent.current?.scrollIntoView({ behavior: 'smooth' })
  }, [params.id])

  let owner = announcement?.seller
    ? announcement?.seller?.user?._id
    : announcement?.user?._id

  const conversationData = {
    senderId: currentUser?._id,
    receiverId: owner,
  }

  let dataTracker = {
    typetracker: 'messagerieuty',
    announcement: announcement?._id,
  }

  const handleConversation = async (e) => {
    postTrackerContact(currentUser?._id, announcement?.user?._id, dataTracker)
    e.preventDefault()
    ReactGA.event({
      category: 'Announcement',
      action: 'Want to buy a product',
    })
    if (currentUser) {
      const chat = await axios.post(
        `${BASE_URL}/api/chat/create-chat`,
        conversationData
      )
      try {
        if (chat.data.message === 'success') {
          localStorage.setItem('chat', JSON.stringify(chat.data.chat))
          sendMessage(chat?.data?.chat?._id)
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      setConnecting(true)
    }
  }

  const sendMessage = async (chatId) => {
    setLoaderBtn(true)
    //send message in socket
    const message = {
      senderId: currentUser?._id,
      chatId: chatId,
      announcementId: params?.id,
      receiverId: owner,
    }

    try {
      const { data } = await axios.post(`${BASE_URL}/api/message/send`, message)

      if (data.result) {
        navigate(
          `/conversations/${currentUser?._id}?user=${currentUser?.username}`
        )
        setLoaderBtn(false)
      }
    } catch (err) {
      setLoaderBtn(false)
      console.log(err)
    }

    const messagetoSocket = {
      senderId: currentUser?._id,
      announcementId: params?.id,
      receiverId: announcement?.seller?.user?._id || announcement?.user?._id,
      text: null,
      images: null,
    }
    socket.current.emit('sendMessage', messagetoSocket)
  }

  const handleComment = async () => {
    const commentData = {
      user: currentUser?._id,
      announcement: params?.id,
      comment: comment,
    }

    if (comment) {
      try {
        const { data } = await axios.post(
          `${BASE_URL}/api/comment/add-comment`,
          commentData
        )
        if (data) {
          window.location.reload()
        }
      } catch (err) {
        console.log(err)
      }

      setComment('')
    }
  }

  const handleLikeAnnouncement = async (announcement) => {
    if (currentUser) {
      let data = await addLikeAnnouncement(currentUser?._id, announcement?._id)
      if (data?.status === 200) {
        if (announcement?.likes?.includes(currentUser?._id)) {
          // The user already liked the announcement, so we need to unlike it
          const updatedLikes = announcement.likes.filter(
            (userId) => userId !== currentUser?._id
          )
          // Update announcement with updated likes
          setAnnouncement({ ...announcement, likes: updatedLikes })
        } else {
          // The user hasn't liked the announcement, we need to add it to likes
          const updatedLikes = [...announcement.likes, currentUser?._id]
          // Update announcement with updated likes
          setAnnouncement({ ...announcement, likes: updatedLikes })
        }
      }
    } else {
      if (announcement?.likes?.includes(generateCodeForNoUser)) {
        const updatedLikes = announcement.likes.filter(
          (userId) => userId !== generateCodeForNoUser
        )
        setAnnouncement({ ...announcement, likes: updatedLikes })
      } else {
        const updatedLikes = [...announcement.likes, generateCodeForNoUser]
        setAnnouncement({ ...announcement, likes: updatedLikes })
      }
    }
  }

  useEffect(() => {
    scrolRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [comments])

  // Move Social and call
  useEffect(() => {
    const handleScroll = () => {
      const infoUserElement = infoUserRef.current
      if (infoUserElement) {
        const rect = infoUserElement.getBoundingClientRect()
        if (rect.top < 35 && !fixedCall) {
          setFixedCall(true)
        } else if (window.scrollY === 0 && fixedCall) {
          setFixedCall(false)
        }
      }
    }
    const debouncedHandleScroll = debounce(handleScroll, 100) // Debounce à 100ms
    window.addEventListener('scroll', debouncedHandleScroll)
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll)
    }
  }, [fixedCall])

  //Clean effect scroll
  const debounce = (func, wait) => {
    let timeout
    return function (...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }

  return (
    <div className="relative" ref={scrolRefContent}>
      <div
        className={`flex px-4 ${isShowComment && 'hidden lg:flex'} flex-col md:px-16`}
      >
        <div>
          <Nav />
        </div>
        <div>
          <div className="flex flex-col pt-32 lg:pt-36 lg:flex-row md:pt-24 lg:gap-8 md:gap-6">
            <div>
              <ImageAnnounceCarrousel
                setIsShowComment={setIsShowComment}
                images={announcement?.images}
                handleLikeAnnouncement={handleLikeAnnouncement}
                announcement={announcement}
                user={currentUser}
              />
              <InfoUser
                announcement={announcement}
                currentUser={currentUser}
                comments={comments}
                navigate={navigate}
                params={params}
                handleConversation={handleConversation}
                loaderBtn={loaderBtn}
                ref={null}
                infoUserRef={infoUserRef}
                fixedCall={fixedCall}
                handleLikeAnnouncement={handleLikeAnnouncement}
                generateCodeForNoUser={generateCodeForNoUser}
              />
            </div>

            <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 lg:w-full lg:flex">
              <div className="lg:w-full">
                <div className="flex items-center justify-between pt-5 md:pt-0 lg:pt-0">
                  <ProfilSeller
                    announcement={announcement}
                    Profil={Profil}
                    fistLetterToUpparcase={fistLetterToUpparcase}
                  />
                  <CountLikeAndView announcement={announcement} />
                </div>

                <InfoProduct announcement={announcement} />

                <hr className="my-3 border-blue-100 lg:my-6" />

                {/* <AddToCart
                  announcement={announcement}
                  user={currentUser}
                  setConnecting={setConnecting}
                  generateCodeForNoUser={generateCodeForNoUser}
                  handleLikeAnnouncement={handleLikeAnnouncement}
                /> */}
              </div>

              <div className="hidden lg:w-full md:hidden lg:block ">
                <CommentsAnnouncement
                  comments={comments}
                  navigate={navigate}
                  scrolRef={scrolRef}
                  currentUser={currentUser}
                  handleComment={handleComment}
                  announcement={announcement}
                  setComment={setComment}
                  isShowComment={isShowComment}
                  setConnecting={setConnecting}
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <OtherAnnouncementsSeller
            announcement={announcement}
            announcementsByCategory={announcementsByCategory?.announcements}
            loader={loader}
          />
        </div>

        {connecting && (
          <Register
            openModal={connecting}
            setOpenModal={setConnecting}
            setRegistering={setRegistering}
          />
        )}

        {registering && (
          <RegisterModal
            openModal={registering}
            setOpenModal={setRegistering}
          />
        )}
      </div>

      <div className={isShowComment ? 'absolute block  lg:hidden' : 'hidden'}>
        <CommentsAnnouncement
          comments={comments}
          navigate={navigate}
          scrolRef={scrolRef}
          currentUser={currentUser}
          handleComment={handleComment}
          announcement={announcement}
          setComment={setComment}
          setIsShowComment={setIsShowComment}
          setConnecting={setConnecting}
        />
      </div>
    </div>
  )
}

export default AnnouncementDetail
