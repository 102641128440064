import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getAnnouncementsByUser = async (userId, setAnouncements) => {
  await axios
    .get(`${BASE_URL}/api/announcements/user/${userId}`)
    .then((response) => {
      setAnouncements(response.data)
    })
}

export { getAnnouncementsByUser }
