import React from 'react'
import Moment from 'react-moment'
import 'moment-timezone'

function MessageTextAndMessage({ owner, message }) {
  return (
    <div
      className={
        owner
          ? 'max-w-[250px] bg-[#8ba1ee] shadow-white-500/50 my-2 md:my-2 flex  rounded-[7px]'
          : 'max-w-[250px] bg-yellow-300 shadow-white-500/50 my-2 md:my-2 flex rounded-[7px]'
      }
    >
      <div className="relative">
        <p className=" text-[rgba(0,0,0,0.76)] text-[14px] px-1 py-1">
          <div className="">
            {message.images.map((img) => (
              <img
                src={img}
                alt="images sender"
                className="h-[200px] w-[100%] object-cover rounded-[3px]"
                key={img._id}
              />
            ))}
          </div>
          {message.text}
        </p>
        <div className="absolute right-0 bottom-0 gradiant py-1 px-2 text-[11px] rounded text-white">
          <Moment
            format="HH:mm:ss"
            className="text-[10px] text-[#d8d8d8]"
            date={message?.createdAt}
          />
        </div>
      </div>
    </div>
  )
}

export default MessageTextAndMessage
