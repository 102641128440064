import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getSubtCategory = async (id, setState) => {
  await axios.get(`${BASE_URL}/api/subcategories/${id}`).then((response) => {
    // console.log('subcategory:', response?.data?.subcategory)
    setState(response?.data?.subcategory)
  })
}

export { getSubtCategory }
