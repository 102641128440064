import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getRequestRefused = async (setDelivers) => {
  // setDelivers
  const response = await axios.get(
    `${BASE_URL}/api/delivers/get-decline-request-delivers`
  )

  try {
    if (response?.status === 200) setDelivers(response?.data?.delivers)
  } catch (error) {
    console.error(error)
  }
  //   setDelivers(data?.data?.delivers)
}

export { getRequestRefused }
