import React from 'react'
import indexLoader from '../assets/sellerAnnouncementLoader.json'
import LottieLoader from './LottieLoader'
export default function PageLoader({ message }) {
  return (
    <div>
      <div className="flex pt-10 flex-col items-center justify-center sm:h-screen">
        <div>
          <LottieLoader animation={indexLoader} />
        </div>
        <div>{message}</div>
      </div>
    </div>
  )
}
