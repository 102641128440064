import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Cart'],
  endpoints: (builder) => ({
    addToCart: builder.mutation({
      query: (cart) => ({
        url: 'api/cart/add',
        method: 'POST',
        body: cart,
      }),
      invalidatesTags: ['Cart'],
    }),
    createOrder: builder.mutation({
      query: (order) => ({
        url: 'api/orders/create',
        method: 'POST',
        body: order,
      }),
      invalidatesTags: ['Cart'],
    }),
    updateProductQuantity: builder.mutation({
      query: (product) => ({
        url: `api/cart/quantity`,
        method: 'POST',
        body: product,
      }),
      invalidatesTags: ['Cart'],
    }),
    deleteProductToCart: builder.mutation({
      query: ({ product, user }) => ({
        url: `api/cart/delete-product/${user}`,
        method: 'POST',
        body: { productId: product },
      }),
      invalidatesTags: ['Cart'],
    }),
    getCurrentCart: builder.query({
      query: (user) => `api/cart/${user}`,
      providesTags: ['Cart'],
    }),
  }),
})

export const {
  useAddToCartMutation,
  useUpdateProductQuantityMutation,
  useCreateOrderMutation,
  useDeleteProductToCartMutation,
  useGetCurrentCartQuery,
} = cartApi
