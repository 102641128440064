const thisUser = (shipment, currentUser) => {
  if (shipment?.status === 'accepted') {
    if (shipment?.order?.customer?._id === currentUser?._id)
      return [
        {
          contact: shipment?.deliver,
          title: 'le livreur',
          color: 'bg-green-500 text-white',
        },
        {
          contact: shipment?.order?.seller,
          title: 'le vendeur',
          color: 'bg-red-500 text-white',
        },
      ]
    if (shipment?.deliver?.user?._id === currentUser?._id)
      return [
        {
          contact: shipment?.order?.seller,
          title: 'le vendeur',
          color: 'bg-red-500 text-white',
        },
      ]
  }
  if (shipment?.status === 'in delivery') {
    if (shipment?.order?.customer?._id === currentUser?._id)
      return [
        {
          contact: shipment?.deliver,
          title: 'le livreur',
          color: 'bg-green-500 text-white',
        },
        {
          contact: shipment?.order?.seller,
          title: 'le vendeur',
          color: 'bg-red-500 text-white',
        },
      ]
    if (shipment?.deliver?.user?._id === currentUser?._id)
      return [
        {
          contact: shipment?.order?.customer,
          title: "l'acheteur",
          color: 'bg-primary text-white',
        },
        {
          contact: shipment?.order?.seller,
          title: 'le vendeur',
          color: 'bg-red-500 text-white',
        },
      ]
    if (shipment?.order?.seller?._id === currentUser?._id)
      return [
        {
          contact: shipment?.order?.customer,
          title: "l'acheteur",
          color: 'bg-primary text-white',
        },
        {
          contact: shipment?.deliver,
          title: 'le livreur',
          color: 'bg-green-500 text-white',
        },
      ]
  }
}

export default thisUser
