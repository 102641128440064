import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DataTableComponent from '../../../components/datatable/DataTable'
import columns from '../../../helpers/product-types/column-show-types'
import { getProductTypes } from '../../../services/productTypes/get-product-types'

function ListProductType() {
  const navigate = useNavigate()

  const [types, setTypes] = useState([])
  const [loader, setLoader] = useState(null)

  const columnsConfig = columns(navigate)

  useEffect(() => {
    getProductTypes(setTypes, setLoader)
  }, [])

  return (
    <div className="pt-[60px] md:pt-0">
      <div className="card">
        <div className="px-[16px] md:px-0 flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h2 className=" font-bold text-[17px] pb-5">
              {'Liste de type de produits.'}
            </h2>
          </div>
          <div className="flex justify-end gap-2 text-xs">
            <button
              className="bg-primary text-white px-3 py-2 border-none rounded-[3px] outline-none cursor-pointer"
              onClick={() => navigate(`/Account/add-product-type`)}
            >
              Ajouter un type
            </button>
          </div>
        </div>
        <DataTableComponent
          data={types}
          columns={columnsConfig}
          name={'Type'}
          loader={loader}
        />
      </div>
    </div>
  )
}

export default ListProductType
