import React from 'react'
import { useSelector } from 'react-redux'

function CountLikeAndView({ announcement }) {
  const currentUser = useSelector((state) => state.user.currentUser)

  return (
    currentUser?._id === announcement?.user?._id && (
      <div>
        <div className="text-xs font-roboto bg-gray-100 px-2 rounded-md">
          <i className="fa-solid fa-eye mr-1"></i>
          {announcement?.seen?.length}{' '}
          {announcement?.seen?.length < 2 ? 'Vue' : 'Vues'}
        </div>
        <div className="text-xs font-roboto bg-gray-100 px-2 rounded-md">
          <i className="fa-solid fa-heart mr-1"></i>
          {announcement?.likes?.length}{' '}
          {announcement?.likes?.length < 2 ? "J'aime" : "J'aimes"}
        </div>
      </div>
    )
  )
}

export default CountLikeAndView
