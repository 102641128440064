import React, { useEffect, useState } from 'react'
import { getRequestDelivers } from '../../../services/delivers/get-request-delivers'
import DataTableComponent from '../../datatable/DataTable'
import columns from '../../../helpers/admins/delivers/colums-show-requests'
import ModalVaalidate from '../modals/modal-validate-request'
import { validateRequestDeliver } from '../../../services/delivers/validate-deliver'
import ModalRefusedRequest from '../modals/modal-validate-refused'
// import { refuseRequestDeliver } from '../../../services/delivers/refused-request-deliver'
import ModalDetailDeliver from '../modals/modal-detail-deliver'

function RequestDelivers({ active, request }) {
  const [delivers, setDelivers] = useState([])
  const [loader, setLoader] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showModalDecline, setShowModalDecline] = useState(false)
  const [user, setUser] = useState(null)
  const [isLoader, setIsLoader] = useState(0)
  // const [active, setActive] = useState('validated')

  const [showModalDetail, setShowModalDetail] = useState(false)

  const columnsConfig = columns(
    setShowModal,
    setShowModalDecline,
    setShowModalDetail,
    setUser
  )

  useEffect(() => {
    getRequestDelivers(setDelivers)
    setLoader(delivers)
  }, [isLoader])

  const handleValidate = () => {
    setShowModal(false)
    validateRequestDeliver(user?._id, isLoader, setIsLoader)
  }

  // const handleRefuse = () => {
  //   setShowModalDecline(false)
  //   refuseRequestDeliver(user?._id, isLoader, setIsLoader)
  // }

  return (
    active === request && (
      <div>
        {/* This modal is showing the detail of deliver man */}
        <ModalDetailDeliver
          showModal={showModalDetail}
          setShowModal={setShowModalDetail}
        />

        {/* Modal : for validating the request deliver */}
        <ModalVaalidate
          showModal={showModal}
          setShowModal={setShowModal}
          handleClick={handleValidate}
        />

        {/* Modal : for refusing the request of deliver */}
        <ModalRefusedRequest
          showModal={showModalDecline}
          setShowModal={setShowModalDecline}
          // handleClick={handleRefuse}
          userId={user?._id}
          setIsLoader={setIsLoader}
          isLoader={isLoader}
        />

        {/* here we are passing the to data table*/}
        <div className="pt-[60px] md:pt-0">
          <div className="card">
            <DataTableComponent
              data={delivers}
              columns={columnsConfig}
              name={'Démande'}
              loader={loader}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default RequestDelivers
