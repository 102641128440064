import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import columns from '../../../helpers/categories/colums-show-categories'
import { getCategories } from '../../../services/Categories/get-categories'
import DataTableComponent from '../../../components/datatable/DataTable'

export default function ShowCategories() {
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(null)

  const navigate = useNavigate()

  //pass navigate param in column
  const columnsConfig = columns(navigate)

  useEffect(() => {
    getCategories(setCategories, setLoader)
  }, [])

  return (
    <div className="pt-[60px] md:pt-0">
      <div className="card">
        <div className="px-[16px] md:px-0 flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h2 className=" font-bold text-[17px] pb-5">Liste de categories</h2>
          </div>
          <div className="flex justify-end text-xs gap-2">
            <button
              className="bg-primary text-white px-3 py-2 border-none rounded-[3px] outline-none cursor-pointer"
              onClick={() => navigate(`/Account/create-category/`)}
            >
              Ajouter
            </button>
            <button
              className="bg-primary text-white px-3 py-2 border-none rounded-[3px] outline-none cursor-pointer"
              onClick={() => navigate(`/Account/join-subcategory-tocategory/`)}
            >
              Joindre une categorie
            </button>
          </div>
        </div>
        <DataTableComponent
          data={categories}
          columns={columnsConfig}
          name={'categorie'}
          loader={loader}
        />
      </div>
    </div>
  )
}
