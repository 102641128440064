import React from 'react'
import { FileInput, Label } from 'flowbite-react'
import _ from 'lodash'
import { BsFillXCircleFill } from 'react-icons/bs'
import { IoImageOutline } from 'react-icons/io5'
import ErrorModal from './modal/ErrorModal'

export default function AddImages({
  setImages,
  images,
  setImgClicked,
  setPicClick,
  setImagesToDelete,
  setNewImages,
  newImages,
  updating,
}) {
  const [imgSizeError, setImgSizeError] = React.useState(false)
  const deleteImage = (index) => {
    if (setImagesToDelete) {
      setImagesToDelete((curr) => [
        ...curr.concat(images.filter((_, i) => i === index)),
      ])
    }

    setImages(images.filter((_, i) => i !== index))
  }

  const deleteNewImage = (index) => {
    setNewImages(newImages.filter((_, i) => i !== index))
  }
  const readFileHandler = (file) => {
    if (file.size > 20000000) {
      setImgSizeError(true)
      console.log('img to large!!')
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        updating
          ? setNewImages((curr) => [...curr, reader.result])
          : setImages((curr) => [...curr, reader.result])
        return reader.result
      }
    }
  }

  const selectFilesHandler = async (e) => {
    const imagesData = []
    _.forEach(e.target.files, (file) => {
      imagesData.push(readFileHandler(file))
    })
  }
  return (
    <div className="">
      <div className="grid grid-cols-4 gap-3">
        <Label
          htmlFor="dropzone-file"
          className="flex flex-col items-center justify-center p-2 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer max-w-100 bg-gray-50"
        >
          <div className="flex flex-col items-center justify-center">
            <IoImageOutline className="text-[50px]" />
            <p className="mb-2 text-[8px] text-gray-500">
              <span className="font-semibold">Ajouter une photo</span>
            </p>
          </div>
          <FileInput
            id="dropzone-file"
            className="hidden"
            onChange={selectFilesHandler}
            accept=".png, .jpg, .jpeg, .webp"
            multiple
          />
        </Label>
        {images?.map((image, index) => (
          <div className="flex" key={index}>
            <div className="flex shadow-md">
              <img
                src={image}
                className="rounded-[0.25rem] object-cover md:h-32 h-20"
                alt="products-images"
                onClick={() => {
                  setPicClick(true)
                  setImgClicked(image)
                }}
              />
            </div>
            <div
              className="p-1 mt-1 text-red-500 -ml-7"
              onClick={(e) => {
                e.preventDefault()
                deleteImage(index)
              }}
            >
              <BsFillXCircleFill />
            </div>
          </div>
        ))}

        {updating &&
          newImages?.map((image, index) => (
            <div className="flex" key={index}>
              <div className="flex shadow-md">
                <img
                  src={image}
                  className="rounded-[0.25rem] object-cover md:h-40 h-30"
                  alt="products-images"
                  onClick={() => {
                    setPicClick(true)
                    setImgClicked(image)
                  }}
                />
              </div>
              <div
                className="p-1 mt-1 text-red-500 -ml-7"
                onClick={(e) => {
                  e.preventDefault()
                  deleteNewImage(index)
                }}
              >
                <BsFillXCircleFill />
              </div>
            </div>
          ))}
      </div>
      <ErrorModal
        openModal={imgSizeError}
        setOpenModal={setImgSizeError}
        text={'Une image ne doit pas depasser 20 megabytes!!'}
      />
    </div>
  )
}
