import React from 'react'
import { FaCheck } from 'react-icons/fa'

function ToastSuccess({ toast, verify, message }) {
  return (
    <div
      className={
        toast && verify
          ? 'flex justify-center items-center h-[70px] ml-0 px-3 w-[270px] bg-[#121212] transition-all ease-out delay-700 duration-300 text-[#ffffff] rounded-[4px] absolute mt-[80px] mr-6 top-0 right-0 z-50'
          : 'flex justify-center items-center h-[70px] ml-200px px-3 transition-all ease-out delay-700 duration-300 w-[270px] bg-[#121212] text-[#ffffff] top-0 rounded-[4px] absolute mt-[-20px] mr-6 right-[0px] z-50'
      }
    >
      <div className=" bg-green-500 rounded-full p-2 mr-2">
        <FaCheck className=" text-white" />
      </div>
      <div className="">{message}</div>
      <div className="h-[3px] bg-green-500 absolute bottom-0 left-0 right-0 z-20"></div>
    </div>
  )
}

export default ToastSuccess
