import React from 'react'
import emptyImage from '../assets/emptyImage.png'
import { useNavigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { postSeen } from '../services/seens/add-seen'
import { FaRegHeart } from 'react-icons/fa6'
import { AiOutlineEye } from 'react-icons/ai'
import { motion } from 'framer-motion'
import AOS from 'aos'
import 'aos/dist/aos.css'

function ItemProduct({ data }) {
  const slicedData = data?.slice(-500)?.reverse()
  const navigate = useNavigate()

  AOS.init()

  const currentUser = useSelector((state) => state.user.currentUser)

  const hanleAddSeen = async (announce) => {
    const data = {
      announcementId: announce._id,
      userId: currentUser?._id,
    }

    if (currentUser) {
      await postSeen(data)
      navigate(`/announcements/${announce._id}/${announce?.category?._id}`)
    } else {
      navigate(`/announcements/${announce._id}/${announce?.category?._id}`)
    }
  }

  return (
    <>
      {slicedData?.map((announce) => (
        <motion.div
          key={announce._id}
          onClick={() => hanleAddSeen(announce)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          // data-aos="fade-up"
          // data-aos-duration="400"
          className="relative w-full gap-[20px] pb-3 mb-3 transition-shadow duration-300 ease-in-out border border-gray-300 rounded-lg shadow-lg cursor-pointer hover:shadow-2xl"
        >
          <div className="relative">
            {announce?.images[0] ? (
              <img
                src={announce?.images[0]}
                alt=""
                className="object-cover w-full h-40 rounded-t-lg"
              />
            ) : (
              <img
                src={emptyImage}
                alt=""
                className="object-cover w-full h-40 rounded-t-lg"
              />
            )}

            {/* Icônes superposées sur l'image */}
            <div className="absolute flex space-x-2 top-2 right-2">
              <div className="p-2 bg-white rounded-full shadow-md">
                <FaRegHeart className="text-red-500" />
              </div>
              <div className="p-2 bg-white rounded-full shadow-md">
                <AiOutlineEye className="text-blue-500" />
              </div>
            </div>
          </div>

          <div className="px-4 pt-1">
            <div className="mb-1 font-semibold truncate text-md">
              {announce.name}
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm font-bold text-gray-800">
                <NumericFormat
                  value={announce?.price}
                  displayType={'text'}
                  thousandSeparator=" "
                  suffix={` ${announce?.currency?.shortName || 'fc'}`}
                />
              </div>
            </div>
            <div className="mt-2">
              <button
                className="px-4 py-1 text-white transition-colors duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
                onClick={() => hanleAddSeen(announce)}
              >
                Détail
              </button>
            </div>
          </div>
          {/* <div className="flex items-center justify-end">
        <span className="flex gap-3 bg-[rgba(0,0,255,0.012)] px-2 mx-2 mb-3"> 
          <span className="flex text-[11px] pt-[2px] gap-1">
            <span className="">{announce?.likes?.length}</span>
            <FaRegHeart className="text-[17px] font-light text-[rgb(48,48,49)]" />
          </span>
          <span className="flex text-[11px] pt-[2px] gap-1">
            <span className="">{announce?.seen?.length}</span>
            <AiOutlineEye className="text-[17px] font-light text-[rgb(48,48,49)]" />
          </span>
        </span>
      </div> */}
        </motion.div>
      ))}
    </>
  )
}

export default ItemProduct
