import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getUsers = async (setUsers) => {
  const response = await axios.get(`${BASE_URL}/api/auth/users`)
  // console.log('response : ', response?.data?.users)
  try {
    setUsers(response?.data?.users)
  } catch (err) {
    console.log(err)
  }
}

export { getUsers }
