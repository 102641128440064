import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../helpers/Root'

export const shipmentApi = createApi({
  reducerPath: 'shipmentApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Shipment', 'Pendings', 'Shipments'],
  endpoints: (builder) => ({
    createShipment: builder.mutation({
      query: ({ order, comment }) => ({
        url: 'api/shipments/create',
        method: 'POST',
        body: { order: order, comment: comment },
      }),
    }),
    acceptShipment: builder.mutation({
      query: ({ shipment, deliver }) => ({
        url: `api/shipments/accept/${shipment}`,
        method: 'POST',
        body: { deliver: deliver },
      }),
      invalidatesTags: ['Shipments', 'Pendings'],
    }),
    entrust: builder.mutation({
      query: (shipment) => ({
        url: `api/users/entrust/${shipment}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Shipment'],
    }),
    collect: builder.mutation({
      query: (shipment) => ({
        url: `api/delivers/collect/${shipment}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Shipment'],
    }),
    validateDelivery: builder.mutation({
      query: (shipment) => ({
        url: `api/users/validate/${shipment}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Shipment'],
    }),
    dropOffDelivery: builder.mutation({
      query: (shipment) => ({
        url: `api/delivers/drop-off-shipment/${shipment}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Shipment'],
    }),

    // refuseOrder: builder.mutation({
    //   query: (order) => ({
    //     url: `api/orders/order/refuse`,
    //     method: 'POST',
    //     body: { order: order },
    //   }),
    //   invalidatesTags: ['Orders'],
    // }),
    // deleteProductToCart: builder.mutation({
    //   query: ({ product, user }) => ({
    //     url: `api/cart/delete-product/${user}`,
    //     method: 'POST',
    //     body: { productId: product },
    //   }),
    //   invalidatesTags: ['Cart'],
    // }),
    getDeliveredShipments: builder.query({
      query: (deliver) => `api/shipments/delivered/${deliver}`,
    }),
    getDeliveries: builder.query({
      query: (deliver) => `api/shipments/deliver/${deliver}`,
      providesTags: ['Shipments'],
    }),
    getPendingShipments: builder.query({
      query: () => 'api/shipments',
      providesTags: ['Pendings'],
    }),
    getSellerShipments: builder.query({
      query: (seller) => `api/shipments/seller/${seller}`,
    }),
    getOrderShipment: builder.query({
      query: (order) => `api/shipments/order/${order}`,
    }),
    getShipment: builder.query({
      query: (shipment) => `api/shipments/${shipment}`,
      providesTags: ['Shipment'],
    }),
  }),
})

export const {
  useCreateShipmentMutation,
  useAcceptShipmentMutation,
  useGetPendingShipmentsQuery,
  useGetSellerShipmentsQuery,
  useGetOrderShipmentQuery,
  useGetDeliveriesQuery,
  useGetShipmentQuery,
  useGetDeliveredShipmentsQuery,
  useCollectMutation,
  useEntrustMutation,
  useDropOffDeliveryMutation,
  useValidateDeliveryMutation,
} = shipmentApi
