import React from 'react'
import MessageTextAndMessage from './messages/MessageTextAndIage'
import MessageOnlyText from './messages/MessageOnlyText'
import MessageOnlyImage from './messages/MessageOnlyImage'
import MessageAnnouncement from './messages/MessageAnnouncement'

function Message({ owner, message }) {
  return (
    <div className="">
      <div
        className={
          owner
            ? 'px-3 md:px-5 lg:px-16  flex flex-col items-start'
            : 'px-3 md:px-5 lg:px-16 flex flex-col items-end'
        }
      >
        {message?.text && message?.images?.length > 0 && (
          <MessageTextAndMessage message={message} owner={owner} />
        )}
        {message?.text && !message?.images?.length > 0 && (
          <MessageOnlyText message={message} owner={owner} />
        )}
        {!message?.text && message?.images?.length > 0 && (
          <MessageOnlyImage message={message} owner={owner} />
        )}
        {message?.announcementId && (
          <MessageAnnouncement message={message} owner={owner} />
        )}
      </div>
    </div>
  )
}

export default Message
