import React from 'react'
import PassForm from '../../components/shops/PassForm'
import MainForm from '../../components/shops/MainForm'
import Nav from '../../components/layout/Nav'

export default function CreateShop() {
  const [status, setStatus] = React.useState('checking')

  return (
    <div>
      <Nav />
      <div className="pt-40 px-10 lg:px-96 flex flex-col">
        <h6 className="text-[25px] mb-3">Ajout de votre enseigne sur uty</h6>
        {status === 'checking' && <PassForm setStatus={setStatus} />}
        {status === 'checked' && <MainForm />}
      </div>
    </div>
  )
}
