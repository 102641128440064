import React, { useEffect, useState } from 'react'
import DataTableComponent from '../../datatable/DataTable'
import columns from '../../../helpers/admins/delivers/colums-show-requestrefused'
import { getRequestRefused } from '../../../services/delivers/get-request-refused'
import ModalVaalidate from '../modals/modal-validate-request'
import { validateRequestDeliver } from '../../../services/delivers/validate-deliver'
import ModalDetailDeliver from '../modals/modal-detail-deliver'

function RequestRefused({ active, refused }) {
  const [delivers, setDelivers] = useState([])

  const [loader, setLoader] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [user, setUser] = useState(null)
  const [isLoader, setIsLoader] = useState(0)
  const [showModalDetail, setShowModalDetail] = useState(false)

  const columnsConfig = columns(setShowModal, setShowModalDetail, setUser)

  useEffect(() => {
    getRequestRefused(setDelivers)
    setLoader(delivers)
  }, [isLoader, active])

  const handleValidate = () => {
    setShowModal(false)
    validateRequestDeliver(user?._id, isLoader, setIsLoader)
  }

  return (
    active === refused && (
      <div>
        <ModalVaalidate
          showModal={showModal}
          setShowModal={setShowModal}
          handleClick={handleValidate}
        />
        {/* This modal is showing the detail of deliver man */}
        <ModalDetailDeliver
          showModal={showModalDetail}
          setShowModal={setShowModalDetail}
        />
        <div className="pt-[60px] md:pt-0">
          <div className="card">
            <DataTableComponent
              data={delivers}
              columns={columnsConfig}
              name={'Démande'}
              loader={loader}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default RequestRefused
