import React from 'react'

function ButtonForm({ loadingButton }) {
  return (
    <div className="">
      <button className="bg-[#3b5998]  text-[15px] hover:bg-[#3b5998] focus:ring-4 focus:outline-none cursor-pointer py-2 md:py-2 px-8 md:px-10 mt-2 rounded-[4px] text-white">
        {loadingButton ? (
          <div className="">Operation...</div>
        ) : (
          <div className="">Valider</div>
        )}
      </button>
    </div>
  )
}

export default ButtonForm
