import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const joinSubcategoryToCategory = async (category, subcategory, isAdmin) => {
  const response = await axios.post(
    `${BASE_URL}/api/categories/joinsubcategory-tobcategory/${category}/${subcategory}`,
    {
      isAdmin,
    }
  )
  try {
    return response
  } catch (error) {
    console.log(error)
  }
}

export { joinSubcategoryToCategory }
