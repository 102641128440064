import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const getRequestDeliver = async (userId, setDeliver) => {
  console.log('user Id : ', userId)
  // setDeliver
  const response = await axios.get(
    `${BASE_URL}/api/delivers/get-deliver-by-userid/${userId}`
  )
  console.log('response : ', response)
  try {
    if (response?.status === 200) setDeliver(response?.data?.deliver)
  } catch (error) {
    console.error(error)
  }
  //   setDelivers(data?.data?.delivers)
}

export { getRequestDeliver }
